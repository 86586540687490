import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, OnInit, ViewEncapsulation
} from "@angular/core";
import { ModelServices } from "src/app/services/ModelServices";
import { TlWasherModelServices } from "src/app/services/TlWasherModel.service";
import { IBinaryModel, IPhaseName } from "./tl-washer-cyclecombination.component.model";

@Component({
  selector: "app-tl-washer-cyclecmobination",
  templateUrl: "./tl-washer-cyclecmobination.component.html",
  styleUrls: ["./tl-washer-cyclecmobination.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TlWasherCyclecmobinationComponent implements OnInit, AfterViewInit {
  options: any;
  modelNumber: string;
  isdisabled: boolean;
  myOptions = {
    placement: "top",
    "show-delay": "500",
    "tooltip-class": "tooltip",
  };
  SetOptions: any;
  selectedIndex: number = null;
  binaryModel: IBinaryModel[] = [];
  phaseName: IPhaseName[] = [];

  constructor(
    private modelServices: ModelServices,
    private cdr: ChangeDetectorRef,
    private _TlWasherModelServices: TlWasherModelServices
  ) { }

  ngOnInit() {
    this.modelServices.tlWasherSelectedModel.subscribe((modelNumber: any) => {
      this.modelNumber = modelNumber;
      this.selectedIndex = 0;
      this.modelServices.washerCycleData =
        JSON.parse(JSON.stringify(this._TlWasherModelServices.getWasherCycleList(this.modelNumber)));
      this.options = this.modelServices.washerCycleData.CycleName;
      this.SetOptions = this.options;
      this._TlWasherModelServices.prevSelectedCycle = this.SetOptions[0].tvalue;
      this._TlWasherModelServices.selectedCycleSettings = {
        cycleValue: this.SetOptions[0].tvalue,
        title: this.SetOptions[0].title,
        soilLevel: this.modelServices.washerCycleData.SoilLevel,
        temperature: this.modelServices.washerCycleData.Temperature
      }
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  setIndex(index: number, option: any) {
    this._TlWasherModelServices.selectedCycle.next({
      cycleValue: option.tvalue,
      title: option.title,
      soilLevel: this.modelServices.washerCycleData.SoilLevel,
      temperature: this.modelServices.washerCycleData.Temperature,
      prevSelectedCycle: this._TlWasherModelServices.prevSelectedCycle
    });
    this.selectedIndex = index;
  }
  
}