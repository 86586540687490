import { Injectable } from "@angular/core";
import * as FlWasherModel from "src/assets/JsonFiles/fl-washer/model.json";
import * as flWasherSetup from "src/assets/JsonFiles/fl-washer/setup.json";
import * as flWasherCycleVendingSettings from "src/assets/JsonFiles/fl-washer/cycle-vending-settings.json";
import { FlWasherList, ICoolTemperature, IRelationalEntityKey, IRelationalEnvelopeOpen, IValueCoin1, IVended } from "../shared/layout/fl-washer/fl-washer-cycle-vending/fl-washer-cycle-vending.model";
import { FlWasherSetup, FlWasherSetupDetail, FlWasherSetupList, IValue } from "../shared/layout/fl-washer/fl-washer-setup/fl-washer-setup.model";
import { IModelList } from "./ModelServices";
import { FlCycleData, ICoin1Value, ICycleDataList } from "../shared/layout/fl-washer/fl-washer.model";
import * as flWasherCycle from "src/assets/JsonFiles/fl-washer/cycles-data.json";
import * as flWasherCycleSetting from "src/assets/JsonFiles/fl-washer/cycle-settings.json";
import { Subject } from "rxjs";
import { FlWasherCycleSetting, FlWasherCycleSettingCycleName, FlWasherCycleSettingData, FlWasherCycleSettingList, FlWasherCycleSettingRinse, FlWasherCycleSettingValues, ICycleSettingDnldCycle, ICycleSettingValue } from "../shared/layout/fl-washer/fl-washer-cycle/fl-washer-cycle.model";
import { ICommonModel, ICylclesDataList } from "../shared/models/common";
import { CommonServices } from "./CommonServices";
import { Utilities } from "./UtliltyService";
import { IGetUserDefinedConfiguration } from "../shared/layout/user-defined-setup/user-defined-setup.model";

@Injectable({
  providedIn: "root",
})
export class FlWasherModelServices {
  public flWasherModel: IModelList = (FlWasherModel as any).default;
  flwashersetup: FlWasherSetupList = (flWasherSetup as any).default;
  vendingsettings: FlWasherList = (flWasherCycleVendingSettings as any).default;
  flWasherCycleSettings: FlWasherCycleSetting = (flWasherCycleSetting as any)
    .default;
  public combinationCycleList: FlCycleData = (flWasherCycle as any).default;
  isModelChange: boolean = false;
  selectedWasherCycle = new Subject<any>();
  selectedWasherCycleSettings: any;
  prevWasherSelectedCycle: string;
  mNumber: any;
  value: IValue = {
    id: 0,
    name: "",
  };
  public coin1Value: ICoin1Value = {
    cent: '',
    dollar: ''
  };

  regularOffSet: FlWasherSetupDetail = {
    value: 0,
    values: this.value,
    Default: 0,
    arrKey: [],
    binaryKey: "",
    type: "",
  };
  setupModelList: FlWasherSetup = {
    CycleCountingEnable: this.regularOffSet,
    MoneyCountingOption: this.regularOffSet,
    CountViewing: this.regularOffSet,
    VendingDisplay: this.regularOffSet,
    CoinPaymentEnable: this.regularOffSet,
    DebitCardPaymentProtocol: this.regularOffSet,
    AutoClearEscrowEnable: this.regularOffSet,
    CoinSlideEnable: this.regularOffSet,
    hasValue: false,
  };

  ValueCoin1: IValueCoin1 = {
    value: [],
    default: "",
    min: "",
    max: "",
    binaryKey: "",
    arrKey: [],
    type: "",
    dollars: "",
    cents: "",
  };

  relationalEnvelopeOpen: IRelationalEnvelopeOpen = {
    binaryKeyValue: "",
    arrKey: [],
    type: "",
  };

  relationalEntityKey: IRelationalEntityKey = {
    value: "",
    binaryKeyValue: "",
    arrKey: [],
    type: "",
  };

  vendingTemperature: ICoolTemperature = {
    value: [],
    default: "",
    min: "",
    max: "",
    binaryKey: "",
    arrKey: [],
    type: "",
    dollars: "",
    cents: "",
  };

  vendingSettingList: IVended = {
    Relational_Envelope_Open: this.relationalEnvelopeOpen,
    Relational_Envelope_Action: this.relationalEnvelopeOpen,
    Entity_Cycle_Open: this.relationalEnvelopeOpen,
    Relational_Entity_Name: this.relationalEntityKey,
    Relational_Entity_Id: this.relationalEnvelopeOpen,
    DnldCycle_CycleSet_CycleCavityPosition: this.relationalEnvelopeOpen,
    Relational_Array_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Close: this.relationalEnvelopeOpen,
    Entity_Cycle_Close: this.relationalEnvelopeOpen,
    Relational_Array_Close: this.relationalEnvelopeOpen,
    Relational_Envelope_Close: this.relationalEnvelopeOpen,
    ValueCoin1: this.ValueCoin1,
    ValueCoin2: this.ValueCoin1,
    CoolTemperature: this.vendingTemperature,
    WarmTemperature: this.vendingTemperature,
    HotTemperature: this.vendingTemperature,
    HeavySoil: this.vendingTemperature,
    ExtraRinseAdder: this.vendingTemperature,
    hasValue: false,
  };

  cycleSettingValue: ICycleSettingValue = {
    id: "",
    name: "",
  };

  cycleSettingValues: FlWasherCycleSettingValues = {
    value: "",
    default: "",
    values: this.cycleSettingValue,
    binaryKey: "",
    arrKey: [],
    type: "",
  };

  cycleSettingRinseValues: FlWasherCycleSettingRinse = {
    value: false,
    default: "",
    values: this.cycleSettingValue,
    binaryKey: "",
    arrKey: [],
    type: "",
  };

  cycleSettingDnldCycle: ICycleSettingDnldCycle = {
    value: [],
    default: "",
    dollars: "",
    cents: "",
    min: "",
    max: "",
    binaryKey: "",
    arrKey: [],
    type: "",
  };

  cycleSettingList: FlWasherCycleSettingList = {
    Relational_Envelope_Open: this.relationalEnvelopeOpen,
    Relational_Envelope_Action: this.relationalEnvelopeOpen,
    Entity_Cycle_Open: this.relationalEnvelopeOpen,
    Relational_Entity_Name: this.relationalEntityKey,
    Relational_Entity_Id: this.relationalEnvelopeOpen,
    DnldCycle_CycleSet_CycleCavityPosition: this.relationalEnvelopeOpen,
    DnldCycle_PaySet_CycleRegularPrice: this.cycleSettingDnldCycle,
    DnldCycle_PaySet_CycleSpecialPrice: this.cycleSettingDnldCycle,
    DnldCycle_OpSet_Temperature: this.cycleSettingValues,
    Relational_Array_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Close: this.relationalEnvelopeOpen,
    Relational_Array_Close: this.relationalEnvelopeOpen,
    Entity_Cycle_Close: this.relationalEnvelopeOpen,
    Relational_Envelope_Close: this.relationalEnvelopeOpen,
    DnldCycle_OpSet_ExtraRinse: this.cycleSettingRinseValues,
    hasValue: false,
  };

  CycleSettingCycleName: FlWasherCycleSettingCycleName[] = [
    {
      [""]: this.cycleSettingList,
    },
  ];

  CycleSettingModel: FlWasherCycleSettingData = {
    [""]: this.CycleSettingCycleName,
  };

  arrFlWasherSetupConfigSettings: any[] = [];
  arrFlWasherCycleVendingConfigSettings: any[] = [];
  arrFlWasherCycleSettingConfigSettings: any[] = [];
  cycleData: ICylclesDataList = {
    Relational_Entity_Name: '',
    Relational_Entity_Id: 0,
    DnldCycle_OpSet_Temperature: '',
    CycleSpecialPrice: [],
    CycleRegularPrice: [],
    DnldCycle_OpSet_ExtraRinse: ''
  };
  prevCoinValue: string = '';

  constructor(
    private utilityService: Utilities,
    private commonService: CommonServices
  ) {
    this.commonService.setBinaryFlWasher.subscribe((item: ICommonModel) => {
      this.setBinarySetupModels(item);
    })
    this.commonService.isFlWasherModel.subscribe((item: IGetUserDefinedConfiguration) => {
      const setupModelList = JSON.parse(item.setupConfig);
      const vendConf = JSON.parse(item.vendingConfig);
      const cycleConf = JSON.parse(item.cycleConfig);    
      if (setupModelList.hasValue) {
        this.setModelSetupModels(setupModelList);
      }
      if (vendConf.hasValue) {
        this.setModelCycleVending(vendConf);
      }
      if (cycleConf) {
        this.setModelsCycleSettingModels(cycleConf);
      }
    });
  }

  getFlWasherModelList(): IModelList {
    return this.flWasherModel;
  }

  setSetupModels(setupList: FlWasherSetup) {
    this.isModelChange = false;
    this.setupModelList = setupList;
  }

  getSetupModels() {
    return this.setupModelList;
  }

  getFlWasherSetupSettings(modelType: string) {
    this.isModelChange = false;
    if (modelType === "PD") {
      return this.flwashersetup.setup.PD;
    } else if (modelType === "PR") {
      return this.flwashersetup.setup.PR;
    } else if (modelType === "258J") {
      return this.flwashersetup.setup["258J"];
    } else if (modelType === "268J") {
      return this.flwashersetup.setup["268J"];
    }
  }

  getCombinationCycleList() {
    return this.combinationCycleList;
  }

  getWasherCycleList(modelNumber: any): ICycleDataList {
    this.mNumber = modelNumber;
    let isFLWasherReq = false;
    const modelVal = this.mNumber.substr(4, 4);
    const cycleType = localStorage.getItem("SelectedMenu") == "FL-Washer" ? true : false;
    if (cycleType && (modelVal === "258J" || modelVal === "268J")) {
      isFLWasherReq = true;
    }
    const type = this.getProductType(modelNumber, isFLWasherReq);
    switch (type) {
      case "PR":
        return this.combinationCycleList.Cycles.PR;
      case "PD":
        return this.combinationCycleList.Cycles.PD;
      case "258J":
        return this.combinationCycleList.Cycles["258J"];
      case "268J":
        return this.combinationCycleList.Cycles["268J"];
    }
  }
  getProductType(modelNumber: any, isFLWasherReq?: boolean) {
    if (isFLWasherReq) {
      this.flWasherModel.ProductType = modelNumber.substr(4, 4);
      return this.flWasherModel.ProductType;
    }
    var weight = modelNumber.substr(3, 3);
    var productType = null;
    if (isNaN(+weight)) productType = modelNumber.substr(5, 2);
    else productType = modelNumber.substr(6, 2);
    this.flWasherModel.ProductType = productType;
    return productType;
  }

  clearCycleSettingModels(modelNumber: string, modelType: string) {
    const cycles = this.getWasherCycleList(modelNumber);
    this.CycleSettingCycleName = this.getFlWasherCycleSettings(modelType);
    for (let index = 0; index < cycles.CycleName.length; index++) {
      this.cycleSettingList =
        this.CycleSettingCycleName[cycles.CycleName[index].tvalue];
      this.cycleSettingList.Relational_Entity_Name.value = "";
      this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = "0";
      this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = "0";
      this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = "0";
      this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = "0";
      this.cycleSettingList.DnldCycle_OpSet_Temperature.value = "";
      this.cycleSettingList.DnldCycle_OpSet_ExtraRinse.value = false;
      this.cycleSettingList.hasValue = false;
      this.isModelChange = true;
    }
  }

  setCycleSettingModels(commonModel: ICommonModel) {
    let splBytes;
    let regBytes;
    const modelType = localStorage.getItem("ProductType");
    for (let index = 0; index < commonModel.CyclesDataList.length; index++) {
      this.cycleData = JSON.parse(commonModel.CyclesDataList[index]);
      this.cycleSettingList = this.CycleSettingCycleName[this.cycleData.Relational_Entity_Name.replace(/\s/g, "").toUpperCase()];
      if (!this.cycleSettingList) {
        continue;
    }
      this.cycleSettingList.Relational_Entity_Name.value = this.cycleData.Relational_Entity_Name;
      if (modelType != "258J" && modelType != "268J") {
        splBytes = this.utilityService.convertBinToDecArry(this.cycleData.CycleSpecialPrice[0], this.cycleData.CycleSpecialPrice[1]);
        this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = splBytes[0];
        this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = splBytes[1];
      }
      regBytes = this.utilityService.convertBinToDecArry(this.cycleData.CycleRegularPrice[0], this.cycleData.CycleRegularPrice[1]);
      this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = regBytes[0];
      this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = regBytes[1];
      this.cycleSettingList.DnldCycle_OpSet_Temperature.value = this.cycleData.DnldCycle_OpSet_Temperature;
      this.cycleSettingList.DnldCycle_OpSet_ExtraRinse.value = this.cycleData.DnldCycle_OpSet_ExtraRinse == '1' ? true : false;
      this.cycleSettingList.hasValue = true;
      this.commonService.getClose();
    }
  }

  setModelsCycleSettingModels(commonModel: any) {
    const modelType = localStorage.getItem("ProductType");
    let result = Object.keys(commonModel).map((key) => commonModel[key]);
    for (let index = 0; index < commonModel.CyclesDataList.length; index++) {
      const cycleData = result[index];
      const relational_Entity_Name: string = cycleData.Relational_Entity_Name.value.replace(/\s/g, "");
      this.cycleSettingList = this.CycleSettingCycleName[relational_Entity_Name];
      this.cycleSettingList.Relational_Entity_Name.value = cycleData.Relational_Entity_Name.value;
      if (modelType != "258J" && modelType != "268J") {
        this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = cycleData.commonModelDnldCycle_PaySet_CycleSpecialPrice.value[0];
        this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = cycleData.DnldCycle_PaySet_CycleSpecialPrice.value[1];
      }
      this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = cycleData.DnldCycle_PaySet_CycleRegularPrice.value[0];
      this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = cycleData.DnldCycle_PaySet_CycleRegularPrice.value[1];
      this.cycleSettingList.DnldCycle_OpSet_Temperature.value = cycleData.DnldCycle_OpSet_Temperature.value;
      this.cycleSettingList.DnldCycle_OpSet_ExtraRinse.value = cycleData.DnldCycle_OpSet_ExtraRinse.value;
      this.cycleSettingList.hasValue = true;
    }
  }

  clearSetupModels() {
    this.setupModelList.CycleCountingEnable.value = 0;
    this.setupModelList.MoneyCountingOption.value = 0;
    this.setupModelList.CountViewing.value = 1;
    this.setupModelList.VendingDisplay.value = 0;
    this.setupModelList.CoinPaymentEnable.value = 0;
    this.setupModelList.DebitCardPaymentProtocol.value = 0;
    this.setupModelList.AutoClearEscrowEnable.value = 0;
    this.setupModelList.CoinSlideEnable.value = 0;
    this.setupModelList.hasValue = false;
    this.isModelChange = true;
    return this.setupModelList;
  }

  setBinarySetupModels(commonModel: ICommonModel) {
    this.setupModelList = this.getFlWasherSetupSettings(localStorage.getItem("ProductType"));
    this.setupModelList.CycleCountingEnable.value = commonModel.CycleCountingEnable ? commonModel.CycleCountingEnable : 0;
    this.setupModelList.MoneyCountingOption.value = commonModel.MoneyCountingOption ? commonModel.MoneyCountingOption : 0;
    this.setupModelList.CountViewing.value = commonModel.CountViewing ? commonModel.CountViewing : 0;
    this.setupModelList.VendingDisplay.value = commonModel.VendingDisplay ? commonModel.VendingDisplay : 0;
    this.setupModelList.CoinPaymentEnable.value = commonModel.CoinPaymentEnable ? commonModel.CoinPaymentEnable : 0;
    this.setupModelList.DebitCardPaymentProtocol.value = commonModel.DebitCardPaymentProtocol ? commonModel.DebitCardPaymentProtocol : 0;
    this.setupModelList.AutoClearEscrowEnable.value = commonModel.AutoClearEscrowEnable ? commonModel.AutoClearEscrowEnable : 0;
    this.setupModelList.CoinSlideEnable.value = commonModel.CoinSlideEnable ? commonModel.CoinSlideEnable : 0;
    this.setupModelList.hasValue = true;
    this.isModelChange = false;
    this.commonService.getClose();
    this.setCycleSettingModels(commonModel);
    this.setBinaryCycleVending(commonModel);
  }

  setModelSetupModels(setupModelList: any) {
    this.setupModelList = this.getFlWasherSetupSettings(localStorage.getItem("ProductType"));
    this.setupModelList.CycleCountingEnable.value = setupModelList.CycleCountingEnable.value;
    this.setupModelList.MoneyCountingOption.value = setupModelList.MoneyCountingOption.value;
    this.setupModelList.CountViewing.value = setupModelList.CountViewing.value;
    this.setupModelList.VendingDisplay.value = setupModelList.VendingDisplay.value;
    this.setupModelList.CoinPaymentEnable.value = setupModelList.CoinPaymentEnable.value;
    this.setupModelList.DebitCardPaymentProtocol.value = setupModelList.DebitCardPaymentProtocol.value;
    this.setupModelList.AutoClearEscrowEnable.value = setupModelList.AutoClearEscrowEnable.value;
    this.setupModelList.CoinSlideEnable.value = setupModelList.CoinSlideEnable.value;
    this.setupModelList.hasValue = true;
    this.isModelChange = false;
  }

  getFLWasherCycleVendingModels() {
    return this.vendingSettingList;
  }

  getFlWasherCycleVendingSettings(modelType: string) {
    this.isModelChange = false;
    if (modelType === "PD") {
      return this.vendingsettings.vended.PD;
    } else if (modelType === "PR") {
      return this.vendingsettings.vended.PR;
    } else if (modelType === "258J") {
      return this.vendingsettings.vended["258J"];
    } else if (modelType === "268J") {
      return this.vendingsettings.vended["268J"];
    }
  }

  getFlWasherCycleSettings(modelType: string): FlWasherCycleSettingCycleName[] {
    this.isModelChange = false;
    if (modelType === "PD") {
      return this.flWasherCycleSettings.cycleSetting["PD"];
    } else if (modelType === "PR") {
      return this.flWasherCycleSettings.cycleSetting["PR"];
    } else if (modelType === "258J") {
      return this.flWasherCycleSettings.cycleSetting["258J"];
    } else if (modelType === "268J") {
      return this.flWasherCycleSettings.cycleSetting["268J"];
    }
  }

  setTlWasherCycleSettingModelList(cycleSettingList: FlWasherCycleSettingList) {
    this.isModelChange = false;
    this.cycleSettingList = cycleSettingList;
  }

  setFlWasherCycleVendingSModels(venusCycleModel: IVended) {
    this.isModelChange = false;
    this.vendingSettingList = venusCycleModel;
  }

  getFlWasherCycleVendingSModels() {
    return this.vendingSettingList;
  }

  clearFlWasherCycleVendingModels() {
    this.vendingSettingList.CoolTemperature.value[0] = '0';
    this.vendingSettingList.CoolTemperature.value[1] = '0';

    this.vendingSettingList.WarmTemperature.value[0] = '0';
    this.vendingSettingList.WarmTemperature.value[1] = '0';

    this.vendingSettingList.HotTemperature.value[0] = '0';
    this.vendingSettingList.HotTemperature.value[1] = '0';

    this.vendingSettingList.HeavySoil.value[0] = '0';
    this.vendingSettingList.HeavySoil.value[1] = '0';

    this.vendingSettingList.ExtraRinseAdder.value[0] = '0';
    this.vendingSettingList.ExtraRinseAdder.value[1] = '0';
    this.vendingSettingList.ValueCoin1.value[0] = "0";
    this.vendingSettingList.ValueCoin1.value[1] = "0";
    this.vendingSettingList.ValueCoin2.value[0] = "0";
    this.vendingSettingList.ValueCoin2.value[1] = "0";
    this.vendingSettingList.hasValue = false;
    this.isModelChange = true;
    return this.vendingSettingList;
  }

  setBinaryCycleVending(commonModel: ICommonModel) {
    let twoBytes;
    this.vendingSettingList = this.getFlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
    const modelType = localStorage.getItem("ProductType");
    if (modelType != "258J" && modelType != "268J") {
      twoBytes = this.utilityService.convertBinToDecArry(commonModel.CoolTemperature[0], commonModel.CoolTemperature[1]);
      this.vendingSettingList.CoolTemperature.value[0] = twoBytes[0];
      this.vendingSettingList.CoolTemperature.value[1] = twoBytes[1];

      twoBytes = this.utilityService.convertBinToDecArry(commonModel.WarmTemperature[0], commonModel.WarmTemperature[1]);
      this.vendingSettingList.WarmTemperature.value[0] = twoBytes[0];
      this.vendingSettingList.WarmTemperature.value[1] = twoBytes[1];

      twoBytes = this.utilityService.convertBinToDecArry(commonModel.HotTemperature[0], commonModel.HotTemperature[1]);
      this.vendingSettingList.HotTemperature.value[0] = twoBytes[0];
      this.vendingSettingList.HotTemperature.value[1] = twoBytes[1];

      twoBytes = this.utilityService.convertBinToDecArry(commonModel.HeavySoil[0], commonModel.HeavySoil[1]);
      this.vendingSettingList.HeavySoil.value[0] = twoBytes[0];
      this.vendingSettingList.HeavySoil.value[1] = twoBytes[1];

      twoBytes = this.utilityService.convertBinToDecArry(commonModel.ExtraRinseAdder[0], commonModel.ExtraRinseAdder[1]);
      this.vendingSettingList.ExtraRinseAdder.value[0] = twoBytes[0];
      this.vendingSettingList.ExtraRinseAdder.value[1] = twoBytes[1];
    }

    twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin1[0], commonModel.ValueCoin1[1]);
    this.vendingSettingList.ValueCoin1.value[0] = twoBytes[0];
    this.vendingSettingList.ValueCoin1.value[1] = twoBytes[1];
    this.coin1Value.dollar = twoBytes[0];
    this.coin1Value.cent = twoBytes[1];
    twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin2[0], commonModel.ValueCoin2[1]);
    this.vendingSettingList.ValueCoin2.value[0] = twoBytes[0];
    this.vendingSettingList.ValueCoin2.value[1] = twoBytes[1];
    this.vendingSettingList.hasValue = true;
    this.isModelChange = false;
    this.commonService.getClose();
  }

  setModelCycleVending(commonModel: any) {
    this.vendingSettingList = this.getFlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
    const modelType = localStorage.getItem("ProductType");
    if (modelType != "258J" && modelType != "268J") {
      this.vendingSettingList.CoolTemperature.value[0] = commonModel.CoolTemperature.value[0];
      this.vendingSettingList.CoolTemperature.value[1] = commonModel.CoolTemperature.value[1];

      this.vendingSettingList.WarmTemperature.value[0] = commonModel.WarmTemperature.value[0];
      this.vendingSettingList.WarmTemperature.value[1] = commonModel.WarmTemperature.value[1];

      this.vendingSettingList.HotTemperature.value[0] = commonModel.HotTemperature.value[0];
      this.vendingSettingList.HotTemperature.value[1] = commonModel.HotTemperature.value[1];

      this.vendingSettingList.HeavySoil.value[0] = commonModel.HeavySoil.value[0];
      this.vendingSettingList.HeavySoil.value[1] = commonModel.HeavySoil.value[1];

      this.vendingSettingList.ExtraRinseAdder.value[0] = commonModel.ExtraRinseAdder.value[0];
      this.vendingSettingList.ExtraRinseAdder.value[1] = commonModel.ExtraRinseAdder.value[1];
    }
    this.vendingSettingList.ValueCoin1.value[0] = commonModel.ValueCoin1.value[0];
    this.vendingSettingList.ValueCoin1.value[1] = commonModel.ValueCoin1.value[1];

    this.vendingSettingList.ValueCoin2.value[0] = commonModel.ValueCoin2.value[0];
    this.vendingSettingList.ValueCoin2.value[1] = commonModel.ValueCoin2.value[1];
    this.vendingSettingList.hasValue = true;
  }

  modelChanged() {
    return this.isModelChange;
  }

  mapSetupBinaryKey() {
    this.arrFlWasherSetupConfigSettings = [];
    const TlDryerSetup = this.getFlWasherSetupSettings(
      localStorage.getItem("ProductType")
    );
    if (TlDryerSetup) {
      if (TlDryerSetup.hasValue) {
        TlDryerSetup.CycleCountingEnable.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.CycleCountingEnable.value
        );

        TlDryerSetup.MoneyCountingOption.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.MoneyCountingOption.value
        );
        TlDryerSetup.CountViewing.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.CountViewing.value
        );
        TlDryerSetup.VendingDisplay.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.VendingDisplay.value
        );
        TlDryerSetup.CoinPaymentEnable.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.CoinPaymentEnable.value
        );
        TlDryerSetup.DebitCardPaymentProtocol.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.DebitCardPaymentProtocol.value
        );
        TlDryerSetup.AutoClearEscrowEnable.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.AutoClearEscrowEnable.value
        );
        TlDryerSetup.CoinSlideEnable.arrKey.map(item => {
          this.arrFlWasherSetupConfigSettings.push(item);
        })
        this.arrFlWasherSetupConfigSettings.push(TlDryerSetup.CoinSlideEnable.value);
      } else {
        TlDryerSetup.CycleCountingEnable.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.CycleCountingEnable.Default
        );

        TlDryerSetup.MoneyCountingOption.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.MoneyCountingOption.Default
        );
        TlDryerSetup.CountViewing.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.CountViewing.Default
        );
        TlDryerSetup.VendingDisplay.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.VendingDisplay.Default
        );
        TlDryerSetup.CoinPaymentEnable.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.CoinPaymentEnable.Default
        );
        TlDryerSetup.DebitCardPaymentProtocol.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.DebitCardPaymentProtocol.Default
        );
        TlDryerSetup.AutoClearEscrowEnable.arrKey.map((item) => {
          this.arrFlWasherSetupConfigSettings.push(item);
        });
        this.arrFlWasherSetupConfigSettings.push(
          TlDryerSetup.AutoClearEscrowEnable.Default
        );
        TlDryerSetup.CoinSlideEnable.arrKey.map(item => {
          this.arrFlWasherSetupConfigSettings.push(item);
        })
        this.arrFlWasherSetupConfigSettings.push(TlDryerSetup.CoinSlideEnable.Default);
      }
    }
    return this.arrFlWasherSetupConfigSettings;
  }

  mapCycleVendingBinaryKey() {
    this.arrFlWasherCycleVendingConfigSettings = [];
    const modelType = localStorage.getItem("ProductType");
    const cycleVending = this.getFlWasherCycleVendingSettings(
      localStorage.getItem("ProductType")
    );
    if (cycleVending) {
      if (cycleVending.hasValue) {
        cycleVending.ValueCoin1.arrKey.map((item) => {
          this.arrFlWasherCycleVendingConfigSettings.push(item);
        });
        this.calculatePrice(
          cycleVending.ValueCoin1.value[0],
          cycleVending.ValueCoin1.value[1],
          cycleVending.ValueCoin1.type,
          this.arrFlWasherCycleVendingConfigSettings
        );
        cycleVending.ValueCoin2.arrKey.map((item) => {
          this.arrFlWasherCycleVendingConfigSettings.push(item);
        });
        this.calculatePrice(
          cycleVending.ValueCoin2.value[0],
          cycleVending.ValueCoin2.value[1],
          cycleVending.ValueCoin2.type,
          this.arrFlWasherCycleVendingConfigSettings
        );

        if (modelType != "258J" && modelType != "268J") {
          cycleVending.CoolTemperature.arrKey.map((item) => {
            this.arrFlWasherCycleVendingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.CoolTemperature.value[0],
            cycleVending.CoolTemperature.value[1],
            cycleVending.CoolTemperature.type,
            this.arrFlWasherCycleVendingConfigSettings
          );

          cycleVending.WarmTemperature.arrKey.map((item) => {
            this.arrFlWasherCycleVendingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.WarmTemperature.value[0],
            cycleVending.WarmTemperature.value[1],
            cycleVending.WarmTemperature.type,
            this.arrFlWasherCycleVendingConfigSettings
          );

          cycleVending.HotTemperature.arrKey.map((item) => {
            this.arrFlWasherCycleVendingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.HotTemperature.value[0],
            cycleVending.HotTemperature.value[1],
            cycleVending.HotTemperature.type,
            this.arrFlWasherCycleVendingConfigSettings
          );

          cycleVending.HeavySoil.arrKey.map((item) => {
            this.arrFlWasherCycleVendingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.HeavySoil.value[0],
            cycleVending.HeavySoil.value[1],
            cycleVending.HeavySoil.type,
            this.arrFlWasherCycleVendingConfigSettings
          );

          cycleVending.ExtraRinseAdder.arrKey.map((item) => {
            this.arrFlWasherCycleVendingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.ExtraRinseAdder.value[0],
            cycleVending.ExtraRinseAdder.value[1],
            cycleVending.ExtraRinseAdder.type,
            this.arrFlWasherCycleVendingConfigSettings
          );
        }
      } else {
        cycleVending.ValueCoin1.arrKey.map((item) => {
          this.arrFlWasherCycleVendingConfigSettings.push(item);
        });
        this.calculatePrice(
          cycleVending.ValueCoin1.dollars,
          cycleVending.ValueCoin1.cents,
          cycleVending.ValueCoin1.type,
          this.arrFlWasherCycleVendingConfigSettings
        );
        cycleVending.ValueCoin2.arrKey.map((item) => {
          this.arrFlWasherCycleVendingConfigSettings.push(item);
        });
        this.calculatePrice(
          cycleVending.ValueCoin2.dollars,
          cycleVending.ValueCoin2.cents,
          cycleVending.ValueCoin2.type,
          this.arrFlWasherCycleVendingConfigSettings
        );
        if (modelType != "258J" && modelType != "268J") {
          cycleVending.CoolTemperature.arrKey.map((item) => {
            this.arrFlWasherCycleVendingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.CoolTemperature.dollars,
            cycleVending.CoolTemperature.cents,
            cycleVending.CoolTemperature.type,
            this.arrFlWasherCycleVendingConfigSettings
          );

          cycleVending.WarmTemperature.arrKey.map((item) => {
            this.arrFlWasherCycleVendingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.WarmTemperature.dollars,
            cycleVending.WarmTemperature.cents,
            cycleVending.WarmTemperature.type,
            this.arrFlWasherCycleVendingConfigSettings
          );

          cycleVending.HotTemperature.arrKey.map((item) => {
            this.arrFlWasherCycleVendingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.HotTemperature.dollars,
            cycleVending.HotTemperature.cents,
            cycleVending.HotTemperature.type,
            this.arrFlWasherCycleVendingConfigSettings
          );

          cycleVending.HeavySoil.arrKey.map((item) => {
            this.arrFlWasherCycleVendingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.HeavySoil.dollars,
            cycleVending.HeavySoil.cents,
            cycleVending.HeavySoil.type,
            this.arrFlWasherCycleVendingConfigSettings
          );

          cycleVending.ExtraRinseAdder.arrKey.map((item) => {
            this.arrFlWasherCycleVendingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.ExtraRinseAdder.dollars,
            cycleVending.ExtraRinseAdder.cents,
            cycleVending.ExtraRinseAdder.type,
            this.arrFlWasherCycleVendingConfigSettings
          );
        }
      }
    }
    return this.arrFlWasherCycleVendingConfigSettings;
  }

  mapCycleSettingBinaryKey() {
    this.arrFlWasherCycleSettingConfigSettings = [];
    const modelType = localStorage.getItem("ProductType");
    const cycles = this.getWasherCycleList(localStorage.getItem("modelNumber"));
    this.CycleSettingCycleName = this.getFlWasherCycleSettings(
      localStorage.getItem("ProductType")
    );
    if (!this.CycleSettingCycleName) {
      return;
    }
    for (let index = 0; index < cycles.CycleName.length; index++) {
      const cycleVending =
        this.CycleSettingCycleName[cycles.CycleName[index].tvalue];
      cycleVending.Relational_Envelope_Open.arrKey.map((item) => {
        this.arrFlWasherCycleSettingConfigSettings.push(item);
      });
      cycleVending.Relational_Envelope_Action.arrKey.map((item) => {
        this.arrFlWasherCycleSettingConfigSettings.push(item);
      });
      if (cycleVending.hasValue) {
        cycleVending.Entity_Cycle_Open.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        cycleVending.Relational_Entity_Name.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        this.calculateBytes(
          cycleVending.Relational_Entity_Name.value.replace(/\s/g, ""),
          cycleVending.Relational_Entity_Name.type,
          this.arrFlWasherCycleSettingConfigSettings
        );
        // this.arrFlWasherCycleSettingConfigSettings.push("0");
        cycleVending.Relational_Entity_Id.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        const count = index + 1;
        this.arrFlWasherCycleSettingConfigSettings.push(count);
        cycleVending.DnldCycle_CycleSet_CycleCavityPosition.arrKey.map(
          (item) => {
            this.arrFlWasherCycleSettingConfigSettings.push(item);
          }
        );
        cycleVending.DnldCycle_PaySet_CycleRegularPrice.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        this.calculatePrice(
          cycleVending.DnldCycle_PaySet_CycleRegularPrice.value[0],
          cycleVending.DnldCycle_PaySet_CycleRegularPrice.value[1],
          cycleVending.DnldCycle_PaySet_CycleRegularPrice.type,
          this.arrFlWasherCycleSettingConfigSettings
        );
        if (modelType != "258J" && modelType != "268J") {
          cycleVending.DnldCycle_PaySet_CycleSpecialPrice.arrKey.map((item) => {
            this.arrFlWasherCycleSettingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.DnldCycle_PaySet_CycleSpecialPrice.value[0],
            cycleVending.DnldCycle_PaySet_CycleSpecialPrice.value[1],
            cycleVending.DnldCycle_PaySet_CycleSpecialPrice.type,
            this.arrFlWasherCycleSettingConfigSettings
          );
        }
        cycleVending.DnldCycle_OpSet_Temperature.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        this.arrFlWasherCycleSettingConfigSettings.push(
          cycleVending.DnldCycle_OpSet_Temperature.value
        );
        cycleVending.DnldCycle_OpSet_ExtraRinse.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        const rinse = cycleVending.DnldCycle_OpSet_ExtraRinse.value == true ? "1" : "0";
        this.arrFlWasherCycleSettingConfigSettings.push(rinse);
        cycleVending.Relational_Array_Open.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        cycleVending.Entity_Cycle_Part1_Open.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        cycleVending.Entity_Cycle_Part1_Close.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        cycleVending.Relational_Array_Close.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        cycleVending.Entity_Cycle_Close.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });

      } else {
        cycleVending.Entity_Cycle_Open.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        cycleVending.Relational_Entity_Name.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        this.calculateBytes(
          cycles.CycleName[index].tvalue,
          cycleVending.Relational_Entity_Name.type,
          this.arrFlWasherCycleSettingConfigSettings
        );
        // this.arrFlWasherCycleSettingConfigSettings.push("0");
        cycleVending.Relational_Entity_Id.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        const count = index + 1;
        this.arrFlWasherCycleSettingConfigSettings.push(count);
        cycleVending.DnldCycle_CycleSet_CycleCavityPosition.arrKey.map(
          (item) => {
            this.arrFlWasherCycleSettingConfigSettings.push(item);
          }
        );
        cycleVending.DnldCycle_PaySet_CycleRegularPrice.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        this.calculatePrice(
          cycleVending.DnldCycle_PaySet_CycleRegularPrice.dollars,
          cycleVending.DnldCycle_PaySet_CycleRegularPrice.cents,
          cycleVending.DnldCycle_PaySet_CycleRegularPrice.type,
          this.arrFlWasherCycleSettingConfigSettings
        );
        if (modelType != "258J" && modelType != "268J") {
          cycleVending.DnldCycle_PaySet_CycleSpecialPrice.arrKey.map((item) => {
            this.arrFlWasherCycleSettingConfigSettings.push(item);
          });
          this.calculatePrice(
            cycleVending.DnldCycle_PaySet_CycleSpecialPrice.dollars,
            cycleVending.DnldCycle_PaySet_CycleSpecialPrice.cents,
            cycleVending.DnldCycle_PaySet_CycleSpecialPrice.type,
            this.arrFlWasherCycleSettingConfigSettings
          );
        }
        cycleVending.DnldCycle_OpSet_Temperature.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        this.arrFlWasherCycleSettingConfigSettings.push(
          cycleVending.DnldCycle_OpSet_Temperature.default
        );
        cycleVending.DnldCycle_OpSet_ExtraRinse.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        this.arrFlWasherCycleSettingConfigSettings.push(
          cycleVending.DnldCycle_OpSet_ExtraRinse.default
        );
        cycleVending.Relational_Array_Open.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        cycleVending.Entity_Cycle_Part1_Open.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        cycleVending.Entity_Cycle_Part1_Close.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        cycleVending.Relational_Array_Close.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });
        cycleVending.Entity_Cycle_Close.arrKey.map((item) => {
          this.arrFlWasherCycleSettingConfigSettings.push(item);
        });

      }
      cycleVending.Relational_Envelope_Close.arrKey.map((item) => {
        this.arrFlWasherCycleSettingConfigSettings.push(item);
      });
    }
    return this.arrFlWasherCycleSettingConfigSettings;
  }

  calculateBytes(inputNum: string | number, type: string, arr: any[]): any[] {
    switch (type.toLowerCase()) {
      case "u16":
        let firstByte = 0,
          secondByte = 0;
        secondByte = Number(inputNum) & 0x00ff;
        firstByte = (Number(inputNum) & 0xff00) >> 8;
        arr.push(firstByte);
        arr.push(secondByte);
        return arr;
      case "string":
        var hArray = inputNum.toString();
        for (var j = 0; j < hArray.length; j++) {
          arr.push(hArray[j].charCodeAt(0).toString(10));
        }
        arr.push("0");
        return arr;
      case "u8":
        arr.push(inputNum);
        return arr;
    }
  }

  calculatePrice(
    dollar: string,
    cents: string,
    type: string,
    arr: any[]
  ): any[] {
    let price = Number(dollar) * 100 + Number(cents);
    return this.calculateBytes(price, type, arr);
  }

  setCoinPrice() {
    const modeltype = localStorage.getItem("ProductType");
    const vendingSettings = this.getFlWasherCycleVendingSettings(modeltype);
    if (!vendingSettings.ValueCoin1.value.length || (vendingSettings.ValueCoin1.value[0] == '0' && vendingSettings.ValueCoin1.value[1] == '0')) {
      this.coin1Value.dollar = vendingSettings.ValueCoin1.dollars;
      this.coin1Value.cent = vendingSettings.ValueCoin1.cents;
    } else {
      this.coin1Value.dollar = vendingSettings.ValueCoin1.value[0];
      this.coin1Value.cent = vendingSettings.ValueCoin1.value[1];
    }
  }

  setCyclesPrice(isCallDefault: boolean, coinValue?: string): boolean {
    const modeltype = localStorage.getItem("ProductType");
    const cycles = this.getWasherCycleList(localStorage.getItem("modelNumber"));
    this.CycleSettingCycleName = this.getFlWasherCycleSettings(modeltype);
    let isValidPrice = true;
    for (let index = 0; index < cycles.CycleName.length; index++) {
      this.cycleSettingList = this.CycleSettingCycleName[cycles.CycleName[index].tvalue];
      const isValid = this.priceCalc(this.cycleSettingList, cycles.CycleName[index].title, isCallDefault, coinValue);
      if (isValid == false) {
        isValidPrice = false;
        break;
      }
    }
    return isValidPrice;
  }

  priceCalc(cycleSettingList: FlWasherCycleSettingList, cycleName: string, isCallDefault: boolean, coinValue: string) {
    const dollar = this.coin1Value.dollar == '' ? '0' : this.coin1Value.dollar;
    const cents = this.coin1Value.cent == '' ? '0' : this.coin1Value.cent;
    const price = (+dollar * 100) + +cents;
    let regPriceCalc: number;
    let splPriceCalc: number;
    let prevValue: boolean;
    if (!isCallDefault) {
      prevValue = true;
    } else {
      prevValue = +(this.prevCoinValue) < +(coinValue);
    }
    if (+(cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0]) == 50 && +(this.prevCoinValue) < +(coinValue)) {
      return false;
    }
    if (cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] == '0' && cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] == '0' && !isCallDefault) {
      regPriceCalc = (+cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.dollars * 100) + +cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.cents;
      splPriceCalc = (+cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.dollars * 100) + +cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.cents;
      this.setDefault(cycleSettingList, cycleName);
    } else {
      regPriceCalc = (+cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] * 100) + +cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1];
      splPriceCalc = (+cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] * 100) + +cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1];
    }
    this.setRegPriceValue(cycleSettingList, price, regPriceCalc, prevValue);
    if (localStorage.getItem("ProductType") != '9J') {
      this.setSplPriceValue(cycleSettingList, price, splPriceCalc, prevValue);
    }
  }

  setRegPriceValue(cycleSettingList: FlWasherCycleSettingList, price: number, priceCalc: number, prevValue: boolean) {
    let calRegPrice = 0;
    let i = 1;
    let isBreakCond: boolean = false;
    if (price) {
      while (priceCalc > calRegPrice) {
        if (priceCalc == calRegPrice) {
          isBreakCond = true;
          break;
        }
        calRegPrice = price * i;
        i = i + 1;
      }
      if (prevValue == false && priceCalc != calRegPrice) {
        calRegPrice = calRegPrice - price;
      }
      if (calRegPrice > 5000) {
        calRegPrice = calRegPrice - price;
      }
      const regValue = this.dollarConverts(calRegPrice);
      cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = regValue[0].toString();
      cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = regValue[1].toString();
      cycleSettingList.hasValue = true;
      return;
    }
    cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = '0';
    cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = '0';
    cycleSettingList.hasValue = true;

  }

  setSplPriceValue(cycleSettingList: FlWasherCycleSettingList, price: number, priceCalc: number, prevValue: boolean) {
    let calRegPrice = 0;
    let i = 1;
    let isBreakCond: boolean = false;
    if (price) {
      while (priceCalc > calRegPrice) {
        if (priceCalc == calRegPrice) {
          isBreakCond = true;
          break;
        }
        calRegPrice = price * i;
        i = i + 1;
      }
      if (prevValue == false && priceCalc != calRegPrice) {
        calRegPrice = calRegPrice - price;
      }
      if (calRegPrice > 5000) {
        calRegPrice = calRegPrice - price;
      }
      const regValue = this.dollarConverts(calRegPrice);
      cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = regValue[0].toString();
      cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = regValue[1].toString();
      return;
    }
    cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = '0';
    cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = '0';
  }

  dollarConverts(c) {
    var nCents = c;
    var nDollar = (nCents / 100);
    var rDollar = Math.floor(nDollar);
    var cents = (nDollar - rDollar) * 100;
    var rCents = Math.round(cents);
    return [rDollar, rCents]
  }

  setDefault(cycleSettingList: FlWasherCycleSettingList, cycleName: string) {
    cycleSettingList.Relational_Entity_Name.value = cycleName;
    cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.cents;
    cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.dollars;
    cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.cents;
    cycleSettingList.DnldCycle_OpSet_Temperature.value = cycleSettingList.DnldCycle_OpSet_Temperature.default;
    cycleSettingList.DnldCycle_OpSet_ExtraRinse.value = cycleSettingList.DnldCycle_OpSet_ExtraRinse.default == '1' ? true : false;
  }

  resetPriceValue() {
    const modeltype = localStorage.getItem("ProductType");
    const cycles = this.getWasherCycleList(localStorage.getItem("modelNumber"));
    this.CycleSettingCycleName = this.getFlWasherCycleSettings(modeltype);
    for (let index = 0; index < cycles.CycleName.length; index++) {
      this.cycleSettingList = this.CycleSettingCycleName[cycles.CycleName[index].tvalue];
      this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.cents;
      this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.dollars;
      this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.cents;
    }
  }

  resetCoinValue() {
    this.vendingSettingList.ValueCoin1.value[0] = this.vendingSettingList.ValueCoin1.dollars;
    this.vendingSettingList.ValueCoin1.value[1] = this.vendingSettingList.ValueCoin1.cents;
    this.vendingSettingList.ValueCoin2.value[0] = this.vendingSettingList.ValueCoin2.dollars;
    this.vendingSettingList.ValueCoin2.value[1] = this.vendingSettingList.ValueCoin2.cents;
  }

  getSetupModelData() {
    const setupModelList = this.getFlWasherSetupSettings(localStorage.getItem("ProductType"));
    this.commonService.novoEcoModels.setupConfig = '';
    this.commonService.novoEcoModels.setupConfig = JSON.stringify(setupModelList);
  }

  getVendingModelData() {
    const tlDryerVendingList = this.getFlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
    this.commonService.novoEcoModels.vendingConfig = '';
    this.commonService.novoEcoModels.vendingConfig = JSON.stringify(tlDryerVendingList);
  }

  getSettingModelData() {
    const tlDryerSettingList = this.getFlWasherCycleSettings(localStorage.getItem("ProductType"));
    this.commonService.novoEcoModels.cycleConfig = '';
    this.commonService.novoEcoModels.cycleConfig = JSON.stringify(tlDryerSettingList);
  }

}