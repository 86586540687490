import { environment } from "src/environments/environment";

export class AppSettings {
    public static API_ENDPOINT = environment.API_ENDPOINT;
    public static API_TOKENFUNC = '/oauth/token';
    public static password = 'password';
    public static client_id = environment.client_id;
    public static client_secret = environment.client_secret;
    
    public static ContentType = 'application/x-www-form-urlencoded';
    public static API_DETAILSFUNC = '/api/v1/getUserAccountDetailsCL';
    public static dummyURL="https://jsonplaceholder.typicode.com/todos"; 
    public static IDLE = 48 * 60 * 60;
    public static TIMEOUT = 30;
    public static APP_NAME = "usbportal";
}