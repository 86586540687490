import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import * as CYCLE from "src/assets/JsonFiles/CyclesData.json";
import * as cycleSettingValidation from "src/assets/JsonFiles/CycleSettings.json";
import * as cycleVendingData from "src/assets/JsonFiles/cycle_Vending_Setting.json";
import * as HMIData from "src/assets/JsonFiles/HMISetup.json";
import * as MSData from "src/assets/JsonFiles/MachineSetup.json";
import * as PHASE from "src/assets/JsonFiles/phase.json";
import * as data from "src/assets/JsonFiles/Setting.json";
import * as machineConfigList from "src/assets/JsonFiles/MachineConfigKeys.json";
import { MenuNames } from "../shared/enums/enum";

@Injectable({
  providedIn: "root",
})

export class ModelServices {
  models: any = (data as any).default;
  machineConfig: any = (machineConfigList as any).default;
  public modelSetup: any;
  public hmiSetup: any;
  public cycleData: any = [];
  public washerCycleData: any = [];
  public FlWasherCycleData: any = [];
  public cycleDataOriginal: any = [];
  public washerCycleDataOriginal: any = [];
  public userAddedCycles: any = [];
  public selectedCycleListObj: any;
  public cycleWashCycleComponent: any;
  public isCycleWashCycleComponentTobePush: boolean;
  public isHMIComponentTobePush: boolean;
  public isCycleVendingSettingsTobePush: boolean;
  public cycleWashCycleComponentReact: reactCycle = new reactCycle();
  public phaseIndex: any;
  public phaseList: any;
  public vendingSettings: any;
  public modelCycleDataCopy: any;
  public vendingTemp: WashTemp = new WashTemp();
  public static readonly OPL_MAX_CYCLES = 99;
  public static readonly Vended_MAX_CYCLES = 12;
  public cycles: any = (CYCLE as any).default;
  public phase: any = (PHASE as any).default;
  public cycleValidation: any = (cycleSettingValidation as any).default;
  public selectedModel: BehaviorSubject<string>;
  public tlWasherSelectedModel: BehaviorSubject<string>;
  public flWasherSelectedModel: BehaviorSubject<string>;
  public selectedModelSerial = new Subject<any>();
  public isBinaryRead: boolean = false;
  public isPreCall: boolean = true;
  public machineSetup: any = (MSData as any).default;
  public HMISetup: any = (HMIData as any).default;
  public cycleVending: any = (cycleVendingData as any).default;

  constructor() {
    this.models.ModelType = null;
    this.selectedModel = new BehaviorSubject("MYR130PNxxxxx");
    this.tlWasherSelectedModel = new BehaviorSubject("MYR130PNxxxxx");
    this.flWasherSelectedModel = new BehaviorSubject("MYR130PNxxxxx");
    this.models.ProductType = null;
    this.models.Weight = null;
  }

  isPush(cyclewash?: any) {
    if (this.isBinaryRead) {
      this.cycleWashCycleComponent = cyclewash;
      return;
    }
    if (this.isCycleWashCycleComponentTobePush) {
      this.cycleWashCycleComponent = cyclewash;
    } else {
      this.cycleWashCycleComponent = null;
    }

  }

  isPushHMI(hmi?: any) {
    if (this.isHMIComponentTobePush) {
      this.hmiSetup = hmi;
    } else {
      this.hmiSetup = null;
    }
  }

  getModelList() {
    return this.models;
  }

  getMachineConfigList() {
    return this.machineConfig;
  }

  /****Cycle Settings method******* */
  getDefaulCycleList() {
    return this.cycles;
  }


  getvendedRigidPhase() {
    return this.cycles.VENDEDRIGID;
  }

  getOPLRigidPhase() {
    return this.cycles.OPLRIGID;
  }

  getOPLSoftPhase() {
    return this.cycles.OPLSOFT;
  }

  getvendedSoftPhase() {
    return this.cycles.VENDEDSOFT;
  }

  getvendedPhase() {
    return this.phase.vended;
  }

  getOPLPhase() {
    return this.phase.opl;
  }

  getOPLValidation() {
    return this.cycleValidation.Opl;
  }

  getVendedValidation() {
    return this.cycleValidation.vended;
  }

  /****Cycle Settings method******* */
  getModelType(modelNumber: any) {
    var modelType = null;
    this.models.ModelType = modelNumber.substr(1, 2);
    modelType = this.models.ModelType;
    return modelType;
  }

  getModelCapacity(modelNumber: any) {
    var capacity = null;
    capacity = modelNumber.substr(3, 3);
    return capacity;
  }

  getCycleArrayName(modelNumber: any) {
    var arrName = "";
    if (this.getProductType(modelNumber) == "PN") {
      arrName = this.getModelType(modelNumber) == "YR" ? "OPLRIGID" : "OPLSOFT";
    } else {
      arrName =
        this.getModelType(modelNumber) == "YR" ? "VENDEDRIGID" : "VENDEDSOFT";
    }
    return arrName;
  }

  getCycleList(modelNumber: any) {
    if (this.getProductType(modelNumber) == "PN") {
      return this.getModelType(modelNumber) == "YR"
        ? this.cycles.Cycles["OPLRIGID"]
        : this.cycles.Cycles["OPLSOFT"];
    } else {
      return this.getModelType(modelNumber) == "YR"
        ? this.cycles.Cycles["VENDEDRIGID"]
        : this.cycles.Cycles["VENDEDSOFT"];
    }
  }

  getCycleValidation(productType: string) {
    var cycleValidation: String = "";
    switch (productType) {
      case "PN":
        cycleValidation = this.getOPLValidation();
        break;
      default:
        cycleValidation = this.getVendedValidation();
        break;
    }
    return cycleValidation;
  }

  getcycleDetail(cycleType: any) {
    var cycleDetail: String = "";
    switch (cycleType) {
      case "VENDEDRIGID":
        cycleDetail = this.getvendedRigidPhase();
        break;
      case "VENDEDSOFT":
        cycleDetail = this.getvendedSoftPhase();
        break;
      case "OPLRIGID":
        cycleDetail = this.getOPLRigidPhase();
        break;
      case "OPLSOFT":
        cycleDetail = this.getOPLSoftPhase();
        break;
    }
    return cycleDetail;
  }

  getProductType(modelNumber: any, isFLDryerReq?: boolean, isTLDryerReq?: boolean) {
    if (isTLDryerReq) {
      this.models.ProductType = modelNumber.substr(5, 4);
      return this.models.ProductType;
    }
    if (isFLDryerReq) {
      this.models.ProductType = modelNumber.substr(4, 4);
      return this.models.ProductType;
    }
    var weight = modelNumber.substr(3, 3);
    var productType = null;
    if (isNaN(+weight)) productType = modelNumber.substr(5, 2);
    else productType = modelNumber.substr(6, 2);
    this.models.ProductType = productType;
    return productType;
  }

  getModelWeight(modelNumber: any) {
    var weight = modelNumber.substr(3, 3);
    if (isNaN(+weight)) weight = weight.substr(0, 2);
    else weight = weight.substr(0, 3);
    this.models.Weight = weight;
    return weight;
  }

  getModelProperties() {
    for (var val of this.models.Models) {
      var modelType = val.substr(1, 2);
      var weight = val.substr(3, 3);
      var productType = null;
      modelType = '"' + modelType + '",';
      if (isNaN(+weight)) {
        weight = '"' + weight.substr(0, 2) + '",';
        productType = '"' + val.substr(5, 2);
        +'",';
      } else {
        weight = '"' + weight.substr(0, 3) + '",';
        productType = '"' + val.substr(7, 1);
        +'"';
      }
      val = '"' + val + '":';
    }
  }

  getMachineSettings(ProductType: any) {
    //If check of OPL and Vended
    if (ProductType == "PN" || ProductType == "N") return this.machineSetup.Opl;
    else return this.machineSetup.vended;
  }

  geHMISettings(ProductType: any) {
    //If check of OPL and Vended
    if (ProductType == "PN" || ProductType == "N") return this.HMISetup.Opl;
    else return this.HMISetup.vended;
  }

  getCyclevendingData() {
    return this.cycleVending.vended;
  }

  ConvertToFahrenheitService(celcious: any) {
    var farn = ((celcious * 9 / 5) + 32) + "°F";
    return farn;
  }
}

export class WashTemp {
  COLD: any;
  COOL: any;
  WARM: any;
  HOT: any;
}

export class reactCycle {
  drainForm: any;
  reclaimForm: any;
  washForm: any;
}

export interface IModelList {
  Models: string[],
  ModelName: string,
  ModelType: string,
  ProductType: string,
  Weight: string
}

export interface IMultiloadModelList {
  Models: string[],
  ModelName: string,
  ModelType: string,
  ProductType: string,
  Weight: string
}

