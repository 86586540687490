import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { CommonServices } from "src/app/services/CommonServices";
import { ModelServices } from "src/app/services/ModelServices";

@Component({
  selector: "app-leftmenuitem",
  templateUrl: "./leftmenuitem.component.html",
  styleUrls: ["./leftmenuitem.component.scss"],
})
export class LeftmenuitemComponent implements OnInit, AfterViewInit {
  // Ensure Change Detection runs before accessing the instance
  @ContentChild("foo", { static: false }) foo!: ElementRef;
  @Output() passIsOpen = new EventEmitter<boolean>();
  @ViewChild("newSetup", { static: true }) newSetup: ElementRef<HTMLElement>;
  @ViewChild("userDefinedSetup", { static: true }) userDefinedSetup: ElementRef<HTMLElement>;
  isOpen: boolean = true;
  showButtons: Subscription;
  leftMenuHide: any = localStorage.getItem("leftMenuHide");
  className: string;
  lastactive: string;
  showMenu: boolean = true;
  isdisabled: any;
  isdisabledTwo: any;

  constructor(
    private commonServices: CommonServices,
    private modelServices: ModelServices,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.showButtons = this.commonServices.getTopFeaturesDisable().subscribe(() => {
      this.toggleNavByModelNumber();
    });
  }

  ngOnInit() {
    this.toggleNav()
    this.readBinaryConfig();
  }

  ngAfterViewInit() {
    this.renderer.setStyle(
      this.newSetup.nativeElement,
      "background",
      "#273a6b"
    );
    // document.getElementById("mySidenav").classList.add("mySidenav-width");
    this.newSetup.nativeElement.click();
  }

  readBinaryConfig() {
    this.commonServices.homeScreen.subscribe((val: any) => {
      this.toggleApi('dashboard');
      this.commonServices.selectModel.next(val);
      this.selectSetupTab();
    });
  }

  addSelectedItem() {
    var header = document.getElementById("mySidenav");
    var btns = header.getElementsByClassName("box");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
        localStorage.setItem("current", this.className);
      });
    }
  }

  selectSetupTab() {
    var header = document.getElementById("mySidenav");
    var current = document.getElementsByClassName("active");
    current[0].className = current[0].className.replace(" active", "");
  }

  toggleNav() {
    this.isOpen = this.isOpen === false ? true : false;
    this.passToggleNav(this.isOpen);
  }

  passToggleNav(value: boolean) {
    this.passIsOpen.emit(value);
  }

  toggleNavByModelNumber() {
    this.isOpen = this.isOpen === false ? false : false;
    this.passToggleNav(this.isOpen);
  }

  toggleApi(moduleName: string) {
    this.commonServices.isModelSerial = false;
    this.renderer.setStyle(
      this.newSetup.nativeElement,
      "background",
      "#273a6b"
    );
    this.commonServices.sendApiVal();
    this.commonServices.sendUsbValTwo(moduleName);
    this.commonServices.disableTopFeaure.next("Disalbe");
    this.commonServices.closeAllMenu.next();
    this.commonServices.generateButtonHide.next(true);
    this.commonServices.isUserDefinedScreen.next(false);
    this.commonServices.isHideDeleteButton.next(false);
    for (const prop of Object.getOwnPropertyNames(this.commonServices.userDefinedConfig)) {
      delete this.commonServices.userDefinedConfig[prop];
    }
    this.router.navigate(['/']);
  }

  getUsbPage(moduleName: string) {
    this.commonServices.isModelSerial = false;
    this.commonServices.disableTopFeaure.next("Disalbe");
    this.renderer.setStyle(
      this.newSetup.nativeElement,
      "background",
      "#5973b6"
    );
    this.commonServices.sendUsbVal(moduleName);
    this.commonServices.disbleUSB.next(false);
    for (const prop of Object.getOwnPropertyNames(this.commonServices.userDefinedConfig)) {
      delete this.commonServices.userDefinedConfig[prop];
    }
  }

  getModelSerialPage(moduleName: string) {
    this.commonServices.isModelSerial = true;
    this.modelServices.selectedModelSerial.next('');
    this.renderer.setStyle(
      this.newSetup.nativeElement,
      "background",
      "#5973b6"
    );
    this.commonServices.disableTopFeaure.next("Disalbe");
    this.commonServices.hideLeftMenu(moduleName);
    for (const prop of Object.getOwnPropertyNames(this.commonServices.userDefinedConfig)) {
      delete this.commonServices.userDefinedConfig[prop];
    }
  }

  getDiagnosticsPage(moduleName: string) {
    this.commonServices.isModelSerial = false;
    this.commonServices.disableTopFeaure.next("Disalbe");
    this.renderer.setStyle(
      this.newSetup.nativeElement,
      "background",
      "#5973b6"
    );
    this.commonServices.hideLeftMenu(moduleName);
    for (const prop of Object.getOwnPropertyNames(this.commonServices.userDefinedConfig)) {
      delete this.commonServices.userDefinedConfig[prop];
    }
  }

  getUploadMachineConfigPage(moduleName: string) {
    this.commonServices.isModelSerial = false;
    this.commonServices.disableTopFeaure.next("Disalbe");
    this.renderer.setStyle(
      this.newSetup.nativeElement,
      "background",
      "#5973b6"
    );
    this.commonServices.hideLeftMenu(moduleName);
    for (const prop of Object.getOwnPropertyNames(this.commonServices.userDefinedConfig)) {
      delete this.commonServices.userDefinedConfig[prop];
    }
  }

  getAuditReport(moduleName: string) {
    this.commonServices.isModelSerial = false;
    this.commonServices.disableTopFeaure.next("Disalbe");
    this.renderer.setStyle(
      this.newSetup.nativeElement,
      "background",
      "#5973b6"
    );
    this.commonServices.hideLeftMenu(moduleName);
    for (const prop of Object.getOwnPropertyNames(this.commonServices.userDefinedConfig)) {
      delete this.commonServices.userDefinedConfig[prop];
    }
  }

  getUserDefinedSetup(moduleName: string) {
    localStorage.setItem("isUserDefined", "1");
    this.commonServices.isModelSerial = false;
    this.renderer.setStyle(
      this.newSetup.nativeElement,
      "background",
      "#5973b6"
    );
    this.commonServices.sendApiVal();
    this.commonServices.sendUsbValTwo(moduleName);
    this.commonServices.disableTopFeaure.next("Disalbe");
    this.commonServices.generateButtonHide.next(true);
    this.commonServices.hideLeftMenu(moduleName);
    this.commonServices.isUserDefinedScreen.next(true);
  }
}