import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/AuthenticationService';

@Injectable({
  providedIn: 'root'
})

export class editProfileService {
  requestURL: string = '';
  requestHeaderData = new Array<any>();

  constructor(
    private toastr: ToastrService,
    private _authenticationService: AuthenticationService
  ) { }

  public getData(requestURL, requestParam?: any): Observable<any> {
    this.requestURL = requestURL;
    return this._authenticationService.callGetAPI(this.requestURL, this.requestHeaderData);
  }

  public postData(requestURL, JsonBody: string): Observable<any> {
    return this._authenticationService.callPostAPI(requestURL, JsonBody);
  }

  public putData(requestURL, JsonBody: string): Observable<any> {
    return this._authenticationService.callPutAPI(requestURL, JsonBody);
  }

  public deleteData(requestURL): Observable<any> {
    return this._authenticationService.callDeleteAPI(requestURL);
  }

  showMessage(message, isError) {
    if (!isError) {
      this.toastr.success(message, 'Success', {
        positionClass: 'toast-top-right'
      });
    } else {
      this.toastr.error(message, 'Failed', {
        positionClass: 'toast-top-right'
      });
    }
  }

}