import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { CommonServices } from 'src/app/services/CommonServices';
import { ModelServices } from 'src/app/services/ModelServices';
import { NovoFileNames } from '../../../enums/enum';
import { MachineConfig } from '../../../models/UsbGenerator';
import { IGetUserDefinedConfiguration } from '../../user-defined-setup/user-defined-setup.model';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('divDispenser', { read: ElementRef, static: false }) divDispenser: ElementRef;
  model: any = {};
  previousUrl: string;
  checked: any;
  panelOpenState = false;
  arrMachineConfigSettings: any = new Array();
  isOPL = true;
  isShowModel = true;

  ModelType: string = localStorage.getItem("ModelType");//="YS";//or YR
  ProductType: string = localStorage.getItem("ProductType");//="PN"; //or not PN
  Weight: any = localStorage.getItem("Weight");//=99; or //gt99
  ddlWaterInlet: any;
  elmts; any;
  options: any;
  waterInletValves: any; waterInletValvesDefault: any; numberofDrains: any; numberofDrainsDefault: any;
  balanceRetries: any; balanceRetriesDefault: any;
  cold: any; cool: any; warm: any; hot: any; sanTemp: any;
  extChemicalPortMin = 0;
  extChemicalPortMax = 8;
  farnValue: any;

  disabled = true;
  isDisabled: boolean = true;
  public useDefault = false;
  isSetToDefaultHide: boolean = false;

  constructor(
    private modelServices: ModelServices,
    private cdr: ChangeDetectorRef,
    private commonServices: CommonServices,
    private router: Router
  ) {
    this.options = this.modelServices.getMachineSettings(this.ProductType);
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
        if (this.previousUrl === "/") {
          this.modelServices.modelSetup = undefined;
          //this.model = undefined;
        }
      });
    this.isOPL = (localStorage.getItem("ProductType") === "PN")
  }

  ngOnInit() {
    this.isSetToDefaultHide = this.commonServices.isSetToDefaultHide;
    this.isShowModel = localStorage.getItem("isShowModel") == '0' ? false : true;
    var machineConfig: MachineConfig = new MachineConfig();

    this.commonServices.getSetUpValue().subscribe((val: any) => {
      if (!this.model) { return; }
      machineConfig.WaterInletValves = String(this.model.ddlWaterInlet === undefined ? 2 : this.model.ddlWaterInlet);

      machineConfig.NumberOfDrains = String(this.model.ddlNumberDrains === undefined ? 1 : this.model.ddlNumberDrains);

      machineConfig.NumberOfOffBalanceRetries = String(this.model.ddlOffBalance === undefined ? 3 : this.model.ddlOffBalance);
      if (this.isOPL) {

        machineConfig.SanitaryTemperature = String(this.model.inputSanTemp === undefined ? 0 : this.model.inputSanTemp);
        machineConfig.SanitaryTime = String(this.model.inputSanTime === undefined ? 0 : this.model.inputSanTime);

      }
      else {
        machineConfig.TemperatureCold = String(this.model.inputColdTemp === undefined ? 2 : this.model.inputColdTemp);

        machineConfig.TemperatureCool = String(this.model.inputCoolTemp === undefined ? 18 : this.model.inputCoolTemp);

        machineConfig.TemperatureWarm = String(this.model.inputWarmTemp === undefined ? 32 : this.model.inputWarmTemp);

        machineConfig.TemperatureHot = String(this.model.inputHotTemp === undefined ? 49 : this.model.inputHotTemp);
      }

      machineConfig.ExternalChemicalDispenser = this.model.checked ? "1" : "0";

      machineConfig.MinLevelStartSupply = String(this.model.inputWaterLevel === undefined ? 0 : this.model.inputWaterLevel);

      machineConfig.ExDisDetergentValve_1 = String(this.model.inputDPort1 === undefined ? 0 : this.model.inputDPort1);

      machineConfig.ExDisDetergentValve_2 = String(this.model.inputDPort2 === undefined ? 0 : this.model.inputDPort2);

      machineConfig.ExDisDetergentValve_3 = String(this.model.inputDPort3 === undefined ? 0 : this.model.inputDPort3);

      machineConfig.ExDisDetergentValve_4 = String(this.model.inputDPort4 === undefined ? 0 : this.model.inputDPort4);

      machineConfig.ExDisBleachValve_1 = String(this.model.inputBPort1 === undefined ? 0 : this.model.inputBPort1);

      machineConfig.ExDisBleachValve_2 = String(this.model.inputBPort2 === undefined ? 0 : this.model.inputBPort2);

      machineConfig.ExDisBleachValve_3 = String(this.model.inputBPort3 === undefined ? 0 : this.model.inputBPort3);

      machineConfig.ExDisBleachValve_4 = String(this.model.inputBPort4 === undefined ? 0 : this.model.inputBPort4);

      machineConfig.ExDisFabricValve_1 = String(this.model.inputFPort1 === undefined ? 0 : this.model.inputFPort1);

      machineConfig.ExDisFabricValve_2 = String(this.model.inputFPort2 === undefined ? 0 : this.model.inputFPort2);

      machineConfig.ExDisFabricValve_3 = String(this.model.inputFPort3 === undefined ? 0 : this.model.inputFPort3);

      machineConfig.ExDisFabricValve_4 = String(this.model.inputFPort4 === undefined ? 0 : this.model.inputFPort4);

      machineConfig.ExDisChemical1Valve_1 = String(this.model.ECOnePort1 === undefined ? 0 : this.model.ECOnePort1);

      machineConfig.ExDisChemical1Valve_2 = String(this.model.ECOnePort2 === undefined ? 0 : this.model.ECOnePort2);

      machineConfig.ExDisChemical1Valve_3 = String(this.model.ECOnePort3 === undefined ? 0 : this.model.ECOnePort3);

      machineConfig.ExDisChemical1Valve_4 = String(this.model.ECOnePort4 === undefined ? 0 : this.model.ECOnePort4);

      machineConfig.ExDisChemical2Valve_1 = String(this.model.ECTwoPort1 === undefined ? 0 : this.model.ECTwoPort1);

      machineConfig.ExDisChemical2Valve_2 = String(this.model.ECTwoPort2 === undefined ? 0 : this.model.ECTwoPort2);

      machineConfig.ExDisChemical2Valve_3 = String(this.model.ECTwoPort3 === undefined ? 0 : this.model.ECTwoPort3);

      machineConfig.ExDisChemical2Valve_4 = String(this.model.ECTwoPort4 === undefined ? 0 : this.model.ECTwoPort4);

      machineConfig.ExDisChemical3Valve_1 = String(this.model.ECThreePort1 === undefined ? 0 : this.model.ECThreePort1);

      machineConfig.ExDisChemical3Valve_2 = String(this.model.ECThreePort2 === undefined ? 0 : this.model.ECThreePort2);

      machineConfig.ExDisChemical3Valve_3 = String(this.model.ECThreePort3 === undefined ? 0 : this.model.ECThreePort3);

      machineConfig.ExDisChemical3Valve_4 = String(this.model.ECThreePort4 === undefined ? 0 : this.model.ECThreePort4);

      machineConfig.ExDisChemical4Valve_1 = String(this.model.ECFourPort1 === undefined ? 0 : this.model.ECFourPort1);

      machineConfig.ExDisChemical4Valve_2 = String(this.model.ECFourPort2 === undefined ? 0 : this.model.ECFourPort2);

      machineConfig.ExDisChemical4Valve_3 = String(this.model.ECFourPort3 === undefined ? 0 : this.model.ECFourPort3);

      machineConfig.ExDisChemical4Valve_4 = String(this.model.ECFourPort4 === undefined ? 0 : this.model.ECFourPort4);


      machineConfig.ExDisChemical5Valve_1 = String(this.model.ECFivePort1 === undefined ? 0 : this.model.ECFivePort1);

      machineConfig.ExDisChemical5Valve_2 = String(this.model.ECFivePort2 === undefined ? 0 : this.model.ECFivePort2);

      machineConfig.ExDisChemical5Valve_3 = String(this.model.ECFivePort3 === undefined ? 0 : this.model.ECFivePort3);

      machineConfig.ExDisChemical5Valve_4 = String(this.model.ECFivePort4 === undefined ? 0 : this.model.ECFivePort4);
      this.MachineConfigBytes(machineConfig);
      localStorage.setItem('arrMachineConfigSettings', this.arrMachineConfigSettings);
      this.commonServices.novoEcoModels.setupConfig = '';
      this.commonServices.novoEcoModels.setupConfig = JSON.stringify(this.model);
    });
    if (localStorage.getItem("ProductType") === "PN") {
      document.getElementById("opensec").classList.add("newsec");
      document.getElementById("sanitory").classList.add("visible");
      document.getElementById("setWaterTemp").classList.remove("visible");
    } else {
      document.getElementById("opensec").classList.remove("newsec");
      document.getElementById("sanitory").classList.remove("visible");
      document.getElementById("setWaterTemp").classList.add("visible");
    }
  }

  ngAfterViewInit() {
    this.setToDefault();
    this.BindDefaultValues();
    this.readBinaryConfig();
    this.readModelConfig();
    this.cdr.detectChanges();
  }

  bindUserDefinedModelData(data: any) {
    this.model.ddlWaterInlet = data.ddlWaterInlet;//"2"; 

    this.model.ddlNumberDrains = data.ddlNumberDrains;//"1"; 

    this.model.ddlOffBalance = data.ddlOffBalance;//"3"; 

    this.model.inputColdTemp = data.inputColdTemp;//"2"; 

    this.model.inputCoolTemp = data.inputCoolTemp;//"18"; 

    this.model.inputWarmTemp = data.inputWarmTemp;//"32"; 

    this.model.inputHotTemp = data.inputHotTemp;//"49"; 

    this.model.inputSanTemp = data.inputSanTemp;//"49";

    this.model.ExternalChemicalDispenser = data.ExternalChemicalDispenser;

    this.model.inputWaterLevel = data.inputWaterLevel;//"0"; 

    this.model.inputDPort1 = data.inputDPort1;//"0"; 

    this.model.inputDPort2 = data.inputDPort2;//"0"; 

    this.model.inputDPort3 = data.inputDPort3;//"0"; 

    this.model.inputDPort4 = data.inputDPort4

    this.model.inputBPort1 = data.inputBPort1

    this.model.inputBPort2 = data.inputBPort2

    this.model.inputBPort3 = data.inputBPort3

    this.model.inputBPort4 = data.inputBPort4

    this.model.inputFPort1 = data.inputFPort1

    this.model.inputFPort2 = data.inputFPort2

    this.model.inputFPort3 = data.inputFPort3

    this.model.inputFPort4 = data.inputFPort4
    if (this.ProductType == "PN") {
      this.model.ECOnePort1 = data.ECOnePort1

      this.model.ECOnePort2 = data.ECOnePort2

      this.model.ECOnePort3 = data.ECOnePort3

      this.model.ECOnePort4 = data.ECOnePort4

      this.model.ECTwoPort1 = data.ECTwoPort1

      this.model.ECTwoPort2 = data.ECTwoPort2

      this.model.ECTwoPort3 = data.ECTwoPort3

      this.model.ECTwoPort4 = data.ECTwoPort4

      this.model.ECThreePort1 = data.ECThreePort1

      this.model.ECThreePort2 = data.ECThreePort2

      this.model.ECThreePort3 = data.ECThreePort3

      this.model.ECThreePort4 = data.ECThreePort4

      this.model.ECFourPort1 = data.ECFourPort1

      this.model.ECFourPort2 = data.ECFourPort2

      this.model.ECFourPort3 = data.ECFourPort3

      this.model.ECFourPort4 = data.ECFourPort4


      this.model.ECFivePort1 = data.ECFivePort1

      this.model.ECFivePort2 = data.ECFivePort2

      this.model.ECFivePort3 = data.ECFivePort3

      this.model.ECFivePort4 = data.ECFivePort4

      this.model.inputSanTempF = data.inputSanTempF
      this.model.inputSanTime = data.inputSanTime

    }
    this.model.checked = data.checked
    this.model.inputWarmTempF = data.inputWarmTempF
    this.model.inputColdTempF = data.inputColdTempF
    this.model.inputCoolTempF = data.inputCoolTempF
    this.model.inputHotTempF = data.inputHotTempF
    // this.modelServices.vendingTemp.WARM = data.ddlWaterInlet
    // this.modelServices.vendingTemp.COLD = data.ddlWaterInlet
    // this.modelServices.vendingTemp.COOL = data.ddlWaterInlet
    // this.modelServices.vendingTemp.HOT = data.ddlWaterInlet

    if (this.modelServices.modelSetup !== undefined) {
      this.model = this.modelServices.modelSetup;
    }
    else {
      this.modelServices.modelSetup = data;
    }
  }

  readBinaryConfig() {
    this.commonServices.isSetupTabSelected.subscribe((tab) => {
      this.binaryDefaultValues();
    });
  }

  readModelConfig() {
    this.commonServices.isNovoModelSelected.subscribe((userDefinedConfig: IGetUserDefinedConfiguration) => {
      this.bindUserDefinedModelData(JSON.parse(userDefinedConfig.setupConfig));
    });
  }

  binaryDefaultValues() {
    const machineBinaryValue = this.commonServices.getBinaryData(NovoFileNames.Machine_Config);
    if (!machineBinaryValue.length || !machineBinaryValue) {
      return;
    }
    this.model.MachineConstructType = machineBinaryValue[0].value[53];
    this.model.MachineType = machineBinaryValue[0].value[54];
    this.model.TemperatureWait = machineBinaryValue[0].value[55];
    this.model.AutomatiCoolDown = machineBinaryValue[0].value[56];
    this.model.TemperatureOverShot = `${machineBinaryValue[0].value[57] + machineBinaryValue[0].value[58]}`;
    this.model.HeatingTimeMax = `${machineBinaryValue[0].value[59] + machineBinaryValue[0].value[60]}`;
    this.model.FullHeating = machineBinaryValue[0].value[61];
    this.model.StrictTemperature = `${machineBinaryValue[0].value[62] + machineBinaryValue[0].value[63]}`;
    this.model.TemperatureHysterisis = machineBinaryValue[0].value[64];
    this.model.HeavyTime = machineBinaryValue[0].value[65];
    this.model.SmartMotion = machineBinaryValue[0].value[66];
    this.model.SmartMotionSpeed = machineBinaryValue[0].value[67];
    this.model.OverFillDetection = machineBinaryValue[0].value[68];
    this.model.LiquidSoapSupply = machineBinaryValue[0].value[69];
    this.model.inputWaterLevel = machineBinaryValue[0].value[70]
    this.model.MinLevelStartSupply = String(machineBinaryValue[0].value[70] === undefined ? 0 : machineBinaryValue[0].value[70]); //WATER LEVEL BEFORE DISPENSING

    this.model.DrainTimeExtended = machineBinaryValue[0].value[71];
    this.model.DrainExtend = machineBinaryValue[0].value[72];
    this.model.ddlWaterInlet = machineBinaryValue[0].value[73];//"2"; 
    this.model.ddlNumberDrains = machineBinaryValue[0].value[74];//"1"; 
    this.model.ddlOffBalance = machineBinaryValue[0].value[75];
    this.model.NumberOfTempSensors = machineBinaryValue[0].value[76];
    this.model.IsHeaterOn = machineBinaryValue[0].value[77];
    this.model.checked = machineBinaryValue[0].value[78];
    this.model.inputColdTemp = machineBinaryValue[0].value[82];
    this.model.inputColdTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputColdTemp)
    this.model.inputCoolTemp = machineBinaryValue[0].value[81];
    this.model.inputCoolTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputCoolTemp)
    this.model.inputWarmTemp = machineBinaryValue[0].value[80];
    this.model.inputWarmTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputWarmTemp)
    this.model.inputHotTemp = machineBinaryValue[0].value[79];
    this.model.inputHotTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputHotTemp);
    this.model.SpinSpeedLow = `${machineBinaryValue[0].value[83] + machineBinaryValue[0].value[84]}`
    this.model.SpinSpeedMedium = `${machineBinaryValue[0].value[85] + machineBinaryValue[0].value[86]}`;
    this.model.SpinSpeedHigh = `${machineBinaryValue[0].value[87] + machineBinaryValue[0].value[88]} `;
    this.model.RecirculationDrainValve = machineBinaryValue[0].value[89];
    this.model.RecirculationWaterValve = machineBinaryValue[0].value[90];
    this.model.inputDPort1 = machineBinaryValue[0].value[91];
    this.model.inputDPort2 = machineBinaryValue[0].value[92];
    this.model.inputDPort3 = machineBinaryValue[0].value[93];
    this.model.inputDPort4 = machineBinaryValue[0].value[94];
    this.model.inputFPort1 = machineBinaryValue[0].value[95];
    this.model.inputFPort2 = machineBinaryValue[0].value[96];
    this.model.inputFPort3 = machineBinaryValue[0].value[97];
    this.model.inputFPort4 = machineBinaryValue[0].value[98];
    this.model.inputBPort1 = machineBinaryValue[0].value[99];
    this.model.inputBPort2 = machineBinaryValue[0].value[100];
    this.model.inputBPort3 = machineBinaryValue[0].value[101];
    this.model.inputBPort4 = machineBinaryValue[0].value[102];
    this.model.ECOnePort1 = machineBinaryValue[0].value[103];
    this.model.ECOnePort2 = machineBinaryValue[0].value[104];
    this.model.ECOnePort3 = machineBinaryValue[0].value[105];
    this.model.ECOnePort4 = machineBinaryValue[0].value[106];
    this.model.ECTwoPort1 = machineBinaryValue[0].value[107];
    this.model.ECTwoPort2 = machineBinaryValue[0].value[108];
    this.model.ECTwoPort3 = machineBinaryValue[0].value[109];
    this.model.ECTwoPort4 = machineBinaryValue[0].value[110];
    this.model.ECThreePort1 = machineBinaryValue[0].value[111];
    this.model.ECThreePort2 = machineBinaryValue[0].value[112];
    this.model.ECThreePort3 = machineBinaryValue[0].value[113];
    this.model.ECThreePort4 = machineBinaryValue[0].value[114];
    this.model.ECFourPort1 = machineBinaryValue[0].value[115];
    this.model.ECFourPort2 = machineBinaryValue[0].value[116];
    this.model.ECFourPort3 = machineBinaryValue[0].value[117];
    this.model.ECFourPort4 = machineBinaryValue[0].value[118];
    this.model.ECFivePort1 = machineBinaryValue[0].value[119];
    this.model.ECFivePort2 = machineBinaryValue[0].value[120];
    this.model.ECFivePort3 = machineBinaryValue[0].value[121];
    this.model.ECFivePort4 = machineBinaryValue[0].value[122];
    this.model.inputSanTemp = machineBinaryValue[0].value[123];
    this.model.inputSanTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputSanTemp);
    // this.model.SanitaryTemperature = machineBinaryValue[0].value[123];
    this.model.inputSanTime = machineBinaryValue[0].value[124];
    setTimeout(() => {
      this.commonServices.getClose();
    }, 400);
    // this.model.SanitaryTime = machineBinaryValue[0].value[124];
    // this.binaryMachineConfigBytes(this.model);

    // localStorage.setItem('arrMachineConfigSettings', this.arrMachineConfigSettings);
  }

  CalculateBytes(inputNum: number) {
    let firstByte = 0, secondByte = 0;
    firstByte = (inputNum & 0x00FF);
    secondByte = (inputNum & 0xFF00) >> 8;
    return [firstByte, secondByte];
  }

  MachineConfigBytes(machineConfig: any) {
    this.arrMachineConfigSettings = [];
    this.arrMachineConfigSettings.push(machineConfig.MachineConstructType);
    this.arrMachineConfigSettings.push(machineConfig.MachineType);
    this.arrMachineConfigSettings.push(machineConfig.TemperatureWait);
    this.arrMachineConfigSettings.push(machineConfig.AutomatiCoolDown);
    this.arrMachineConfigSettings.push(machineConfig.TemperatureOverShot);
    this.arrMachineConfigSettings.push(machineConfig.HeatingTimeMax);
    this.arrMachineConfigSettings.push(machineConfig.FullHeating);
    this.arrMachineConfigSettings.push(machineConfig.StrictTemperature);
    this.arrMachineConfigSettings.push(machineConfig.TemperatureHysterisis);
    this.arrMachineConfigSettings.push(machineConfig.HeavyTime);
    this.arrMachineConfigSettings.push(machineConfig.SmartMotion);
    this.arrMachineConfigSettings.push(machineConfig.SmartMotionSpeed);
    this.arrMachineConfigSettings.push(machineConfig.OverFillDetection);
    this.arrMachineConfigSettings.push(machineConfig.LiquidSoapSupply);
    this.arrMachineConfigSettings.push(machineConfig.MinLevelStartSupply);
    this.arrMachineConfigSettings.push(machineConfig.DrainTimeExtended);
    this.arrMachineConfigSettings.push(machineConfig.DrainExtend);
    this.arrMachineConfigSettings.push(machineConfig.WaterInletValves);
    this.arrMachineConfigSettings.push(machineConfig.NumberOfDrains);
    this.arrMachineConfigSettings.push(machineConfig.NumberOfOffBalanceRetries);
    this.arrMachineConfigSettings.push(machineConfig.NumberOfTempSensors);
    this.arrMachineConfigSettings.push(machineConfig.IsHeaterOn);
    this.arrMachineConfigSettings.push(machineConfig.ExternalChemicalDispenser);
    this.arrMachineConfigSettings.push(machineConfig.TemperatureHot);
    this.arrMachineConfigSettings.push(machineConfig.TemperatureWarm);
    this.arrMachineConfigSettings.push(machineConfig.TemperatureCool);
    this.arrMachineConfigSettings.push(machineConfig.TemperatureCold);
    let spin_Low1 = 0, spin_Low2 = 0, spin_Med1 = 0, spin_Med2 = 0, spin_High1 = 0, spin_High2 = 0;
    let twoBytes: any;
    let spinValues: any;
    if (this.ProductType !== "PN") {
      if (this.ModelType == "YS") {
        if (this.Weight <= 20) {
          spinValues = this.modelServices.getVendedValidation().SoftFinalSpin.r1.values;
        }
        else if (this.Weight > 20 && this.Weight <= 30) {
          spinValues = this.modelServices.getVendedValidation().SoftFinalSpin.r2.values;
        }
        else {
          spinValues = this.modelServices.getVendedValidation().SoftFinalSpin.r3.values
        }
      }
      else {
        if (this.Weight <= 20) {
          spinValues = this.modelServices.getVendedValidation().RigidFinalSpin.r1.values;
        }
        else if (this.Weight > 20 && this.Weight <= 30) {
          spinValues = this.modelServices.getVendedValidation().RigidFinalSpin.r2.values;
        }
        else {
          spinValues = this.modelServices.getVendedValidation().RigidFinalSpin.r3.values
        }
      }
      if (spinValues !== undefined) {
        twoBytes = this.CalculateBytes(spinValues[0].value);
        spin_Low1 = twoBytes[0];
        spin_Low2 = twoBytes[1];

        twoBytes = this.CalculateBytes(spinValues[1].value);
        spin_Med1 = twoBytes[0];
        spin_Med2 = twoBytes[1];

        twoBytes = this.CalculateBytes(spinValues[2].value);
        spin_High1 = twoBytes[0];
        spin_High2 = twoBytes[1];
      }
    }
    this.arrMachineConfigSettings.push(spin_Low1);
    this.arrMachineConfigSettings.push(spin_Low2);
    this.arrMachineConfigSettings.push(spin_Med1);
    this.arrMachineConfigSettings.push(spin_Med2);
    this.arrMachineConfigSettings.push(spin_High1);
    this.arrMachineConfigSettings.push(spin_High2);
    this.arrMachineConfigSettings.push(machineConfig.RecirculationDrainValve);
    this.arrMachineConfigSettings.push(machineConfig.RecirculationWaterValve);
    this.arrMachineConfigSettings.push(machineConfig.ExDisDetergentValve_1);
    this.arrMachineConfigSettings.push(machineConfig.ExDisDetergentValve_2);
    this.arrMachineConfigSettings.push(machineConfig.ExDisDetergentValve_3);
    this.arrMachineConfigSettings.push(machineConfig.ExDisDetergentValve_4);
    this.arrMachineConfigSettings.push(machineConfig.ExDisFabricValve_1);
    this.arrMachineConfigSettings.push(machineConfig.ExDisFabricValve_2);
    this.arrMachineConfigSettings.push(machineConfig.ExDisFabricValve_3);
    this.arrMachineConfigSettings.push(machineConfig.ExDisFabricValve_4);
    this.arrMachineConfigSettings.push(machineConfig.ExDisBleachValve_1);
    this.arrMachineConfigSettings.push(machineConfig.ExDisBleachValve_2);
    this.arrMachineConfigSettings.push(machineConfig.ExDisBleachValve_3);
    this.arrMachineConfigSettings.push(machineConfig.ExDisBleachValve_4);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical1Valve_1);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical1Valve_2);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical1Valve_3);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical1Valve_4);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical2Valve_1);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical2Valve_2);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical2Valve_3);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical2Valve_4);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical3Valve_1);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical3Valve_2);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical3Valve_3);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical3Valve_4);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical4Valve_1);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical4Valve_2);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical4Valve_3);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical4Valve_4);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical5Valve_1);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical5Valve_2);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical5Valve_3);
    this.arrMachineConfigSettings.push(machineConfig.ExDisChemical5Valve_4);
    this.arrMachineConfigSettings.push(machineConfig.SanitaryTemperature);
    this.arrMachineConfigSettings.push(machineConfig.SanitaryTime);
  }

  binaryMachineConfigBytes(model: any) {
    this.arrMachineConfigSettings = [];
    this.arrMachineConfigSettings.push(model.MachineConstructType);
    this.arrMachineConfigSettings.push(model.MachineType);
    this.arrMachineConfigSettings.push(model.TemperatureWait);
    this.arrMachineConfigSettings.push(model.AutomatiCoolDown);
    this.arrMachineConfigSettings.push(model.TemperatureOverShot);
    this.arrMachineConfigSettings.push(model.HeatingTimeMax);
    this.arrMachineConfigSettings.push(model.FullHeating);
    this.arrMachineConfigSettings.push(model.StrictTemperature);
    this.arrMachineConfigSettings.push(model.TemperatureHysterisis);
    this.arrMachineConfigSettings.push(model.HeavyTime);
    this.arrMachineConfigSettings.push(model.SmartMotion);
    this.arrMachineConfigSettings.push(model.SmartMotionSpeed);
    this.arrMachineConfigSettings.push(model.OverFillDetection);
    this.arrMachineConfigSettings.push(model.LiquidSoapSupply);
    this.arrMachineConfigSettings.push(model.MinLevelStartSupply);
    this.arrMachineConfigSettings.push(model.DrainTimeExtended);
    this.arrMachineConfigSettings.push(model.DrainExtend);
    this.arrMachineConfigSettings.push(model.ddlWaterInlet);
    this.arrMachineConfigSettings.push(model.ddlNumberDrains);
    this.arrMachineConfigSettings.push(model.ddlOffBalance);
    this.arrMachineConfigSettings.push(model.NumberOfTempSensors);
    this.arrMachineConfigSettings.push(model.IsHeaterOn);


    this.arrMachineConfigSettings.push(model.checked);
    this.arrMachineConfigSettings.push(model.inputHotTemp);
    this.arrMachineConfigSettings.push(model.inputWarmTemp);
    this.arrMachineConfigSettings.push(model.inputCoolTemp);
    this.arrMachineConfigSettings.push(model.inputColdTemp);
    let spin_Low1 = 0, spin_Low2 = 0, spin_Med1 = 0, spin_Med2 = 0, spin_High1 = 0, spin_High2 = 0;
    let twoBytes: any;
    let spinValues: any;
    if (this.ProductType !== "PN") {
      if (this.ModelType == "YS") {
        if (this.Weight <= 20) {
          spinValues = this.modelServices.getVendedValidation().SoftFinalSpin.r1.values;
        }
        else if (this.Weight > 20 && this.Weight <= 30) {
          spinValues = this.modelServices.getVendedValidation().SoftFinalSpin.r2.values;
        }
        else {
          spinValues = this.modelServices.getVendedValidation().SoftFinalSpin.r3.values
        }
      }
      else {
        if (this.Weight <= 20) {
          spinValues = this.modelServices.getVendedValidation().RigidFinalSpin.r1.values;
        }
        else if (this.Weight > 20 && this.Weight <= 30) {
          spinValues = this.modelServices.getVendedValidation().RigidFinalSpin.r2.values;
        }
        else {
          spinValues = this.modelServices.getVendedValidation().RigidFinalSpin.r3.values
        }
      }
      if (spinValues !== undefined) {
        twoBytes = this.CalculateBytes(model.SpinSpeedLow);
        spin_Low1 = twoBytes[0];
        spin_Low2 = twoBytes[1];

        twoBytes = this.CalculateBytes(model.SpinSpeedMedium);
        spin_Med1 = twoBytes[0];
        spin_Med2 = twoBytes[1];

        twoBytes = this.CalculateBytes(model.SpinSpeedHigh);
        spin_High1 = twoBytes[0];
        spin_High2 = twoBytes[1];
      }
    }
    this.arrMachineConfigSettings.push(spin_Low1);
    this.arrMachineConfigSettings.push(spin_Low2);
    this.arrMachineConfigSettings.push(spin_Med1);
    this.arrMachineConfigSettings.push(spin_Med2);
    this.arrMachineConfigSettings.push(spin_High1);
    this.arrMachineConfigSettings.push(spin_High2);
    this.arrMachineConfigSettings.push(model.RecirculationDrainValve);
    this.arrMachineConfigSettings.push(model.RecirculationWaterValve);
    this.arrMachineConfigSettings.push(model.inputDPort1);
    this.arrMachineConfigSettings.push(model.inputDPort2);
    this.arrMachineConfigSettings.push(model.inputDPort3);
    this.arrMachineConfigSettings.push(model.inputDPort4);
    this.arrMachineConfigSettings.push(model.inputFPort1);
    this.arrMachineConfigSettings.push(model.inputFPort2);
    this.arrMachineConfigSettings.push(model.inputFPort3);
    this.arrMachineConfigSettings.push(model.inputFPort4);
    this.arrMachineConfigSettings.push(model.inputBPort1);
    this.arrMachineConfigSettings.push(model.inputBPort2);
    this.arrMachineConfigSettings.push(model.inputBPort3);
    this.arrMachineConfigSettings.push(model.inputBPort4);
    this.arrMachineConfigSettings.push(model.ECOnePort1);
    this.arrMachineConfigSettings.push(model.ECOnePort2);
    this.arrMachineConfigSettings.push(model.ECOnePort3);
    this.arrMachineConfigSettings.push(model.ECOnePort4);
    this.arrMachineConfigSettings.push(model.ECTwoPort1);
    this.arrMachineConfigSettings.push(model.ECTwoPort2);
    this.arrMachineConfigSettings.push(model.ECTwoPort3);
    this.arrMachineConfigSettings.push(model.ECTwoPort4);
    this.arrMachineConfigSettings.push(model.ECThreePort1);
    this.arrMachineConfigSettings.push(model.ECThreePort2);
    this.arrMachineConfigSettings.push(model.ECThreePort3);
    this.arrMachineConfigSettings.push(model.ECThreePort4);
    this.arrMachineConfigSettings.push(model.ECFourPort1);
    this.arrMachineConfigSettings.push(model.ECFourPort2);
    this.arrMachineConfigSettings.push(model.ECFourPort3);
    this.arrMachineConfigSettings.push(model.ECFourPort4);
    this.arrMachineConfigSettings.push(model.ECFivePort1);
    this.arrMachineConfigSettings.push(model.ECFivePort2);
    this.arrMachineConfigSettings.push(model.ECFivePort3);
    this.arrMachineConfigSettings.push(model.ECFivePort4);
    this.arrMachineConfigSettings.push(model.inputSanTemp);
    this.arrMachineConfigSettings.push(model.inputSanTime);
  }


  setToDefaultALL() {
    this.setToDefault();
    this.BindDefaultValues();
  }

  /***************************** CHANGE EVENTS***************************** */
  inputColdTemp() {

    if (this.model.inputColdTemp > this.cold.Max) {
      this.model.inputColdTemp = this.cold.Max;
      this.model.inputColdTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputColdTemp);
    }
    if (!this.model.inputColdTemp || this.model.inputColdTemp < this.cold.Min) {
      this.model.inputColdTemp = this.cold.Default;
      this.model.inputColdTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputColdTemp);
    }
  }

  inputCoolTemp() {
    if (this.model.inputCoolTemp > this.cool.Max) {
      this.model.inputCoolTemp = this.cool.Max;
      this.model.inputCoolTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputCoolTemp);
    }
    if (this.model.inputCoolTemp < this.cool.Min) {
      this.model.inputCoolTemp = this.cool.Min;
      this.model.inputCoolTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputCoolTemp);
    }
  }

  inputWarmTemp() {

    if (this.model.inputWarmTemp > this.warm.Max) {
      this.model.inputWarmTemp = this.warm.Max;
      this.model.inputWarmTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputWarmTemp);
    }
    if (this.model.inputWarmTemp < this.warm.Min) {
      this.model.inputWarmTemp = this.warm.Min;
      this.model.inputWarmTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputWarmTemp);
    }
  }

  inputHotTemp() {
    if (this.model.inputHotTemp > this.hot.Max) {
      this.model.inputHotTemp = this.hot.Max;
      this.model.inputHotTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputHotTemp);
    }
    if (this.model.inputHotTemp < this.hot.Min) {
      this.model.inputHotTemp = this.hot.Min;
      this.model.inputHotTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputHotTemp);
    }
  }

  inputSanTemp() {
    if (this.model.inputSanTemp > this.sanTemp.Max) {
      this.model.inputSanTemp = this.sanTemp.Max;
      this.model.inputSanTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputSanTemp);
    }
    if (this.model.inputSanTemp < this.sanTemp.Min) {
      this.model.inputSanTemp = this.sanTemp.Min;
      this.model.inputSanTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputSanTemp);
    }
  }

  inputSanTime() {
    if (this.model.inputSanTime > this.options.Sanitory.Time.Max) {
      this.model.inputSanTime = this.options.Sanitory.Time.Max;
    }
    if (this.model.inputSanTime < this.options.Sanitory.Time.Min) {
      this.model.inputSanTime = this.options.Sanitory.Time.Min;
    }
  }

  inputWaterLevel() {
    if (this.model.inputWaterLevel > this.options.WaterLevelBeforeDispensing.Max) {
      this.model.inputWaterLevel = this.options.WaterLevelBeforeDispensing.Max;
    }
    if (!this.model.inputWaterLevel || this.model.inputWaterLevel < this.options.WaterLevelBeforeDispensing.Min) {
      this.model.inputWaterLevel = this.options.WaterLevelBeforeDispensing.Min;
    }
  }

  inputDPort1() {
    if (this.model.inputDPort1 > this.options.DetergentPorts.Max) {
      this.model.inputDPort1 = this.options.DetergentPorts.Max;
    }
    if (!this.model.inputDPort1 || this.model.inputDPort1 < this.options.DetergentPorts.Min) {
      this.model.inputDPort1 = this.options.DetergentPorts.Min;
    }
  }

  inputDPort2() {
    if (this.model.inputDPort2 > this.options.DetergentPorts.Max) {
      this.model.inputDPort2 = this.options.DetergentPorts.Max;
    }

    if (!this.model.inputDPort2 || this.model.inputDPort2 < this.options.DetergentPorts.Min) {
      this.model.inputDPort2 = this.options.DetergentPorts.Min;
    }
  }

  inputDPort3() {
    if (this.model.inputDPort3 > this.options.DetergentPorts.Max) {
      this.model.inputDPort3 = this.options.DetergentPorts.Max;
    }
    if (!this.model.inputDPort3 || this.model.inputDPort3 < this.options.DetergentPorts.Min) {
      this.model.inputDPort3 = this.options.DetergentPorts.Min;
    }
  }

  inputDPort4() {
    if (this.model.inputDPort4 > this.options.DetergentPorts.Max) {
      this.model.inputDPort4 = this.options.DetergentPorts.Max;
    }
    if (!this.model.inputDPort4 || this.model.inputDPort4 < this.options.DetergentPorts.Min) {
      this.model.inputDPort4 = this.options.DetergentPorts.Min;
    }
  }

  inputBPort1() {
    if (this.model.inputBPort1 > this.options.BleachPorts.Max) {
      this.model.inputBPort1 = this.options.BleachPorts.Max;
    }
    if (!this.model.inputBPort1 || this.model.inputBPort1 < this.options.BleachPorts.Min) {
      this.model.inputBPort1 = this.options.BleachPorts.Min;
    }

  }

  inputBPort2() {
    if (this.model.inputBPort2 > this.options.BleachPorts.Max) {
      this.model.inputBPort2 = this.options.BleachPorts.Max;
    }
    if (!this.model.inputBPort2 || this.model.inputBPort2 < this.options.BleachPorts.Min) {
      this.model.inputBPort2 = this.options.BleachPorts.Min;
    }
  }

  inputBPort3() {
    if (this.model.inputBPort3 > this.options.BleachPorts.Max) {
      this.model.inputBPort3 = this.options.BleachPorts.Max;
    }
    if (!this.model.inputBPort3 || this.model.inputBPort3 < this.options.BleachPorts.Min) {
      this.model.inputBPort3 = this.options.BleachPorts.Min;
    }
  }

  inputBPort4() {
    if (this.model.inputBPort4 > this.options.BleachPorts.Max) {
      this.model.inputBPort4 = this.options.BleachPorts.Max;
    }
    if (!this.model.inputBPort4 || this.model.inputBPort4 < this.options.BleachPorts.Min) {
      this.model.inputBPort4 = this.options.BleachPorts.Min;
    }

  }

  inputFPort1() {
    if (this.model.inputFPort1 > this.options.FabricSoftenerPort.Max) {
      this.model.inputFPort1 = this.options.FabricSoftenerPort.Max;
    }
    if (!this.model.inputFPort1 || this.model.inputFPort1 < this.options.FabricSoftenerPort.Min) {
      this.model.inputFPort1 = this.options.FabricSoftenerPort.Min;
    }
  }

  inputFPort2() {
    if (this.model.inputFPort2 > this.options.FabricSoftenerPort.Max) {
      this.model.inputFPort2 = this.options.FabricSoftenerPort.Max;
    }
    if (!this.model.inputFPort2 || this.model.inputFPort2 < this.options.FabricSoftenerPort.Min) {
      this.model.inputFPort2 = this.options.FabricSoftenerPort.Min;
    }
  }

  inputFPort3() {
    if (this.model.inputFPort3 > this.options.FabricSoftenerPort.Max) {
      this.model.inputFPort3 = this.options.FabricSoftenerPort.Max;
    }
    if (!this.model.inputFPort3 || this.model.inputFPort3 < this.options.FabricSoftenerPort.Min) {
      this.model.inputFPort3 = this.options.FabricSoftenerPort.Min;
    }
  }

  inputFPort4() {
    if (this.model.inputFPort4 > this.options.FabricSoftenerPort.Max) {
      this.model.inputFPort4 = this.options.FabricSoftenerPort.Max;
    }
    if (!this.model.inputFPort4 || this.model.inputFPort4 < this.options.FabricSoftenerPort.Min) {
      this.model.inputFPort4 = this.options.FabricSoftenerPort.Min;
    }
  }

  inputEC1Port1() {
    if (this.model.ECOnePort1 > this.extChemicalPortMax) {
      this.model.ECOnePort1 = this.extChemicalPortMax;
    }
    if (!this.model.ECOnePort1 || this.model.ECOnePort1 < this.extChemicalPortMin) {
      this.model.ECOnePort1 = this.extChemicalPortMin;
    }
  }

  inputEC1Port2() {
    if (this.model.ECOnePort2 > this.extChemicalPortMax) {
      this.model.ECOnePort2 = this.extChemicalPortMax;
    }
    if (!this.model.ECOnePort2 || this.model.ECOnePort2 < this.extChemicalPortMin) {
      this.model.ECOnePort2 = this.extChemicalPortMin;
    }
  }

  inputEC1Port3() {
    if (this.model.ECOnePort3 > this.extChemicalPortMax) {
      this.model.ECOnePort3 = this.extChemicalPortMax;
    }
    if (!this.model.ECOnePort3 || this.model.ECOnePort3 < this.extChemicalPortMin) {
      this.model.ECOnePort3 = this.extChemicalPortMin;
    }
  }

  inputEC1Port4() {
    if (this.model.ECOnePort4 > this.extChemicalPortMax) {
      this.model.ECOnePort4 = this.extChemicalPortMax;
    }
    if (!this.model.ECOnePort4 || this.model.ECOnePort4 < this.extChemicalPortMin) {
      this.model.ECOnePort4 = this.extChemicalPortMin;
    }
  }

  inputEC2Port1() {
    if (this.model.ECTwoPort1 > this.extChemicalPortMax) {
      this.model.ECTwoPort1 = this.extChemicalPortMax;
    }
    if (!this.model.ECTwoPort1 || this.model.ECTwoPort1 < this.extChemicalPortMin) {
      this.model.ECTwoPort1 = this.extChemicalPortMin;
    }
  }

  inputEC2Port2() {
    if (this.model.ECTwoPort2 > this.extChemicalPortMax) {
      this.model.ECTwoPort2 = this.extChemicalPortMax;
    }
    if (!this.model.ECTwoPort2 || this.model.ECTwoPort2 < this.extChemicalPortMin) {
      this.model.ECTwoPort2 = this.extChemicalPortMin;
    }
  }

  inputEC2Port3() {
    if (this.model.ECTwoPort3 > this.extChemicalPortMax) {
      this.model.ECTwoPort3 = this.extChemicalPortMax;
    }
    if (!this.model.ECTwoPort3 || this.model.ECTwoPort3 < this.extChemicalPortMin) {
      this.model.ECTwoPort3 = this.extChemicalPortMin;
    }
  }

  inputEC2Port4() {
    if (this.model.ECTwoPort4 > this.extChemicalPortMax) {
      this.model.ECTwoPort4 = this.extChemicalPortMax;
    }
    if (!this.model.ECTwoPort4 || this.model.ECTwoPort4 < this.extChemicalPortMin) {
      this.model.ECTwoPort4 = this.extChemicalPortMin;
    }
  }

  inputEC3Port1() {
    if (this.model.ECThreePort1 > this.extChemicalPortMax) {
      this.model.ECThreePort1 = this.extChemicalPortMax;
    }
    if (!this.model.ECThreePort1 || this.model.ECThreePort1 < this.extChemicalPortMin) {
      this.model.ECThreePort1 = this.extChemicalPortMin;
    }
  }

  inputEC3Port2() {
    if (this.model.ECThreePort2 > this.extChemicalPortMax) {
      this.model.ECThreePort2 = this.extChemicalPortMax;
    }
    if (!this.model.ECThreePort2 || this.model.ECThreePort2 < this.extChemicalPortMin) {
      this.model.ECThreePort2 = this.extChemicalPortMin;
    }
  }

  inputEC3Port3() {
    if (this.model.ECThreePort3 > this.extChemicalPortMax) {
      this.model.ECThreePort3 = this.extChemicalPortMax;
    }
    if (!this.model.ECThreePort3 || this.model.ECThreePort3 < this.extChemicalPortMin) {
      this.model.ECThreePort3 = this.extChemicalPortMin;
    }
  }

  inputEC3Port4() {
    if (this.model.ECThreePort4 > this.extChemicalPortMax) {
      this.model.ECThreePort4 = this.extChemicalPortMax;
    }
    if (!this.model.ECThreePort4 || this.model.ECThreePort4 < this.extChemicalPortMin) {
      this.model.ECThreePort4 = this.extChemicalPortMin;
    }
  }

  inputEC4Port1() {
    if (this.model.ECFourPort1 > this.extChemicalPortMax) {
      this.model.ECFourPort1 = this.extChemicalPortMax;
    }
    if (!this.model.ECFourPort1 || this.model.ECFourPort1 < this.extChemicalPortMin) {
      this.model.ECFourPort1 = this.extChemicalPortMin;
    }
  }

  inputEC4Port2() {
    if (this.model.ECFourPort2 > this.extChemicalPortMax) {
      this.model.ECFourPort2 = this.extChemicalPortMax;
    }
    if (!this.model.ECFourPort2 || this.model.ECFourPort2 < this.extChemicalPortMin) {
      this.model.ECFourPort2 = this.extChemicalPortMin;
    }
  }

  inputEC4Port3() {
    if (this.model.ECFourPort3 > this.extChemicalPortMax) {
      this.model.ECFourPort3 = this.extChemicalPortMax;
    }
    if (!this.model.ECFourPort3 || this.model.ECFourPort3 < this.extChemicalPortMin) {
      this.model.ECFourPort3 = this.extChemicalPortMin;
    }
  }

  inputEC4Port4() {
    if (this.model.ECFourPort4 > this.extChemicalPortMax) {
      this.model.ECFourPort4 = this.extChemicalPortMax;
    }

    if (!this.model.ECFourPort4 || this.model.ECFourPort4 < this.extChemicalPortMin) {
      this.model.ECFourPort4 = this.extChemicalPortMin;
    }
  }

  inputEC5Port1() {
    if (this.model.ECFivePort1 > this.extChemicalPortMax) {
      this.model.ECFivePort1 = this.extChemicalPortMax;
    }
    if (!this.model.ECFivePort1 || this.model.ECFivePort1 < this.extChemicalPortMin) {
      this.model.ECFivePort1 = this.extChemicalPortMin;
    }
  }

  inputEC5Port2() {
    if (this.model.ECFivePort2 > this.extChemicalPortMax) {
      this.model.ECFivePort2 = this.extChemicalPortMax;
    }
    if (!this.model.ECFivePort2 || this.model.ECFivePort2 < this.extChemicalPortMin) {
      this.model.ECFivePort2 = this.extChemicalPortMin;
    }
  }

  inputEC5Port3() {
    if (this.model.ECFivePort3 > this.extChemicalPortMax) {
      this.model.ECFivePort3 = this.extChemicalPortMax;
    }
    if (!this.model.ECFivePort3 || this.model.ECFivePort3 < this.extChemicalPortMin) {
      this.model.ECFivePort3 = this.extChemicalPortMin;
    }
  }

  inputEC5Port4() {
    if (this.model.ECFivePort4 > this.extChemicalPortMax) {
      this.model.ECFivePort4 = this.extChemicalPortMax;
    }
    if (!this.model.ECFivePort4 || this.model.ECFivePort4 < this.extChemicalPortMin) {
      this.model.ECFivePort4 = this.extChemicalPortMin;
    }
  }

  /***************************** CHANGE EVENTS***************************** */
  setToDefault() {
    this.waterInletValves = this.returnResult(this.options.WaterInletValves);
    if (this.Weight < 55) //If check of less than 55
      this.numberofDrains = this.returnResult(this.options.NumberofDrains.ls55);
    else
      this.numberofDrains = this.returnResult(this.options.NumberofDrains.gt55);
    this.balanceRetries = this.returnResult(this.options.BalanceRetries);
    this.BindDropDown("ddlWaterInlet", this.waterInletValves);
    this.BindDropDown("ddlNumberDrains", this.numberofDrains);
    this.BindDropDown("ddlOffBalance", this.balanceRetries);

    if (this.ModelType == 'YR') {
      this.cool = this.returnResult(this.options.VendedSoft) === undefined ? undefined : this.options.VendedSoft.CoolTemperature;
      this.cold = this.returnResult(this.options.VendedSoft) === undefined ? undefined : this.options.VendedSoft.ColdTemperature;
      this.warm = this.returnResult(this.options.VendedSoft) === undefined ? undefined : this.options.VendedSoft.WarmTemperature;
      this.hot = this.returnResult(this.options.VendedSoft) === undefined ? undefined : this.options.VendedSoft.HotTemperature;
    }
    else {
      this.cool = this.returnResult(this.options.VendedRigid) === undefined ? undefined : this.options.VendedRigid.CoolTemperature;
      this.cold = this.returnResult(this.options.VendedRigid) === undefined ? undefined : this.options.VendedRigid.ColdTemperature;
      this.warm = this.returnResult(this.options.VendedRigid) === undefined ? undefined : this.options.VendedRigid.WarmTemperature;
      this.hot = this.returnResult(this.options.VendedRigid) === undefined ? undefined : this.options.VendedRigid.HotTemperature;
    }

    this.BindTextBox("inputColdTemp", this.cold); this.BindTextBox("inputCoolTemp", this.cool);
    this.BindTextBox("inputWarmTemp", this.warm); this.BindTextBox("inputHotTemp", this.hot);

    this.BindTextBox("inputWaterLevel", this.returnResult(this.options.WaterLevelBeforeDispensing));

    this.LoadPortValues("inputDPort", this.returnResult(this.options.DetergentPorts));
    this.LoadPortValues("inputBPort", this.returnResult(this.options.BleachPorts));
    this.LoadPortValues("inputFPort", this.returnResult(this.options.FabricSoftenerPort));

    if (this.ProductType == "PN") {
      this.LoadPortValues("ECOnePort", this.returnResult(this.options.ExternalChemical1Port));
      this.LoadPortValues("ECTwoPort", this.returnResult(this.options.ExternalChemical2Port));
      this.LoadPortValues("ECThreePort", this.returnResult(this.options.ExternalChemical3Port));
      this.LoadPortValues("ECFourPort", this.returnResult(this.options.ExternalChemical4Port));
      this.LoadPortValues("ECFivePort", this.returnResult(this.options.ExternalChemical5Port));
      this.sanTemp = this.options.Sanitory.Temperature;
      this.BindTextBox("inputSanTemp", this.sanTemp);
      this.setFarn('inputSanTemp', 'inputSanTempF');
      this.BindTextBox("inputSanTime", this.returnResult(this.options.Sanitory.Time));

    }
    this.setFarn('inputColdTemp', 'inputColdTempF'); this.setFarn('inputCoolTemp', 'inputCoolTempF');
    this.setFarn('inputWarmTemp', 'inputWarmTempF'); this.setFarn('inputHotTemp', 'inputHotTempF');
    //State Management
  }

  BindDefaultValues() {
    this.model.ddlWaterInlet = this.waterInletValves.Default;//"2"; 

    this.model.ddlNumberDrains = this.numberofDrains.Default;//"1"; 

    this.model.ddlOffBalance = this.balanceRetries.Default;//"3"; 

    this.model.inputColdTemp = this.returnResult(this.cold) === undefined ? "0" : this.cold.Default;//"2"; 

    this.model.inputCoolTemp = this.returnResult(this.cool) === undefined ? "0" : this.cool.Default;//"18"; 

    this.model.inputWarmTemp = this.returnResult(this.warm) === undefined ? "0" : this.warm.Default;//"32"; 

    this.model.inputHotTemp = this.returnResult(this.hot) === undefined ? "0" : this.hot.Default;//"49"; 

    this.model.inputSanTemp = this.returnResult(this.sanTemp) === undefined ? "0" : this.sanTemp.Default;//"49";

    this.model.ExternalChemicalDispenser = "0";

    this.model.inputWaterLevel = this.options.WaterLevelBeforeDispensing.Default;//"0"; 

    this.model.inputDPort1 = this.options.DetergentPorts.Default;//"0"; 

    this.model.inputDPort2 = this.options.DetergentPorts.Default;//"0"; 

    this.model.inputDPort3 = this.options.DetergentPorts.Default;//"0"; 

    this.model.inputDPort4 = this.options.DetergentPorts.Default;//"0"; 

    this.model.inputBPort1 = this.options.BleachPorts.Default;//"0"; 

    this.model.inputBPort2 = this.options.BleachPorts.Default;//"0"; 

    this.model.inputBPort3 = this.options.BleachPorts.Default;//"0"; 

    this.model.inputBPort4 = this.options.BleachPorts.Default;//"0"; 

    this.model.inputFPort1 = this.options.FabricSoftenerPort.Default;//"0"; 

    this.model.inputFPort2 = this.options.FabricSoftenerPort.Default;//"0"; 

    this.model.inputFPort3 = this.options.FabricSoftenerPort.Default;//"0"; 

    this.model.inputFPort4 = this.options.FabricSoftenerPort.Default;//"0"; 
    if (this.ProductType == "PN") {
      this.model.ECOnePort1 = this.options.ExternalChemical1Port.Default;//"0"; 

      this.model.ECOnePort2 = this.options.ExternalChemical1Port.Default;//"0"; 

      this.model.ECOnePort3 = this.options.ExternalChemical1Port.Default;//"0"; 

      this.model.ECOnePort4 = this.options.ExternalChemical1Port.Default;//"0"; 

      this.model.ECTwoPort1 = this.options.ExternalChemical2Port.Default;//"0"; 

      this.model.ECTwoPort2 = this.options.ExternalChemical2Port.Default;//"0"; 

      this.model.ECTwoPort3 = this.options.ExternalChemical2Port.Default;//"0"; 

      this.model.ECTwoPort4 = this.options.ExternalChemical2Port.Default;//"0"; 

      this.model.ECThreePort1 = this.options.ExternalChemical3Port.Default;//"0"; 

      this.model.ECThreePort2 = this.options.ExternalChemical3Port.Default;//"0"; 

      this.model.ECThreePort3 = this.options.ExternalChemical3Port.Default;//"0"; 

      this.model.ECThreePort4 = this.options.ExternalChemical3Port.Default;//"0"; 

      this.model.ECFourPort1 = this.options.ExternalChemical4Port.Default;//"0"; 

      this.model.ECFourPort2 = this.options.ExternalChemical4Port.Default;//"0"; 

      this.model.ECFourPort3 = this.options.ExternalChemical4Port.Default;//"0"; 

      this.model.ECFourPort4 = this.options.ExternalChemical4Port.Default;//"0"; 


      this.model.ECFivePort1 = this.options.ExternalChemical5Port.Default;//"0"; 

      this.model.ECFivePort2 = this.options.ExternalChemical5Port.Default;//"0"; 

      this.model.ECFivePort3 = this.options.ExternalChemical5Port.Default;//"0"; 

      this.model.ECFivePort4 = this.options.ExternalChemical5Port.Default;//"0"; 

      this.model.inputSanTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputSanTemp);
      this.model.inputSanTime = this.options.Sanitory.Time.Default;

    }
    this.model.checked = this.options.ExternalChemicalDispenserAvailable;
    this.model.inputWarmTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputWarmTemp);
    this.model.inputColdTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputColdTemp);
    this.model.inputCoolTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputCoolTemp);
    this.model.inputHotTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputHotTemp);
    this.modelServices.vendingTemp.WARM = this.model.inputWarmTemp;
    this.modelServices.vendingTemp.COLD = this.model.inputColdTemp;
    this.modelServices.vendingTemp.COOL = this.model.inputCoolTemp;
    this.modelServices.vendingTemp.HOT = this.model.inputHotTemp;

    if (this.modelServices.modelSetup !== undefined) {
      this.model = this.modelServices.modelSetup;
    }
    else {
      this.modelServices.modelSetup = this.model;
    }
  }

  LoadPortValues(elementId: any, Item: any) {
    var NewElementId = "";
    for (var count = 1; count < 5; count++) {
      NewElementId = elementId + count;
      this.BindTextBox(NewElementId, Item);
      NewElementId = "";
    }
  }

  BindDropDown(elementId: any, Item: any) {
    var select = (document.getElementById(elementId) as HTMLSelectElement);
    if (Item === undefined) {
      select.disabled = true;
      select.classList.add("disableControl");
    }
    else {
      select.disabled = false;
      select.classList.remove("disableControl");
      select.innerHTML = "";
      this.elmts = Item.values;
      for (var i = 0; i < this.elmts.length; i++) {
        var optn = this.elmts[i];
        var el = document.createElement("option");
        el.text = String(optn.value);
        el.value = String(optn.value);
        select.appendChild(el);
      }
      select.value = Item.Default;
    }
  }

  BindTextBox(elementId: any, item: any) {
    var txtBox = (document.getElementById(elementId) as HTMLInputElement);
    if (item === undefined) {
      txtBox.readOnly = true;
      txtBox.classList.add("disableControl");
      txtBox.min = "0"; txtBox.max = "0"; txtBox.value = "0";
    }
    else {
      txtBox.readOnly = false;
      txtBox.classList.remove("disableControl");
      txtBox.min = item.Min; txtBox.max = item.Max; txtBox.value = item.Default;
    }

  }

  returnResult(_value: any) {
    var value = _value;
    return value;
  }


  setFarn(source: any, destination: any) {
    (<HTMLInputElement>document.getElementById(destination)).value = this.modelServices.ConvertToFahrenheitService((<HTMLInputElement>document.getElementById(source)).value);
  }

  setFarnModel(temp: string) {
    switch (temp) {
      case "cool":
        this.model.inputCoolTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputCoolTemp);
        break;
      case "cold":
        this.model.inputColdTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputColdTemp);
        break;
      case "hot":
        this.model.inputHotTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputHotTemp);
        break;
      case "warm":
        this.model.inputWarmTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputWarmTemp);
        break;
      case "santemp":
        this.model.inputSanTempF = this.modelServices.ConvertToFahrenheitService(this.model.inputSanTemp);
        break;
      default:
        console.log("No Choice");
        break;

    }

  }

  public toggle(event: any) {
    this.isDisabled = event.checked;
  }

  onChange(enable: boolean) {
    if (enable)
      this.checked = true;
    else
      this.checked = false;
    if (this.modelServices.modelSetup)
      this.modelServices.modelSetup.checked = this.checked;
  }

  isdisableDive(value: boolean) {
    if (value)
      this.divDispenser.nativeElement.classList.remove('disableDiv');
    else
      this.divDispenser.nativeElement.classList.add('disableDiv');
  }

  changeDrain(event) {
    this.commonServices.noDrainChange = String(event);
  }

  ngOnDestroy(): void {
    this.modelServices.modelSetup = this.model;
  }
}
