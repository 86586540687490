import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { filter } from 'rxjs/operators';
import { routerTransition } from '../app/configs/config';
import { AuthenticationService } from '../app/services/AuthenticationService';
import { AppSettings } from '../app/shared/constant/AppSettings';
import { CommonServices } from './services/CommonServices';


@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  animations: [routerTransition()],
  host: { '[@routerTransition]': '' }
})
export class AppComponent {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  previousUrl: string = null;
  currentUrl: string = null;

  constructor(private authenticationService: AuthenticationService, private router: Router, private idle: Idle, private keepalive: Keepalive, private commonService: CommonServices) {


    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // console.log("Previous URL:" + this.previousUrl);
      // console.log("Current URL:" + this.currentUrl);
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });

    idle.setIdle(AppSettings.IDLE);
    idle.setTimeout(AppSettings.TIMEOUT);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
      console.log("Session Timeout :" + this.idleState);
    });

    idle.onTimeout.subscribe(() => {

      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.authenticationService.logout();
      this.router.navigate(['/login']);
      console.log("Session Timeout :" + this.idleState);
      this.commonService.getProgressClose("Good Bye");
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      console.log("Session Timeout :" + this.idleState);
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      this.commonService.getLoadingBar('You will time out in ' + countdown + ' seconds!');
      console.log("Session Timeout :" + this.idleState);
    });


    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.authenticationService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    })
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    console.log("Session Timeout :" + this.idleState);
    this.timedOut = false;
  }
}