import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
    HttpResponseBase
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../services/AuthenticationService';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    public currentUser: any;
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.currentUser = JSON.parse(localStorage.getItem("currentUserToken"));
        return next.handle(request).pipe(catchError(response => {
            if (response instanceof HttpErrorResponse) {
                // Check if this error has a 2xx status
                if (this.is2xxStatus(response)) {
                    // Convert the error to a standard response with a null body and then return
                    return of(new HttpResponse({
                        headers: response.headers,
                        status: response.status,
                        statusText: response.error.text,
                        url: response.url,
                        body: response.error.text
                    }));
                }
            }
            if ([401, 403].indexOf(response.status) !== -1) {
                this.authenticationService.logout();
                location.reload();
            }
            const error = response.error.message || response.error.status;
            if (!error) {
                if (this.currentUser) {
                    if (response.url.split('/')[5] == 'updateAccount') {
                        return throwError(response.error);
                    }
                    this.authenticationService.logout();
                    location.reload();
                    return throwError('Session Expires!!');
                }
            }
            return throwError(error);
        }))
    }

    private is2xxStatus(response: HttpResponseBase) {
        return response.status >= 200 && response.status < 300 && response.statusText === 'OK';
    }
}
