
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonServices } from 'src/app/services/CommonServices';
import { MenuNames } from '../../enums/enum';
declare const $: any;

@Component({
  selector: 'app-topfeatures',
  templateUrl: './topfeatures.component.html',
  styleUrls: ['./topfeatures.component.scss']
})
export class TopfeaturesComponent implements OnInit {
  @Input() selectedTab: string = '';
  @ViewChild('topfeatures', { static: false }) input: ElementRef<HTMLInputElement>;
  @ViewChild('topSetup', { static: false }) setup: ElementRef<HTMLInputElement>;
  @ViewChild('topHMIDisplay', { static: false }) HMIDisplay: ElementRef<HTMLInputElement>;
  @ViewChild('topCycleSetting', { static: false }) cycleSetting: ElementRef<HTMLInputElement>;
  @ViewChild('topfeaturesVending', { static: false }) dvVending: ElementRef<HTMLDivElement>;

  elementnew = $("#" + localStorage.getItem("lastActiveTab"));
  showButtons: Subscription;
  isDisabled: boolean = true;
  isDisableVendingSetup: boolean = true;
  isDisableVendingCycle: boolean = false;
  selectedMenu: string;
  MlgMle: string;
  TLDryer: string;
  TLWasher: string;
  FLDryer: string;
  FLWasher: string;

  constructor(
    private commonServices: CommonServices,
    private router: Router
  ) {
    this.showButtons = this.commonServices.getTopFeaturesDisable().subscribe((data) => {
      this.disableDiv(data);
    })
  }

  ngOnInit() {
    this.MlgMle = MenuNames.MlgMle
    this.TLDryer = MenuNames.TLDryer
    this.TLWasher = MenuNames.TLWasher
    this.FLDryer = MenuNames.FLDryer
    this.FLWasher = MenuNames.FLWasher
    this.commonServices.defaultScreen.subscribe((val: any) => {
      this.selectedTab = "nav-tab";
    });
    this.commonServices.disableTopFeaure.subscribe((data) => {
      this.input.nativeElement.classList.add('disableDiv')
      this.selectedTab = "nav-tab";
    });
    this.readBinaryConfig();
    this.readModelConfig();
  }

  readBinaryConfig() {
    this.commonServices.selectTab.subscribe((tab) => {
      this.onGoToTab(tab.tab);
      if (tab.type == MenuNames.MlgMle) {
        this.commonServices.isCSPSetupTabSelected.next(true);
      } else if (tab.type == MenuNames.MultiLoad) {
        this.commonServices.isSetupTabSelected.next(true);
      }
    });
  }

  readModelConfig() {
    this.commonServices.selectModelTab.subscribe((tab) => {
      this.onGoToTab(tab.tab);
      if (tab.type == MenuNames.MlgMle) {
        const userDefinedConfig = this.commonServices.userDefinedConfig;
        this.commonServices.isEchoModelSelected.next(userDefinedConfig);
      } else if (tab.type == MenuNames.MultiLoad) {
        const userDefinedConfig = this.commonServices.userDefinedConfig;
        this.commonServices.isNovoModelSelected.next(userDefinedConfig);
      }
    });
  }
  
  ngAfterViewInit() {
    if (localStorage.getItem("ProductType") === "PN") {
      this.dvVending.nativeElement.classList.add('disableAnchor');
    } else {
      this.dvVending.nativeElement.classList.remove('disableAnchor');
    }
    if (localStorage.getItem("lastActiveTab") != undefined) {
      this.input.nativeElement.classList.remove('disableDiv');
    }
  }

  disableDiv(data: any) {
    if (!data) {
      this.input.nativeElement.classList.add('disableDiv');
      this.HMIDisplay.nativeElement.classList.add('disableAnchor');
      this.cycleSetting.nativeElement.classList.add('disableAnchor');
      this.dvVending.nativeElement.classList.add('disableAnchor');
      return;
    }
    this.input.nativeElement.classList.remove('disableDiv');
    if (data === "PN") {
      this.HMIDisplay.nativeElement.classList.remove('disableAnchor');
      this.cycleSetting.nativeElement.classList.remove('disableAnchor');
      this.dvVending.nativeElement.classList.add('disableAnchor');
    } else if (localStorage.getItem("SelectedMenu") === MenuNames.MlgMle || localStorage.getItem("SelectedMenu") === MenuNames.TLDryer) {
      this.setup.nativeElement.classList.remove('disableAnchor');
      this.HMIDisplay.nativeElement.classList.add('disableAnchor');
      this.cycleSetting.nativeElement.classList.add('disableAnchor');
      this.dvVending.nativeElement.classList.remove('disableAnchor');
    } else if (localStorage.getItem("SelectedMenu") === MenuNames.TLWasher) {
      this.setup.nativeElement.classList.remove('disableAnchor');
      this.HMIDisplay.nativeElement.classList.add('disableAnchor');
      this.cycleSetting.nativeElement.classList.remove('disableAnchor');
      this.dvVending.nativeElement.classList.remove('disableAnchor');
    } else if (localStorage.getItem("SelectedMenu") === MenuNames.FLDryer) {
      this.setup.nativeElement.classList.remove('disableAnchor');
      this.HMIDisplay.nativeElement.classList.add('disableAnchor');
      this.cycleSetting.nativeElement.classList.add('disableAnchor');
      this.dvVending.nativeElement.classList.remove('disableAnchor');
    } else if (localStorage.getItem("SelectedMenu") === MenuNames.FLWasher) {
      this.setup.nativeElement.classList.remove('disableAnchor');
      this.HMIDisplay.nativeElement.classList.add('disableAnchor');
      this.cycleSetting.nativeElement.classList.remove('disableAnchor');
      this.dvVending.nativeElement.classList.remove('disableAnchor');
    } else {
      this.HMIDisplay.nativeElement.classList.remove('disableAnchor');
      this.cycleSetting.nativeElement.classList.remove('disableAnchor');
      this.dvVending.nativeElement.classList.remove('disableAnchor');
    }
  }

  onGoToTab(id) {
    this.selectedMenu = localStorage.getItem("SelectedMenu");
    localStorage.setItem('lastTabId', this.selectedTab);
    this.selectedTab = id;
    this.commonServices.showButtons();
    this.navigateTo(id);
  }

  navigateTo(type) {
    switch (type) {
      case 'nav-setup-tab':
        if (localStorage.getItem("SelectedMenu") === MenuNames.MlgMle) {
          this.router.navigate(["/venus-setup"]);
          return;
        }
        if (localStorage.getItem("SelectedMenu") === MenuNames.TLDryer) {
          this.router.navigate(["/tl-dryer-setup"]);
          return;
        }
        if (localStorage.getItem("SelectedMenu") === MenuNames.TLWasher) {
          this.router.navigate(["/tl-washer-setup"]);
          return;
        }
        if (localStorage.getItem("SelectedMenu") === MenuNames.FLDryer) {
          this.router.navigate(["/fl-dryer-setup"]);
          return;
        }
        if (localStorage.getItem("SelectedMenu") === MenuNames.FLWasher) {
          this.router.navigate(["/fl-washer-setup"]);
          return;
        }
        this.router.navigate(["/setup"]);
        return;
      case 'nav-HMI-tab':
        this.router.navigate(["/Hmi-setup"]);
        return;
      case 'nav-cycle-tab':
        if (localStorage.getItem("SelectedMenu") === MenuNames.TLWasher) {
          setTimeout(() => {
            this.router.navigate(["/tl-washer-cycle-setting"]);
          }, 100);
          return;
        }
        if (localStorage.getItem("SelectedMenu") === MenuNames.FLWasher) {
          setTimeout(() => {
            this.router.navigate(["/fl-washer-cycle-setting"]);
          }, 100);
          return;
        }
        this.router.navigate(["/cycle-setting"]);
        return;
      case 'nav-vending-tab':
        if (localStorage.getItem("SelectedMenu") === MenuNames.MlgMle) {
          this.router.navigate(["/venus-cycle-vending"]);
          return;
        }
        if (localStorage.getItem("SelectedMenu") === MenuNames.TLDryer) {
          this.router.navigate(["/tl-dryer-cycle-vending"]);
          return;
        }
        if (localStorage.getItem("SelectedMenu") === MenuNames.TLWasher) {

          this.router.navigate(["/tl-washer-cycle-vending"]);
          return;
        }
        if (localStorage.getItem("SelectedMenu") === MenuNames.FLDryer) {
          this.router.navigate(["/fl-dryer-cycle-vending"]);
          return;
        }
        if (localStorage.getItem("SelectedMenu") === MenuNames.FLWasher) {
          this.router.navigate(["/fl-washer-cycle-vending"]);
          return;
        }
        this.router.navigate(["/vending-setting"]);
        return;
    }

  }

}