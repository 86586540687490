import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonServices } from "src/app/services/CommonServices";
import { TlDryerModelServices } from "src/app/services/TlDryerModel.service";
import { ISetupModelList, IValue, ITlDryerSetup, ITlDryerSetupDetail } from "./tl-dryer-setup.model";
declare const $: any;

@Component({
  selector: "app-tl-dryer-setup",
  templateUrl: "./tl-dryer-setup.component.html",
  styleUrls: ["./tl-dryer-setup.component.scss"],
})

export class TLDryerSetupComponent implements OnInit, OnDestroy {
  TlDryerSetupForm: FormGroup;
  model: ISetupModelList = {
    CycleCountingEnable: 0,
    MoneyCountingOption: 0,
    CoinBoxOpenMoneyCount: 0,
    VendingDisplay: 0,
    CoinPaymentEnable: 0,
    DebitCardPaymentProtocol: 0,
    AutoClearEscrowEnable: 0,
    CoinSlideEnable: 0,
    hasValue: false
  }
  value: IValue = {
    id: 0,
    name: ''
  }
  regularOffSet: ITlDryerSetupDetail = {
    value: 0,
    values: this.value,
    Default: 0,
    arrKey: [],
    binaryKey: '',
    type: ''
  }
  tlDryerSettings: ITlDryerSetup = {
    CycleCountingEnable: this.regularOffSet,
    MoneyCountingOption: this.regularOffSet,
    CountViewing: this.regularOffSet,
    VendingDisplay: this.regularOffSet,
    CoinPaymentEnable: this.regularOffSet,
    DebitCardPaymentProtocol: this.regularOffSet,
    AutoClearEscrowEnable: this.regularOffSet,
    CoinSlideEnable: this.regularOffSet,
    hasValue: true
  }
  isSetToDefaultHide: boolean = false;

  constructor(
    private _tlDryerModelServices: TlDryerModelServices,
    private fb: FormBuilder,
    private commonServices: CommonServices,
  ) { }

  ngOnInit() {
    this.isSetToDefaultHide = this.commonServices.isSetToDefaultHide;
    this.getControls();
    this.tlDryerSettings = this._tlDryerModelServices.getSetupModels()
    if (!this.tlDryerSettings) {
      this.tlDryerSettings = this._tlDryerModelServices.getTlDryerSetupSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    } else if (this.tlDryerSettings && !this.tlDryerSettings.hasValue) {
      this.tlDryerSettings = this._tlDryerModelServices.getTlDryerSetupSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    }
  }

  getControls() {
    this.TlDryerSetupForm = this.fb.group({
      cycleCounter: ["", Validators.required],
      moneyCounter: ["", Validators.required],
      vaultViewingOption: ["", Validators.required],
      addCoinOption: ["", Validators.required],
      coinOption: ["", Validators.required],
      debitOption: ["", Validators.required],
      priceSupressionOption: ["", Validators.required],
      clearEscrowOption: ["", Validators.required],
      coinSlideEnable: ["", Validators.required],
    });
  }

  setToDefault() {
    this.tlDryerSettings.CycleCountingEnable.value = this.tlDryerSettings.CycleCountingEnable.Default;
    this.tlDryerSettings.MoneyCountingOption.value = this.tlDryerSettings.MoneyCountingOption.Default;
    this.tlDryerSettings.CountViewing.value = this.tlDryerSettings.CountViewing.Default;
    this.tlDryerSettings.VendingDisplay.value = this.tlDryerSettings.VendingDisplay.Default;
    this.tlDryerSettings.CoinPaymentEnable.value = this.tlDryerSettings.CoinPaymentEnable.Default;
    this.tlDryerSettings.DebitCardPaymentProtocol.value = this.tlDryerSettings.DebitCardPaymentProtocol.Default;
    this.tlDryerSettings.AutoClearEscrowEnable.value = this.tlDryerSettings.AutoClearEscrowEnable.Default;
    this.tlDryerSettings.CoinSlideEnable.value = this.tlDryerSettings.CoinSlideEnable.Default;
    this.tlDryerSettings.hasValue = true;
  }

  returnResult(_value: any) {
    const value = _value;
    return value;
  }

  ngOnDestroy() {
    const isModel = this._tlDryerModelServices.modelChanged();
    if (!isModel) {
      this._tlDryerModelServices.setSetupModels(this.tlDryerSettings);
    }
  }

}