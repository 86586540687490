import { E } from "@angular/cdk/keycodes";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  // releaseNotes(event) {
  //   window.open(
  //     "/home/arunsahani/ARUNSAHANI/PROJECTS/USB_PROJECT/Release Note/ReleaseNotes.htm",
  //     "_blank"
  //   );
  //   event.preventDefault();
  // }
}
