import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModelServices } from 'src/app/services/ModelServices';
import { CommonServices } from 'src/app/services/CommonServices';
import { ResetFormService } from 'src/app/services/ResetService';
import { DataGetSetService } from 'src/app/services/DataGetSetService';
import { VendingSettingDetail } from '../../../models/vendingpayment';
import { NovoFileNames } from '../../../enums/enum';

@Component({
  selector: 'app-cyclevending',
  templateUrl: './cyclevending.component.html',
  styleUrls: ['./cyclevending.component.scss'],

})
export class CyclevendingComponent implements OnInit {
  options: any;
  elmts; any;
  arrCycleVendingDetail: any = new Array();
  vendingSettingDetail: VendingSettingDetail = new VendingSettingDetail();
  constructor(
    private modelServices: ModelServices,
    private commonServices: CommonServices,
    private resetFormService: ResetFormService,
    public datagetsetService: DataGetSetService,
    private cdr: ChangeDetectorRef
  ) {
    this.options = this.modelServices.getCyclevendingData();
    this.resetFormService.changeEmitted$.subscribe(data => {
      if (data) {
        this.setToDefault();
      }
    });
  }

  ngOnInit() {
    let paymentMode = document.getElementById("ddPayment") as HTMLSelectElement;
    let ClearEscrow = document.getElementById("ddEscrow") as HTMLSelectElement;
    let vaultPrice = document.getElementById("ddVault") as HTMLSelectElement;
    let vendingDisplay = document.getElementById("ddVending") as HTMLSelectElement;
    let moneyCounter = document.getElementById("ddMoney") as HTMLSelectElement;
    let currencySign = document.getElementById("ddCurrency") as HTMLSelectElement;
    this.commonServices.getVendingSettingValue().subscribe(() => {
      this.vendingSettingDetail.paymentMode = String(this.options.paymentMode === undefined ? 0 : paymentMode.selectedIndex); // - selected index
      this.vendingSettingDetail.escrowOption = String(this.options.ClearEscrow === undefined ? 0 : ClearEscrow.selectedIndex);
      this.vendingSettingDetail.valultViewOption = String(this.options.vaultPrice === undefined ? 0 : vaultPrice.selectedIndex);
      this.vendingSettingDetail.vendingDisplay = String(this.options.vendingDisplay === undefined ? 0 : vendingDisplay.selectedIndex); // - selected index
      this.vendingSettingDetail.moneyCounter = String(this.options.moneyCounter === undefined ? 0 : moneyCounter.selectedIndex);
      this.vendingSettingDetail.currencySign = String(this.options.currencySign === undefined ? 0 : currencySign.selectedIndex + 1);
      this.VendingSettingConfigBytes(this.vendingSettingDetail);
      this.datagetsetService.data = this.vendingSettingDetail;
    });
  }

  ngAfterViewInit() {
    this.setToDefault();
    this.readBinaryConfig();
    this.cdr.detectChanges();
    this.commonServices.setVendingSetting.subscribe((cycleVendingSetting) => {
      const settingData = cycleVendingSetting.cyclevendingObj;
      this.bindBinDropDown("ddPayment", this.returnResult(this.options.paymentMode), this.getDDName(this.options.paymentMode, settingData.paymentMode));
      this.bindBinDropDown("ddEscrow", this.returnResult(this.options.ClearEscrow), this.getDDName(this.options.ClearEscrow, settingData.escrowOption));
      this.bindBinDropDown("ddMoney", this.returnResult(this.options.moneyCounter), this.getDDName(this.options.moneyCounter, settingData.moneyCounter));
      this.bindBinDropDown("ddCurrency", this.returnResult(this.options.currencySign), this.getCurrencyName(this.options.currencySign, settingData.currencySign));
      this.bindBinDropDown("ddVending", this.returnResult(this.options.vendingDisplay), this.getDDName(this.options.vendingDisplay, settingData.vendingDisplay));
      this.bindBinDropDown("ddVault", this.returnResult(this.options.vaultPrice), this.getDDName(this.options.vaultPrice, settingData.valultViewOption));
    });
  }

  readBinaryConfig() {
    this.commonServices.isSetupTabSelected.subscribe((tab) => {
      this.binaryDefaultValues();
    });
    this.binaryDefaultValues();
  }

  binaryDefaultValues() {
    const machineBinaryValue = this.commonServices.getBinaryData(NovoFileNames.cycle_vending_Settings);
    if (!machineBinaryValue || !machineBinaryValue.length) {
      return;
    }
    this.bindBinDropDown("ddPayment", this.returnResult(this.options.paymentMode), this.getDDName(this.options.paymentMode, machineBinaryValue[0].value[55]));
    this.bindBinDropDown("ddEscrow", this.returnResult(this.options.ClearEscrow), this.getDDName(this.options.ClearEscrow, machineBinaryValue[0].value[56]));
    this.bindBinDropDown("ddMoney", this.returnResult(this.options.moneyCounter), this.getDDName(this.options.moneyCounter, machineBinaryValue[0].value[58]));
    this.bindBinDropDown("ddCurrency", this.returnResult(this.options.currencySign), this.getCurrencyName(this.options.currencySign, machineBinaryValue[0].value[59]));
    this.bindBinDropDown("ddVending", this.returnResult(this.options.vendingDisplay), this.getDDName(this.options.vendingDisplay, machineBinaryValue[0].value[54]));
    this.bindBinDropDown("ddVault", this.returnResult(this.options.vaultPrice), this.getDDName(this.options.vaultPrice, machineBinaryValue[0].value[64]));
  }

  getDDName(data, defaultValue) {
    let defValue: string;
    Object.keys(data.values).map((key) => {
      if (key.toString() == defaultValue.toString()) {
        defValue = data.values[key];
      }
    });
    return defValue;
  }

  getCurrencyName(data, defaultValue) {
    let defValue: string;
    const val = defaultValue - 1;
    Object.keys(data.values).map((key) => {
      if (key.toString() == val.toString()) {
        defValue = data.values[key];
      }
    });
    return defValue;
  }

  setToDefault() {
    this.bindDropDown("ddPayment", this.returnResult(this.options.paymentMode), this.returnResult(this.options.paymentMode));
    this.bindDropDown("ddEscrow", this.returnResult(this.options.ClearEscrow), this.returnResult(this.options.ClearEscrow));
    this.bindDropDown("ddMoney", this.returnResult(this.options.moneyCounter), this.returnResult(this.options.moneyCounter));
    this.bindDropDown("ddCurrency", this.returnResult(this.options.currencySign), this.returnResult(this.options.currencySign));
    this.bindDropDown("ddVending", this.returnResult(this.options.vendingDisplay), this.returnResult(this.options.vendingDisplay));
    this.bindDropDown("ddVault", this.returnResult(this.options.vaultPrice), this.returnResult(this.options.vaultPrice));
  }

  bindDropDown(elementId: any, values: any, defaultValue: any) {
    var select = (document.getElementById(elementId) as HTMLSelectElement);
    if (!select) {
      return;
    }
    select.innerHTML = "";
    this.elmts = values.values;
    for (var i = 0; i < this.elmts.length; i++) {
      var optn = this.elmts[i];
      var el = document.createElement("option");
      el.text = String(optn);
      el.value = String(optn);
      select.appendChild(el);
    }
    select.value = defaultValue.Default;
  }

  bindBinDropDown(elementId: any, values: any, defaultValue: any) {
    var select = (document.getElementById(elementId) as HTMLSelectElement);
    if (!select) {
      return;
    }
    select.innerHTML = "";
    this.elmts = values.values;
    for (var i = 0; i < this.elmts.length; i++) {
      var optn = this.elmts[i];
      var el = document.createElement("option");
      el.text = String(optn);
      el.value = String(optn);
      select.appendChild(el);
    }
    select.value = defaultValue;
  }

  returnResult(_value: any) {
    var value = _value;
    return value;
  }

  VendingSettingConfigBytes(cyclevending: any) {
    this.arrCycleVendingDetail = [];
    this.arrCycleVendingDetail.push(cyclevending.paymentMode);
    this.arrCycleVendingDetail.push(cyclevending.escrowOption);
    this.arrCycleVendingDetail.push(cyclevending.valultViewOption);
    this.arrCycleVendingDetail.push(cyclevending.vendingDisplay);
    this.arrCycleVendingDetail.push(cyclevending.moneyCounter);
    this.arrCycleVendingDetail.push(cyclevending.currencySign);
  }
}