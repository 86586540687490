export interface ICompanyList {
    COMPANY_NAME: string;
    COMPANY_ID: number;
}

export interface ICountryList {
    COUNTRY_CODE: string;
    COUNTRY_ID: number;
    COUNTRY_NAME: string;
    STATE_LABEL: string;
    ZIP_CODE: string;
}

export interface userRole {
    roleName: string,
    privilegeList: []
}
export interface userProfileData {
    accountId: number,
    companyId: number,
    firstName: string,
    lastName: string,
    email: string,
    phoneNo: number,
    country: string,
    addressLine: string,
    companyName: string,
    userRolePrivilege: userRole[]
}
export class ErrorList {
    msgEmailVerify: string = 'Email already exists into database.';
    msgCompanyVerify: string = 'Company already exists into database.';
    msgSaveSuccessfully: string = 'Saved successfully!!';
    msgPhoneNumber: string = 'Phone no should be 10 digits.';
}

export interface IUserDetail {
    defaultFirstName: string,
    defaultLastName: string,
    accountId: string,
    firstName: string,
    lastName: string,
    countryId: string,
    email: string,
    phoneNo: string,
    lastUpdateDate: string,
    companyName: string,
    oldPassword: string,
    newPassword: string
    confirmPassword: string
}

