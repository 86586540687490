
export class VendingSettingDetail {
    paymentMode?: string;
    escrowOption?: string;
    valultViewOption?: string;
    vendingDisplay?: string;
    moneyCounter?: string;
    currencySign?: string;

    isSpecialPricing?: boolean;
    specialPriceDay?: any;
    starHour?: number;
    endHour?: number;
    coinFirstDollar?: number;
    coinFirstCent?: number;
    coinSecondDollar?: number;
    coinSecondCent?: number;
    coolerWaterAdderDollar?: number;
    coolerWaterAdderCent?: number;
    warmWaterAdderDollar?: number;
    warmWaterAdderCent?: number;
    hotWaterAdderDollar?: number;
    hotWaterAdderCent?: number;

    detergentAdderDollar?: number;
    detergentAdderCent?: number;
    fabricSoftnerAdderDollar?: number;
    fabricSoftnerAdderCent?: number;
    bleachAdderDollar?: number;
    bleachAdderCent?: number;
    mediumSpeedAdderDollar?: number;
    mediumSpeedAdderCent?: number;
    highSpeedAdderDollar?: number;
    highSpeedAdderCent?: number;
    soilLevelAdderDollar?: number;
    soilLevelAdderCent?: number;
    extraRinseAdderDollar?: number;
    extraRinseAdderCent?: number;

    startHourMin: number;
    starHourMax: number;

    endHourMin: number;
    endHourMax: number;
    soillevel?: number;
    rfu?: string = '0000000000000000000000000';//rfu 25 zeros
}