import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder, FormGroup,
  Validators
} from "@angular/forms";
import { MatCheckboxChange } from "@angular/material";
import { CommonServices } from "src/app/services/CommonServices";
import { VenusModelServices } from "src/app/services/VenusModel.service";
import { ICycleSpecialPrice, IFixedCycleEnable, IRegularPriceCycleLen, IRelationalEnvelopeOpen, ISpecialPriceCycleLen, ISpecialPriceTime, ISpecialPricingEnable, ISundaySpecialPriceEnable, ITimePerCoin, IValueChecked, IValueCoin1, IVended, IVenusCycleModelList } from "./venus-cycle-vending.model";
declare const $: any;

@Component({
  selector: "app-venus-cycle-vending",
  templateUrl: "./venus-cycle-vending.component.html",
  styleUrls: ["./venus-cycle-vending.component.scss"],
})

export class VenusCycleVendingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('splPriceDayChecked', { static: false }) splPriceDayChecked: ElementRef;
  subscription1: any;
  subscription2: any;
  vendingForm: FormGroup;
  model: IVenusCycleModelList = {
    SpecialPriceTimeStart: '',
    SpecialPriceTimeEnd: '',
    CycleSpecialPriceDollar: '',
    CycleSpecialPriceCents: '',
    CycleRegularPriceDollar: '',
    CycleRegularPriceCents: '',
    ValueCoin1Dollar: '',
    ValueCoin1Cents: '',
    ValueCoin2Dollar: '',
    ValueCoin2Cents: '',
    TimePerCoinVal: 5
  }

  ValueChecked: IValueChecked = {
    id: 0,
    name: '',
    checked: false
  }

  regularPriceCycleLen: IRegularPriceCycleLen = {
    value: '',
    default: '',
    values: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    min: '',
    max: ''
  }

  fixedCycleEnable: IFixedCycleEnable = {
    value: '',
    default: '',
    values: [],
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  specialPricingEnable: ISpecialPricingEnable = {
    value: false,
    default: false,
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  specialPriceEnable: ISundaySpecialPriceEnable = {
    value: '',
    default: '',
    values: this.ValueChecked,
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  specialPriceTime: ISpecialPriceTime = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    endHour: '',
    startHour: ''
  }

  specialPriceCycleLen: ISpecialPriceCycleLen = {
    value: '',
    default: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    min: '',
    max: ''
  }

  cycleSpecialPrice: ICycleSpecialPrice = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    cents: '',
    dollars: ''
  }

  ValueCoin1: IValueCoin1 = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: '',
  }

  relationalEnvelopeOpen: IRelationalEnvelopeOpen = {
    binaryKeyValue: '',
    arrKey: [],
    type: ''
  }

  timePerCoinVal: ITimePerCoin = {
    value: 0,
    default: 0
  }

  vendingSettings: IVended = {
    Relational_Envelope_Open: this.relationalEnvelopeOpen,
    Relational_Envelope_Action: this.relationalEnvelopeOpen,
    Entity_Cycle_Open: this.relationalEnvelopeOpen,
    Relational_Entity_Name: this.relationalEnvelopeOpen,
    Relational_Entity_Id: this.relationalEnvelopeOpen,
    DnldCycle_CycleSet_CycleCavityPosition: this.relationalEnvelopeOpen,
    Relational_Array_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Close: this.relationalEnvelopeOpen,
    Relational_Array_Close: this.relationalEnvelopeOpen,
    Entity_Cycle_Close: this.relationalEnvelopeOpen,
    Relational_Envelope_Close: this.relationalEnvelopeOpen,
    DnldCycle_OpSet_RegularPriceCycleLen: this.regularPriceCycleLen,
    FixedCycleEnable: this.fixedCycleEnable,
    SpecialPricingEnable: this.specialPricingEnable,
    SundaySpecialPriceEnable: this.specialPriceEnable,
    MondaySpecialPriceEnable: this.specialPriceEnable,
    TuesdaySpecialPriceEnable: this.specialPriceEnable,
    WednesdaySpecialPriceEnable: this.specialPriceEnable,
    ThursdaySpecialPriceEnable: this.specialPriceEnable,
    FridaySpecialPriceEnable: this.specialPriceEnable,
    SaturdaySpecialPriceEnable: this.specialPriceEnable,
    SpecialPriceTime: this.specialPriceTime,
    DnldCycle_OpSet_SpecialPriceCycleLen: this.specialPriceCycleLen,
    DnldCycle_PaySet_CycleSpecialPrice: this.cycleSpecialPrice,
    DnldCycle_PaySet_CycleRegularPrice: this.cycleSpecialPrice,
    ValueCoin1: this.ValueCoin1,
    ValueCoin2: this.ValueCoin1,
    TimePerCoinVal: this.timePerCoinVal,
    hasValue: false
  }
  master_indeterminate: boolean = false;
  modelType: string = '';
  centsName = [
    'CycleSpecialPrice',
    'CycleRegularPrice',
    'ValueCoin1',
    'ValueCoin2'
  ]
  timePerCoin = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
  ]
  // timePerCoinVal: number = 5;
  prevValue = {
    timePerCoin: this.vendingSettings.TimePerCoinVal.value,
    regularDollar: '',
    regularCents: '',
    specialDollar: '',
    specialCents: '',
    coin1Dollar: '',
    coin1Cents: '',
  }
  isValueChanged = [{
    name: 'timeCoin',
    value: false
  }, {
    name: 'regularDollar',
    value: false
  }, {
    name: 'regularCents',
    value: false
  }, {
    name: 'specialDollar',
    value: false
  }, {
    name: 'specialCents',
    value: false
  }, {
    name: 'coin1Dollar',
    value: false
  }, {
    name: 'coin1Cents',
    value: false
  }]
  isSetToDefaultHide: boolean = false;

  constructor(
    private _venusModelServices: VenusModelServices,
    private fb: FormBuilder,
    private commonServices: CommonServices,
  ) {
  }

  ngOnInit() {
    this.isSetToDefaultHide = this.commonServices.isSetToDefaultHide;
    this.modelType = localStorage.getItem("ProductType");
    this.bindControls();
    this.setDollarCentValues();
    this.vendingSettings = this._venusModelServices.getVenusCycleModels();
    if (this.modelType === 'PR') {
      this.vendingForm.controls['regularDryTime'].enable();
      this.vendingForm.controls['splDryTime'].enable();
    } else {
      this.vendingForm.controls['regularDryTime'].disable();
      this.vendingForm.controls['splDryTime'].disable();
    }
    if (!this.vendingSettings) {
      this.vendingSettings = this._venusModelServices.getVenusVendingSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    } else if (this.vendingSettings && !this.vendingSettings.hasValue) {
      this.vendingSettings = this._venusModelServices.getVenusVendingSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    } else if (this.vendingSettings.hasValue) {
      this.setDollarCents();
    }
  }

  ngAfterViewInit(): void {
    this.bindCentsFields();
    if (this.modelType == 'PD') {
      this.calcRegularDryTime();
    }
  }

  bindCentsFields() {
    for (var i = 0; i < this.centsName.length; i++) {
      this.jqueryFunc(this.centsName[i]);
    }
  }

  bindControls() {
    this.vendingForm = this.fb.group({
      regularDryTime: [{ value: "" }, Validators.required],
      typeofDryerPrice: ["", Validators.required],
      pennyOfset: ["", Validators.required],
      splPricingChecked: ["", Validators.required],
      splPriceDayChecked: ["", Validators.required],
      startHour: [{ value: "", disabled: !this.vendingSettings.SpecialPricingEnable }, Validators.required],
      endHour: [{ value: "", disabled: !this.vendingSettings.SpecialPricingEnable }, Validators.required],
      splCycleDollars: [{ value: "" }, Validators.required],
      splCycleCents: [{ value: "" }, Validators.required],
      coinOneDollars: ["", Validators.required],
      coinOneCents: ["", Validators.required],
      coinTwoCents: ["", Validators.required],
      coinTwoDollars: ["", Validators.required],
      regularPriceDollars: ["", Validators.required],
      regularPriceCents: ["", Validators.required],
      splDryTime: [{ value: "" }, Validators.required],
      timePerCoin: ["", Validators.required],
    });
  }

  setDollarCents() {

    this.model.SpecialPriceTimeStart = this.vendingSettings.SpecialPriceTime.value[0];
    this.model.SpecialPriceTimeEnd = this.vendingSettings.SpecialPriceTime.value[1];
    this.model.CycleSpecialPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0];
    this.model.CycleSpecialPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1];

    this.model.CycleRegularPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[0];
    this.model.CycleRegularPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[1];

    this.model.ValueCoin1Dollar = this.vendingSettings.ValueCoin1.value[0];
    this.model.ValueCoin1Cents = this.vendingSettings.ValueCoin1.value[1];
    this.model.ValueCoin2Dollar = this.vendingSettings.ValueCoin2.value[0];
    this.model.ValueCoin2Cents = this.vendingSettings.ValueCoin2.value[1];
    this.model.TimePerCoinVal = this.vendingSettings.TimePerCoinVal.value;

    this.prevValue.regularDollar = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[0];
    this.prevValue.regularCents = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[1];
    this.prevValue.specialDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0];
    this.prevValue.specialCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1];
    this.prevValue.timePerCoin = this.vendingSettings.TimePerCoinVal.value;
    this.prevValue.coin1Dollar = this.vendingSettings.ValueCoin1.value[0];
    this.prevValue.coin1Cents = this.vendingSettings.ValueCoin1.value[1];

    if (!this.vendingSettings.SpecialPricingEnable.value) {
      this.disableSplPricingControls();
    }
  }

  setToDefault(isClick?: boolean) {
    this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.default;
    this.vendingSettings.FixedCycleEnable.value = this.vendingSettings.FixedCycleEnable.default;
    this.vendingSettings.SpecialPricingEnable.value = this.vendingSettings.SpecialPricingEnable.default;
    this.vendingSettings.SundaySpecialPriceEnable.value = this.vendingSettings.SundaySpecialPriceEnable.default;
    this.vendingSettings.MondaySpecialPriceEnable.value = this.vendingSettings.MondaySpecialPriceEnable.default;
    this.vendingSettings.TuesdaySpecialPriceEnable.value = this.vendingSettings.TuesdaySpecialPriceEnable.default;
    this.vendingSettings.WednesdaySpecialPriceEnable.value = this.vendingSettings.WednesdaySpecialPriceEnable.default;
    this.vendingSettings.ThursdaySpecialPriceEnable.value = this.vendingSettings.ThursdaySpecialPriceEnable.default;
    this.vendingSettings.FridaySpecialPriceEnable.value = this.vendingSettings.FridaySpecialPriceEnable.default;
    this.vendingSettings.SaturdaySpecialPriceEnable.value = this.vendingSettings.SaturdaySpecialPriceEnable.default;
    this.vendingSettings.SpecialPriceTime.value[0] = this.vendingSettings.SpecialPriceTime.startHour;
    this.vendingSettings.SpecialPriceTime.value[1] = this.vendingSettings.SpecialPriceTime.endHour;
    this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default;

    this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
    this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
    this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.cents;
    this.vendingSettings.ValueCoin1.value[0] = this.vendingSettings.ValueCoin1.dollars;
    this.vendingSettings.ValueCoin1.value[1] = this.vendingSettings.ValueCoin1.cents;
    this.vendingSettings.ValueCoin2.value[0] = this.vendingSettings.ValueCoin2.dollars;
    this.vendingSettings.ValueCoin2.value[1] = this.vendingSettings.ValueCoin2.cents;

    this.prevValue.regularDollar = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
    this.prevValue.regularCents = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.cents;
    this.prevValue.specialDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.prevValue.specialCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
    this.prevValue.timePerCoin = this.vendingSettings.TimePerCoinVal.value;
    this.prevValue.coin1Dollar = this.vendingSettings.ValueCoin1.dollars;
    this.prevValue.coin1Cents = this.vendingSettings.ValueCoin1.cents;
    this.vendingSettings.hasValue = true;
    this.setDefaultDollarCents();
    if (isClick) {
      this.setToDefaultSplPricing();
    }
    if (!this.vendingSettings.SpecialPricingEnable.value) {
      this.disableSplPricingControls();
    }
  }

  setDefaultDollarCents() {
    this.model.SpecialPriceTimeStart = this.vendingSettings.SpecialPriceTime.startHour;
    this.model.SpecialPriceTimeEnd = this.vendingSettings.SpecialPriceTime.endHour;
    this.model.CycleSpecialPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.model.CycleSpecialPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
    this.model.CycleRegularPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
    this.model.CycleRegularPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.cents;
    this.model.ValueCoin1Dollar = this.vendingSettings.ValueCoin1.dollars;
    this.model.ValueCoin1Cents = this.vendingSettings.ValueCoin1.cents;
    this.model.ValueCoin2Dollar = this.vendingSettings.ValueCoin2.dollars;
    this.model.ValueCoin2Cents = this.vendingSettings.ValueCoin2.cents;
    this.model.TimePerCoinVal = this.vendingSettings.TimePerCoinVal.default;
  }

  setToDefaultSplPricing() {
    if (this.modelType == 'PR') {
      this.disableSplPricingControls();
    } else if (this.modelType == 'PD') {
      this.enableSplPricingControls();
    }
    this.vendingSettings.SpecialPricingEnable.value = this.vendingSettings.SpecialPricingEnable.default;
    this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default;
    this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;

    this.vendingSettings.SundaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.MondaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.TuesdaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.WednesdaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.ThursdaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.FridaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.SaturdaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.SpecialPriceTime.startHour = '0';
    this.vendingSettings.SpecialPriceTime.endHour = '0';

    this.model.SpecialPriceTimeStart = this.vendingSettings.SpecialPriceTime.startHour;
    this.model.SpecialPriceTimeEnd = this.vendingSettings.SpecialPriceTime.endHour;
    this.model.CycleSpecialPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.model.CycleSpecialPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
  }

  disableSplPricingControls() {
    this.vendingForm.controls['startHour'].disable();
    this.vendingForm.controls['endHour'].disable();
  }

  enableSplPricingControls() {
    this.vendingForm.controls['startHour'].enable();
    this.vendingForm.controls['endHour'].enable();
    this.vendingForm.controls['splCycleDollars'].enable();
    this.vendingForm.controls['splCycleCents'].enable();
    if (this.modelType === 'PR') {
      this.vendingForm.controls['splDryTime'].enable();
    } else {
      this.vendingForm.controls['splDryTime'].disable();
    }
  }

  onDaySelection(event: MatCheckboxChange, day: string) {
    this.daySelection(event.checked, day);
  }

  daySelection(checked: boolean, day: string) {
    switch (day) {
      case 'Sun':
        this.vendingSettings.SundaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.SundaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Mon':
        this.vendingSettings.MondaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.MondaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Tue':
        this.vendingSettings.TuesdaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.TuesdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Wed':
        this.vendingSettings.WednesdaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.WednesdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Thu':
        this.vendingSettings.ThursdaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.ThursdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Fri':
        this.vendingSettings.FridaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.FridaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Sat':
        this.vendingSettings.SaturdaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.SaturdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
    }
  }

  onChange(isEnable: boolean) {
    if (isEnable) {
      this.vendingSettings.SpecialPricingEnable.value = true;
      this.enableSplPricingControls();
    } else {
      this.disableSplPricingControls();
      this.vendingSettings.SpecialPricingEnable.value = false;
      this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default;
      this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
      this.vendingSettings.SundaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.MondaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.TuesdaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.WednesdaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.ThursdaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.FridaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.SaturdaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.SpecialPriceTime.startHour = '0';
      this.vendingSettings.SpecialPriceTime.endHour = '0';
      this.model.SpecialPriceTimeStart = this.vendingSettings.SpecialPriceTime.startHour;
      this.model.SpecialPriceTimeEnd = this.vendingSettings.SpecialPriceTime.endHour;
      this.model.CycleSpecialPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.model.CycleSpecialPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
    }
  }

  setDollarCentValues() {
    let changeValues = [
      { "dollar": 'splCycleDollars', "cent": 'splCycleCents' },
      { "dollar": 'regularPriceDollars', "cent": 'regularPriceCents' },
      { "dollar": 'coinOneDollars', "cent": 'coinOneCents' },
      { "dollar": 'coinTwoDollars', "cent": 'coinTwoCents' },
    ];
    changeValues.forEach(data => {
      this.vendingForm.get(data["dollar"]).valueChanges.subscribe(x => {
        if (x > 99) {
          this.vendingForm.get(data["dollar"]).setValue(99, { emitEvent: false });
        }
      })
      this.vendingForm.get(data["cent"]).valueChanges.subscribe(x => {
        var d = Number(this.vendingForm.get(data["dollar"]).value);
        if (x > 95) {
          this.vendingForm.get(data["cent"]).setValue(99, { emitEvent: false });
        }
        if (x == 100 && d < 99) {
          var result = this.dollarConverts(x);
          d = d + Number(result[0]);
          this.vendingForm.get(data["dollar"]).setValue(d);
          this.vendingForm.get(data["cent"]).setValue(result[1]);
        }
      });
    });
  }

  dollarConverts(c) {
    var nCents = c;
    var nDollar = (nCents / 100);
    var rDollar = Math.floor(nDollar);
    var cents = (nDollar - rDollar) * 100;
    var rCents = Math.round(cents);
    return [rDollar, rCents]
  }

  getDollarCents() {
    this.vendingSettings.SpecialPriceTime.value[0] = this.model.SpecialPriceTimeStart;
    this.vendingSettings.SpecialPriceTime.value[1] = this.model.SpecialPriceTimeEnd;
    this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.model.CycleSpecialPriceDollar;
    this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.model.CycleSpecialPriceCents;
    this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.model.CycleRegularPriceDollar;
    this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.model.CycleRegularPriceCents;
    this.vendingSettings.ValueCoin1.value[0] = this.model.ValueCoin1Dollar;
    this.vendingSettings.ValueCoin1.value[1] = this.model.ValueCoin1Cents;
    this.vendingSettings.ValueCoin2.value[0] = this.model.ValueCoin2Dollar;
    this.vendingSettings.ValueCoin2.value[1] = this.model.ValueCoin2Cents;
    this.vendingSettings.TimePerCoinVal.value = this.model.TimePerCoinVal;
  }

  inputStartHourRange(value) {
    if (!this.vendingForm.get("startHour").value) {
      this.vendingForm.get("startHour").setValue(this.vendingSettings.SpecialPriceTime.default);
      return;
    }
    if (this.vendingForm.get("startHour").value > this.vendingSettings.SpecialPriceTime.max) {
      this.vendingForm.get("startHour").setValue(this.vendingSettings.SpecialPriceTime.max);
    }
    if (this.vendingForm.get("startHour").value < this.vendingSettings.SpecialPriceTime.min) {
      this.vendingForm.get("startHour").setValue(this.vendingSettings.SpecialPriceTime.min);
    }
    this.vendingSettings.SpecialPriceTime.value[0] = value;
  }

  inputEndHourRange(value) {
    if (!this.vendingForm.get("endHour").value) {
      this.vendingForm.get("endHour").setValue(this.vendingSettings.SpecialPriceTime.default);
      return;
    }
    if (this.vendingForm.get("endHour").value > this.vendingSettings.SpecialPriceTime.max) {
      this.vendingForm.get("endHour").setValue(this.vendingSettings.SpecialPriceTime.max);
    }
    if (this.vendingForm.get("endHour").value < this.vendingSettings.SpecialPriceTime.min) {
      this.vendingForm.get("endHour").setValue(this.vendingSettings.SpecialPriceTime.min);
    }
    this.vendingSettings.SpecialPriceTime.value[1] = value;
  }

  inputSplDryTime() {
    if (!this.vendingForm.get("splDryTime").value && this.vendingForm.get("splDryTime").value != '0') {
      this.vendingForm.get("splDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default);
      return;
    }
    if (this.vendingForm.get("splDryTime").value > this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.max) {
      this.vendingForm.get("splDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.max);
    }
    if (this.vendingForm.get("splDryTime").value < this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.min) {
      this.vendingForm.get("splDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.min);
    }
  }

  inputRegularDryTime() {
    if (!this.vendingForm.get("regularDryTime").value && this.vendingForm.get("regularDryTime").value != '0') {
      this.vendingForm.get("regularDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.default);
      return;
    }
    if (this.vendingForm.get("regularDryTime").value > this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.max) {
      this.vendingForm.get("regularDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.max);
    }
    if (this.vendingForm.get("regularDryTime").value < this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.min) {
      this.vendingForm.get("regularDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.min);
    }
  }

  changeDollar(name: string, value: string) {
    switch (name) {
      case 'CycleSpecialPrice':
        this.model.CycleSpecialPriceDollar = this.model.CycleSpecialPriceDollar ? this.model.CycleSpecialPriceDollar : '0';
        if (Number(value) > 9 && this.modelType == 'PD') {
          value = '9';
          this.model.CycleSpecialPriceDollar = '9';
          this.hasChangedValue('specialDollar');
          this.calcRegularDryTime();
          return;
        }
        if (!value && value != '0') {
          this.model.CycleSpecialPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
          value = this.model.CycleSpecialPriceDollar;
        }
        this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = value;
        if (this.modelType == 'PD') {
          this.hasChangedValue('specialDollar');
          this.calcRegularDryTime();
        }
        break;
      case 'CycleRegularPrice':
        this.model.CycleRegularPriceDollar = this.model.CycleRegularPriceDollar ? this.model.CycleRegularPriceDollar : '0';
        if (Number(value) > 9 && this.modelType == 'PD') {
          value = '9';
          this.model.CycleRegularPriceDollar = '9';
          this.hasChangedValue('regularDollar');
          this.calcRegularDryTime();
          return;
        }
        if (!value && value != '0') {
          this.model.CycleRegularPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
          value = this.model.CycleRegularPriceDollar;
        }
        this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = value;

        if (this.modelType == 'PD') {
          this.hasChangedValue('regularDollar');
          this.calcRegularDryTime();
        }
        break;
      case 'ValueCoin1':
        this.model.ValueCoin1Dollar = this.model.ValueCoin1Dollar ? this.model.ValueCoin1Dollar : '0';
        if (Number(value) > 9 && this.modelType == 'PD') {
          value = '9';
          this.model.ValueCoin1Dollar = '9';
          this.hasChangedValue('coin1Dollar');
          this.calcRegularDryTime();
          return;
        }
        if (!value && value != '0') {
          this.model.ValueCoin1Dollar = this.vendingSettings.ValueCoin1.dollars;
          value = this.model.ValueCoin1Dollar;
        }
        this.vendingSettings.ValueCoin1.value[0] = value;
        if (this.modelType == 'PD') {
          this.hasChangedValue('coin1Dollar');
          this.calcRegularDryTime();
        }
        break;
      case 'ValueCoin2':
        this.model.ValueCoin2Dollar = this.model.ValueCoin2Dollar ? this.model.ValueCoin2Dollar : '0';
        if (!value && value != '0') {
          this.model.ValueCoin2Dollar = this.vendingSettings.ValueCoin2.dollars;
          value = this.model.ValueCoin2Dollar;
        }
        this.vendingSettings.ValueCoin2.value[0] = value;
        break;
      default:
        break;
    }
  }

  changeCents(name: string, value: string) {
    this.jqueryFunc(name);
    switch (name) {
      case 'CycleSpecialPrice':
        this.model.CycleSpecialPriceDollar = this.model.CycleSpecialPriceDollar ? this.model.CycleSpecialPriceDollar : '0';
        if (Number(this.model.CycleSpecialPriceDollar) > 9 && this.modelType == 'PD') {
          value = '95';
          this.model.CycleSpecialPriceCents = '95';
          this.model.CycleSpecialPriceDollar = '9';
          this.hasChangedValue('specialCents');
          this.hasChangedValue('specialDollar');
          this.calcRegularDryTime();
          return;
        }
        if (!value && value != '0') {
          this.model.CycleSpecialPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
          value = this.model.CycleSpecialPriceCents;
        }
        this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1] = value;
        this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.model.CycleSpecialPriceDollar
        if (this.modelType == 'PD') {
          this.hasChangedValue('specialCents');
          this.calcRegularDryTime();
        }
        break;
      case 'CycleRegularPrice':
        this.model.CycleRegularPriceDollar = this.model.CycleRegularPriceDollar ? this.model.CycleRegularPriceDollar : '0';
        if (Number(this.model.CycleRegularPriceDollar) > 9 && this.modelType == 'PD') {
          value = '95';
          this.model.CycleRegularPriceCents = '95';
          this.model.CycleRegularPriceDollar = '9';
          this.hasChangedValue('regularCents');
          this.hasChangedValue('regularDollar');
          this.calcRegularDryTime();
          return;
        }
        if (!value && value != '0') {
          this.model.CycleRegularPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.cents;
          value = this.model.CycleRegularPriceCents;
        }
        this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[1] = value;
        this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.model.CycleRegularPriceDollar;
        if (this.modelType == 'PD') {
          this.hasChangedValue('regularCents');
          this.calcRegularDryTime();
        }
        break;
      case 'ValueCoin1':
        this.model.ValueCoin1Cents = this.model.ValueCoin1Cents ? this.model.ValueCoin1Cents : '0';
        if (Number(this.model.ValueCoin1Dollar) > 9 && this.modelType == 'PD') {
          value = '95';
          this.model.ValueCoin1Cents = '95';
          this.model.ValueCoin1Dollar = '9';
          this.hasChangedValue('coin1Cents');
          this.hasChangedValue('coin1Dollar');
          this.calcRegularDryTime();
          return;
        }
        if (!value && value != '0') {
          this.model.ValueCoin1Cents = this.vendingSettings.ValueCoin1.cents;
          value = this.model.ValueCoin1Cents;
        }
        this.vendingSettings.ValueCoin1.value[1] = value;
        this.vendingSettings.ValueCoin1.value[0] = this.model.ValueCoin1Dollar;
        if (this.modelType == 'PD') {
          this.hasChangedValue('coin1Cents');
          this.calcRegularDryTime();
        }
        break;
      case 'ValueCoin2':
        this.model.ValueCoin2Cents = this.model.ValueCoin2Cents ? this.model.ValueCoin2Cents : '0';
        if (!value && value != '0') {
          this.model.ValueCoin2Cents = this.vendingSettings.ValueCoin2.cents;
          value = this.model.ValueCoin2Cents;
        }
        this.vendingSettings.ValueCoin2.value[1] = value;
        this.vendingSettings.ValueCoin2.value[0] = this.model.ValueCoin2Dollar;
        break;
      default:
        break;
    }
  }

  changeTimeCoin() {
    this.hasChangedValue('timeCoin');
    this.calcRegularDryTime();
  }

  hasChangedValue(controlName: string) {
    this.isValueChanged.map(item => {
      if (item.name === controlName) {
        item.value = true;
      } else {
        item.value = false;
      }
    });
  }

  calcRegularDryTime() {
    setTimeout(() => {
      const cycleSpecialPriceDollar = Number(this.model.CycleSpecialPriceDollar);
      const cycleSpecialPriceCents = Number(this.model.CycleSpecialPriceCents);
      const cycleRegularPriceDollar = Number(this.model.CycleRegularPriceDollar);
      const cycleRegularPriceCents = Number(this.model.CycleRegularPriceCents);
      const coin1Dollar = Number(this.model.ValueCoin1Dollar);
      const coin1Cents = Number(this.model.ValueCoin1Cents);
      const regularPrice = (cycleRegularPriceDollar * 100) + cycleRegularPriceCents;
      const specialPrice = (cycleSpecialPriceDollar * 100) + cycleSpecialPriceCents;
      const coin1 = (coin1Dollar * 100) + coin1Cents;
      if (coin1 === 0) {
        this.vendingSettings.TimePerCoinVal.value = this.model.TimePerCoinVal;
        this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = '0';
        this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = '0';
        return;
      }
      const regularDryTime = String(Math.round((regularPrice / coin1) * this.model.TimePerCoinVal));
      const specialDryTime = String(Math.round((specialPrice / coin1) * this.model.TimePerCoinVal));
      const isValid = this.validateMinMaxDryTime(regularDryTime, specialDryTime);
      if (isValid) {
        this.setPrevValuesInModel(regularDryTime, specialDryTime);
      }
    }, 100);
  }

  setPrevValuesInModel(regularDryTime: string, specialDryTime: string) {
    this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = regularDryTime;
    this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = specialDryTime;
    this.vendingSettings.TimePerCoinVal.value = this.model.TimePerCoinVal;
    this.prevValue.timePerCoin = this.model.TimePerCoinVal;
    this.prevValue.regularDollar = this.model.CycleRegularPriceDollar;
    this.prevValue.regularCents = this.model.CycleRegularPriceCents;
    this.prevValue.specialDollar = this.model.CycleSpecialPriceDollar;
    this.prevValue.specialCents = this.model.CycleSpecialPriceCents;
    this.prevValue.coin1Dollar = this.model.ValueCoin1Dollar;
    this.prevValue.coin1Cents = this.model.ValueCoin1Cents;
  }

  validateMinMaxDryTime(regularDryTime: string, specialDryTime: string): boolean {
    if (Number(regularDryTime) > 75 || Number(specialDryTime) > 75) {
      this.setPrevValues();
      return false;
    }
    if (Number(regularDryTime) < 5 || Number(specialDryTime) < 5) {
      this.setPrevValues();
      return false;
    }
    return true;
  }

  setPrevValues() {
    this.isValueChanged.map(item => {
      if (item.value) {
        switch (item.name.toLowerCase()) {
          case 'timecoin':
            this.model.TimePerCoinVal = this.prevValue.timePerCoin;
            break;
          case 'regulardollar':
            this.model.CycleRegularPriceDollar = this.prevValue.regularDollar;
            break;
          case 'regularcents':
            this.model.CycleRegularPriceCents = this.prevValue.regularCents;
            break;
          case 'specialdollar':
            this.model.CycleSpecialPriceDollar = this.prevValue.specialDollar;
            break;
          case 'specialcents':
            this.model.CycleSpecialPriceCents = this.prevValue.specialCents
            break;
          case 'coin1dollar':
            this.model.ValueCoin1Dollar = this.prevValue.coin1Dollar;
            break;
          case 'coin1cents':
            this.model.ValueCoin1Cents = this.prevValue.coin1Cents;
            break;
        }
      }
    });
  }

  jqueryFunc(name: string) {
    const inputName = `${'#' + name}`;
    $(inputName).bind('keyup input', function () {
      this.setAttribute("step", "5");
    });
  }

  ngOnDestroy(): void {
    const isModel = this._venusModelServices.modelChanged();
    if (!isModel) {
      this.getDollarCents();
      this._venusModelServices.setVenusCycleModels(this.vendingSettings);
    }
  }

}
