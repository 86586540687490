import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DataGetSetService {
  recieveddata: any[];
  constructor() {}

  get(): any {
    return this.recieveddata;
  }

  set data(val: any) {
    this.recieveddata = val;
    console.log(this.recieveddata);
  }
}
