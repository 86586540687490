import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { auditReportService } from 'src/app/services/auditReport.service';
import { AuditReport } from './audit-report.model';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})

export class AuditReportComponent implements OnInit {
  @ViewChild('dt', { read: '', static: true }) dt: any;
  showGrid: boolean = false;
  dateTime: Date;
  auditData: any;
  cols: any[];
  searchText: string;
  valueOFRevenueCycle: any;
  totalRevenue: any;
  groupList: any = [];
  sumOfRevenue = 0;
  sumOfNoOfCycle: any;
  newKey: any = [];
  NewModelArray: any = [];
  selectedDate: any;
  dateValue = "";
  responseData: any;
  submitted = false;
  captionShow = true;
  auditReport: AuditReport[] = [];
  copyAuditReport: AuditReport[] = [];

  constructor(
    private _auditReportService: auditReportService,
    private datePipe: DatePipe
  ) {
    this.searchText = "";
    this.dateTime = new Date();
  }

  ngOnInit() {
    this.cols = [
      { field: 'model', header: 'Model List' },
      { field: 'revenue', header: 'Revenue' },
      { field: 'cycleCount', header: 'No. Of Cycle' },
      { field: 'revenueCycleCount', header: 'Revenue / No. Of Cycle' }
    ];
  }

  generateReport() {
    this.showGrid = true;
    this.loadModelData();
  }

  loadModelData() {
    this.auditReport = [];
    this.copyAuditReport = [];
    this.showGrid = false;
    const requestURL = `${"/api/v1/reports/getUsbAuditReport/" + this.selectedDate}`;
    this._auditReportService.getData(requestURL).subscribe((res) => {
      this.getAuditReportList(res);
      this.showGrid = true;
      this.submitted = false;
    }, (err) => {
      this._auditReportService.showMessage(err, true);
    });
  }

  getAuditReportList(res: any) {
    res.auditReport.map((element, index) => {
      let revenue: number = 0;
      let noOfCycle: number = 0
      let cycleRevenues: number = 0
      const serialData = element.serialData.map(c => {
        const rev = (+c.cardMoney + +c.coinMoney);
        let cycleRev = 0;
        revenue += rev;
        noOfCycle += c.cycleCount;
        if (!rev || !c.cycleCount) {
          cycleRev = 0;
        } else {
          cycleRev = (+rev / +c.cycleCount)
        }
        return {
          serialNo: c.serial,
          revenue: rev,
          cycleRevenue: cycleRev.toFixed(2),
          cycleCount: c.cycleCount
        }
      });
      if (!revenue || !noOfCycle) {
        cycleRevenues = 0;
      } else {
        cycleRevenues = (+revenue / +noOfCycle)
      }
      this.auditReport.push({
        srNo: index,
        revenue: revenue,
        cycleCount: noOfCycle,
        revenueCycleCount: cycleRevenues.toFixed(2),
        model: element.model,
        serialData: serialData
      });
    });
    this.copyAuditReport = this.auditReport;
  }

  dateChangeFn(e) {
    this.submitted = true;
    this.selectedDate = this.datePipe.transform(this.dateValue, "yyyy-MM-dd");
    this.showGrid = false;
  }

  exportExcel() {
    this._auditReportService.exportAsExcelFile(this.getExcelData(), 'Audit Report');
  }

  getExcelData() {
    let dataList = [];
    for (let modelData of this.auditReport) {
      let model = [];
      model = [
        modelData.model,
        '',
        modelData.revenue,
        modelData.cycleCount,
        Number(modelData.revenueCycleCount),
      ]
      dataList.push(model);
      modelData.serialData.map((item, index) => {
        model = [];
        model = [
          '',
          item.serialNo,
          item.revenue,
          item.cycleCount,
          Number(item.cycleRevenue)
        ]
        dataList.push(model);
      });
    }
    return dataList;
  }

  onRowSelect(index: number, modelNo: string) {
    this.auditReport[index].serialData = this.copyAuditReport.find(f => { return f.model === modelNo }).serialData;
  }
}