import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { CommonServices } from "src/app/services/CommonServices";
import { KeyboardSelectionEnum } from "src/app/shared/enums/enum";
import { ErrorList, ICompanyList, ICountryList } from "./create-user.model";
import { CreateUserService } from "./create-user.service";

@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"],
})
export class CreateUserComponent implements OnInit {
  @ViewChild(FormGroupDirective, { static: false }) formGroupDirective: FormGroupDirective;
  public registerUser: FormGroup;
  public countryList: ICountryList[];
  public companyList: ICompanyList[];
  public filteredCompanyList: ICompanyList[];
  public filteredCountryList: ICountryList[];
  keyboardSelectionEnum = KeyboardSelectionEnum;

  public validation_messages = {
    firstName: [{ type: "required", message: "First Name is required" }],
    lastName: [{ type: "required", message: "Last Name is required" }],
    email: [{ type: "required", message: "Email is required" }],
    phoneNo: [{ type: "required", message: "Phone No is required" }],
    company: [{ type: "required", message: "Company is required" }],
    specify: [{ type: "required", message: "Specify new company name is required" }],
    country: [{ type: "required", message: "Country is required" }],
  };
  public isSpecifyShow: boolean = false;
  public isSpecifyDisable: boolean = true;
  public errorList = new ErrorList();
  selectedValue: any;
  searchTxt: any;
  
  constructor(
    private commonService: CommonServices,
    private fb: FormBuilder,
    private _createUserService: CreateUserService
  ) { }

  ngOnInit() {
    this.getFormFields();
    this.getCountryList();
  }

  get f() {
    return this.registerUser.controls;
  }

  getFormFields() {
    this.registerUser = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      company: ["", [Validators.required]],
      phoneNo: ["", Validators.required],
      country: ["", Validators.required],
      specify: ["", Validators.required],
    });
  }

  getCountryList() {
    this.commonService.getLoadingBar('Please Wait ..');
    this.countryList = [];
    this.filteredCountryList = [];
    const requestURL = "/public/v1/countryCL";
    this._createUserService.getData(requestURL).subscribe((res) => {
      this.countryList = res;
      this.filteredCountryList = this.countryList;
      this.getCompanyList();
    }, err => {
      this._createUserService.showMessage(
        err,
        true
      );
      this.commonService.getClose();
    });
  }

  getCompanyList() {
    this.companyList = [];
    this.filteredCompanyList = [];
    const requestURL = "/api/v1/getcompanies";
    this._createUserService.getData(requestURL).subscribe((res) => {
      this.companyList = res;
      this.companyList.push({
        COMPANY_ID: 0,
        COMPANY_NAME: "Add New Company",
      });
      this.filteredCompanyList = this.companyList;
      this.commonService.getClose();
    }, err => {
      this._createUserService.showMessage(
        err,
        true
      );
      this.commonService.getClose();
    });
  }

  selectNewComp() {
    this.isSpecifyShow = false;
    this.isSpecifyDisable = true;
    if (this.f.company.value === 0) {
      this.isSpecifyShow = true;
      this.isSpecifyDisable = false;
    } else {
      this.isSpecifyShow = false;
      this.isSpecifyDisable = true;
      this.f.specify.reset();
    }
  }

  verifyCompany(specify) {
    const isExists = this.companyList.find(f => { return f.COMPANY_NAME.toLowerCase() == specify.toLowerCase() });
    if (isExists) {
      this._createUserService.showMessage(this.errorList.msgCompanyVerify, true);
      this.f.specify.reset();
      return;
    }
  }

  verifyEmail(email) {
    const emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
    const isValid = this.fb.group({
      officialEmail: [
        email,
        [Validators.required, Validators.pattern(emailPattern)],
      ],
    }).valid;
    if (!isValid) {
      return;
    }
    const requestURL = `${"/api/v1/client_auth/accounts/publicMailCheck/" + email}`;
    this._createUserService.getData(requestURL).subscribe(
      (res) => {
        if (res && res.EMAIL) {
          this._createUserService.showMessage(
            this.errorList.msgEmailVerify,
            true
          );
          this.f.email.reset();
        }
      },
      (err) => {
        this._createUserService.showMessage(
          err,
          true
        );
        this.f.email.reset();
      }
    );
  }

  onSubmitUserDetails(form: FormGroup) {
    const company = this.companyList.find((f) => {
      return this.f.company.value === f.COMPANY_ID;
    });
    const isValid = this.validateFields();
    if (!form.valid) {
      this.registerUser.markAllAsTouched();
      return;
    }
    if (Number(this.f.phoneNo.value.length) <= 9) {
      this._createUserService.showMessage(this.errorList.msgPhoneNumber, true);
      return;
    }

    if (this.f.specify.value === 'Add New Company') {
      this._createUserService.showMessage(this.errorList.msgCompanyVerify, true);
      return;
    }
    const requestURL = "/api/v1/clInviteUser";
    const payload = {
      account: {
        companyId: company.COMPANY_ID,
        inviteLink: "",
        firstName: this.f.firstName.value,
        lastName: this.f.lastName.value,
        email: this.f.email.value,
        languageId: "1",
        phoneNo: this.f.phoneNo.value,
        timeFormat: "24",
        companyName:
          company.COMPANY_ID === 0
            ? this.f.specify.value
            : company.COMPANY_NAME,
      },
      countryId: this.f.country.value,
      handheldFpPrivelegesId: -1,
      handheldViewPrivelegesId: -1,
      all: false,
      monitor: false,
      setup: false,
      collect: false,
      timeFormatName: "24",
      fpPriveledgesString: "",
    };
    this._createUserService
      .postData(requestURL, JSON.stringify(payload))
      .subscribe(
        (res) => {
          this.isSpecifyShow = false;
          this.isSpecifyDisable = true;
          this.formGroupDirective.resetForm();
          this._createUserService.showMessage(res, false);
          this.getCompanyList();
        },
        (err) => {
          this._createUserService.showMessage(err, true);
        }
      );
  }

  validateFields(): boolean {
    return !!this.f.firstName.value
      && !!this.f.lastName.value
      && !!this.f.email.value
      && !!this.f.company.value
      && !!this.f.country.value
      && !!this.f.phoneNo.value
  }

  onKey(value) {
    this.filteredCompanyList = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.companyList.filter(option => option.COMPANY_NAME.toLowerCase().startsWith(filter));
  }

  onKeyCountry(value) {
    this.filteredCountryList = this.searchCountry(value);
  }

  searchCountry(value: string) {
    let filter = value.toLowerCase();
    return this.countryList.filter(option => option.COUNTRY_NAME.toLowerCase().startsWith(filter));
  }

}