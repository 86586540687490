import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FlWasherModelServices } from "src/app/services/FlWasherModel.service";
import { FlWasherCycleModelList, ICoolTemperature, IExtraRinse, IHeavySoil, IHotTemperature, IRelationalEntityKey, IRelationalEnvelopeOpen, IValueCoin1, IVended, IWarmTemperature } from "./fl-washer-cycle-vending.model";
declare const $: any;

@Component({
  selector: "app-fl-washer-cycle-vending",
  templateUrl: "./fl-washer-cycle-vending.component.html",
  styleUrls: ["./fl-washer-cycle-vending.component.scss"],
})

export class FLWasherCycleVendingComponent implements OnInit, OnDestroy, AfterViewInit {
  vendingForm: FormGroup;
  modelType: string = '';
  model: FlWasherCycleModelList = {
    ValueCoin1Dollar: '',
    ValueCoin1Cents: '',
    ValueCoin2Dollar: '',
    ValueCoin2Cents: '',
    CoolTemperatureDollar: '',
    CoolTemperatureCents: '',
    WarmTemperatureDollar: '',
    WarmTemperatureCents: '',
    HotTemperatureDollar: '',
    HotTemperatureCents: '',
    HeavySoilDollar: '',
    HeavySoilCents: '',
    ExtraRinseDollar: '',
    ExtraRinseCents: ''
  }

  ValueCoin1: IValueCoin1 = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: '',
  }

  CoolTemperature: ICoolTemperature = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: ''
  }
  WarmTemperature: IWarmTemperature = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: ''
  }
  HotTemperature: IHotTemperature = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: ''
  }
  HeavySoil: IHeavySoil = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: ''
  }
  ExtraRinseAdder: IExtraRinse = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: ''
  }

  relationalEnvelopeOpen: IRelationalEnvelopeOpen = {
    binaryKeyValue: '',
    arrKey: [],
    type: ''
  }

  relationalEntityKey: IRelationalEntityKey = {
    value: '',
    binaryKeyValue: '',
    arrKey: [],
    type: ''
  }

  vendingSettings: IVended = {
    Relational_Envelope_Open: this.relationalEnvelopeOpen,
    Relational_Envelope_Action: this.relationalEnvelopeOpen,
    Entity_Cycle_Open: this.relationalEnvelopeOpen,
    Relational_Entity_Name: this.relationalEntityKey,
    Relational_Entity_Id: this.relationalEnvelopeOpen,
    DnldCycle_CycleSet_CycleCavityPosition: this.relationalEnvelopeOpen,
    Relational_Array_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Close: this.relationalEnvelopeOpen,
    Relational_Array_Close: this.relationalEnvelopeOpen,
    Entity_Cycle_Close: this.relationalEnvelopeOpen,
    Relational_Envelope_Close: this.relationalEnvelopeOpen,
    ValueCoin1: this.ValueCoin1,
    ValueCoin2: this.ValueCoin1,
    CoolTemperature: this.CoolTemperature,
    WarmTemperature: this.WarmTemperature,
    HotTemperature: this.HotTemperature,
    HeavySoil: this.HeavySoil,
    ExtraRinseAdder: this.ExtraRinseAdder,
    hasValue: false
  }

  isValueChanged = [{
    name: 'coin1Dollar',
    value: false
  }, {
    name: 'coin1Cents',
    value: false
  }]

  centsName = [
    'ValueCoin1',
    'ValueCoin2',
    'CoolTemperature',
    'WarmTemperature',
    'HotTemperature',
    'HeavySoil',
    'ExtraRinse'
  ]

  constructor(
    private fb: FormBuilder,
    private _flWasherModelServices: FlWasherModelServices
  ) { }

  ngOnInit() {
    this.bindControls();
    this.modelType = localStorage.getItem("ProductType");
    this.vendingSettings = this._flWasherModelServices.getFLWasherCycleVendingModels();
    this.setDollarCentValues1();
    this.setDollarCentValues2();
    if (!this.vendingSettings) {
      this.vendingSettings = this._flWasherModelServices.getFlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    } else if (this.vendingSettings && !this.vendingSettings.hasValue) {
      this.vendingSettings = this._flWasherModelServices.getFlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
      this._flWasherModelServices.setCyclesPrice(false);
    } else if (this.vendingSettings.hasValue) {
      this.setDollarCents();
    }
  }

  ngAfterViewInit(): void {
    this.bindCentsFields();
  }

  bindCentsFields() {
    for (var i = 0; i < this.centsName.length; i++) {
      this.jqueryFunc(this.centsName[i]);
    }
  }

  bindControls() {
    this.vendingForm = this.fb.group({
      coinOneDollars: ["", Validators.required],
      coinOneCents: ["", Validators.required],
      coinTwoCents: ["", Validators.required],
      coinTwoDollars: ["", Validators.required],
      coldWaterDollar: ["", Validators.required],
      coldWaterCents: ["", Validators.required],
      coolWaterDollar: ["", Validators.required],
      coolWaterCents: ["", Validators.required],
      warmWaterDollar: ["", Validators.required],
      warmWaterCents: ["", Validators.required],
      hotWaterDollar: ["", Validators.required],
      hotWaterCents: ["", Validators.required],
      solLevelDollar: ["", Validators.required],
      solLevelCents: ["", Validators.required],
      extraRinseDollar: ["", Validators.required],
      extraRinseCents: ["", Validators.required]
    });
  }

  setDollarCents() {
    this.model.ValueCoin1Dollar = this.vendingSettings.ValueCoin1.value[0];
    this.model.ValueCoin1Cents = this.vendingSettings.ValueCoin1.value[1];
    this.model.ValueCoin2Dollar = this.vendingSettings.ValueCoin2.value[0];
    this.model.ValueCoin2Cents = this.vendingSettings.ValueCoin2.value[1];
    this.model.CoolTemperatureDollar = this.vendingSettings.CoolTemperature.value[0];
    this.model.CoolTemperatureCents = this.vendingSettings.CoolTemperature.value[1];
    this.model.WarmTemperatureDollar = this.vendingSettings.WarmTemperature.value[0];
    this.model.WarmTemperatureCents = this.vendingSettings.WarmTemperature.value[1];
    this.model.HotTemperatureDollar = this.vendingSettings.HotTemperature.value[0];
    this.model.HotTemperatureCents = this.vendingSettings.HotTemperature.value[1];
    this.model.HeavySoilDollar = this.vendingSettings.HeavySoil.value[0];
    this.model.HeavySoilCents = this.vendingSettings.HeavySoil.value[1];
    this.model.ExtraRinseDollar = this.vendingSettings.ExtraRinseAdder.value[0];
    this.model.ExtraRinseCents = this.vendingSettings.ExtraRinseAdder.value[1];
  }

  setToDefault(isClick?: boolean) {
    if (this.modelType != '258J' && this.modelType != '268J') {
      this.vendingSettings.CoolTemperature.value[0] = this.vendingSettings.CoolTemperature.dollars;
      this.vendingSettings.CoolTemperature.value[1] = this.vendingSettings.CoolTemperature.cents;
      this.vendingSettings.WarmTemperature.value[0] = this.vendingSettings.WarmTemperature.dollars;
      this.vendingSettings.WarmTemperature.value[1] = this.vendingSettings.WarmTemperature.cents;
      this.vendingSettings.HotTemperature.value[0] = this.vendingSettings.HotTemperature.dollars;
      this.vendingSettings.HotTemperature.value[1] = this.vendingSettings.HotTemperature.cents;
      this.vendingSettings.HeavySoil.value[0] = this.vendingSettings.HeavySoil.dollars;
      this.vendingSettings.HeavySoil.value[1] = this.vendingSettings.HeavySoil.cents;
      this.vendingSettings.ExtraRinseAdder.value[0] = this.vendingSettings.ExtraRinseAdder.dollars;
      this.vendingSettings.ExtraRinseAdder.value[1] = this.vendingSettings.ExtraRinseAdder.cents
    }
    this.vendingSettings.ValueCoin1.value[0] = this.vendingSettings.ValueCoin1.dollars;
    this.vendingSettings.ValueCoin1.value[1] = this.vendingSettings.ValueCoin1.cents;
    this.vendingSettings.ValueCoin2.value[0] = this.vendingSettings.ValueCoin2.dollars;
    this.vendingSettings.ValueCoin2.value[1] = this.vendingSettings.ValueCoin2.cents;
    this._flWasherModelServices.coin1Value.dollar = this.vendingSettings.ValueCoin1.dollars;
    this._flWasherModelServices.coin1Value.cent = this.vendingSettings.ValueCoin1.cents;
    this.vendingSettings.hasValue = true;
    this.setDefaultDollarCents();
    if (isClick) {
      this._flWasherModelServices.resetPriceValue();
    }
  }

  setDefaultDollarCents() {
    if (this.modelType != '258J' && this.modelType != '268J') {
      this.model.CoolTemperatureDollar = this.vendingSettings.CoolTemperature.dollars;
      this.model.CoolTemperatureCents = this.vendingSettings.CoolTemperature.cents;
      this.model.WarmTemperatureDollar = this.vendingSettings.WarmTemperature.dollars;
      this.model.WarmTemperatureCents = this.vendingSettings.WarmTemperature.cents;
      this.model.HotTemperatureDollar = this.vendingSettings.HotTemperature.dollars;
      this.model.HotTemperatureCents = this.vendingSettings.HotTemperature.cents;
      this.model.HeavySoilDollar = this.vendingSettings.HeavySoil.dollars;
      this.model.HeavySoilCents = this.vendingSettings.HeavySoil.cents;
      this.model.ExtraRinseDollar = this.vendingSettings.ExtraRinseAdder.dollars;
      this.model.ExtraRinseCents = this.vendingSettings.ExtraRinseAdder.cents;
    }
    this.model.ValueCoin1Dollar = this.vendingSettings.ValueCoin1.dollars;
    this.model.ValueCoin1Cents = this.vendingSettings.ValueCoin1.cents;
    this.model.ValueCoin2Dollar = this.vendingSettings.ValueCoin2.dollars;
    this.model.ValueCoin2Cents = this.vendingSettings.ValueCoin2.cents;
    this._flWasherModelServices.coin1Value.dollar = this.model.ValueCoin1Dollar;
    this._flWasherModelServices.coin1Value.cent = this.model.ValueCoin1Cents;
  }

  setDollarCentValues1() {
    let changeValues = [
      { "dollar": 'coinOneDollars', "cent": 'coinOneCents' },
      { "dollar": 'coinTwoDollars', "cent": 'coinTwoCents' },
    ];
    changeValues.forEach(data => {
      this.vendingForm.get(data["dollar"]).valueChanges.subscribe(x => {
        if (x > 9) {
          this.vendingForm.get(data["dollar"]).setValue(9, { emitEvent: false });
          this.vendingForm.get(data["cent"]).setValue(0, { emitEvent: false });
        }
      })
      this.vendingForm.get(data["cent"]).valueChanges.subscribe(x => {
        var d = Number(this.vendingForm.get(data["dollar"]).value);
        if (x > 95) {
          this.vendingForm.get(data["cent"]).setValue(95, { emitEvent: false });
        }
        if (x == 100 && d < 9) {
          var result = this.dollarConverts(x);
          d = d + Number(result[0]);
          this.vendingForm.get(data["dollar"]).setValue(d);
          this.vendingForm.get(data["cent"]).setValue(result[1]);
        }
      });
    });
  }

  setDollarCentValues2() {
    let changeValues = [
      { "dollar": 'coolWaterDollar', "cent": 'coolWaterCents' },
      { "dollar": 'warmWaterDollar', "cent": 'warmWaterCents' },
      { "dollar": 'hotWaterDollar', "cent": 'hotWaterCents' },
      { "dollar": 'solLevelDollar', "cent": 'solLevelCents' },
      { "dollar": 'extraRinseDollar', "cent": 'extraRinseCents' },
    ];
    changeValues.forEach(data => {
      this.vendingForm.get(data["dollar"]).valueChanges.subscribe(x => {
        if (x > 9) {
          this.vendingForm.get(data["dollar"]).setValue(10, { emitEvent: false });
          this.vendingForm.get(data["cent"]).setValue(0, { emitEvent: false });
        }
      });
      this.vendingForm.get(data["cent"]).valueChanges.subscribe(x => {
        var d = Number(this.vendingForm.get(data["dollar"]).value);
        if (d == 10) {
          this.vendingForm.get(data["cent"]).setValue(0, { emitEvent: false });
          return;
        }
        if (x > 95) {
          this.vendingForm.get(data["cent"]).setValue(95, { emitEvent: false });
        }
        if (x == 100 && d < 9) {
          var result = this.dollarConverts(x);
          d = d + Number(result[0]);
          this.vendingForm.get(data["dollar"]).setValue(d);
          this.vendingForm.get(data["cent"]).setValue(result[1]);
        }
      });
    });
  }

  dollarConverts(c) {
    var nCents = c;
    var nDollar = (nCents / 100);
    var rDollar = Math.floor(nDollar);
    var cents = (nDollar - rDollar) * 100;
    var rCents = Math.round(cents);
    return [rDollar, rCents]
  }

  getDollarCents() {
    if (this.modelType != '258J' && this.modelType != '268J') {
      this.vendingSettings.CoolTemperature.value[0] = this.model.CoolTemperatureDollar;
      this.vendingSettings.CoolTemperature.value[1] = this.model.CoolTemperatureCents;
      this.vendingSettings.WarmTemperature.value[0] = this.model.WarmTemperatureDollar;
      this.vendingSettings.WarmTemperature.value[1] = this.model.WarmTemperatureCents;
      this.vendingSettings.HotTemperature.value[0] = this.model.HotTemperatureDollar;
      this.vendingSettings.HotTemperature.value[1] = this.model.HotTemperatureCents;
      this.vendingSettings.HeavySoil.value[0] = this.model.HeavySoilDollar;
      this.vendingSettings.HeavySoil.value[1] = this.model.HeavySoilCents;
      this.vendingSettings.ExtraRinseAdder.value[0] = this.model.ExtraRinseDollar;
      this.vendingSettings.ExtraRinseAdder.value[1] = this.model.ExtraRinseCents;
    }
    this.vendingSettings.ValueCoin1.value[0] = this.model.ValueCoin1Dollar;
    this.vendingSettings.ValueCoin1.value[1] = this.model.ValueCoin1Cents;
    this.vendingSettings.ValueCoin2.value[0] = this.model.ValueCoin2Dollar;
    this.vendingSettings.ValueCoin2.value[1] = this.model.ValueCoin2Cents;
    this._flWasherModelServices.coin1Value.dollar = this.model.ValueCoin1Dollar;
    this._flWasherModelServices.coin1Value.cent = this.model.ValueCoin1Cents;
  }

  changeDollar(name: string, value: string) {
    switch (name) {
      case 'ValueCoin1':
        this._flWasherModelServices.prevCoinValue = this.vendingSettings.ValueCoin1.value[0];
        this.model.ValueCoin1Dollar = this.model.ValueCoin1Dollar ? this.model.ValueCoin1Dollar : '0';
        const isValid = this.validateDollarCoin(this.modelType, value);
        if (isValid) {
          if (!value && value != '0') {
            this.model.ValueCoin1Dollar = this.vendingSettings.ValueCoin1.dollars;
            value = this.model.ValueCoin1Dollar;
          }
          this.vendingSettings.ValueCoin1.value[0] = value;
          this._flWasherModelServices.coin1Value.dollar = value;
          if (this.modelType == 'PD') {
            this.hasChangedValue('coin1Dollar');
          }
        }
        const isValidPrice = this._flWasherModelServices.setCyclesPrice(true, value);
        if (!isValidPrice) {
          this.vendingSettings.ValueCoin1.value[0] = this._flWasherModelServices.prevCoinValue;
          this._flWasherModelServices.coin1Value.dollar = this._flWasherModelServices.prevCoinValue;
          this.model.ValueCoin1Dollar = this._flWasherModelServices.prevCoinValue;
        }
        break;
      case 'ValueCoin2':
        this.model.ValueCoin2Dollar = this.model.ValueCoin2Dollar ? this.model.ValueCoin2Dollar : '0';
        if (Number(this.model.ValueCoin2Dollar) > 9) {
          value = '9';
          this.model.ValueCoin2Dollar = '9';
          return;
        }
        if (!value && value != '0') {
          this.model.ValueCoin2Dollar = this.vendingSettings.ValueCoin2.dollars;
          value = this.model.ValueCoin2Dollar;
        }
        this.vendingSettings.ValueCoin2.value[0] = value;
        break;

      case 'CoolTemperature':
        this.model.CoolTemperatureDollar = this.model.CoolTemperatureDollar ? this.model.CoolTemperatureDollar : '0';
        if (!value && value != '0') {
          this.model.CoolTemperatureDollar = this.vendingSettings.CoolTemperature.dollars;
          value = this.model.CoolTemperatureDollar;
        }
        this.vendingSettings.CoolTemperature.value[0] = value;
        break;

      case 'WarmTemperature':
        this.model.WarmTemperatureDollar = this.model.WarmTemperatureDollar ? this.model.WarmTemperatureDollar : '0';
        if (!value && value != '0') {
          this.model.WarmTemperatureDollar = this.vendingSettings.WarmTemperature.dollars;
          value = this.model.WarmTemperatureDollar;
        }
        this.vendingSettings.WarmTemperature.value[0] = value;
        break;

      case 'HotTemperature':
        this.model.HotTemperatureDollar = this.model.HotTemperatureDollar ? this.model.HotTemperatureDollar : '0';
        if (!value && value != '0') {
          this.model.HotTemperatureDollar = this.vendingSettings.HotTemperature.dollars;
          value = this.model.HotTemperatureDollar;
        }
        this.vendingSettings.HotTemperature.value[0] = value;
        break;

      case 'HeavySoil':
        this.model.HeavySoilDollar = this.model.HeavySoilDollar ? this.model.HeavySoilDollar : '0';
        if (!value && value != '0') {
          this.model.HeavySoilDollar = this.vendingSettings.HeavySoil.dollars;
          value = this.model.HeavySoilDollar;
        }
        this.vendingSettings.HeavySoil.value[0] = value;
        break;

      case 'ExtraRinse':
        this.model.ExtraRinseDollar = this.model.ExtraRinseDollar ? this.model.ExtraRinseDollar : '0';
        if (!value && value != '0') {
          this.model.ExtraRinseDollar = this.vendingSettings.ExtraRinseAdder.dollars;
          value = this.model.ExtraRinseDollar;
        }
        this.vendingSettings.ExtraRinseAdder.value[0] = value;
        break;
      default:
        break;
    }
  }

  validateDollarCoin(modelType: string, value: string): boolean {
    if (Number(value) > 9 && this.modelType == 'PD') {
      value = '9';
      this.model.ValueCoin1Dollar = '9';
      return;
    } else if (Number(value) > 9 && (modelType == 'PR' || modelType == '258J')) {
      value = '9';
      this.model.ValueCoin1Dollar = '9';
      return false;
    }
    return true;
  }

  changeCents(name: string, value: string) {
    this.jqueryFunc(name);
    switch (name) {
      case 'ValueCoin1':
        this._flWasherModelServices.prevCoinValue = this.vendingSettings.ValueCoin1.value[1];
        this.model.ValueCoin1Cents = this.model.ValueCoin1Cents ? this.model.ValueCoin1Cents : '0';
        const isValid = this.validateCents(this.modelType, value);
        if (isValid) {
          if (!value && value != '0') {
            this.model.ValueCoin1Cents = this.vendingSettings.ValueCoin1.cents;
            value = this.model.ValueCoin1Cents;
          }
          this._flWasherModelServices.coin1Value.dollar = this.model.ValueCoin1Dollar;
          this._flWasherModelServices.coin1Value.cent = value;
          this.vendingSettings.ValueCoin1.value[1] = value;
          if (this.modelType == 'PD') {
            this.hasChangedValue('coin1Cents');
          }
          const isValidPrice = this._flWasherModelServices.setCyclesPrice(true, value);
          if (!isValidPrice) {
            this.vendingSettings.ValueCoin1.value[1] = this._flWasherModelServices.prevCoinValue;
            this._flWasherModelServices.coin1Value.cent = this._flWasherModelServices.prevCoinValue;
            this.model.ValueCoin1Cents = this._flWasherModelServices.prevCoinValue;
          }
        }
        break;
      case 'ValueCoin2':
        this.model.ValueCoin2Cents = this.model.ValueCoin2Cents ? this.model.ValueCoin2Cents : '0';
        if (Number(this.model.ValueCoin2Dollar) > 9) {
          value = '9';
          this.model.ValueCoin2Cents = '95';
          this.model.ValueCoin2Dollar = '9';
          return;
        }
        if (!value && value != '0') {
          this.model.ValueCoin2Cents = this.vendingSettings.ValueCoin2.cents;
          value = this.model.ValueCoin2Cents;
        }
        this.vendingSettings.ValueCoin2.value[1] = value;
        break;

      case 'CoolTemperature':
        this.model.CoolTemperatureCents = this.model.CoolTemperatureCents ? this.model.CoolTemperatureCents : '0';
        if (!value && value != '0') {
          this.model.CoolTemperatureCents = this.vendingSettings.CoolTemperature.cents;
          value = this.model.CoolTemperatureCents;
        }
        this.vendingSettings.CoolTemperature.value[1] = value;
        break;

      case 'WarmTemperature':
        this.model.WarmTemperatureCents = this.model.WarmTemperatureCents ? this.model.WarmTemperatureCents : '0';
        if (!value && value != '0') {
          this.model.WarmTemperatureCents = this.vendingSettings.WarmTemperature.cents;
          value = this.model.WarmTemperatureCents;
        }
        this.vendingSettings.WarmTemperature.value[1] = value;
        break;
      case 'HotTemperature':
        this.model.HotTemperatureCents = this.model.HotTemperatureCents ? this.model.HotTemperatureCents : '0';
        if (!value && value != '0') {
          this.model.HotTemperatureCents = this.vendingSettings.HotTemperature.cents;
          value = this.model.HotTemperatureCents;
        }
        this.vendingSettings.HotTemperature.value[1] = value;
        break;

      case 'HeavySoil':
        this.model.HeavySoilCents = this.model.HeavySoilCents ? this.model.HeavySoilCents : '0';
        if (!value && value != '0') {
          this.model.HeavySoilCents = this.vendingSettings.HeavySoil.cents;
          value = this.model.HeavySoilCents;
        }
        this.vendingSettings.HeavySoil.value[1] = value;
        break;

      case 'ExtraRinse':
        this.model.ExtraRinseCents = this.model.ExtraRinseCents ? this.model.ExtraRinseCents : '0';
        if (!value && value != '0') {
          this.model.ExtraRinseCents = this.vendingSettings.ExtraRinseAdder.cents;
          value = this.model.ExtraRinseCents;
        }
        this.vendingSettings.ExtraRinseAdder.value[1] = value;
        break;

      default:
        break;
    }
  }

  validateCents(type: string, value: string): boolean {
    if (Number(this.model.ValueCoin1Dollar) > 9 && this.modelType == 'PD') {
      value = '95';
      this.model.ValueCoin1Cents = '95';
      this.model.ValueCoin1Dollar = '9';
      this.hasChangedValue('coin1Dollar');
      this.hasChangedValue('coin1Cents');
      return false;
    } else if (Number(this.model.ValueCoin1Dollar) > 9 && (type == 'PR' || type == '258J')) {
      value = '95';
      this.model.ValueCoin1Cents = '95';
      this.model.ValueCoin1Dollar = '9';
      return false;
    }
    return true;
  }

  changeTimeCoin() {
    this.hasChangedValue('timeCoin');
  }

  hasChangedValue(controlName: string) {
    this.isValueChanged.map(item => {
      if (item.name === controlName) {
        item.value = true;
      } else {
        item.value = false;
      }
    });
  }

  jqueryFunc(name: string) {
    const inputName = `${'#' + name}`;
    $(inputName).bind('keyup input', function () {
      this.setAttribute("step", "5");
    });
  }

  jqueryFuncDefault() {
    $("input[type=number]").bind('keyup input', function () {
      setTimeout(() => {
        this.setAttribute("step", "5");
      }, 50);
    });
  }

  ngOnDestroy(): void {
    const isModel = this._flWasherModelServices.modelChanged();
    if (!isModel) {
      this.getDollarCents();
      this._flWasherModelServices.setFlWasherCycleVendingSModels(this.vendingSettings);
    }
  }
}
