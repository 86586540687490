export interface ICountryList {
  COUNTRY_CODE: string;
  COUNTRY_ID: number;
  COUNTRY_NAME: string;
  STATE_LABEL: string;
  ZIP_CODE: string;
}

export interface ICompanyList {
  COMPANY_NAME: string;
  COMPANY_ID: number;
}

export class ErrorList {
  msgEmailVerify: string = 'Email already exists into database.';
  msgCompanyVerify: string = 'Company already exists into database.';
  msgSaveSuccessfully: string = 'Saved successfully!!';
  msgPhoneNumber: string = 'Phone no should be 10 digits.'; 
}
