import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonServices } from 'src/app/services/CommonServices';
import { DataGetSetService } from 'src/app/services/DataGetSetService';
import { ModelServices } from 'src/app/services/ModelServices';
import { ResetFormService } from 'src/app/services/ResetService';
import { NovoFileNames } from '../../../enums/enum';
import { VendingSettingDetail } from '../../../models/vendingpayment';
import { IGetUserDefinedConfiguration } from '../../user-defined-setup/user-defined-setup.model';

@Component({
  selector: 'app-vendingsetting',
  templateUrl: './vendingsetting.component.html',
  styleUrls: ['./vendingsetting.component.scss'],
})
export class VendingsettingComponent implements OnInit, OnDestroy, AfterViewInit {
  model: VendingSettingDetail;
  defaultValues: any;
  vendingForm: FormGroup;
  IsDisabled: any = true;
  cyclevendingObj: any = {};
  isShowModel = true;
  options: any;
  selectedDayArr: any = [];
  elmts; any;
  arrVendingSettingDetail: any = new Array();
  isSetToDefaultHide: boolean = false;

  constructor(
    private modelServices: ModelServices,
    private commonServices: CommonServices,
    private resetFormService: ResetFormService,
    public datagetsetService: DataGetSetService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.options = this.modelServices.getCyclevendingData();
    this.vendingForm = this.fb.group({
      dollarOne: new FormControl('0', Validators.compose([Validators.required, Validators.min(2), Validators.max(2)])),
      centOne: new FormControl('0'),
      dollarTwo: new FormControl('0'),
      centsTwo: new FormControl('0'),
      startHour: new FormControl('0'),
      toggleChecked: new FormControl(this.options.isPricingEnable["Default"] === 'yes' ? true : false),
      endHour: new FormControl('0'),
      isSpecialPricing: this.options.isPricingEnable["Default"],
      coolWaterDollar: new FormControl('0'),
      coolWaterCents: new FormControl('0'),
      warmWaterDollar: new FormControl('0'),
      warmWaterCents: new FormControl('0'),
      hotWaterDollar: new FormControl('0'),
      hotWaterCents: new FormControl('0'),
      detergentDollar: new FormControl('0'),
      detergentCents: new FormControl('0'),
      fabricDollar: new FormControl('0'),
      fabricCents: new FormControl('0'),
      bleachDollar: new FormControl('0'),
      bleachCents: new FormControl('0'),
      mediumSpeedDollar: new FormControl('0'),
      mediumSpeedCents: new FormControl('0'),
      highSpeedDollar: new FormControl('0'),
      highSpeedCents: new FormControl('0'),
      solLevelDollar: new FormControl('0'),
      solLevelCents: new FormControl('0'),
      extraRinseDollar: new FormControl('0'),
      extraRinseCents: new FormControl('0'),
      specialPriceDay: new FormControl('0'),
      cyclevendingObj: new FormControl('0'),
    });
    this.vendingForm.get("startHour").disable();
    this.vendingForm.get("endHour").disable();
  }

  ngOnInit() {
    this.isSetToDefaultHide = this.commonServices.isSetToDefaultHide;
    this.isShowModel = localStorage.getItem("isShowModel") == '0' ? false : true;
    let changeValues = [
      { "dollar": 'dollarOne', "cent": 'centOne' },
      { "dollar": 'dollarTwo', "cent": 'centsTwo' },
      { "dollar": 'coolWaterDollar', "cent": 'coolWaterCents' },
      { "dollar": 'warmWaterDollar', "cent": 'warmWaterCents' },
      { "dollar": 'hotWaterDollar', "cent": 'hotWaterCents' },
      { "dollar": 'detergentDollar', "cent": 'detergentCents' },
      { "dollar": 'fabricDollar', "cent": 'fabricCents' },
      { "dollar": 'bleachDollar', "cent": 'bleachCents' },
      { "dollar": 'mediumSpeedDollar', "cent": 'mediumSpeedCents' },
      { "dollar": 'highSpeedDollar', "cent": 'highSpeedCents' },
      { "dollar": 'solLevelDollar', "cent": 'solLevelCents' },
      { "dollar": 'extraRinseDollar', "cent": 'extraRinseCents' }
    ];
    this.vendingForm.get("toggleChecked").valueChanges.subscribe(newToogleValue => {
      if (newToogleValue == true) {
        this.vendingForm.controls.startHour.enable();
        this.vendingForm.controls.endHour.enable();
        this.IsDisabled = false;
      } else {
        this.IsDisabled = true;
        this.vendingForm.get("startHour").disable();
        this.vendingForm.get("endHour").disable();
        this.vendingForm.get("startHour").setValue(this.options.startHourval.Min);
        this.vendingForm.get("endHour").setValue(this.options.endtHourval.Min);
        this.model.specialPriceDay.filter((day) => {
          day.checked = false;
        });
        if (this.selectedDayArr && this.selectedDayArr.length > 0) {
          this.selectedDayArr.length = 0;
        }
        if (this.modelServices.vendingSettings && this.modelServices.vendingSettings.selectedDays.length > 0) {
          this.modelServices.vendingSettings.selectedDays.length = 0;
        }
      }
    });
    changeValues.forEach(data => {
      this.vendingForm.get(data["dollar"]).valueChanges.subscribe(x => {
        if (x > 99) {
          this.vendingForm.get(data["dollar"]).setValue(99, { emitEvent: false });

        }
      })
      this.vendingForm.get(data["cent"]).valueChanges.subscribe(x => {
        var d = Number(this.vendingForm.get(data["dollar"]).value);
        if (x > 99) {
          this.vendingForm.get(data["cent"]).setValue(99, { emitEvent: false });
        }
        if (x == 100 && d < 99) {

          var result = this.dollarConverts(x);
          d = d + Number(result[0]);

          this.vendingForm.get(data["dollar"]).setValue(d);
          this.vendingForm.get(data["cent"]).setValue(result[1])
        }

      });

    });
    this.setToDefault();
    //let specialPriceDays = this.getDaysBinary(this.model.specialPriceDay);
    var vendingSettingDetail: VendingSettingDetail = new VendingSettingDetail();
    this.commonServices.getVendingSettingValue().subscribe(() => {
      this.cyclevendingObj = this.datagetsetService.get();
      let specialPriceDays = this.getDaysBinary(this.selectedDayArr);
      vendingSettingDetail.isSpecialPricing = Boolean(this.vendingForm.get("toggleChecked").value); //this.options.isPricingEnable["Default"] === 'yes' ? true : false; // 1:0
      vendingSettingDetail.specialPriceDay = String(this.model.specialPriceDay === undefined ? 0 : specialPriceDays);
      vendingSettingDetail.starHour = Number(this.vendingForm.get("startHour").value === undefined ? 0 : this.vendingForm.get("startHour").value);
      vendingSettingDetail.endHour = Number(this.vendingForm.get("endHour").value === undefined ? 0 : this.vendingForm.get("endHour").value);
      vendingSettingDetail.coinFirstDollar = Number(this.vendingForm.get("dollarOne").value === undefined ? 0 : this.vendingForm.get("dollarOne").value);
      vendingSettingDetail.coinFirstCent = Number(this.vendingForm.get("centOne").value === undefined ? 0 : this.vendingForm.get("centOne").value);
      vendingSettingDetail.coinSecondDollar = Number(this.vendingForm.get("dollarTwo").value === undefined ? 0 : this.vendingForm.get("dollarTwo").value);
      vendingSettingDetail.coinSecondCent = Number(this.vendingForm.get("centsTwo").value === undefined ? 0 : this.vendingForm.get("centsTwo").value);
      vendingSettingDetail.coolerWaterAdderDollar = Number(this.vendingForm.get("coolWaterDollar").value === undefined ? 0 : this.vendingForm.get("coolWaterDollar").value);
      vendingSettingDetail.coolerWaterAdderCent = Number(this.vendingForm.get("coolWaterCents").value === undefined ? 0 : this.vendingForm.get("coolWaterCents").value);
      vendingSettingDetail.warmWaterAdderDollar = Number(this.vendingForm.get("warmWaterDollar").value === undefined ? 0 : this.vendingForm.get("warmWaterDollar").value);
      vendingSettingDetail.warmWaterAdderCent = Number(this.vendingForm.get("warmWaterCents").value === undefined ? 0 : this.vendingForm.get("warmWaterCents").value);
      vendingSettingDetail.hotWaterAdderDollar = Number(this.vendingForm.get("hotWaterDollar").value === undefined ? 0 : this.vendingForm.get("hotWaterDollar").value);
      vendingSettingDetail.hotWaterAdderCent = Number(this.vendingForm.get("hotWaterCents").value === undefined ? 0 : this.vendingForm.get("hotWaterCents").value);
      vendingSettingDetail.detergentAdderDollar = Number(this.vendingForm.get("detergentDollar").value === undefined ? 0 : this.vendingForm.get("detergentDollar").value);
      vendingSettingDetail.detergentAdderCent = Number(this.vendingForm.get("detergentCents").value === undefined ? 0 : this.vendingForm.get("detergentCents").value);
      vendingSettingDetail.fabricSoftnerAdderDollar = Number(this.vendingForm.get("fabricDollar").value === undefined ? 0 : this.vendingForm.get("fabricDollar").value);
      vendingSettingDetail.fabricSoftnerAdderCent = Number(this.vendingForm.get("fabricCents").value === undefined ? 0 : this.vendingForm.get("fabricCents").value);
      vendingSettingDetail.bleachAdderDollar = Number(this.vendingForm.get("bleachDollar").value === undefined ? 0 : this.vendingForm.get("bleachDollar").value);
      vendingSettingDetail.bleachAdderCent = Number(this.vendingForm.get("bleachCents").value === undefined ? 0 : this.vendingForm.get("bleachCents").value);
      vendingSettingDetail.extraRinseAdderDollar = Number(this.vendingForm.get("extraRinseDollar").value === undefined ? 0 : this.vendingForm.get("extraRinseDollar").value);
      vendingSettingDetail.extraRinseAdderCent = Number(this.vendingForm.get("extraRinseCents").value === undefined ? 0 : this.vendingForm.get("extraRinseCents").value);
      vendingSettingDetail.mediumSpeedAdderDollar = Number(this.vendingForm.get("mediumSpeedDollar").value === undefined ? 0 : this.vendingForm.get("mediumSpeedDollar").value);
      vendingSettingDetail.mediumSpeedAdderCent = Number(this.vendingForm.get("mediumSpeedCents").value === undefined ? 0 : this.vendingForm.get("mediumSpeedCents").value);
      vendingSettingDetail.highSpeedAdderDollar = Number(this.vendingForm.get("highSpeedDollar").value === undefined ? 0 : this.vendingForm.get("highSpeedDollar").value);
      vendingSettingDetail.highSpeedAdderCent = Number(this.vendingForm.get("highSpeedCents").value === undefined ? 0 : this.vendingForm.get("highSpeedCents").value);
      vendingSettingDetail.soilLevelAdderDollar = Number(this.vendingForm.get("solLevelDollar").value === undefined ? 0 : this.vendingForm.get("solLevelDollar").value);
      vendingSettingDetail.soilLevelAdderCent = Number(this.vendingForm.get("solLevelCents").value === undefined ? 0 : this.vendingForm.get("solLevelCents").value);
      vendingSettingDetail.soillevel = Number('0');
      vendingSettingDetail.rfu = '0000000000000000000000000';
      this.VendingSettingConfigBytes(vendingSettingDetail);
      localStorage.setItem('arrVendingSettingDetail', this.arrVendingSettingDetail);
      this.commonServices.novoEcoModels.vendingConfig = '';
      this.vendingForm.get("specialPriceDay").setValue(this.model.specialPriceDay);
      this.vendingForm.get("cyclevendingObj").setValue(this.cyclevendingObj);
      this.commonServices.novoEcoModels.vendingConfig = JSON.stringify(this.vendingForm.value);
    });
    this.modelServices.isCycleVendingSettingsTobePush = true;
  }

  ngAfterViewInit() {
    this.readBinaryConfig();
    this.readModelConfig();
    this.cdr.detectChanges();
  }

  readBinaryConfig() {
    this.commonServices.isSetupTabSelected.subscribe((tab) => {
      this.binaryDefaultValues();
    });
  }

  readModelConfig() {
    this.commonServices.isNovoModelSelected.subscribe((userDefinedConfig: IGetUserDefinedConfiguration) => {
      if (localStorage.getItem("ProductType") !== "PN") {
        this.commonServices.setVendingSetting.next(JSON.parse(userDefinedConfig.vendingConfig));
        this.bindUserDefinedModelData(JSON.parse(userDefinedConfig.vendingConfig));
      }
    });
  }

  bindUserDefinedModelData(cycleVendingSetting) {
    this.selectedDayArr = [];

    // setTimeout(() => {
    const isSpecialEnable = Boolean(cycleVendingSetting.isSpecialPricing) ? 1 : 0;
    this.vendingForm.get("toggleChecked").setValue(isSpecialEnable);
    this.model.specialPriceDay = cycleVendingSetting.specialPriceDay;
    cycleVendingSetting.specialPriceDay.map(item => {
      if (item.checked) {
        this.selectedDayArr.push(item)
      }
    })
    // }, 50);
    this.vendingForm.get("dollarOne").setValue(cycleVendingSetting.dollarOne);
    this.vendingForm.get("centOne").setValue(cycleVendingSetting.centOne);
    this.vendingForm.get("dollarTwo").setValue(cycleVendingSetting.dollarTwo);
    this.vendingForm.get("centsTwo").setValue(cycleVendingSetting.centsTwo);
    this.vendingForm.get("coolWaterDollar").setValue(cycleVendingSetting.coolWaterDollar);
    this.vendingForm.get("coolWaterCents").setValue(cycleVendingSetting.coolWaterCents);
    this.vendingForm.get("warmWaterDollar").setValue(cycleVendingSetting.warmWaterDollar);
    this.vendingForm.get("warmWaterCents").setValue(cycleVendingSetting.warmWaterCents);
    this.vendingForm.get("hotWaterDollar").setValue(cycleVendingSetting.hotWaterDollar);
    this.vendingForm.get("hotWaterCents").setValue(cycleVendingSetting.hotWaterCents);
    this.vendingForm.get("extraRinseDollar").setValue(cycleVendingSetting.extraRinseDollar);
    this.vendingForm.get("extraRinseCents").setValue(cycleVendingSetting.extraRinseCents);
    this.vendingForm.get("mediumSpeedDollar").setValue(cycleVendingSetting.mediumSpeedDollar);
    this.vendingForm.get("mediumSpeedCents").setValue(cycleVendingSetting.mediumSpeedCents);
    this.vendingForm.get("highSpeedDollar").setValue(cycleVendingSetting.highSpeedDollar);
    this.vendingForm.get("highSpeedCents").setValue(cycleVendingSetting.highSpeedCents);
    this.vendingForm.get("detergentDollar").setValue(cycleVendingSetting.detergentDollar);
    this.vendingForm.get("detergentCents").setValue(cycleVendingSetting.detergentCents);
    this.vendingForm.get("bleachDollar").setValue(cycleVendingSetting.bleachDollar);
    this.vendingForm.get("bleachCents").setValue(cycleVendingSetting.bleachCents);
    this.vendingForm.get("fabricDollar").setValue(cycleVendingSetting.fabricDollar);
    this.vendingForm.get("fabricCents").setValue(cycleVendingSetting.fabricCents);
    this.vendingForm.get("solLevelDollar").setValue(cycleVendingSetting.solLevelDollar);
    this.vendingForm.get("solLevelCents").setValue(cycleVendingSetting.solLevelCents);
    this.vendingForm.get("startHour").setValue(cycleVendingSetting.startHour);
    this.vendingForm.get("endHour").setValue(cycleVendingSetting.endHour);
  }

  priceChange(event) {
    event.target.value = event.target.value ? parseInt(event.target.value, 10) : 0;
  }

  binaryDefaultValues() {
    const machineBinaryValue = this.commonServices.getBinaryData(NovoFileNames.cycle_vending_Settings);
    if (!machineBinaryValue || !machineBinaryValue.length) {
      return;
    }
    this.vendingForm.get("toggleChecked").setValue(machineBinaryValue[0].value[53]);
    let twoBytes = this.convertDecimalToBinary(machineBinaryValue[0].value[57]);
    twoBytes.slice().reverse().map((item, index) => {
      if (item == "1") {
        this.selectedDayArr.push({ value: this.model.specialPriceDay[index].value, checked: item == "1" ? true : false })
      }
    })
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[61], machineBinaryValue[0].value[60]);
    this.vendingForm.get("dollarOne").setValue(twoBytes[0]);
    this.vendingForm.get("centOne").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[63], machineBinaryValue[0].value[62]);
    this.vendingForm.get("dollarTwo").setValue(twoBytes[0]);
    this.vendingForm.get("centsTwo").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[66], machineBinaryValue[0].value[65]);
    this.vendingForm.get("coolWaterDollar").setValue(twoBytes[0]);
    this.vendingForm.get("coolWaterCents").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[68], machineBinaryValue[0].value[67]);
    this.vendingForm.get("warmWaterDollar").setValue(twoBytes[0]);
    this.vendingForm.get("warmWaterCents").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[70], machineBinaryValue[0].value[69]);
    this.vendingForm.get("hotWaterDollar").setValue(twoBytes[0]);
    this.vendingForm.get("hotWaterCents").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[72], machineBinaryValue[0].value[71]);
    this.vendingForm.get("extraRinseDollar").setValue(twoBytes[0]);
    this.vendingForm.get("extraRinseCents").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[74], machineBinaryValue[0].value[73]);
    this.vendingForm.get("mediumSpeedDollar").setValue(twoBytes[0]);
    this.vendingForm.get("mediumSpeedCents").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[76], machineBinaryValue[0].value[75]);
    this.vendingForm.get("highSpeedDollar").setValue(twoBytes[0]);
    this.vendingForm.get("highSpeedCents").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[78], machineBinaryValue[0].value[77]);
    this.vendingForm.get("detergentDollar").setValue(twoBytes[0]);
    this.vendingForm.get("detergentCents").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[80], machineBinaryValue[0].value[79]);
    this.vendingForm.get("bleachDollar").setValue(twoBytes[0]);
    this.vendingForm.get("bleachCents").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[82], machineBinaryValue[0].value[81]);
    this.vendingForm.get("fabricDollar").setValue(twoBytes[0]);
    this.vendingForm.get("fabricCents").setValue(twoBytes[1]);
    twoBytes = this.convertDecimalToBinArry(machineBinaryValue[0].value[84], machineBinaryValue[0].value[83]);
    this.vendingForm.get("solLevelDollar").setValue(twoBytes[0]);
    this.vendingForm.get("solLevelCents").setValue(twoBytes[1]);
    this.vendingForm.get("startHour").setValue(machineBinaryValue[0].value[85]);
    this.vendingForm.get("endHour").setValue(machineBinaryValue[0].value[87]);
    // this.binaryVendingSettingConfigBytes(machineBinaryValue);

    // localStorage.setItem('arrVendingSettingDetail', this.arrVendingSettingDetail);
  }

  convertDecimalToBinArry(firstVal: number, secondVal: number) {
    const oneValue = firstVal.toString(16).padStart(2, '0');
    const secondValue = secondVal.toString(16).padStart(2, '0');
    const intVal = parseInt(`${oneValue + secondValue}`, 16);
    const calcVal = (intVal / 100).toFixed(2).toString().split(".");
    return calcVal;
  }

  convertDecimalToBinary(value: string) {
    const number = parseInt(value);
    const result = number.toString(2);
    return result.split(/(.{1})/).filter(x => x.length == 1);
  }

  setToFormDefault() {
    this.vendingForm.reset(this.defaultValues);
    this.resetFormService.emitChange(true);
    this.model.isSpecialPricing = this.options.isPricingEnable["Default"] === 'yes' ? true : false;
    this.vendingForm.get("startHour").setValue(this.options.startHourval.Min);
    this.vendingForm.get("endHour").setValue(this.options.endtHourval.Min);
    this.model.specialPriceDay.filter((day) => {
      day.checked = false;
    });
    if (this.selectedDayArr && this.selectedDayArr.length > 0) {
      this.selectedDayArr.length = 0;
    }
    if (this.modelServices.vendingSettings && this.modelServices.vendingSettings.selectedDays.length > 0) {
      this.modelServices.vendingSettings.selectedDays.length = 0;
    }
  }

  ngOnDestroy(): void {
    if (this.modelServices.isCycleVendingSettingsTobePush) {
      this.modelServices.vendingSettings = this.vendingForm.value;
      this.modelServices.vendingSettings.selectedDays = [];
      this.selectedDayArr.forEach(element => {
        this.modelServices.vendingSettings.selectedDays.push(element);
      });
    } else {
      this.modelServices.vendingSettings = undefined;
      this.resetFormService.emitChange(true);
    }
  }

  CalculateBytes(dollar: number, cents: number) {
    let price = dollar * 100 + cents;
    let firstByte = 0, secondByte = 0;
    firstByte = (price & 0x00FF);
    secondByte = (price & 0xFF00) >> 8;
    return [firstByte, secondByte];
  }

  VendingSettingConfigBytes(vendingSetting: any) {
    this.arrVendingSettingDetail = [];
    this.arrVendingSettingDetail.push(vendingSetting.isSpecialPricing == true ? 1 : 0);
    this.arrVendingSettingDetail.push(this.cyclevendingObj.vendingDisplay);
    this.arrVendingSettingDetail.push(this.cyclevendingObj.paymentMode);
    this.arrVendingSettingDetail.push(this.cyclevendingObj.escrowOption);
    this.arrVendingSettingDetail.push(vendingSetting.specialPriceDay);
    this.arrVendingSettingDetail.push(this.cyclevendingObj.moneyCounter);
    this.arrVendingSettingDetail.push(this.cyclevendingObj.currencySign);

    let twoBytes = this.CalculateBytes(vendingSetting.coinFirstDollar, vendingSetting.coinFirstCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(vendingSetting.coinSecondDollar, vendingSetting.coinSecondCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);
    this.arrVendingSettingDetail.push(this.cyclevendingObj.valultViewOption);

    twoBytes = this.CalculateBytes(vendingSetting.coolerWaterAdderDollar, vendingSetting.coolerWaterAdderCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(vendingSetting.warmWaterAdderDollar, vendingSetting.warmWaterAdderCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(vendingSetting.hotWaterAdderDollar, vendingSetting.hotWaterAdderCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(vendingSetting.extraRinseAdderDollar, vendingSetting.extraRinseAdderCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(vendingSetting.mediumSpeedAdderDollar, vendingSetting.mediumSpeedAdderCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(vendingSetting.highSpeedAdderDollar, vendingSetting.highSpeedAdderCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(vendingSetting.detergentAdderDollar, vendingSetting.detergentAdderCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(vendingSetting.bleachAdderDollar, vendingSetting.bleachAdderCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(vendingSetting.fabricSoftnerAdderDollar, vendingSetting.fabricSoftnerAdderCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(vendingSetting.soilLevelAdderDollar, vendingSetting.soilLevelAdderCent);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(0, vendingSetting.starHour);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(0, vendingSetting.endHour);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    this.arrVendingSettingDetail.push(vendingSetting.soillevel);
    this.arrVendingSettingDetail.push(vendingSetting.soillevel);
    this.arrVendingSettingDetail.push(vendingSetting.rfu);
  }

  binaryVendingSettingConfigBytes(machineBinaryValue) {
    this.arrVendingSettingDetail = [];
    this.arrVendingSettingDetail.push(machineBinaryValue[0].value[53]);
    this.arrVendingSettingDetail.push(machineBinaryValue[0].value[54]);
    this.arrVendingSettingDetail.push(machineBinaryValue[0].value[55]);
    this.arrVendingSettingDetail.push(machineBinaryValue[0].value[56]);
    this.arrVendingSettingDetail.push(machineBinaryValue[0].value[57]);
    this.arrVendingSettingDetail.push(machineBinaryValue[0].value[58]);
    this.arrVendingSettingDetail.push(machineBinaryValue[0].value[59]);

    let twoBytes = this.CalculateBytes(machineBinaryValue[0].value[61], machineBinaryValue[0].value[60]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[63], machineBinaryValue[0].value[62]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);
    this.arrVendingSettingDetail.push(machineBinaryValue[0].value[64]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[66], machineBinaryValue[0].value[65]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[68], machineBinaryValue[0].value[67]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[70], machineBinaryValue[0].value[69]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[72], machineBinaryValue[0].value[71]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[74], machineBinaryValue[0].value[73]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[76], machineBinaryValue[0].value[75]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[78], machineBinaryValue[0].value[77]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[80], machineBinaryValue[0].value[79]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[82], machineBinaryValue[0].value[81]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(machineBinaryValue[0].value[84], machineBinaryValue[0].value[83]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(0, machineBinaryValue[0].value[85]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    twoBytes = this.CalculateBytes(0, machineBinaryValue[0].value[87]);
    this.arrVendingSettingDetail.push(twoBytes[0]);
    this.arrVendingSettingDetail.push(twoBytes[1]);

    this.arrVendingSettingDetail.push(Number('0'));
    this.arrVendingSettingDetail.push(Number('0'));
    this.arrVendingSettingDetail.push('0000000000000000000000000');

  }

  onDaySelection(event) {
    var index = this.selectedDayArr.findIndex(function (wizard) {
      return wizard.value === event.source.value.value;
    });

    if (index == -1) {
      event.source.value.checked = true;
      this.selectedDayArr.push(event.source.value);

    } else {
      this.selectedDayArr.splice(index, 1);
    }
  }

  setToDefault() {
    var arrDays: any = [];
    if (this.modelServices.vendingSettings && this.modelServices.vendingSettings.selectedDays.length > 0) {
      this.options.specialDays["values"].filter((item) => {
        arrDays.push({ value: item, checked: false })
      })
      arrDays.filter((item) => {
        this.modelServices.vendingSettings.selectedDays.forEach((element) => {
          if (item.value == element.value) {
            item.checked = true
          }
        })

      })
    } else {
      this.options.specialDays["values"].filter((item) => {
        arrDays.push({ value: item, checked: false })
      })
    }

    this.model = {
      isSpecialPricing: this.options.isPricingEnable["Default"],
      specialPriceDay: this.modelServices.vendingSettings && this.modelServices.vendingSettings.selectedDays.length > 0 ? arrDays : this.options.specialDays["values"].map(day => ({
        value: day, checked: false
      })),
      startHourMin: this.options.startHourval["Min"],
      starHourMax: this.options.startHourval["Max"],

      endHourMin: this.options.endtHourval["Min"],
      endHourMax: this.options.endtHourval["Max"]

    }


    this.BindTextBox("centOne", "dollarOne", this.returnResult(this.options.centsOneval));
    this.BindTextBox("centsTwo", "dollarTwo", this.returnResult(this.options.centsTwoval));
    this.BindTextBox("startHour", "", this.returnResult(this.options.startHourval));

    this.BindTextBox("endHour", "", this.returnResult(this.options.endtHourval));

    this.BindTextBox("coolWaterCents", "coolWaterDollar", this.returnResult(this.options.coolWatercentsval));
    this.BindTextBox("warmWaterCents", "warmWaterDollar", this.returnResult(this.options.warmWatercentsval));
    this.BindTextBox("hotWaterCents", "hotWaterDollar", this.returnResult(this.options.hotWatercentsval));
    this.BindTextBox("detergentCents", "detergentDollar", this.returnResult(this.options.detergentcentsval));
    this.BindTextBox("fabricCents", "fabricDollar", this.returnResult(this.options.fabriccentsval));
    this.BindTextBox("bleachCents", "bleachDollar", this.returnResult(this.options.bleachcentsval));
    this.BindTextBox("mediumSpeedCents", "mediumSpeedDollar", this.returnResult(this.options.mediumSpeedcentsval));
    this.BindTextBox("highSpeedCents", "highSpeedDollar", this.returnResult(this.options.highSpeedcentsval));
    this.BindTextBox("solLevelCents", "solLevelDollar", this.returnResult(this.options.solAddercentsval));
    this.BindTextBox("extraRinseCents", "extraRinseDollar", this.returnResult(this.options.rinseAddercentsval));
    this.defaultValues = this.vendingForm.value;

    if (this.modelServices.vendingSettings !== undefined) {
      this.selectedDayArr = [];
      this.vendingForm.reset(this.modelServices.vendingSettings);
      this.modelServices.vendingSettings.selectedDays.forEach(element => {
        this.selectedDayArr.push(element);
      });
    }

    if (this.vendingForm.get("endHour").value == null) {
      this.vendingForm.get("endHour").setValue(this.options.endtHourval.Min);
    }
  }

  BindDropDown(elementId: any, values: any, defaultValue: any) {
    var select = (document.getElementById(elementId) as HTMLSelectElement);
    select.innerHTML = "";
    this.elmts = values.values;
    for (var i = 0; i < this.elmts.length; i++) {
      var optn = this.elmts[i];
      var el = document.createElement("option");
      el.text = String(optn);
      el.value = String(optn);
      select.appendChild(el);
    }


    select.value = defaultValue.Default;
  }

  BindTextBox(elementId: string, dollarId: string, defaultValue: any) {

    var amt = this.dollarConverts(defaultValue["Default"]);
    if (dollarId.length !== 0) {
      this.vendingForm.get(dollarId).setValue(amt[0]);
    }
    this.vendingForm.get(elementId).setValue(amt[1]);


  }

  bindAmount(elementId: any, centsVal: any, dollarVal: any) {

    var amt = this.dollarConverts(this.options.centsTwoval.Default);
    var txtElement2 = (<HTMLInputElement>document.getElementById(elementId));
    txtElement2.name = dollarVal.amt[0];
    txtElement2.name = centsVal.amt[1];

  }

  returnResult(_value: any) {
    var value = _value;
    return value;
  }

  dollarConverts(c) {
    var nCents = c;
    var nDollar = (nCents / 100);
    var rDollar = Math.floor(nDollar);

    var cents = (nDollar - rDollar) * 100;
    var rCents = Math.round(cents);
    return [rDollar, rCents]
  }

  getDaysBinary(specialDays: any) {
    //let tempNum: any = '0';
    let sun, mon, tue, wed, thu, fri, sat;
    sun = mon = tue = wed = thu = fri = sat = '0';
    specialDays.forEach(element => {
      switch (element.value.toUpperCase()) {
        case "SUN":
          sun = "1";
          break;
        case "MON":
          mon = "1";
          break;
        case "TUE":
          tue = "1";
          break;
        case "WED":
          wed = "1";
          break;
        case "THURS":
          thu = "1";
          break;
        case "FRI":
          fri = "1";
          break;
        case "SAT":
          sat = "1";
          break;
      }
    });
    return parseInt(0 + sat + fri + thu + wed + tue + mon + sun, 2);
  }
  /***************************** CHANGE EVENTS***************************** */

  inputStartHourRange() {
    if (this.vendingForm.get("startHour").value > this.options.startHourval.Max) {
      this.vendingForm.get("startHour").setValue(this.options.startHourval.Max);
    }
    if (!this.vendingForm.get("startHour").value || this.vendingForm.get("startHour").value < this.options.startHourval.Min) {
      this.vendingForm.get("startHour").setValue(this.options.startHourval.Min);
    }
  }

  inputEndHourRange() {
    if (this.vendingForm.get("endHour").value > this.options.endtHourval.Max) {
      this.vendingForm.get("endHour").setValue(this.options.endtHourval.Max);
    }
    if (!this.vendingForm.get("endHour").value || this.vendingForm.get("endHour").value < this.options.endtHourval.Min) {
      this.vendingForm.get("endHour").setValue(this.options.endtHourval.Min);
    }
  }

}
