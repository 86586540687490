import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { Router } from "@angular/router";
import { CommonServices } from "src/app/services/CommonServices";
import { ModelServices } from "src/app/services/ModelServices";
import { NovoFileNames } from "../../../enums/enum";
import { CycleSettings } from "../../../models/UsbGenerator";
import { IGetUserDefinedConfiguration } from "../../user-defined-setup/user-defined-setup.model";
import { IBinaryModel, IPhaseDetail, IPhaseName } from "./cyclecombination.component.model";

@Component({
  selector: "app-cyclecmobination",
  templateUrl: "./cyclecmobination.component.html",
  styleUrls: ["./cyclecmobination.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CyclecmobinationComponent implements OnInit, AfterViewInit {
  options: any;
  selectedCycleList: any;
  modelNumber: string;
  isdisabled: boolean;
  myOptions = {
    placement: "top",
    "show-delay": "500",
    "tooltip-class": "tooltip",
  };
  @ViewChild("panel", { read: ElementRef, static: false })
  public panel: ElementRef<any>;
  title = "Animation Application";
  isEnlarge: boolean = false;
  buttonText: string = "Enlarge";
  ProductType: string; //="PN"; //or not PN
  Weight: any; //=99; or //gt9
  numberofDrains: number;
  SetOptions: any;
  modelValidation: any;
  arrCycleSettings: any = new Array();
  macOptions: any;
  public modelValues: any = {};
  selectedIndex: number = null;

  binaryModel: IBinaryModel[] = [];
  phaseName: IPhaseName[] = [];
  inputColdTemp: string;
  inputCoolTemp: string;
  inputWarmTemp: string;
  inputHotTemp: string;

  constructor(
    private modelServices: ModelServices,
    private commonService: CommonServices,
    private route: Router,
    private cdr: ChangeDetectorRef,
    private commonServices: CommonServices
  ) { }

  ngOnInit() {
    this.commonService.receivedApiValTwo().subscribe((d) => {
      setTimeout(() => {
        this.isdisabled = d;
      }, 50);
    });

    this.modelServices.selectedModel.subscribe((val: any) => {
      this.modelNumber = val;
      this.isdisabled = false;
      this.selectedIndex = 0;
      this.ProductType = this.modelServices.getProductType(this.modelNumber);
      this.modelValidation = this.modelServices.getCycleValidation(
        this.ProductType
      );
      this.Weight = this.modelServices.getModelWeight(this.modelNumber);
      this.modelServices.cycleDataOriginal = this.modelServices.getCycleList(
        this.modelNumber
      );
      this.modelServices.cycleData = JSON.parse(
        JSON.stringify(this.modelServices.getCycleList(this.modelNumber))
      );
      this.options =
        this.modelServices.cycleData && this.modelServices.cycleData <= 1
          ? this.modelServices.cycleDataOriginal
          : this.modelServices.cycleData;

      this.SetOptions = this.options;

      this.macOptions = this.modelServices.getMachineSettings(this.ProductType);

      this.selectedCycleList = this.modelServices.getcycleDetail(this.modelServices.getCycleArrayName(this.modelNumber)); //Subhash
      this.modelServices.selectedCycleListObj = this.selectedCycleList;
      //copy json object as object.assign does not work with JSON
      this.modelServices.modelCycleDataCopy = JSON.parse(
        JSON.stringify(this.selectedCycleList)
      );
      let first: any = this.options[0];
      if (this.Weight <= 55)
        //If check of less than 55
        this.numberofDrains = this.macOptions.NumberofDrains.ls55;
      else this.numberofDrains = this.macOptions.NumberofDrains.gt55;
      this.commonService.selCycleSetting = {
        drains: this.numberofDrains,
        cycleName: first.tvalue,
        productType: this.ProductType,
        product: this.modelServices.getModelType(this.modelNumber),
        cycleIcon: this.selectedCycleList[first.tvalue].cycleIcon,
        title: first.title,
        extra: this.selectedCycleList[first.tvalue].Exta,
        pass: this.selectedCycleList[first.tvalue].pass,
        soil: this.selectedCycleList[first.tvalue].Soil,
        modelWeight: this.Weight,
        selectedModelNumberValidation: this.modelValidation,
        price:
          this.ProductType != "PN"
            ? this.selectedCycleList[first.tvalue].price
            : 0,
        regDollar: this.selectedCycleList[first.tvalue].regDollar,
        regCents: this.selectedCycleList[first.tvalue].regCents,
        spDollar: this.selectedCycleList[first.tvalue].spDollar,
        spCents: this.selectedCycleList[first.tvalue].spCents,
        cycleData: this.selectedCycleList[first.tvalue],
        cycleType: this.modelServices.getCycleArrayName(this.modelNumber),
        machineWaterLevelType: this.modelServices.getModelCapacity(
          this.modelNumber
        ),
      };
    });
    this.commonService.selectedWash.subscribe((data: any) => {
      this.setIndex(data.index, data.tvalue, data.title);
    });
    this.commonService.getSetUpValue().subscribe((val: any) => {
      let cycle: any = this.options[this.selectedIndex];
      if (this.route.url.indexOf("cycle-setting") >= 0) {
        this.commonService.currentCycle.next();
      }
      var cycleSettings: CycleSettings = new CycleSettings();
      cycleSettings.NumberOfCycles = String(this.options.length);
      this.CycleSettingsBytes(cycleSettings, this.SetOptions, this.modelServices.modelCycleDataCopy);
      localStorage.setItem("arrCycleSettings", this.arrCycleSettings.join("@$"));
      this.commonServices.novoEcoModels.cycleConfig = '';
      let modelData = [];
      modelData.push(
        this.modelServices.modelCycleDataCopy,
        this.SetOptions,
        this.modelServices.userAddedCycles
      )
      this.commonServices.novoEcoModels.cycleConfig = JSON.stringify(modelData);
    });
  }

  ngAfterViewInit() {
    this.readBinaryConfig();
    this.readModelConfig();
    this.cdr.detectChanges();
  }

  readBinaryConfig() {
    this.commonServices.isSetupTabSelected.subscribe((tab) => {
      this.modelServices.isBinaryRead = true;
      this.binaryDefaultValues();
    });
  }

  readModelConfig() {
    this.commonServices.isNovoModelSelected.subscribe((userDefinedConfig: IGetUserDefinedConfiguration) => {
      this.bindUserDefinedModelData(JSON.parse(userDefinedConfig.cycleConfig));
    });
  }

  bindUserDefinedModelData(cycleConfig: any) {
    this.options = [];
    this.SetOptions = [];
    this.options = cycleConfig[1];
    this.SetOptions = cycleConfig[1];
    this.modelServices.cycleData = cycleConfig[1];
    this.modelServices.modelCycleDataCopy = cycleConfig[0];
    this.modelServices.selectedCycleListObj = cycleConfig[0];
    this.modelServices.userAddedCycles = cycleConfig[2];
    this.SetOptions.map(item => {
      this.modelServices.modelCycleDataCopy[item.tvalue].title = item.title;
    })
    this.commonServices.defaultCycleNumber.Max = this.SetOptions.length;
  }

  binaryDefaultValues() {
    setTimeout(() => {
      this.isdisabled = false;
    }, 100);
    const machineBinaryValue = this.commonServices.getBinaryData(NovoFileNames.Cycle_data_Settings);
    const setupBinaryValue = this.commonServices.getBinaryData(NovoFileNames.Machine_Config);
    this.inputColdTemp = setupBinaryValue[0].value[82].toString();
    this.inputCoolTemp = setupBinaryValue[0].value[81].toString();
    this.inputWarmTemp = setupBinaryValue[0].value[80].toString();
    this.inputHotTemp = setupBinaryValue[0].value[79].toString();
    if (!machineBinaryValue || !machineBinaryValue.length) {
      return;
    }
    console.log(machineBinaryValue); //After adding this console intermittent crash is not coming
    const nmbrOfCycles = machineBinaryValue[0].value[53];
    this.binaryModel = [];
    let cycleNum = 0;
    this.options = [];
    let isCycleValuesExist = [];
    let cycleValue: any;
    for (var setCount = 0; setCount < nmbrOfCycles; setCount++) {
      let modelArr: number[] = [];
      for (let [index, value] of machineBinaryValue[0].value.entries()) {
        if (index >= 54 + cycleNum && index <= 63 + cycleNum && value != 0) {
          modelArr.push(value);
        }
      };
      const modelName = String.fromCharCode(...modelArr);
      let name: string;
      const nameExist = this.modelServices.cycleDataOriginal.find(f => { return f.tvalue.toLowerCase() == modelName.toLowerCase() });
      if (nameExist) {
        name = nameExist.tvalue;
      } else {
        name = modelName.replace(/[^a-z0-9\s]/gi, '');
      }
      if (!name) {
        cycleNum += 2048;
        break;
      }
      let title = name;
      const isCycleExists = this.options.find(f => { return f.title == name });
      if (isCycleExists) {
        title = `${title + setCount}`;
      }
      let cycleKey = name.replace(/[^a-z0-9\s]/gi, '').split(' ').join('');
      const cycName = this.modelServices.cycleDataOriginal.some(f => {
        if (f.tvalue.toLowerCase().startsWith(cycleKey.toLowerCase()) && !isCycleValuesExist.includes(f.tvalue)) {
          cycleValue = f;
          isCycleValuesExist.push(f.tvalue);
          return true;
        }
      })
      if (cycName) {
        this.options.push({
          title: name, tvalue: cycleValue.tvalue
        });
      } else {
        let title, tvalue;
        if (this.modelServices.userAddedCycles.length > 0) {
          let lastValue = this.modelServices.userAddedCycles.slice(-1);
          let arrVal = lastValue[0]["title"].split(" ");
          title = "Cycle " + (Number(arrVal[1]) + 1);
          tvalue = "Cycle" + (Number(arrVal[1]) + 1);
        } else {
          title = "Cycle " + (this.modelServices.userAddedCycles.length + 1);
          tvalue = "Cycle" + (this.modelServices.userAddedCycles.length + 1);
        }
        this.modelServices.userAddedCycles.push({ title: title, tvalue: tvalue });
        this.options.push({
          title: name, tvalue: tvalue
        });
        cycleKey = tvalue;
      }
      this.SetOptions = this.options;
      this.modelServices.cycleData = this.options;
      modelArr = [];
      for (let [index, value] of machineBinaryValue[0].value.entries()) {
        if (index >= 1951 + cycleNum && index <= 2076 + cycleNum && value != 0) { //125 bytes
          modelArr.push(value);
        }
      };
      const desc = String.fromCharCode(...modelArr);
      const icon = machineBinaryValue[0].value[1950 + cycleNum];
      const phase = [];
      let num = cycleNum;
      let phaseDetail: IPhaseDetail[] = [];
      this.phaseName = [];
      let phaseCount = 1;
      do {
        const phaseName = this.getPhase(machineBinaryValue[0].value[69 + num], true);
        phase.push(phaseCount);
        const reclaimStdData = this.convertDecimalToBin(machineBinaryValue[0].value[78 + num]);
        phaseDetail.push({
          phase: phaseName,
          value: phaseCount,
          dispenser1: this.getDispenser(machineBinaryValue[0].value[70 + num], true),
          dispenser2: this.getDispenser(machineBinaryValue[0].value[71 + num], true),
          waterlevel: machineBinaryValue[0].value[72 + num],
          temprature: this.ProductType == "PN" ? machineBinaryValue[0].value[73 + num] : this.getMachineName(machineBinaryValue[0].value[73 + num].toString()),
          Extchemtime: `${machineBinaryValue[0].value[74 + num] + ',' + machineBinaryValue[0].value[75 + num] + ',' + machineBinaryValue[0].value[76 + num] + ',' + machineBinaryValue[0].value[77 + num]}`,
          Extchemport: `${machineBinaryValue[0].value[74 + num] + ',' + machineBinaryValue[0].value[75 + num] + ',' + machineBinaryValue[0].value[76 + num] + ',' + machineBinaryValue[0].value[77 + num]}`,
          Extchemtype: this.getExtChemicalName(machineBinaryValue[0].value[87 + num]),
          Motorspeed: machineBinaryValue[0].value[79 + num],
          Motoron: machineBinaryValue[0].value[80 + num],
          Motoroff: machineBinaryValue[0].value[81 + num],
          tumble: machineBinaryValue[0].value[82 + num],
          Drainvalue: machineBinaryValue[0].value[83 + num],
          drainSpeed: this.convertDecimalToHex(machineBinaryValue[0].value[84 + num], machineBinaryValue[0].value[85 + num]),
          Draintime: machineBinaryValue[0].value[86 + num],
          Recirc: this.setBinaryPhase(machineBinaryValue[0].value[69 + num], phaseCount),
          spin: 0,
          reclaim: Number(reclaimStdData[0]),
          sant: Number(reclaimStdData[2]),
          std: Number(reclaimStdData[1]),
        });
        num += 19;
        phaseCount++;
      } while (machineBinaryValue[0].value[69 + num] != 9)
      this.binaryModel.push({
        desc: desc,
        name: name,
        cycleIcon: icon.toString(),
        Standard: '',
        price: parseInt(`${machineBinaryValue[0].value[65 + cycleNum]}.${machineBinaryValue[0].value[66 + cycleNum]}`, 10),
        phase: phase,
        Total_Time: 0,
        Exta: machineBinaryValue[0].value[2080 + cycleNum] == 0 ? 'No' : 'Yes',
        Soil: machineBinaryValue[0].value[2081 + cycleNum] == 0 ? 'No' : 'Yes',
        additives: '',
        pass: machineBinaryValue[0].value[64 + cycleNum] == 0 ? 'No' : 'Yes',
        isCycleDisabled: '',
        PhaseName: this.phaseName,
        phaseDetail: phaseDetail,
        regDollar: machineBinaryValue[0].value[65 + cycleNum],
        regCents: machineBinaryValue[0].value[66 + cycleNum],
        spDollar: machineBinaryValue[0].value[67 + cycleNum],
        spCents: machineBinaryValue[0].value[68 + cycleNum],
      });
      if (cycName) {
        Object.assign(this.modelServices.modelCycleDataCopy, {
          [cycleValue.tvalue]: {
            desc: desc,
            cycleIcon: icon.toString(),
            name: name,
            Standard: '',
            price: parseInt(`${machineBinaryValue[0].value[65 + cycleNum]}.${machineBinaryValue[0].value[66 + cycleNum]}`, 10),
            phase: phase,
            Total_Time: 0,
            Exta: machineBinaryValue[0].value[2080 + cycleNum] == 0 ? 'No' : 'Yes',
            Soil: machineBinaryValue[0].value[2081 + cycleNum] == 0 ? 'No' : 'Yes',
            additives: '',
            pass: machineBinaryValue[0].value[64 + cycleNum] == 0 ? 'No' : 'Yes',
            isCycleDisabled: '',
            PhaseName: this.phaseName,
            phaseDetail: phaseDetail,
            regDollar: machineBinaryValue[0].value[65 + cycleNum],
            regCents: machineBinaryValue[0].value[66 + cycleNum],
            spDollar: machineBinaryValue[0].value[67 + cycleNum],
            spCents: machineBinaryValue[0].value[68 + cycleNum],
          }
        });
      } else {
        Object.assign(this.modelServices.modelCycleDataCopy, {
          [cycleKey]: {
            desc: desc,
            cycleIcon: icon.toString(),
            name: name,
            Standard: '',
            price: parseInt(`${machineBinaryValue[0].value[65 + cycleNum]}.${machineBinaryValue[0].value[66 + cycleNum]}`, 10),
            phase: phase,
            Total_Time: 0,
            Exta: machineBinaryValue[0].value[2080 + cycleNum] == 0 ? 'No' : 'Yes',
            Soil: machineBinaryValue[0].value[2081 + cycleNum] == 0 ? 'No' : 'Yes',
            additives: '',
            pass: machineBinaryValue[0].value[64 + cycleNum] == 0 ? 'No' : 'Yes',
            isCycleDisabled: '',
            PhaseName: this.phaseName,
            phaseDetail: phaseDetail,
            regDollar: machineBinaryValue[0].value[65 + cycleNum],
            regCents: machineBinaryValue[0].value[66 + cycleNum],
            spDollar: machineBinaryValue[0].value[67 + cycleNum],
            spCents: machineBinaryValue[0].value[68 + cycleNum],
          }
        });
        Object.assign(this.modelServices.selectedCycleListObj, {
          [cycleKey]: {
            desc: desc,
            cycleIcon: icon.toString(),
            name: name,
            Standard: '',
            price: parseInt(`${machineBinaryValue[0].value[65 + cycleNum]}.${machineBinaryValue[0].value[66 + cycleNum]}`, 10),
            phase: phase,
            Total_Time: 0,
            Exta: machineBinaryValue[0].value[2080 + cycleNum] == 0 ? 'No' : 'Yes',
            Soil: machineBinaryValue[0].value[2081 + cycleNum] == 0 ? 'No' : 'Yes',
            additives: '',
            pass: machineBinaryValue[0].value[64 + cycleNum] == 0 ? 'No' : 'Yes',
            isCycleDisabled: '',
            PhaseName: this.phaseName,
            phaseDetail: phaseDetail,
            regDollar: machineBinaryValue[0].value[65 + cycleNum],
            regCents: machineBinaryValue[0].value[66 + cycleNum],
            spDollar: machineBinaryValue[0].value[67 + cycleNum],
            spCents: machineBinaryValue[0].value[68 + cycleNum],
          },
        });
      }
      cycleNum += 2048;
    }
    this.commonServices.defaultCycleNumber.Max = this.SetOptions.length;
    const modelData: IPhaseDetail = this.binaryModel[0].phaseDetail[0];
    let hemTime = modelData.Extchemport.split(",");
  }

  setBinaryPhase(phaseValue, count) {
    this.phaseName.push({
      title: this.getPhase(phaseValue, true),
      value: count.toString()
    })
    return '';
  }

  convertDecimalToHex(firstVal: number, secondVal?: number) {
    const oneValue = firstVal.toString(16).padStart(2, '0');
    const secondValue = secondVal.toString(16).padStart(2, '0');
    const intVal = parseInt(`${secondValue}${oneValue}`, 16);
    return intVal;
  }


  convertDecimalToBin(value: number) {
    let binValue = Number(value).toString(2).split('');
    if (binValue.length == 8) {
      return binValue;
    } else {
      for (let i = 0; i < 8; i++)
        if (binValue.length != 8) {
          binValue.splice(0, 0, '0');
        }
    }
    return binValue;
  }

  ConvertIntoArray(values: any) {
    var tempArray = new Array();
    var hArray = new Array();
    hArray = values.split(",");
    for (var i = 0; i < hArray.length; i++) {
      if (!isNaN(hArray[i]) && parseInt(hArray[i]) == 0) {
        for (var j = 0; j < hArray[i].length; j++) {
          tempArray.push(hArray[i].charAt(j));
        }
      } else {
        if (!isNaN(hArray[i]) && parseInt(hArray[i]) > 0) {
          tempArray.push(hArray[i]);
        } else {
          for (var j = 0; j < hArray[i].length; j++) {
            if (!isNaN(hArray[i].charAt(j)))
              tempArray.push(hArray[i].charAt(j).charCodeAt(0).toString(10));
            else tempArray.push(hArray[i].charAt(j));
          }
        }
      }
    }
    return tempArray;
  }

  CycleSettingsBytes(cycleSettings: any, SetOptions: any, selectedCycleListCopy: any) {
    var remainingCycles: any = 0;
    if (this.ProductType == "PN")
      // For OPL
      remainingCycles = 99 - cycleSettings.NumberOfCycles;
    else remainingCycles = 12 - cycleSettings.NumberOfCycles;
    let selectedCycle: any;
    let name: any;
    let desc: any;
    let title: any;
    this.arrCycleSettings = [];
    this.arrCycleSettings.push(cycleSettings.NumberOfCycles);
    for (var setCount = 0; setCount < SetOptions.length; setCount++) {
      name = SetOptions[setCount].tvalue;
      selectedCycle = selectedCycleListCopy[name];
      title = SetOptions[setCount].title;
      if (title.length > 9) title = title.substring(0, 10);
      this.arrCycleSettings.push(title);
      const val = this.getZeroToAdd(title)
      if (val != "") {
        this.arrCycleSettings.push(val);
        //this.arrCycleSettings.push("0");
      }
      if (selectedCycle.pass === "No") this.arrCycleSettings.push("0");
      else this.arrCycleSettings.push("1");

      desc = selectedCycle.desc;
      if (this.ProductType == "PN") {
        // For OPL
        this.arrCycleSettings.push("0000"); //Regular and special price 2 bytes for each
      } else {
        if (selectedCycle.regDollar != undefined) {
          this.arrCycleSettings.push(selectedCycle.regDollar); //GEE_CYCLE_PRICE_UNION_TYPE -- Regular Doller
          this.arrCycleSettings.push(selectedCycle.regCents); //GEE_CYCLE_SPECIAL_PRICE_UNION_TYPE --- regular Cents
          this.arrCycleSettings.push(selectedCycle.spDollar); //GEE_CYCLE_PRICE_UNION_TYPE --- special Doller
          this.arrCycleSettings.push(selectedCycle.spCents);
        } else {
          let doller = selectedCycle.price;
          doller = Math.floor(doller);
          let cent = (doller - Math.floor(doller)) * 100;

          this.arrCycleSettings.push(doller); //GEE_CYCLE_PRICE_UNION_TYPE -- Regular Doller
          this.arrCycleSettings.push(cent); //GEE_CYCLE_SPECIAL_PRICE_UNION_TYPE --- regular Cents
          this.arrCycleSettings.push(doller); //GEE_CYCLE_PRICE_UNION_TYPE --- special Doller
          this.arrCycleSettings.push(cent); //GEE_CYCLE_SPECIAL_PRICE_UNION_TYPE --- Special Cents
        }
      }
      for (var phaseCount = 0; phaseCount < selectedCycle.PhaseName.length; phaseCount++) {
        let phaseData = selectedCycle.phaseDetail.find(ph => ph.value == selectedCycle.PhaseName[phaseCount].value);
        if (!phaseData || !phaseData.phase) {
          phaseData = this.selectedCycleList[name].phaseDetail.find(ph => ph.phase == selectedCycle.PhaseName[phaseCount].title);
        }
        this.arrCycleSettings.push(this.getPhase(phaseData.phase.trim().toUpperCase())); //GEE_GENERIC_SEQUENCE_TYPE
        this.arrCycleSettings.push(this.getDispenser(phaseData.dispenser1));
        this.arrCycleSettings.push(this.getDispenser(phaseData.dispenser2));
        let waterlevel = this.calculateWaterLevel(String(phaseData.waterlevel));
        this.arrCycleSettings.push(waterlevel);
        let temp = phaseData.temprature;
        if (this.ProductType == "PN") {
          temp = isNaN(temp) ? 0 : temp;
          this.arrCycleSettings.push(temp);
        } else {
          let tempVal = this.getMachineTemp(temp);
          this.arrCycleSettings.push(tempVal);
        }
        let arrExtchemtype: any = phaseData.Extchemtime.split(","); //EXTERNAL CHEMICAL SUPPLY ---4
        this.arrCycleSettings.push(arrExtchemtype[0]);
        this.arrCycleSettings.push(arrExtchemtype[1]);
        this.arrCycleSettings.push(arrExtchemtype[2]);
        this.arrCycleSettings.push(arrExtchemtype[3]);

        //chkSanitary,chkStandard,chkWaterReclamation  sant,std,reclaim
        let sant: any = 0;
        let std: any = 0;
        let reclaim: any = phaseData.reclaim;
        if (this.ProductType == "PN") {
          sant = phaseData.sant;
          std = phaseData.std;
        }
        this.arrCycleSettings.push(this.getFlagString(sant, std, reclaim)); //Sequence_Parameter_Flags---flags --- Nitesh

        this.arrCycleSettings.push(
          phaseData.Motorspeed
        ); // unsigned char Wash_RPM; tumble speed
        this.arrCycleSettings.push(
          phaseData.Motoron
        ); // unsigned char On_Time; motoron
        this.arrCycleSettings.push(
          phaseData.Motoroff
        ); // unsigned char Off_Time; motoroff
        this.arrCycleSettings.push(
          phaseData.tumble
        ); // unsigned char Time; Tumbletime(Not available---I think so)

        this.arrCycleSettings.push(
          phaseData.Drainvalue
        ); //unsigned char Drain; drainvalve 0,1,2
        let arrSpinSpeed: any;
        if (phaseData.drainSpeed != undefined) {
          arrSpinSpeed = this.Uint16_To_Byte_Array(
            phaseData.drainSpeed
          );
        } else {
          //phaseData.drainSpeed = phaseData.spin;
          if (this.ProductType == "PN")
            // opl
            arrSpinSpeed = this.Uint16_To_Byte_Array(
              phaseData.spin1
            );
          else {
            this.setSpinSpeed(phaseData);
            arrSpinSpeed = this.Uint16_To_Byte_Array(
              phaseData.drainSpeed
            );

          }

        }
        this.arrCycleSettings.push(arrSpinSpeed[0]);
        this.arrCycleSettings.push(arrSpinSpeed[1]);

        this.arrCycleSettings.push(
          phaseData.Draintime
        ); //unsigned char Drain_Time; Drain Time

        this.arrCycleSettings.push(
          this.getExtChemicalType(
            phaseData.Extchemtype
          )
        ); //EXTERNAL CHEMICAL SUPPLY
      }
      //adding remaining phases
      for (let num = 0; num < 99 - selectedCycle.PhaseName.length; num++) {
        //Invalid Phase Index
        this.arrCycleSettings.push(this.getPhase("NO_SEQ_SELECTION"));
        this.arrCycleSettings.push("000000000000000000"); //18 bytes of phase data
      }

      const valueType = typeof (SetOptions[setCount].tvalue);
      if (valueType === 'number') {
        name = SetOptions[setCount].title;
      } else if (valueType === 'string') {
        name = SetOptions[setCount].tvalue;
      }

      // this.arrCycleSettings.push(this.getCycleIcon(name.trim())); //GEE_CYCLE_ICON_TYPE //
      if (selectedCycle.cycleIcon == undefined) {
        this.arrCycleSettings.push("3");
      } else {
        this.arrCycleSettings.push(selectedCycle.cycleIcon.toString());
      }//GEE_CYCLE_ICON_TYPE //

      if (desc.length > 124) desc = desc.substring(0, 124);
      this.arrCycleSettings.push(desc);
      this.arrCycleSettings.push(this.getZeroToAddInDescription(desc));
      this.arrCycleSettings.push("0");

      this.arrCycleSettings.push("0000"); //CommuRegularPriceCounter 2 zeros(doller and cents(two times))
      this.arrCycleSettings.push(selectedCycle.Exta === undefined ? "0" : selectedCycle.Exta === "No" ? "0" : "1"); //Extra_Rinse
      this.arrCycleSettings.push(selectedCycle.Soil === undefined ? "0" : selectedCycle.Soil === "No" ? "0" : "1"); //Soil_Level

      this.arrCycleSettings.push("0000"); //IntervaRegularPriceCounter --- doller and cents(two times)

      this.arrCycleSettings.push("00000000000000"); //NUM_OF_RFU_IN_GEE_CYCLE_DATA_TYPE    14(zeros)
      this.arrCycleSettings.push("00"); //unsigned short int CRC
    }

    let addZeroCycles: any = "0";
    addZeroCycles = addZeroCycles.repeat(remainingCycles * 2048);
    this.arrCycleSettings.push(addZeroCycles);
  }

  setSpinSpeed(phaseData) {
    let finalSpinSpeeds;
    phaseData.drainSpeed = phaseData.spin;
    if (phaseData.phase === "Final Spin") {
      if (this.modelServices.models.ModelType === "YS") {
        finalSpinSpeeds = this.modelValidation.SoftFinalSpin;
      }
      else {
        finalSpinSpeeds = this.modelValidation.RigidFinalSpin;
      }
      if (finalSpinSpeeds) {
        if (this.Weight <= 20) {
          phaseData.drainSpeed = finalSpinSpeeds.r1.values[2].value;
        }
        else if (this.Weight <= 30) {
          phaseData.drainSpeed = finalSpinSpeeds.r2.values[2].value;
        }
        else {
          phaseData.drainSpeed = finalSpinSpeeds.r3.values[2].value;
        }
      }
    }

  }

  binaryCycleSettingsBytes(cycleSettings: any, SetOptions: any, selectedCycleListCopy: any) {
    var remainingCycles: any = 0;
    if (this.ProductType == "PN")
      // For OPL
      remainingCycles = 99 - cycleSettings.NumberOfCycles;
    else remainingCycles = 12 - cycleSettings.NumberOfCycles;

    let selectedCycle: any;
    let name: any;
    let desc: any;
    let title: any;
    this.arrCycleSettings = [];
    this.arrCycleSettings.push(cycleSettings.NumberOfCycles);
    for (var setCount = 0; setCount < SetOptions.length; setCount++) {
      name = SetOptions[setCount].tvalue;
      selectedCycle = selectedCycleListCopy[name];

      title = SetOptions[setCount].title;
      if (title.length > 9) title = title.substring(0, 9);
      this.arrCycleSettings.push(title);
      this.arrCycleSettings.push(this.getZeroToAdd(title));
      this.arrCycleSettings.push("0");
      if (selectedCycle.pass === "No") this.arrCycleSettings.push("0");
      else this.arrCycleSettings.push("1");

      desc = selectedCycle.desc;

      if (this.ProductType == "PN") {
        // For OPL
        this.arrCycleSettings.push("0000"); //Regular and special price 2 bytes for each
      } else {
        if (selectedCycle.regDollar != undefined) {
          this.arrCycleSettings.push(selectedCycle.regDollar); //GEE_CYCLE_PRICE_UNION_TYPE -- Regular Doller
          this.arrCycleSettings.push(selectedCycle.regCents); //GEE_CYCLE_SPECIAL_PRICE_UNION_TYPE --- regular Cents
          this.arrCycleSettings.push(selectedCycle.spDollar); //GEE_CYCLE_PRICE_UNION_TYPE --- special Doller
          this.arrCycleSettings.push(selectedCycle.spCents);
        } else {
          let doller = selectedCycle.price;
          doller = Math.floor(doller);
          let cent = (doller - Math.floor(doller)) * 100;

          this.arrCycleSettings.push(doller); //GEE_CYCLE_PRICE_UNION_TYPE -- Regular Doller
          this.arrCycleSettings.push(cent); //GEE_CYCLE_SPECIAL_PRICE_UNION_TYPE --- regular Cents
          this.arrCycleSettings.push(doller); //GEE_CYCLE_PRICE_UNION_TYPE --- special Doller
          this.arrCycleSettings.push(cent); //GEE_CYCLE_SPECIAL_PRICE_UNION_TYPE --- Special Cents
        }
      }
      for (var phaseCount = 0; phaseCount < selectedCycle.PhaseName.length; phaseCount++) {
        let phaseData = selectedCycle.phaseDetail.find(ph => ph.value == selectedCycle.PhaseName[phaseCount].value);
        if (!phaseData || !phaseData.phase) {
          phaseData = this.selectedCycleList[name].phaseDetail.find(ph => ph.phase == selectedCycle.PhaseName[phaseCount].title);
        }
        this.arrCycleSettings.push(this.getPhase(phaseData.phase.trim().toUpperCase())); //GEE_GENERIC_SEQUENCE_TYPE
        this.arrCycleSettings.push(this.getDispenser(phaseData.dispenser1));
        this.arrCycleSettings.push(this.getDispenser(phaseData.dispenser2));
        let waterlevel = this.calculateWaterLevel(String(phaseData.waterlevel));
        this.arrCycleSettings.push(waterlevel);
        let temp = phaseData.temprature;
        if (this.ProductType == "PN") {
          temp = isNaN(temp) ? 0 : temp;
          this.arrCycleSettings.push(temp);
        } else {
          let tempVal = this.getMachineTemp(temp);
          this.arrCycleSettings.push(tempVal);
        }
        let arrExtchemtype: any = phaseData.Extchemtime.split(","); //EXTERNAL CHEMICAL SUPPLY ---4
        this.arrCycleSettings.push(arrExtchemtype[0]);
        this.arrCycleSettings.push(arrExtchemtype[1]);
        this.arrCycleSettings.push(arrExtchemtype[2]);
        this.arrCycleSettings.push(arrExtchemtype[3]);

        //chkSanitary,chkStandard,chkWaterReclamation  sant,std,reclaim
        let sant: any = 0;
        let std: any = 0;
        let reclaim: any = phaseData.reclaim;
        if (this.ProductType == "PN") {
          sant = phaseData.sant;
          std = phaseData.std;
        }
        this.arrCycleSettings.push(this.getFlagString(sant, std, reclaim)); //Sequence_Parameter_Flags---flags --- Nitesh

        this.arrCycleSettings.push(
          phaseData.Motorspeed
        ); // unsigned char Wash_RPM; tumble speed
        this.arrCycleSettings.push(
          phaseData.Motoron
        ); // unsigned char On_Time; motoron
        this.arrCycleSettings.push(
          phaseData.Motoroff
        ); // unsigned char Off_Time; motoroff
        this.arrCycleSettings.push(
          phaseData.tumble
        ); // unsigned char Time; Tumbletime(Not available---I think so)

        this.arrCycleSettings.push(
          phaseData.Drainvalue
        ); //unsigned char Drain; drainvalve 0,1,2
        let arrSpinSpeed: any;
        if (phaseData.drainSpeed != undefined) {
          arrSpinSpeed = this.Uint16_To_Byte_Array(
            phaseData.drainSpeed
          );
        } else {
          if (this.ProductType == "PN")
            // opl
            arrSpinSpeed = this.Uint16_To_Byte_Array(
              phaseData.spin1
            );
          //unsigned short int Spin_RPM; -- SpinSpeed
          else
            arrSpinSpeed = this.Uint16_To_Byte_Array(
              phaseData.drainSpeed
            );
        }
        this.arrCycleSettings.push(arrSpinSpeed[0]);
        this.arrCycleSettings.push(arrSpinSpeed[1]);

        this.arrCycleSettings.push(
          phaseData.Draintime
        ); //unsigned char Drain_Time; Drain Time

        this.arrCycleSettings.push(
          this.getExtChemicalType(
            phaseData.Extchemtype
          )
        ); //EXTERNAL CHEMICAL SUPPLY
      }
      //adding remaining phases
      for (let num = 0; num < 99 - selectedCycle.PhaseName.length; num++) {
        //Invalid Phase Index
        this.arrCycleSettings.push(this.getPhase("NO_SEQ_SELECTION"));
        this.arrCycleSettings.push("000000000000000000"); //18 bytes of phase data
      }
      if (selectedCycle.cycleIcon == undefined) {
        this.arrCycleSettings.push("3");
      } else {
        this.arrCycleSettings.push(selectedCycle.cycleIcon.toString());
      }
      //this.arrCycleSettings.push(selectedCycle.cycleIcon); //GEE_CYCLE_ICON_TYPE //

      //this.arrCycleSettings.push(this.getCycleIcon(name.trim())); //GEE_CYCLE_ICON_TYPE //

      if (desc.length > 124) desc = desc.substring(0, 124);
      this.arrCycleSettings.push(desc);
      this.arrCycleSettings.push(this.getZeroToAddInDescription(desc));
      
      this.arrCycleSettings.push("0");

      this.arrCycleSettings.push("0000"); //CommuRegularPriceCounter 2 zeros(doller and cents(two times))
      this.arrCycleSettings.push(selectedCycle.Exta === undefined ? "0" : selectedCycle.Exta === "No" ? "0" : "1"); //Extra_Rinse
      this.arrCycleSettings.push(selectedCycle.Soil === undefined ? "0" : selectedCycle.Soil === "No" ? "0" : "1"); //Soil_Level

      this.arrCycleSettings.push("0000"); //IntervaRegularPriceCounter --- doller and cents(two times)

      this.arrCycleSettings.push("00000000000000"); //NUM_OF_RFU_IN_GEE_CYCLE_DATA_TYPE    14(zeros)
      this.arrCycleSettings.push("00"); //unsigned short int CRC
    }

    let addZeroCycles: any = "0";
    addZeroCycles = addZeroCycles.repeat(remainingCycles * 2048);
    this.arrCycleSettings.push(addZeroCycles);
  }

  getMachineTemp(strTemp: string) {
    var retVal: number = 0;
    if (this.modelServices.modelSetup != undefined) {
      switch (strTemp) {
        case "COLD":
          retVal = this.modelServices.modelSetup.inputColdTemp;
          break;
        case "COOL":
          retVal = this.modelServices.modelSetup.inputCoolTemp;
          break;
        case "WARM":
          retVal = this.modelServices.modelSetup.inputWarmTemp;
          break;
        case "HOT":
          retVal = this.modelServices.modelSetup.inputHotTemp;
          break;
      }
    }
    else if (this.modelServices.vendingTemp[strTemp]) {
      retVal = this.modelServices.vendingTemp[strTemp];
    }
    return retVal;
  }

  getMachineName(strVal: string) {
    var retVal: string = '';
    if (this.modelServices.modelSetup != undefined) {
      switch (strVal) {
        case this.inputColdTemp:
          retVal = "COLD";
          break;
        case this.inputCoolTemp:
          retVal = "COOL";
          break;
        case this.inputWarmTemp:
          retVal = "WARM";
          break;
        case this.inputHotTemp:
          retVal = "HOT";
          break;
        case 'None':
          retVal = "0";
          break;
        default:
          retVal = "0";
      }
    }
    else if (this.modelServices.vendingTemp[strVal]) {
      retVal = this.modelServices.vendingTemp[strVal];
    }

    return retVal;
  }

  calculateWaterLevel(strWaterLevel: String) {
    let wLevel: number;
    if (strWaterLevel.indexOf("/") > 0) {
      let arrWaterLevel = strWaterLevel.split("/");
      let capacity = this.modelServices.getModelCapacity(this.modelNumber);
      capacity = capacity.replace(/\D/g, "");
      switch (Number(capacity)) {
        case 20:
          wLevel = Number(arrWaterLevel[0]);
          break;
        case 30:
          wLevel = Number(arrWaterLevel[1]);
          break;
        default:
          wLevel = Number(arrWaterLevel[2]);
      }
    } else {
      wLevel = Number(strWaterLevel);
    }
    return wLevel;
  }

  getFlagString(chkSanitary: any, chkStandard: any, chkWaterReclamation: any) {
    let flagString: any = "00000";
    let waterReclamationString: any = "0";
    let SanitaryString: any = "0";
    let StandardString: any = "0";

    if (chkSanitary)
      // need to put undefined check too with zero
      SanitaryString = "1";
    if (chkStandard) StandardString = "1";
    if (chkWaterReclamation) waterReclamationString = "1";

    return parseInt(
      waterReclamationString + StandardString + SanitaryString + flagString,
      2
    );
  }

  Uint16_To_Byte_Array(value) {
    let firstByte = 0, secondByte = 0;
    firstByte = value & 0x00ff;
    secondByte = (value & 0xff00) >> 8;
    return [firstByte, secondByte];
  }

  getPhase(val: any, isBinName?: boolean) {
    let retVal: any;
    if (!isBinName) {
      if (val == "PREWASH") retVal = 0;
      else if (val == "WASH") retVal = 1;
      else if (val == "RINSE") retVal = 2;
      else if (val == "FINAL SPIN") retVal = 3;
      else if (val == "SOAK") retVal = 4;
      else if (val == "COOLDOWN") retVal = 5;
      else if (val == "FINAL TUMBLE") retVal = 6;
      else retVal = 9;
    } else {
      if (val == 0) retVal = "Prewash";
      else if (val == 1) retVal = "Wash";
      else if (val == 2) retVal = "Rinse";
      else if (val == 3) retVal = "Final Spin";
      else if (val == 4) retVal = "Soak";
      else if (val == 5) retVal = "CoolDown";
      else if (val == 6) retVal = "Final Tumble";
      else "N/A"
    }
    return retVal;
  }

  getDispenser(
    val: any, //DISPENSER 1 and DISPENSER 2
    isBinaryName?: boolean
  ) {
    let retVal: any;
    if (!isBinaryName) {
      if (val == "Dispenser_Prewash") retVal = 0;
      else if (val == "Dispenser_Wash") retVal = 1;
      else if (val == "Dispenser_Bleach") retVal = 2;
      else if (val == "Dispenser_Fabric_Softner") retVal = 3;
      else if (val == "Dispenser_Inlet-3") retVal = 4;
      else if (val == "Dispenser_None") retVal = 5;
      else retVal = 9;
    } else {
      if (val == 0) retVal = "Dispenser_Prewash";
      else if (val == 1) retVal = "Dispenser_Wash";
      else if (val == 2) retVal = "Dispenser_Bleach";
      else if (val == 3) retVal = "Dispenser_Fabric_Softner";
      else if (val == 4) retVal = "Dispenser_Inlet-3";
      else if (val == 5) retVal = "Dispenser_None";
      else retVal = 'N/A';
    }
    return retVal;
  }

  getExtChemicalType(val: any) {
    let retVal: any;
    retVal = this.modelValidation.ChemicalType.values.findIndex(
      (chem) => chem.value == val
    );
    retVal = retVal > 0 ? retVal : 0;
    return retVal;
  }

  getExtChemicalName(val: any) {
    let retVal: any;
    Object.keys(this.modelValidation.ChemicalType.values).map((key) => {
      if (key.toString() == val.toString()) {
        retVal = this.modelValidation.ChemicalType.values[key].name;
      }
    });
    return retVal;
  }

  getCycleIcon(
    val: any, //CYCLE ICON--- CYCLE_ICON_TYPE
  ) {
    let retVal: any;
    if (val == "PowerWash") retVal = 0;
    else if (val == "Delicates") retVal = 1;
    else if (val == "Brights") retVal = 2;
    else if (val == "Normal") retVal = 3;
    else if (val == "Whites") retVal = 4;
    else if (val == "Woolens") retVal = 5;
    else retVal = 3;
    return retVal;
  }

  getZeroToAddInDescription(data: any) {
    var zeroTOAdd = 124 - data.length;
    var value = "";
    for (var i = 0; i < zeroTOAdd; i++) {
      value = value + "0";
    }
    return value;
  }

  getZeroToAdd(data: any) {
    var zeroTOAdd = 10 - data.length;
    var value = "";
    for (var i = 0; i < zeroTOAdd; i++) {
      value = value + "0";
    }
    return value;
  }

  setIndex(index: number, option: string, title: string) {
    if (this.selectedCycleList[option]) {
      if (isNaN(this.selectedCycleList)) {
        this.commonService.selectedCycle.next({
          drains: this.numberofDrains,
          cycleName: option,
          title: title,
          price:
            this.ProductType != "PN" ? this.selectedCycleList[option].price : 0,
          cycleIcon: this.selectedCycleList[option].cycleIcon,
          cycleData: this.selectedCycleList[option],
          extra: this.selectedCycleList[option].Exta,
          pass: this.selectedCycleList[option].pass,
          soil: this.selectedCycleList[option].Soil,
          cycleType: this.modelServices.getCycleArrayName(this.modelNumber),
          machineWaterLevelType: this.modelServices.getModelCapacity(
            this.modelNumber
          ),
        });
      }
    } else {
      this.commonService.selectedCycle.next({
        drains: this.numberofDrains,
        cycleName: option,
        title: title,
        price:
          this.ProductType != "PN" ? this.modelServices.modelCycleDataCopy[option].price : 0,
        cycleIcon: this.modelServices.modelCycleDataCopy[option].cycleIcon,
        cycleData: this.modelServices.modelCycleDataCopy[option],
        extra: this.modelServices.modelCycleDataCopy[option].Exta,
        pass: this.modelServices.modelCycleDataCopy[option].pass,
        soil: this.modelServices.modelCycleDataCopy[option].Soil,
        cycleType: this.modelServices.getCycleArrayName(this.modelNumber),
        machineWaterLevelType: this.modelServices.getModelCapacity(
          this.modelNumber
        ),
      });
    }
    // this.route.navigate(["/cycle-setting"]);
    this.selectedIndex = index;
    this.modelServices.phaseIndex = 0;
  }

  moveLeft() {
    if (this.selectedIndex < this.options.length - 1) {
      let nextIndex = this.selectedIndex + 1;
      let curr = this.options[this.selectedIndex];
      let next = this.options[nextIndex];
      let temp = Object.assign({}, curr);
      this.options[this.selectedIndex] = next;
      this.options[nextIndex] = temp;
      this.selectedIndex = nextIndex;
    }
  }

  moveRight() {
    if (this.selectedIndex > 0) {
      let nextIndex = this.selectedIndex - 1;
      let curr = this.options[this.selectedIndex];
      let next = this.options[nextIndex];
      let temp = Object.assign({}, curr);
      this.options[this.selectedIndex] = next;
      this.options[nextIndex] = temp;
      this.selectedIndex = nextIndex;
    }
  }


}
