import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { CommonServices } from "src/app/services/CommonServices";
import { ModelServices, reactCycle } from "src/app/services/ModelServices";
import { Utilities } from "src/app/services/UtliltyService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CycleValidation, phaseDetail } from "../../../models/cycle";

@Component({
  selector: "app-washcycle",
  templateUrl: "./washcycle.component.html",
  styleUrls: ["./washcycle.component.scss"],
})
export class WashcycleComponent implements OnInit, AfterViewInit, OnDestroy {
  regDollarOne: any;
  regCentOne: any;
  spDollarOne: any;
  spCentOne: any;
  modifyingList: any;
  cycleDetail: any;
  cycleType: string;
  weight: number;
  public model: any = {};
  selectedItem = 0;
  isDisabled: boolean;
  isDrainDisabled: boolean;
  validationModel: CycleValidation;
  selectedCycleList: any;
  selectedIndex: number = null;
  isVend = true;
  isWashPhase = false;
  values: string[];
  visited = "";
  capacity: string;
  vendedPhase: any;
  target: any = [];
  oplPhase: any;

  @ViewChild("back", { static: true }) input: ElementRef;
  washForm: FormGroup;
  drainForm: FormGroup;
  reclaimForm: FormGroup;
  isdeleteRow: boolean = true;

  modelNumberValidation: any;
  phaselist: string[];
  phaselistoptions: Observable<string[]>;
  subscription1: any;
  subscription2: any;
  subscription3: any;
  subcurrentCycle: any;
  dropdownList = [];
  selectedItems = [];
  myOptions = {
    placement: "bottom",
    "show-delay": "500",
    height: "200",
    "tooltip-class": "tooltip",
  };
  dialogRef: any;
  cycleList: any = [];
  isCycleIconDisabled: Boolean = true;
  mediumContainerFinalSpeed: string = "Delicates,Woolens";
  showCopyCycle: Boolean = false;
  showEditPhase: Boolean = false;
  showCopyPhase: Boolean = false;
  showSoilLevel: Boolean = false;
  isShowModel = true;
  counter = 0;
  lastIndex: number = null;
  isSetToDefaultHide: boolean = false;

  constructor(
    private commonService: CommonServices,
    private modelServices: ModelServices,
    private fb: FormBuilder,
    private cdref: ChangeDetectorRef,
    private route: ActivatedRoute,
    public dialog3: MatDialog,
    private commonServices: CommonServices
  ) {
    this.washForm = this.fb.group({
      regDollarOne: new FormControl("0"),
      regCentOne: new FormControl("0"),
      spDollarOne: new FormControl("0"),
      spCentOne: new FormControl("0"),
    });
    this.reclaimForm = this.fb.group({
      Standard: new FormControl(false),
      Sanitary: new FormControl(false),
    });

    this.drainForm = this.fb.group({
      drainFormDrainTime: new FormControl("0"),
      drainFormdrainValveD: new FormControl({ value: "0", disabled: false }),
      drainFormdrainSpeed: new FormControl("0"),
    });
    this.modelNumberValidation =
      this.commonService.selCycleSetting.selectedModelNumberValidation; //once Validation
    this.weight = this.commonService.selCycleSetting.modelWeight;
    this.vendedPhase = this.modelServices.getvendedPhase();
    this.oplPhase = this.modelServices.getOPLPhase();
  }

  ngOnDestroy() {
    this.commonService.paraHeader.next("false");
    this.modelServices.phaseIndex = this.selectedIndex;
    this.modelServices.phaseList = this.phaselist;
    this.subcurrentCycle && this.subcurrentCycle.unsubscribe();

    let tabId = localStorage.getItem("lastTabId");
    if (tabId && tabId === "nav-cycle-tab") {
      // setTimeout(() => {
      this.modelServices.isPush(this.model);
      this.setPreviouCycleData();
      // }, 5);

    }
  }

  ngOnInit() {
    this.isSetToDefaultHide = this.commonServices.isSetToDefaultHide;
    this.modelServices.isBinaryRead = false;
    this.isShowModel = localStorage.getItem("isShowModel") == '0' ? false : true;
    this.dropdownList = [];
    this.selectedItems = [];
    if (this.isVend) {
      let changeValues = [
        { dollar: "regDollarOne", cent: "regCentOne" },
        { dollar: "spDollarOne", cent: "spCentOne" },
      ];
      changeValues.forEach((data) => {
        if (this.modelNumberValidation.pricing !== undefined) {
          this.subscription1 = this.washForm
            .get(data["dollar"])
            .valueChanges.subscribe((x) => {
              if (x > this.modelNumberValidation.pricing.Max) {
                this.washForm
                  .get(data["dollar"])
                  .setValue(this.modelNumberValidation.pricing.Max, {
                    emitEvent: false,
                  });
              }
            });

          this.subscription2 = this.washForm
            .get(data["cent"])
            .valueChanges.subscribe((x) => {
              // var d = Number(this.washForm.get(data["dollar"]).value);
              if (x > this.modelNumberValidation.pricing.Max) {
                this.washForm
                  .get(data["cent"])
                  .setValue(this.modelNumberValidation.pricing.Max, {
                    emitEvent: false,
                  });
              }
              let d = parseInt(this.washForm.get(data["dollar"]).value);
              if (x == 100 && d < this.modelNumberValidation.pricing.Max) {
                let result = this.commonService.dollarConverts(x);
                d = d + parseInt(result[0].toString());

                this.washForm.get(data["dollar"]).setValue(d);
                this.washForm.get(data["cent"]).setValue(result[1]);
              }
            });
        }
      });
    }

    this.visited = "green";
    this.model = {
      cycleName: "",
      cycleIcon: "",
      cycleDescription: "",
      isCycleDisabled: false
    };
    //*****Initialize Data
    if (this.modelServices.cycleWashCycleComponent) {
      var cycleObj =
        this.modelServices.selectedCycleListObj[
        this.modelServices.cycleWashCycleComponent.cycleValue
        ];
      this.commonService.selCycleSetting.cycleName =
        this.modelServices.cycleWashCycleComponent.cycleValue;
      this.commonService.selCycleSetting.title =
        this.modelServices.cycleWashCycleComponent.cycleName;
      this.commonService.selCycleSetting.cycleData = cycleObj;
    }
    this.preRequisteSetup(this.commonService.selCycleSetting);

    //************************************************* */
    this.commonService.currCycleSetting = this.commonService.selCycleSetting;

    //****this method is calling more than one times so need to unsubscribe at right place ****//
    this.commonService.selectedCycle.subscribe((cycle: any) => {
      this.commonService.currCycleSetting = cycle;
      this.preRequisteSetup(cycle);

    });
    this.commonService.paraHeader.next("true");
    this.firstSetup();
    if (this.modelServices.cycleWashCycleComponent != null) {
      this.model = this.modelServices.cycleWashCycleComponent;
      this.washForm =
        this.modelServices.cycleWashCycleComponentReact["washForm"];
      this.reclaimForm =
        this.modelServices.cycleWashCycleComponentReact["reclaimForm"];
      this.drainForm =
        this.modelServices.cycleWashCycleComponentReact["drainForm"];
      this.selectedIndex = this.modelServices.phaseIndex;
      this.phaselist = this.modelServices.phaseList;
    } else {
      this.modelServices.cycleWashCycleComponentReact = new reactCycle();
      this.modelServices.phaseIndex = 0;
      this.modelServices.isCycleWashCycleComponentTobePush = true;
      this.modelServices.cycleWashCycleComponentReact["washForm"] =
        this.washForm;
      this.modelServices.cycleWashCycleComponentReact["reclaimForm"] =
        this.reclaimForm;
      this.modelServices.cycleWashCycleComponentReact["drainForm"] =
        this.drainForm;
    }
    this.subcurrentCycle = this.commonService.currentCycle.subscribe(() => {
      this.setPreviouCycleData();
    });
  } //End of Init


  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  binaryDefaultValues() {
  }

  deleteRow() {
    var t;
    let phaseCount = 0;
    for (var i = 0; i < this.target.length; i++) {
      t = this.target[i];
      if (this.target[i].isChecked) {
        phaseCount = phaseCount + 1;
      }
    }
    if (phaseCount == this.target.length) {
      this.commonService.getProgressClose(`You can't delete all phases.`);
    } else {
      for (var i = this.target.length - 1; i >= 0; i--) {
        if (this.target[i].isChecked) {
          let compData = this.target[i].value;

          let cycleObj =
            this.modelServices.modelCycleDataCopy[this.model.cycleValue];
          cycleObj.PhaseName = cycleObj.PhaseName.filter(
            (item) => item["value"] !== compData
          );
          cycleObj.phaseDetail = cycleObj.phaseDetail.filter(
            (item) => item["value"] !== compData
          );
          this.phaselist = this.phaselist.filter(
            (item) => item["value"] !== compData
          );
          this.target = this.target.filter(
            (item) => item["value"] !== compData
          );
          this.selectedIndex = Number(i) - 1;
        }
      }
    }
  }

  updateSelectedDelete($event) {
    //Find index of specific object using findIndex method.
    var objIndex = this.target.findIndex(
      (obj) => obj.id == $event.target.value
    );
    if ($event.target.checked) {
      this.target[objIndex].isChecked = true;
    } else {
      this.target[objIndex].isChecked = false;
    }
  }

  openModal(mytemplate) {
    this.setPreviousPhaseData();
    this.modifyingList = Object.assign([], this.phaselist);
    this.modelServices.phaseIndex = this.selectedIndex;
    this.modifyingList.forEach((order: any, index) => {
      this.target.push({
        isChecked: false,
        title: order.title,
        value: order.value,
        id: index,
      });
    });
    this.dialogRef = this.dialog3.open(mytemplate, {
      width: "800px",
      height: "600px",
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      this.target = [];
      this.selectedItems = [];
    });
  }

  continue() {
    this.phaselist = this.target;
    this.modelServices.modelCycleDataCopy[this.model.cycleValue].PhaseName =
      this.phaselist;

    for (var index = 0; index < this.target.length; index++) {
      let phaseVal = this.target[index].value;
      let findIndex = this.modelServices.modelCycleDataCopy[this.model.cycleValue].phaseDetail.findIndex((ph) => ph.value == phaseVal);
      //if cycle does not exists then add here
      if (findIndex == -1) {
        let addCycle = this.selectedItems.find((ph) => ph.value == phaseVal);
        this.modelServices.modelCycleDataCopy[
          this.model.cycleValue
        ].phaseDetail.splice(index, 0, addCycle);
      }
    }
    //selecting first phase after continue
    this.modelServices.phaseIndex = -1;
    this.phaseSelect(this.phaselist[0]["value"], 0);
    this.lastIndex = 0;
    this.counter = 0;
    this.dialogRef.close();
  }

  close() {
    this.selectedIndex = this.modelServices.phaseIndex;
    this.lastIndex = 0;
    this.counter = 0;
    this.dialogRef.close();
  }

  inputWater() {
    if (this.model.water > this.modelNumberValidation.WaterLevel.Max) {
      this.model.water = this.modelNumberValidation.WaterLevel.Max;
    }
    if (!this.model.water || this.model.water < this.modelNumberValidation.WaterLevel.Min) {
      this.model.water = this.modelNumberValidation.WaterLevel.Min;
    }
  }

  inputWaterTemp() {
    if (
      this.model.waterTemp > this.modelNumberValidation.WaterTemperature.Max
    ) {
      this.model.waterTemp = this.modelNumberValidation.WaterTemperature.Max;
    }
    if (!this.model.waterTemp ||
      this.model.waterTemp < this.modelNumberValidation.WaterTemperature.Min
    ) {
      this.model.waterTemp = this.modelNumberValidation.WaterTemperature.Min;
    }
  }

  inputValid(ChemicalTime) {
    switch (ChemicalTime) {
      case "Extchemtime_1":
        if (
          this.model.Extchemtime_1 >
          this.modelNumberValidation.ExtChemicalTime.Max
        ) {
          this.model.Extchemtime_1 =
            this.modelNumberValidation.ExtChemicalTime.Max;
        }
        if (!this.model.Extchemtime_1 ||
          this.model.Extchemtime_1 <
          this.modelNumberValidation.ExtChemicalTime.Min
        ) {
          this.model.Extchemtime_1 =
            this.modelNumberValidation.ExtChemicalTime.Min;
        }
        break;
      case "Extchemtime_2":
        if (
          this.model.Extchemtime_2 >
          this.modelNumberValidation.ExtChemicalTime.Max
        ) {
          this.model.Extchemtime_2 =
            this.modelNumberValidation.ExtChemicalTime.Max;
        }
        if (!this.model.Extchemtime_2 ||
          this.model.Extchemtime_2 <
          this.modelNumberValidation.ExtChemicalTime.Min
        ) {
          this.model.Extchemtime_2 =
            this.modelNumberValidation.ExtChemicalTime.Min;
        }
        break;
      case "Extchemtime_3":
        if (
          this.model.Extchemtime_3 >
          this.modelNumberValidation.ExtChemicalTime.Max
        ) {
          this.model.Extchemtime_3 =
            this.modelNumberValidation.ExtChemicalTime.Max;
        }
        if (!this.model.Extchemtime_3 ||
          this.model.Extchemtime_3 <
          this.modelNumberValidation.ExtChemicalTime.Min
        ) {
          this.model.Extchemtime_3 =
            this.modelNumberValidation.ExtChemicalTime.Min;
        }
        break;
      case "Extchemtime_4":
        if (
          this.model.Extchemtime_4 >
          this.modelNumberValidation.ExtChemicalTime.Max
        ) {
          this.model.Extchemtime_4 =
            this.modelNumberValidation.ExtChemicalTime.Max;
        }
        if (!this.model.Extchemtime_4 ||
          this.model.Extchemtime_4 <
          this.modelNumberValidation.ExtChemicalTime.Min
        ) {
          this.model.Extchemtime_4 =
            this.modelNumberValidation.ExtChemicalTime.Min;
        }
        break;
      case "tumbleSpeed":
        if (
          this.model.tumbleSpeed > this.modelNumberValidation.TumbleSpeed.Max
        ) {
          this.model.tumbleSpeed = this.modelNumberValidation.TumbleSpeed.Max;
        }
        if (!this.model.tumbleSpeed ||
          this.model.tumbleSpeed < this.modelNumberValidation.TumbleSpeed.Min
        ) {
          this.model.tumbleSpeed = this.modelNumberValidation.TumbleSpeed.Min;
        }
        break;
      case "tumble":
        if (this.model.tumble > this.modelNumberValidation.TumbleTime.Max) {
          this.model.tumble = this.modelNumberValidation.TumbleTime.Max;
        }
        if (!this.model.tumble || this.model.tumble < this.modelNumberValidation.TumbleTime.Min) {
          this.model.tumble = this.modelNumberValidation.TumbleTime.Min;
        }
        break;
      case "Motoron":
        if (this.model.Motoron > this.modelNumberValidation.MotorOnTime.Max) {
          this.model.Motoron = this.modelNumberValidation.MotorOnTime.Max;
        }
        if (!this.model.Motoron || this.model.Motoron < this.modelNumberValidation.MotorOnTime.Min) {
          this.model.Motoron = this.modelNumberValidation.MotorOnTime.Min;
        }
        break;
      case "Motoroff":
        if (this.model.Motoroff > this.modelNumberValidation.MotorOffTime.Max) {
          this.model.Motoroff = this.modelNumberValidation.MotorOffTime.Max;
        }
        if (!this.model.Motoroff || this.model.Motoroff < this.modelNumberValidation.MotorOffTime.Min) {
          this.model.Motoroff = this.modelNumberValidation.MotorOffTime.Min;
        }
        break;
      case "Draintime":
        if (this.model.Draintime > this.modelNumberValidation.DrainTime.Max) {
          this.model.Draintime = this.modelNumberValidation.DrainTime.Max;
        }
        if (!this.model.Draintime || this.model.Draintime < this.modelNumberValidation.DrainTime.Min) {
          this.model.Draintime = this.modelNumberValidation.DrainTime.Min;
        }
        break;
      case "drainSpeed":
        if (this.model.drainSpeed > this.validationModel.drainSpeedMax) {
          this.model.drainSpeed = this.validationModel.drainSpeedMax;
        }
        if (!this.model.drainSpeed || this.model.drainSpeed < this.validationModel.drainSpeeedMin) {
          this.model.drainSpeed = this.validationModel.drainSpeeedMin;
        }
        break;
      default:
        break;
    }
  }

  setToFormDefault() {
    //Update the title from OriginalCycleSettings
    let cycleObj = this.modelServices.cycleDataOriginal.find(
      (obj) => obj.tvalue == this.model.cycleValue
    );
    if (cycleObj) {
      //updating current cycle data
      this.modelServices.cycleData.some((element) => {
        if (element.tvalue == cycleObj.tvalue) {
          element.title = cycleObj.title;
        }
      })
      //updating currCycleSetting used to bind the model
      this.commonService.currCycleSetting.title = cycleObj.title;
    }
    this.preRequisteSetup(this.commonService.currCycleSetting, true);
    this.modelServices.phaseIndex = 0;
    this.firstSetup();
  }

  delete() {
    this.commonService.getProgressClose(Utilities.UnderWorkingProgressMessage);
  }

  setPreviouCycleData() {
    var txtBox = document.getElementById("cycleName") as HTMLInputElement;
    if (
      this.modelServices.modelCycleDataCopy[this.model.cycleValue] !=
      undefined && txtBox &&
      txtBox.value == this.model.cycleName
    ) {
      let prevCycle =
        this.modelServices.modelCycleDataCopy[this.model.cycleValue];
      prevCycle["desc"] = this.model.cycleDescription;
      prevCycle["cycleIcon"] = this.model.cycleIcon;
      prevCycle["Soil"] =
        this.model.soil == "0" ? "No" : this.model.soil == "1" ? "Yes" : "";
      prevCycle["Exta"] = this.model.rinse ? "Yes" : "No";
      prevCycle["pass"] = this.model.pass ? "Yes" : "No";
      prevCycle.regDollar = this.model.regDollar; // this.washForm.controls["regDollarOne"].value;
      prevCycle.regCents = this.model.regCents; // this.washForm.get("regCentOne").value;
      prevCycle.spDollar = this.model.spDollar; // this.washForm.get("spDollarOne").value;
      prevCycle.spCents = this.model.spCents; // this.washForm.get("spCentOne").value;
      this.phaselist = prevCycle.PhaseName;
      // }

      this.setPreviousPhaseData();
    }
  }

  handleChange(controlName, value) {
    switch (controlName.toLowerCase()) {
      case 'regdollarone':
        this.model.regDollar = value ? parseInt(value, 10) : 0;
        this.washForm.get("regDollarOne").setValue(this.model.regDollar);
        break;
      case 'regcentone':
        this.model.regCents = value ? parseInt(value, 10) : 0;
        this.washForm.get("regCentOne").setValue(this.model.regCents);

        break;
      case 'spdollarone':
        this.model.spDollar = value ? parseInt(value, 10) : 0;
        this.washForm.get("spDollarOne").setValue(this.model.spDollar);

        break;
      case 'spcentone':
        this.model.spCents = value ? parseInt(value, 10) : 0;
        this.washForm.get("spCentOne").setValue(this.model.spCents);

        break;
    }
  }

  cycleAddWithlimit() {
    let title, tvalue;
    if (this.modelServices.userAddedCycles.length > 0) {
      let lastValue = this.modelServices.userAddedCycles.slice(-1);
      let arrVal = lastValue[0]["title"].split(" ");
      title = "Cycle " + (Number(arrVal[1]) + 1);
      tvalue = "Cycle" + (Number(arrVal[1]) + 1);
    } else {
      title = "Cycle " + (this.modelServices.userAddedCycles.length + 1);
      tvalue = "Cycle" + (this.modelServices.userAddedCycles.length + 1);
    }
    const defaultCycleNumber = +this.commonServices.defaultCycleNumber.Max;
    this.commonServices.defaultCycleNumber.Max = String(defaultCycleNumber + 1);
    this.modelServices.cycleData.push({ title: title, tvalue: tvalue });
    this.modelServices.userAddedCycles.push({ title: title, tvalue: tvalue });
    let tempObj = JSON.parse(
      JSON.stringify(
        this.modelServices.getcycleDetail(
          this.modelServices.getCycleArrayName(
            this.modelServices.selectedModel.getValue()
          )
        )[String(this.modelServices.cycleData[0].tvalue)]
      )
    );
    tempObj.isCycleDisabled = "false";
    if (this.validationModel.productType == "PN") {
      tempObj.cycleIcon = undefined;
    } else {
      tempObj.cycleIcon = "3";
    }
    tempObj.desc = "";
    for (const key in tempObj) {
      if (key == "name") {
        tempObj[key] = tvalue;
        break;
      }
    }
    Object.assign(this.modelServices.selectedCycleListObj, {
      [tvalue]: JSON.parse(JSON.stringify(tempObj)),
    });
    Object.assign(this.modelServices.modelCycleDataCopy, {
      [tvalue]: JSON.parse(JSON.stringify(tempObj)),
    });
    this.commonService.selectedWash.next({
      index: this.modelServices.cycleData.length - 1,
      title: title,
      tvalue: tvalue,
    });
  }

  addCycle() {
    if (this.validationModel.productType == "PN") {
      if (this.modelServices.cycleData.length < ModelServices.OPL_MAX_CYCLES) {
        this.cycleAddWithlimit();
      } else {
        this.commonService.getProgressClose(
          `OPL model can have max ${ModelServices.OPL_MAX_CYCLES} cycles`
        );
      }
    } else {
      if (
        this.modelServices.cycleData.length < ModelServices.Vended_MAX_CYCLES
      ) {
        this.cycleAddWithlimit();
      } else {
        this.commonService.getProgressClose(
          `Vended model can have max ${ModelServices.Vended_MAX_CYCLES} cycles`
        );
      }
    }
  }

  updateCycleName() {
    /* name is key it should not be modified */
    var findCycle = this.modelServices.cycleData.find(
      (obj) => obj.tvalue == this.model.cycleValue
    );

    let tempStorage = findCycle.title;
    if (findCycle) {
      findCycle.title = this.model.cycleName;
    }

    if (this.model.cycleName.length == 0) {
      this.model.cycleName = tempStorage;
      findCycle.title = tempStorage;
      // this.model.cycleSelected = tempStorage;
    } else {
      // this.model.cycleSelected = this.model.cycleName;
    }
  }

  deleteCycle(cycleToDeleteData) {
    let cycleToDelete = cycleToDeleteData.replace(/ +/g, "");

    Swal.fire({
      title:
        "<h5 style='color:#5973b6'>" +
        "Are you sure you want to delete cycle?" +
        "</h5>",
      showCancelButton: true,
      confirmButtonText: "Ok",
      confirmButtonColor: "#5973b6",
      cancelButtonColor: "#5973b6",
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.modelServices.cycleData.length > 1) {
          var index1 = this.modelServices.cycleData.findIndex(function (
            wizard
          ) {
            return wizard.tvalue === cycleToDelete;
          });
          if (index1 == -1) {
            Swal.fire("Unable to delete.", "", "info");
          } else {
            delete this.modelServices.modelCycleDataCopy[
              this.modelServices.cycleData[index1].tvalue
            ];
            this.modelServices.cycleData.splice(index1, 1);
          }
          var index = this.modelServices.userAddedCycles.findIndex(function (
            wizard
          ) {
            return wizard.tvalue === cycleToDelete;
          });
          if (index == -1) {
          } else {
            this.modelServices.userAddedCycles.splice(index, 1);
          }
          if (index1 > this.modelServices.cycleData.length - 1) {
            index1 = 0;
          }
          this.commonService.selectedWash.next({
            index: index1,
            title: this.modelServices.cycleData[index1].title,
            tvalue: this.modelServices.cycleData[index1].tvalue,
          });
          const defaultCycleNumber = +this.commonServices.defaultCycleNumber.Max;
          this.commonServices.defaultCycleNumber.Max = String(defaultCycleNumber - 1);
          this.commonService.setCycleNumber.next(this.commonServices.defaultCycleNumber.Max);
          Swal.fire("Cycle deleted successfully.", "", "success");
        } else {
          Swal.fire("You can't delete all cycles.", "", "info");
        }
      }
    });
  }

  setPreviousPhaseData() {
    if (
      this.modelServices.phaseIndex != undefined &&
      this.modelServices.phaseIndex >= 0
    ) {
      let prevCycle =
        this.modelServices.modelCycleDataCopy[this.model.cycleValue];
      let prevPhase = undefined;
      for (let index = 0; index < prevCycle.phaseDetail.length; index++) {
        if (prevCycle.phaseDetail[index].value == this.phaselist[this.modelServices.phaseIndex]["value"]) {
          prevPhase = prevCycle.phaseDetail[index];
          //console.log(prevPhase);
          break;
        }
      }
      if (prevPhase != undefined) {
        prevPhase.waterlevel = this.model.water;
        prevPhase.dispenser1 = this.model.dispenser1;
        prevPhase.dispenser2 = this.model.dispenser2;
        prevPhase.Extchemtype = this.model.extchemtype;
        prevPhase.temprature = this.model.waterTemp;
        prevPhase.Extchemtime =
          this.model.Extchemtime_1 +
          "," +
          this.model.Extchemtime_2 +
          "," +
          this.model.Extchemtime_3 +
          "," +
          this.model.Extchemtime_4;
        prevPhase.tumble = this.model.tumble;
        prevPhase.Motoron = this.model.Motoron;
        prevPhase.Draintime = this.model.Draintime;
        prevPhase.Drainvalue = this.model.Drainvalve;
        prevPhase.Motoroff = this.model.Motoroff;
        prevPhase.Motorspeed = this.model.tumbleSpeed;
        prevPhase.reclaim = this.model.reclaim ? 1 : 0;
        if (prevPhase.phase == 'Wash') {
          this.isWashPhase = true;
          prevPhase.std = this.reclaimForm.get("Standard").value ? 1 : 0;
          prevPhase.sant = this.reclaimForm.get("Sanitary").value ? 1 : 0;
        } else {
          this.isWashPhase = false;
          prevPhase.std = 0;
          prevPhase.sant = 0;
        }
        prevPhase.drainSpeed = this.model.drainSpeed;
      }
    }
  }

  copyDefault(source: any) {
    let isStd;
    let CycleData =
      this.modelServices.modelCycleDataCopy[this.model.cycleValue];
    let sources = CycleData.phaseDetail.find(
      (data) => data.value == this.phaselist[this.selectedIndex]["value"]
    );
    if (sources != undefined) {
      let isPresent = String(source.waterlevel).indexOf("/");
      if (isPresent > 0) {
        this.model.water = this.calculateWaterLevel(
          source.waterlevel,
          this.capacity
        );
      } else {
        this.model.water = sources.waterlevel;
      }

      this.model.dispenser1 = sources.dispenser1;
      this.model.dispenser2 = sources.dispenser2;
      this.model.extchemtype = sources.Extchemtype;

      this.model.waterTemp = sources.temprature;
      let chemTime = sources.Extchemtime.split(",");
      this.model.Extchemtime_1 = chemTime[0];
      this.model.Extchemtime_2 = chemTime[1];
      this.model.Extchemtime_3 = chemTime[2];
      this.model.Extchemtime_4 = chemTime[3];
      this.model.tumble = sources.tumble;
      this.model.Motoron = sources.Motoron;
      this.model.Draintime = sources.Draintime;
      this.model.Drainvalve = sources.Drainvalue;
      this.model.Motoroff = sources.Motoroff;
      this.model.tumbleSpeed = sources.Motorspeed;
      this.model.reclaim = sources.reclaim == 0 ? false : true;
      if (!this.isVend) {
        //OPL
        if (sources.drainSpeed != undefined) {
          this.model.drainSpeed = sources.drainSpeed;
        } else {
          this.model.drainSpeed = this.selectSpinSpeed(source, this.capacity);
        }
      }
      if (source.phase == 'Wash') {
        this.isWashPhase = true;
        isStd = sources.std == 0 ? false : true;
        this.reclaimForm.get("Standard").setValue(isStd);
        isStd = sources.sant == 0 ? false : true;
        this.reclaimForm.get("Sanitary").setValue(isStd);
      } else {
        this.isWashPhase = false;
        isStd = sources.std == false;
        this.reclaimForm.get("Standard").setValue(isStd);
        isStd = sources.sant == false;
        this.reclaimForm.get("Sanitary").setValue(isStd);
      }
    }
  }

  selectWashType(event, opt) {
    if (event.checked) {
      if (opt.name == "Standard") {
        this.reclaimForm.get("Sanitary").setValue(false);
      }
      else if (opt.name == "Sanitary") {
        this.reclaimForm.get("Standard").setValue(false);
      }
    }
  }

  phaseSelect(value: any, index: number) {
    let phasedetail;
    this.setPreviousPhaseData();
    this.selectedIndex = index;
    this.modelServices.phaseIndex = index;

    this.visited = "black";
    if (!this.cycleType.includes("OPL")) {
      this.isVend = true;
      this.modelServices.modelCycleDataCopy[
        this.model.cycleValue
      ].phaseDetail.forEach((element) => {
        phasedetail = Object.assign(new phaseDetail(), element);
        if (value == phasedetail.value) {
          this.copyDefault(Object.assign(new phaseDetail(), element));
          this.changeFinalSpeed(phasedetail);
        }
      });
    } else {
      this.modelServices.modelCycleDataCopy[
        this.model.cycleValue
      ].phaseDetail.forEach((element) => {
        phasedetail = Object.assign(new phaseDetail(), element);

        if (value == phasedetail.value) {
          this.changeFinalSpeed(phasedetail);
          this.copyDefault(Object.assign(new phaseDetail(), element));
        }
      });
    }
  }

  deletePhase() {
    if (this.selectedIndex < 0) {
      Swal.fire("Please select phase.", "", "warning");
      return;
    }
    if (
      this.modelServices.modelCycleDataCopy[this.model.cycleValue].PhaseName
        .length > 1
    ) {
      Swal.fire({
        title:
          "<h5 style='color:#5973b6'>" +
          "Are you sure you want to delete " +
          "'" +
          this.modelServices.modelCycleDataCopy[this.model.cycleValue]
            .PhaseName[this.selectedIndex]["title"] +
          "'" +
          " phase?" +
          "</h5>",
        showCancelButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#5973b6",
        cancelButtonColor: "#5973b6",
      }).then((result) => {
        if (result.isConfirmed) {
          //get the deleted phase value
          let phaseKey = this.phaselist[this.selectedIndex]["value"];
          let cycleObj =
            this.modelServices.modelCycleDataCopy[this.model.cycleValue];
          //this.phaselist = this.phaselist.filter(item => item["value"] !== phaseKey);
          cycleObj.PhaseName = cycleObj.PhaseName.filter(
            (item) => item["value"].toString() !== phaseKey.toString()
          );
          cycleObj.phaseDetail = cycleObj.phaseDetail.filter(
            (item) => item["value"].toString() !== phaseKey.toString()
          );
          this.phaselist = cycleObj.PhaseName;
          this.resetPhaseData();
          Swal.fire("Phase deleted successfully.", "", "success");
        }
      });
    } else {
      let msg = "You can't delete this.";
      this.commonService.getProgressClose(msg);
    }
  }

  resetPhaseData() {
    this.selectedIndex = -1;
    this.modelServices.phaseIndex = -1;
    this.model.water = "";
    this.model.dispenser1 = -1;
    this.model.dispenser2 = -1;
    this.model.extchemtype = -1;
    this.model.waterTemp = "";
    this.model.Extchemtime_1 =
      this.model.Extchemtime_2 =
      this.model.Extchemtime_3 =
      this.model.Extchemtime_4 =
      "";

    this.model.tumble = "";
    this.model.Motoron = "";
    this.model.Draintime = "";

    this.model.Drainvalve = "";
    this.model.Motoroff = "";
    this.model.tumbleSpeed = "";
    this.model.reclaim = 0;
    this.model.drainSpeed = "";
  }

  addphaseSelect(value: any, index: number) {
    let phasedetail;
    this.selectedIndex = index;
  }

  addToList(phaseData) {
    if (this.target.length < 99) {
      var c = 1;
      if (this.counter < 1) {
        this.counter++;
        this.lastIndex = this.selectedIndex + c;
        let valueToAdd = String(Number(Math.max.apply(Math, this.target.map(function (o) { return o.value; }))) + 1);
        this.target.splice(this.selectedIndex + c, 0, {
          isChecked: false,
          title: phaseData.item_text,
          value: valueToAdd,
          id: Math.floor(Math.random() * (99 - 1 + 1) + 1),
        });
        let index = this.modelServices.selectedCycleListObj[this.modelServices.cycleDataOriginal[0].tvalue]["phaseDetail"].findIndex(function (wizard) {
          return wizard.phase === phaseData.item_text;
        });
        if (index == -1) {
        } else {
          let temp = JSON.parse(JSON.stringify(this.modelServices.selectedCycleListObj[this.modelServices.cycleDataOriginal[0].tvalue]["phaseDetail"][index]));
          temp.value = valueToAdd;
          this.selectedItems.push(temp);
        }
      } else {
        if (!this.target[this.lastIndex]) {
          console.log("Unable to find the index in target=====" + this.lastIndex);
        }
        //crash with lastindex..workaround.
        this.lastIndex = this.target[this.lastIndex]
          ? this.lastIndex
          : this.target.length - 1;

        var valueToAdd = String(Number(Math.max.apply(Math, this.target.map(function (o) { return o.value; }))) + 1);
        this.target.splice(this.selectedIndex + c, 0, {
          isChecked: false,
          title: phaseData.item_text,
          value: valueToAdd,
          id: Math.floor(Math.random() * (99 - 1 + 1) + 1),
        });
        let index = this.modelServices.selectedCycleListObj[this.modelServices.cycleDataOriginal[0].tvalue]["phaseDetail"].findIndex(function (wizard) {
          return wizard.phase === phaseData.item_text;
        });
        if (index == -1) {
        } else {
          //getting the phase from first cycle
          let temp = JSON.parse(JSON.stringify(this.modelServices.selectedCycleListObj[this.modelServices.cycleDataOriginal[0].tvalue]["phaseDetail"][index]));
          if (temp) {
            temp.value = valueToAdd;
            this.selectedItems.push(temp);
          }
        }
      }

      this.lastIndex = this.selectedIndex + c;
    } else {
      let msg = "You can add max 99 phases.";
      this.commonService.getProgressClose(msg);
    }
  }

  preRequisteSetup(cycleSetup: any, setToDefault = false) {
    if (!setToDefault) {
      this.setPreviouCycleData();
    } else {
      this.modelServices.modelCycleDataCopy[this.model.cycleValue] = JSON.parse(
        JSON.stringify(cycleSetup.cycleData)
      );
    }
    this.washForm.get("regDollarOne").setValue(Math.floor(cycleSetup.price));
    this.washForm.get("spDollarOne").setValue(Math.floor(cycleSetup.price));
    this.washForm
      .get("regCentOne")
      .setValue((cycleSetup.price - Math.floor(cycleSetup.price)) * 100);
    this.washForm
      .get("spCentOne")
      .setValue((cycleSetup.price - Math.floor(cycleSetup.price)) * 100);
    this.model.regDollar = Math.floor(cycleSetup.price);
    this.model.regCents = (cycleSetup.price - Math.floor(cycleSetup.price)) * 100
    this.model.spDollar = Math.floor(cycleSetup.price);
    this.model.spCents = (cycleSetup.price - Math.floor(cycleSetup.price)) * 100
    this.capacity = cycleSetup.machineWaterLevelType;
    this.model.title = cycleSetup.title;

    this.model.cycleValue = cycleSetup.cycleName;
    this.model.isCycleDisabled = "false"; // cycleSetup.cycleData.isCycleDisabled;\
    let cycleSetups = this.modelServices.modelCycleDataCopy[cycleSetup.cycleName];
    if (cycleSetups != undefined && cycleSetups.regDollar != undefined) {
      setTimeout(() => {
        this.washForm.get("regDollarOne").setValue(cycleSetups.regDollar);
        this.washForm.get("regCentOne").setValue(cycleSetups.regCents);
        this.washForm.get("spDollarOne").setValue(cycleSetups.spDollar);
        this.washForm.get("spCentOne").setValue(cycleSetups.spCents);
        this.model.regDollar = cycleSetups.regDollar;
        this.model.regCents = cycleSetups.regCents
        this.model.spDollar = cycleSetups.spDollar;
        this.model.spCents = cycleSetups.spCents;
      }, 5);
    }
    this.model.Drainvalve = cycleSetup.drains.Default;
    if (!cycleSetups.title) {
      this.model.cycleName = cycleSetup.title;
    } else {
      this.model.cycleName = cycleSetups.title
    }

    this.cycleType = cycleSetup.cycleType;
    this.cycleDetail = cycleSetups;
    this.model.cycleIcon = cycleSetups.cycleIcon;
    setTimeout(() => {
      this.model.rinse = cycleSetups.Exta === "No" ? false : true;
      this.model.soil =
        cycleSetups.Soil == "No" ? "0" : cycleSetups.Soil == "Yes" ? "1" : "";
      this.model.pass = cycleSetups.pass === "No" ? false : true;
    }, 5);
    this.setDefault();

  }

  setDefault() {
    let defaultfirstRow;
    let isStd;
    this.selectedIndex = 0;
    if (!this.cycleType.includes("OPL")) {
      if (this.dropdownList.length == 0) {
        for (let i = 0; i < this.vendedPhase.length; i++) {
          this.dropdownList.push({
            item_id: this.vendedPhase[i].value,
            item_text: this.vendedPhase[i].title,
          });
        }
      }
    } else {
      if (this.dropdownList.length == 0) {
        for (let i = 0; i < this.oplPhase.length; i++) {
          this.dropdownList.push({
            item_id: this.oplPhase[i].value,
            item_text: this.oplPhase[i].title,
          });
        }
      }
    }
    let cycleDetail =
      this.modelServices.modelCycleDataCopy[this.model.cycleValue];
    setTimeout(() => {
      this.model.cycleDescription = cycleDetail["desc"];
    }, 5)
    if (
      typeof this.cycleDetail["PhaseName"] !== "undefined" &&
      this.cycleDetail["PhaseName"].length > 0
    ) {
      this.phaselist = cycleDetail["PhaseName"];
    }
    this.modelServices.phaseList = this.phaselist;
    if (!this.cycleType.includes("OPL")) {
      this.isDisabled = false;
      if (this.validationModel != undefined && this.modelNumberValidation && this.modelNumberValidation.SoftRigidAll) {
        this.validationModel.vendedDrainSpeed =
          this.modelNumberValidation.SoftRigidAll.values;
      }
      this.isVend = true;
      if (typeof this.cycleDetail["phaseDetail"] !== "undefined" && this.cycleDetail["phaseDetail"].length > 0) {
        defaultfirstRow = cycleDetail.phaseDetail.find(
          (data) => data.value == this.phaselist[this.selectedIndex]["value"]
        );
        // this.model.waterTemp = defaultfirstRow.temprature;
        //setTimeout(() => {
        let chemTime = defaultfirstRow.Extchemtime.split(",");
        this.model.Extchemtime_1 = chemTime[0];
        this.model.Extchemtime_2 = chemTime[1];
        this.model.Extchemtime_3 = chemTime[2];
        this.model.Extchemtime_4 = chemTime[3];
        this.model.dispenser1 = defaultfirstRow.dispenser1;
        this.model.dispenser2 = defaultfirstRow.dispenser2;
        this.model.extchemtype = defaultfirstRow.Extchemtype;
        this.model.tumble = defaultfirstRow.tumble;
        this.model.Motoron = defaultfirstRow.Motoron;
        this.model.Motoroff = defaultfirstRow.Motoroff;
        this.model.Draintime = defaultfirstRow.Draintime;
        this.model.water = defaultfirstRow.waterlevel;
        this.model.waterTemp = defaultfirstRow.temprature == '' ? '0' : defaultfirstRow.temprature;
        this.model.reclaim = defaultfirstRow.reclaim == 0 ? false : true;
        this.model.Drainvalve = defaultfirstRow.Drainvalue;
        this.model.tumbleSpeed = defaultfirstRow.Motorspeed;
        if (defaultfirstRow.drainSpeed != undefined) {
          this.model.drainSpeed = defaultfirstRow.drainSpeed;
        } else {
          this.model.drainSpeed = defaultfirstRow.spin;
        }
        //}, 5);
      }
    } else {
      this.model.soil = "";
      this.isDisabled = true;
      this.model.rinse = false;
      this.isVend = false;

      let oplCycle = cycleDetail.phaseDetail.find(
        (data) => data.value == this.phaselist[this.selectedIndex]["value"]
      );
      //setTimeout(() => {
      let isPresent = String(oplCycle.waterlevel).indexOf("/");
      if (isPresent > 0) {
        this.model.water = this.calculateWaterLevel(
          oplCycle.waterlevel,
          this.capacity
        );
      } else {
        this.model.water = oplCycle.waterlevel;
      }
      this.model.waterTemp = oplCycle.temprature == '' ? '0' : oplCycle.temprature;
      this.model.dispenser1 = oplCycle.dispenser1;
      this.model.dispenser2 = oplCycle.dispenser2;
      this.model.extchemtype = oplCycle.Extchemtype;
      let chemTime = oplCycle.Extchemtime.split(",");
      this.model.Extchemtime_1 = chemTime[0];
      this.model.Extchemtime_2 = chemTime[1];
      this.model.Extchemtime_3 = chemTime[2];
      this.model.Extchemtime_4 = chemTime[3];
      this.model.tumble = oplCycle.tumble;
      this.model.Motoron = oplCycle.Motoron;
      this.model.Motoroff = oplCycle.Motoroff;
      this.model.Draintime = oplCycle.Draintime;
      this.model.tumbleSpeed = oplCycle.Motorspeed;
      this.model.reclaim = oplCycle.reclaim == 0 ? false : true;
      if (oplCycle.phase == 'Wash') {
        this.isWashPhase = true;
        isStd = oplCycle.std == 0 ? false : true;
        this.reclaimForm.get("Standard").setValue(isStd);
        isStd = oplCycle.sant == 0 ? false : true;
        this.reclaimForm.get("Sanitary").setValue(isStd);
      } else {
        this.isWashPhase = false;
        isStd = oplCycle.std == false;
        this.reclaimForm.get("Standard").setValue(isStd);
        isStd = oplCycle.sant == false;
        this.reclaimForm.get("Sanitary").setValue(isStd);
      }
      this.model.Drainvalve = oplCycle.Drainvalue;
      if (oplCycle.drainSpeed != undefined) {
        this.model.drainSpeed = oplCycle.drainSpeed;
      } else {
        this.model.drainSpeed = this.selectSpinSpeed(oplCycle, this.capacity);
      }
      // }, 5);
    }
  }

  firstSetup() {
    this.validationModel = {
      drainSpeedMax: 0,
      drainSpeeedMin: 0,
      productType: this.commonService.selCycleSetting.productType,
      product: this.commonService.selCycleSetting.product,
      tumbleTimeMin: 0,
      tumbleTimeMax: 0,
    };
    if (!this.isVend) {
      //OPL
      if (
        this.validationModel.product == "YR" &&
        this.validationModel.productType == "PN"
      ) {
        this.validationModel.drainSpeedMax =
          this.modelNumberValidation.RigidAll.Max;
        this.validationModel.drainSpeeedMin =
          this.modelNumberValidation.RigidAll.Min;
      }
      if (
        this.validationModel.product == "YS" &&
        this.validationModel.productType == "PN"
      ) {
        this.validationModel.drainSpeedMax =
          this.modelNumberValidation.SoftAll.Max;
        this.validationModel.drainSpeeedMin =
          this.modelNumberValidation.SoftAll.Min;
      }
    } else {
      //Vended
      this.validationModel.vendedDrainSpeed =
        this.modelNumberValidation.SoftRigidAll.values;
    }
    this.values =
      this.weight < 55 || this.commonService.noDrainChange == "1"
        ? this.modelNumberValidation.NumberofDrains.ls55
        : this.modelNumberValidation.NumberofDrains.gt65;
    this.validationModel.tumbleTimeMin =
      this.modelNumberValidation.TumbleTime.Min;
    this.validationModel.tumbleTimeMax =
      this.modelNumberValidation.TumbleTime.Max;
  }

  commonsetUp(setup: any) {
    this.model.dispenser1 = setup.dispenser1;
    this.model.dispenser2 = setup.dispenser2;
    let chemTime = setup.Extchemtime.split(",");
    this.model.Extchemtime_1 = chemTime[0];
    this.model.Extchemtime_2 = chemTime[1];
    this.model.Extchemtime_3 = chemTime[2];
    this.model.Extchemtime_4 = chemTime[3];
    this.model.Motoron = setup.Motoron;
    this.model.Motoroff = setup.Motoroff;
    this.model.Draintime = setup.Draintime;
    this.model.tumbleSpeed = setup.Motorspeed;
  }

  changeFinalSpeed(phasedetail: any) {
    var phase: string = phasedetail.phase.replace(/\s/g, "");

    if (phase == "FinalSpin") {
      if (
        this.validationModel.product == "YR" &&
        this.validationModel.productType == "PN"
      ) {
        if (this.weight <= 20) {
          this.validationModel.drainSpeedMax =
            this.modelNumberValidation.RigidFinalSpin.r1.Max;
        } else if (this.weight <= 25 || this.weight <= 30) {
          this.validationModel.drainSpeedMax =
            this.modelNumberValidation.RigidFinalSpin.r2.Max;
        } else if (
          this.weight <= 40 ||
          this.weight <= 55 ||
          this.weight <= 65
        ) {
          this.validationModel.drainSpeedMax =
            this.modelNumberValidation.RigidFinalSpin.r3.Max;
        }
      } else if (
        this.validationModel.product == "YS" &&
        this.validationModel.productType == "PN"
      ) {
        if (this.weight <= 20) {
          this.validationModel.drainSpeedMax =
            this.modelNumberValidation.SoftFinalSpin.r1.Max;
        } else if (this.weight <= 25 || this.weight <= 30) {
          this.validationModel.drainSpeedMax =
            this.modelNumberValidation.SoftFinalSpin.r2.Max;
        } else if (
          this.weight <= 40 ||
          this.weight <= 55 ||
          this.weight <= 65
        ) {
          this.validationModel.drainSpeedMax =
            this.modelNumberValidation.SoftFinalSpin.r3.Max;
        }
      } else {
        //for Vended
        if (this.validationModel.product == "YS") {
          if (this.weight <= 20) {
            this.model.drainSpeed = this.commonService.getMaxWashCycleVended(
              this.modelNumberValidation.SoftFinalSpin.r1.values
            );

            this.validationModel.vendedDrainSpeed =
              this.modelNumberValidation.SoftFinalSpin.r1.values;
          } else if (this.weight <= 25 || this.weight <= 30) {
            this.model.drainSpeed = this.commonService.getMaxWashCycleVended(
              this.modelNumberValidation.SoftFinalSpin.r2.values
            );
            this.validationModel.vendedDrainSpeed =
              this.modelNumberValidation.SoftFinalSpin.r2.values;
          } else if (
            this.weight <= 40 ||
            this.weight <= 55 ||
            this.weight <= 65
          ) {
            this.model.drainSpeed = this.commonService.getMaxWashCycleVended(
              this.modelNumberValidation.SoftFinalSpin.r3.values
            );
            this.validationModel.vendedDrainSpeed =
              this.modelNumberValidation.SoftFinalSpin.r3.values;
          }
        } else {
          //Rigid
          if (this.weight <= 20) {
            this.model.drainSpeed = this.mediumContainerFinalSpeed.includes(
              this.model.cycleName
            )
              ? this.commonService.getSecondMaxWashCycleVended(
                this.modelNumberValidation.RigidFinalSpin.r1.values
              )
              : this.commonService.getMaxWashCycleVended(
                this.modelNumberValidation.RigidFinalSpin.r1.values
              );

            this.validationModel.vendedDrainSpeed =
              this.modelNumberValidation.RigidFinalSpin.r1.values;
          } else if (this.weight <= 25 || this.weight <= 30) {
            this.model.drainSpeed = this.mediumContainerFinalSpeed.includes(
              this.model.cycleName
            )
              ? this.commonService.getSecondMaxWashCycleVended(
                this.modelNumberValidation.RigidFinalSpin.r2.values
              )
              : this.commonService.getMaxWashCycleVended(
                this.modelNumberValidation.RigidFinalSpin.r2.values
              );

            this.validationModel.vendedDrainSpeed =
              this.modelNumberValidation.RigidFinalSpin.r2.values;
          } else if (
            this.weight <= 40 ||
            this.weight <= 55 ||
            this.weight <= 65
          ) {
            this.model.drainSpeed = this.mediumContainerFinalSpeed.includes(
              this.model.cycleName
            )
              ? this.commonService.getSecondMaxWashCycleVended(
                this.modelNumberValidation.RigidFinalSpin.r2.values
              )
              : this.commonService.getMaxWashCycleVended(
                this.modelNumberValidation.RigidFinalSpin.r3.values
              );

            this.validationModel.vendedDrainSpeed =
              this.modelNumberValidation.RigidFinalSpin.r3.values;
          }
        }
      }
    } else {
      //Other Spin
      if (
        this.validationModel.product == "YR" &&
        this.validationModel.productType == "PN"
      ) {
        //OPL
        this.validationModel.drainSpeedMax =
          this.modelNumberValidation.RigidAll.Max;
      } else if (
        this.validationModel.product == "YS" &&
        this.validationModel.productType == "PN"
      ) {
        //Soft
        this.validationModel.drainSpeedMax =
          this.modelNumberValidation.SoftAll.Max;
      } else {
        //Vended

        this.validationModel.vendedDrainSpeed =
          this.modelNumberValidation.SoftRigidAll.values;
        this.model.drainSpeed = phasedetail.spin;
      }
    }
    let CycleData =
      this.modelServices.modelCycleDataCopy[this.model.cycleValue];
    let phasedetails = CycleData.phaseDetail.find(
      (data) => data.value == this.phaselist[this.selectedIndex]["value"]
    );
    if (phasedetails.drainSpeed != undefined) {
      this.model.drainSpeed = phasedetails.drainSpeed;
    }
    //Rinse Phase

    if (phase == "Rinse") {
      this.validationModel.tumbleTimeMax =
        this.modelNumberValidation.TumbleTimeRinsePhase.Max;
      this.validationModel.tumbleTimeMin =
        this.modelNumberValidation.TumbleTimeRinsePhase.Min;
    } else if (phase == "Soak") {
      this.validationModel.tumbleTimeMax =
        this.modelNumberValidation.TumbleTimeSoakPhase.Max;
      this.validationModel.tumbleTimeMin =
        this.modelNumberValidation.TumbleTimeSoakPhase.Min;
    } else {
      this.validationModel.tumbleTimeMax =
        this.modelNumberValidation.TumbleTime.Max;
      this.validationModel.tumbleTimeMin =
        this.modelNumberValidation.TumbleTime.Min;
    }
  } //End of Method

  calculateWaterLevel(tempLevel: string, modeltype: string) {
    let tempOPLSoft = tempLevel.split("/");
    modeltype = modeltype.replace(/\D/g, "");
    let selectedTemp: number;
    switch (Number(modeltype)) {
      case 20:
        selectedTemp = Number(tempOPLSoft[0]);
        break;
      case 30:
        selectedTemp = Number(tempOPLSoft[1]);
        break;

      default:
        selectedTemp = Number(tempOPLSoft[2]);
    }

    return selectedTemp;
  } //End of Method

  selectSpinSpeed(oplCycle: any, modeltype: string) {
    modeltype = modeltype.replace(/\D/g, "");
    let selectedTemp: number;
    switch (Number(modeltype)) {
      case 20:
        selectedTemp = Number(oplCycle.spin1);
        break;
      case 30 || 40:
        selectedTemp = Number(oplCycle.spin2);
        break;

      default:
        selectedTemp = Number(oplCycle.spin3);
        break;
    }

    return selectedTemp;
  }

  moveDown() {
    if (
      !(this.selectedIndex < 0) &&
      this.selectedIndex < this.phaselist.length - 1
    ) {
      let nextIndex = this.selectedIndex + 1;
      let curr = this.phaselist[this.selectedIndex];
      let next = this.phaselist[nextIndex];
      let temp = Object.assign({}, curr);
      this.phaselist[this.selectedIndex] = next;
      this.phaselist[nextIndex] = temp;
      this.selectedIndex = nextIndex;
      this.modelServices.phaseList = this.phaselist;
      this.modelServices.phaseIndex = this.selectedIndex;
    }
  }

  moveUp() {
    if (!(this.selectedIndex < 0) && this.selectedIndex > 0) {
      let nextIndex = this.selectedIndex - 1;
      let curr = this.phaselist[this.selectedIndex];
      let next = this.phaselist[nextIndex];
      let temp = Object.assign({}, curr);
      this.phaselist[this.selectedIndex] = next;
      this.phaselist[nextIndex] = temp;
      this.selectedIndex = nextIndex;
      this.modelServices.phaseList = this.phaselist;
      this.modelServices.phaseIndex = this.selectedIndex;
    }
  }

  insertAndShift(arr, from, to) {
    let cutOut = arr.splice(from, 1)[0]; // cut the element at index 'from'
    arr.splice(to, 0, cutOut); // insert it at index 'to'
  }

} //End of Class
