import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material";
import { CommonServices } from "src/app/services/CommonServices";
import { TlWasherModelServices } from "src/app/services/TlWasherModel.service";
import { ICoolTemperature, ICycleSpecialPrice, IExtraRinse, IFixedCycleEnable, IHeavySoil, IHotTemperature, IRegularPriceCycleLen, IRelationalEntityKey, IRelationalEnvelopeOpen, ISpecialPriceCycleLen, ISpecialPriceTime, ISpecialPricingEnable, ISundaySpecialPriceEnable, IValueChecked, IValueCoin1, IVended, IWarmTemperature, TlWasherCycleModelList } from "./tl-washer-cycle-vending.model";
declare const $: any;

@Component({
  selector: "app-tl-washer-cycle-vending",
  templateUrl: "./tl-washer-cycle-vending.component.html",
  styleUrls: ["./tl-washer-cycle-vending.component.scss"],
})

export class TLWasherCycleVendingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('splPriceDayChecked', { static: false }) splPriceDayChecked: ElementRef;
  subscription1: any;
  subscription2: any;
  vendingForm: FormGroup;
  model: TlWasherCycleModelList = {
    SpecialPriceTimeStart: '',
    SpecialPriceTimeEnd: '',
    CycleSpecialPriceDollar: '',
    CycleSpecialPriceCents: '',
    CycleRegularPriceDollar: '',
    CycleRegularPriceCents: '',
    ValueCoin1Dollar: '',
    ValueCoin1Cents: '',
    ValueCoin2Dollar: '',
    ValueCoin2Cents: '',
    CoolTemperatureDollar: '',
    CoolTemperatureCents: '',
    WarmTemperatureDollar: '',
    WarmTemperatureCents: '',
    HotTemperatureDollar: '',
    HotTemperatureCents: '',
    HeavySoilDollar: '',
    HeavySoilCents: '',
    ExtraRinseDollar: '',
    ExtraRinseCents: ''
  }

  ValueChecked: IValueChecked = {
    id: 0,
    name: '',
    checked: false
  }

  regularPriceCycleLen: IRegularPriceCycleLen = {
    value: '',
    default: '',
    values: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    min: '',
    max: ''
  }

  fixedCycleEnable: IFixedCycleEnable = {
    value: '',
    default: '',
    values: [],
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  specialPricingEnable: ISpecialPricingEnable = {
    value: false,
    default: false,
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  specialPriceEnable: ISundaySpecialPriceEnable = {
    value: '',
    default: '',
    values: this.ValueChecked,
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  specialPriceTime: ISpecialPriceTime = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    endHour: '',
    startHour: ''
  }

  specialPriceCycleLen: ISpecialPriceCycleLen = {
    value: '',
    default: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    min: '',
    max: ''
  }

  cycleSpecialPrice: ICycleSpecialPrice = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    cents: '',
    dollars: ''
  }

  ValueCoin1: IValueCoin1 = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: '',
  }

  CoolTemperature: ICoolTemperature = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: ''
  }
  WarmTemperature: IWarmTemperature = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: ''
  }
  HotTemperature: IHotTemperature = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: ''
  }
  HeavySoil: IHeavySoil = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: ''
  }
  ExtraRinse: IExtraRinse = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: ''
  }

  relationalEnvelopeOpen: IRelationalEnvelopeOpen = {
    binaryKeyValue: '',
    arrKey: [],
    type: ''
  }

  relationalEntityKey: IRelationalEntityKey = {
    value: '',
    binaryKeyValue: '',
    arrKey: [],
    type: ''
  }

  vendingSettings: IVended = {
    Relational_Envelope_Open: this.relationalEnvelopeOpen,
    Relational_Envelope_Action: this.relationalEnvelopeOpen,
    Entity_Cycle_Open: this.relationalEnvelopeOpen,
    Relational_Entity_Name: this.relationalEntityKey,
    Relational_Entity_Id: this.relationalEnvelopeOpen,
    DnldCycle_CycleSet_CycleCavityPosition: this.relationalEnvelopeOpen,
    Relational_Array_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Close: this.relationalEnvelopeOpen,
    Relational_Array_Close: this.relationalEnvelopeOpen,
    Entity_Cycle_Close: this.relationalEnvelopeOpen,
    Relational_Envelope_Close: this.relationalEnvelopeOpen,
    DnldCycle_OpSet_RegularPriceCycleLen: this.regularPriceCycleLen,
    FixedCycleEnable: this.fixedCycleEnable,
    SpecialPricingEnable: this.specialPricingEnable,
    SundaySpecialPriceEnable: this.specialPriceEnable,
    MondaySpecialPriceEnable: this.specialPriceEnable,
    TuesdaySpecialPriceEnable: this.specialPriceEnable,
    WednesdaySpecialPriceEnable: this.specialPriceEnable,
    ThursdaySpecialPriceEnable: this.specialPriceEnable,
    FridaySpecialPriceEnable: this.specialPriceEnable,
    SaturdaySpecialPriceEnable: this.specialPriceEnable,
    SpecialPriceTime: this.specialPriceTime,
    DnldCycle_OpSet_SpecialPriceCycleLen: this.specialPriceCycleLen,
    DnldCycle_PaySet_CycleSpecialPrice: this.cycleSpecialPrice,
    DnldCycle_PaySet_CycleRegularPrice: this.cycleSpecialPrice,
    ValueCoin1: this.ValueCoin1,
    ValueCoin2: this.ValueCoin1,
    CoolTemperature: this.CoolTemperature,
    WarmTemperature: this.WarmTemperature,
    HotTemperature: this.HotTemperature,
    HeavySoil: this.HeavySoil,
    ExtraRinseAdder: this.ExtraRinse,
    hasValue: false
  }

  master_indeterminate: boolean = false;
  modelType: string = '';
  centsName = [
    'CycleSpecialPrice',
    'CycleRegularPrice',
    'ValueCoin1',
    'ValueCoin2',
    'CoolTemperature',
    'WarmTemperature',
    'HotTemperature',
    'HeavySoil',
    'ExtraRinse'
  ]
  timePerCoin = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10
  ]
  // timePerCoinVal: number = 5;
  timePerCoinVal: number = 5;

  isValueChanged = [{
    name: 'timeCoin',
    value: false
  }, {
    name: 'regularDollar',
    value: false
  }, {
    name: 'regularCents',
    value: false
  }, {
    name: 'specialDollar',
    value: false
  }, {
    name: 'specialCents',
    value: false
  }, {
    name: 'coin1Dollar',
    value: false
  }, {
    name: 'coin1Cents',
    value: false
  }];
  isSetToDefaultHide: boolean = false;

  constructor(
    private _tlWasherModelServices: TlWasherModelServices,
    private commonServices: CommonServices,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.isSetToDefaultHide = this.commonServices.isSetToDefaultHide;
    this.modelType = localStorage.getItem("ProductType");
    this.bindControls();
    this.vendingSettings = this._tlWasherModelServices.getVenusCycleModels();
    this.setDollarCentValues1();
    this.setDollarCentValues2();
    if (!this.vendingSettings) {
      this.vendingSettings = this._tlWasherModelServices.getTlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    } else if (this.vendingSettings && !this.vendingSettings.hasValue) {
      this.vendingSettings = this._tlWasherModelServices.getTlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
      this._tlWasherModelServices.setCyclePrice(false);
    } else if (this.vendingSettings.hasValue) {
      this.setDollarCents();
    }
  }

  ngAfterViewInit(): void {
    this.bindCentsFields();
    if (this.modelType != '9J') {
      this.calcRegularDryTime();
    }
  }

  bindCentsFields() {
    for (var i = 0; i < this.centsName.length; i++) {
      this.jqueryFunc(this.centsName[i]);
    }
  }

  bindControls() {
    this.vendingForm = this.fb.group({
      regularDryTime: [{ value: "", disabled: true }, Validators.required],
      typeofDryerPrice: ["", Validators.required],
      pennyOfset: ["", Validators.required],
      splPricingChecked: ["", Validators.required],
      splPriceDayChecked: ["", Validators.required],
      startHour: [{ value: "", disabled: !this.vendingSettings.SpecialPricingEnable }, Validators.required],
      endHour: [{ value: "", disabled: !this.vendingSettings.SpecialPricingEnable }, Validators.required],
      splCycleDollars: [{ value: "", disabled: !this.vendingSettings.SpecialPricingEnable }, Validators.required],
      splCycleCents: [{ value: "", disabled: !this.vendingSettings.SpecialPricingEnable }, Validators.required],
      coinOneDollars: ["", Validators.required],
      coinOneCents: ["", Validators.required],
      coinTwoCents: ["", Validators.required],
      coinTwoDollars: ["", Validators.required],
      regularPriceDollars: ["", Validators.required],
      regularPriceCents: ["", Validators.required],
      splDryTime: [{ value: "", disabled: !this.vendingSettings.SpecialPricingEnable }, Validators.required],
      timePerCoin: ["", Validators.required],
      coldWaterDollar: ["", Validators.required],
      coldWaterCents: ["", Validators.required],
      coolWaterDollar: ["", Validators.required],
      coolWaterCents: ["", Validators.required],
      warmWaterDollar: ["", Validators.required],
      warmWaterCents: ["", Validators.required],
      hotWaterDollar: ["", Validators.required],
      hotWaterCents: ["", Validators.required],
      solLevelDollar: ["", Validators.required],
      solLevelCents: ["", Validators.required],
      extraRinseDollar: ["", Validators.required],
      extraRinseCents: ["", Validators.required]
    });
  }

  setDollarCents() {
    if (this.modelType != '9J') {
      this.model.CycleSpecialPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0];
      this.model.CycleSpecialPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1];
      this.model.CycleRegularPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[0];
      this.model.CycleRegularPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[1];
      this.model.SpecialPriceTimeStart = this.vendingSettings.SpecialPriceTime.value[0];
      this.model.SpecialPriceTimeEnd = this.vendingSettings.SpecialPriceTime.value[1];
    }
    this.model.ValueCoin1Dollar = this.vendingSettings.ValueCoin1.value[0];
    this.model.ValueCoin1Cents = this.vendingSettings.ValueCoin1.value[1];
    this.model.ValueCoin2Dollar = this.vendingSettings.ValueCoin2.value[0];
    this.model.ValueCoin2Cents = this.vendingSettings.ValueCoin2.value[1];
    this.model.CoolTemperatureDollar = this.vendingSettings.CoolTemperature.value[0];
    this.model.CoolTemperatureCents = this.vendingSettings.CoolTemperature.value[1];
    this.model.WarmTemperatureDollar = this.vendingSettings.WarmTemperature.value[0];
    this.model.WarmTemperatureCents = this.vendingSettings.WarmTemperature.value[1];
    this.model.HotTemperatureDollar = this.vendingSettings.HotTemperature.value[0];
    this.model.HotTemperatureCents = this.vendingSettings.HotTemperature.value[1];
    this.model.HeavySoilDollar = this.vendingSettings.HeavySoil.value[0];
    this.model.HeavySoilCents = this.vendingSettings.HeavySoil.value[1];
    this.model.ExtraRinseDollar = this.vendingSettings.ExtraRinseAdder.value[0];
    this.model.ExtraRinseCents = this.vendingSettings.ExtraRinseAdder.value[1];
    if (this.modelType != '9J') {
      if (!this.vendingSettings.SpecialPricingEnable.value) {
        this.disableSplPricingControls();
      }
    }
  }

  setToDefault(isClick?: boolean) {
    if (this.modelType != '9J') {
      this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.default;
      this.vendingSettings.FixedCycleEnable.value = this.vendingSettings.FixedCycleEnable.default;
      this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default;
      this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
      this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
      this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.cents;
      this.vendingSettings.SpecialPricingEnable.value = this.vendingSettings.SpecialPricingEnable.default;
      this.vendingSettings.SundaySpecialPriceEnable.value = this.vendingSettings.SundaySpecialPriceEnable.default;
      this.vendingSettings.MondaySpecialPriceEnable.value = this.vendingSettings.MondaySpecialPriceEnable.default;
      this.vendingSettings.TuesdaySpecialPriceEnable.value = this.vendingSettings.TuesdaySpecialPriceEnable.default;
      this.vendingSettings.WednesdaySpecialPriceEnable.value = this.vendingSettings.WednesdaySpecialPriceEnable.default;
      this.vendingSettings.ThursdaySpecialPriceEnable.value = this.vendingSettings.ThursdaySpecialPriceEnable.default;
      this.vendingSettings.FridaySpecialPriceEnable.value = this.vendingSettings.FridaySpecialPriceEnable.default;
      this.vendingSettings.SaturdaySpecialPriceEnable.value = this.vendingSettings.SaturdaySpecialPriceEnable.default;
      this.vendingSettings.SpecialPriceTime.value[0] = this.vendingSettings.SpecialPriceTime.startHour;
      this.vendingSettings.SpecialPriceTime.value[1] = this.vendingSettings.SpecialPriceTime.endHour;
      this.vendingSettings.CoolTemperature.value[0] = this.vendingSettings.CoolTemperature.dollars;
      this.vendingSettings.CoolTemperature.value[1] = this.vendingSettings.CoolTemperature.cents;
      this.vendingSettings.WarmTemperature.value[0] = this.vendingSettings.WarmTemperature.dollars;
      this.vendingSettings.WarmTemperature.value[1] = this.vendingSettings.WarmTemperature.cents;
      this.vendingSettings.HotTemperature.value[0] = this.vendingSettings.HotTemperature.dollars;
      this.vendingSettings.HotTemperature.value[1] = this.vendingSettings.HotTemperature.cents;
      this.vendingSettings.HeavySoil.value[0] = this.vendingSettings.HeavySoil.dollars;
      this.vendingSettings.HeavySoil.value[1] = this.vendingSettings.HeavySoil.cents;
      this.vendingSettings.ExtraRinseAdder.value[0] = this.vendingSettings.ExtraRinseAdder.dollars;
      this.vendingSettings.ExtraRinseAdder.value[1] = this.vendingSettings.ExtraRinseAdder.cents
    }
    this.vendingSettings.ValueCoin1.value[0] = this.vendingSettings.ValueCoin1.dollars;
    this.vendingSettings.ValueCoin1.value[1] = this.vendingSettings.ValueCoin1.cents;
    this.vendingSettings.ValueCoin2.value[0] = this.vendingSettings.ValueCoin2.dollars;
    this.vendingSettings.ValueCoin2.value[1] = this.vendingSettings.ValueCoin2.cents;
    this._tlWasherModelServices.coin1Value.dollar = this.vendingSettings.ValueCoin1.dollars;
    this._tlWasherModelServices.coin1Value.cent = this.vendingSettings.ValueCoin1.cents;
    this.vendingSettings.hasValue = true;
    this.setDefaultDollarCents();
    if (this.modelType != '9J') {
      if (isClick) {
        this.setToDefaultSplPricing();
      }
      if (!this.vendingSettings.SpecialPricingEnable.value) {
        this.disableSplPricingControls();
      }
    }
    if (isClick) {
      this._tlWasherModelServices.resetPriceValue();
    }

  }

  setDefaultDollarCents() {
    if (this.modelType != '9J') {
      this.model.CycleSpecialPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.model.CycleSpecialPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
      this.model.CycleRegularPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
      this.model.CycleRegularPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.cents;
      this.model.SpecialPriceTimeStart = this.vendingSettings.SpecialPriceTime.startHour;
      this.model.SpecialPriceTimeEnd = this.vendingSettings.SpecialPriceTime.endHour;
      this.model.CoolTemperatureDollar = this.vendingSettings.CoolTemperature.dollars;
      this.model.CoolTemperatureCents = this.vendingSettings.CoolTemperature.cents;
      this.model.WarmTemperatureDollar = this.vendingSettings.WarmTemperature.dollars;
      this.model.WarmTemperatureCents = this.vendingSettings.WarmTemperature.cents;
      this.model.HotTemperatureDollar = this.vendingSettings.HotTemperature.dollars;
      this.model.HotTemperatureCents = this.vendingSettings.HotTemperature.cents;
      this.model.HeavySoilDollar = this.vendingSettings.HeavySoil.dollars;
      this.model.HeavySoilCents = this.vendingSettings.HeavySoil.cents;
      this.model.ExtraRinseDollar = this.vendingSettings.ExtraRinseAdder.dollars;
      this.model.ExtraRinseCents = this.vendingSettings.ExtraRinseAdder.cents;
    }
    this.model.ValueCoin1Dollar = this.vendingSettings.ValueCoin1.dollars;
    this.model.ValueCoin1Cents = this.vendingSettings.ValueCoin1.cents;
    this.model.ValueCoin2Dollar = this.vendingSettings.ValueCoin2.dollars;
    this.model.ValueCoin2Cents = this.vendingSettings.ValueCoin2.cents;
    this._tlWasherModelServices.coin1Value.dollar = this.model.ValueCoin1Dollar;
    this._tlWasherModelServices.coin1Value.cent = this.model.ValueCoin1Cents;
  }

  setToDefaultSplPricing() {
    if (this.modelType == 'PR') {
      this.disableSplPricingControls();
    } else if (this.modelType == 'PD') {
      this.enableSplPricingControls();
    }
    this.vendingSettings.SpecialPricingEnable.value = this.vendingSettings.SpecialPricingEnable.default;
    this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default;
    this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;

    this.vendingSettings.SundaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.MondaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.TuesdaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.WednesdaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.ThursdaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.FridaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.SaturdaySpecialPriceEnable.values.checked = false;
    this.vendingSettings.SpecialPriceTime.startHour = '0';
    this.vendingSettings.SpecialPriceTime.endHour = '0';

    this.model.SpecialPriceTimeStart = this.vendingSettings.SpecialPriceTime.startHour;
    this.model.SpecialPriceTimeEnd = this.vendingSettings.SpecialPriceTime.endHour;
    this.model.CycleSpecialPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.model.CycleSpecialPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
  }

  disableSplPricingControls() {
    this.vendingForm.controls['startHour'].disable();
    this.vendingForm.controls['endHour'].disable();
    this.vendingForm.controls['splCycleDollars'].disable();
    this.vendingForm.controls['splCycleCents'].disable();
    this.vendingForm.controls['splDryTime'].disable();
  }

  enableSplPricingControls() {
    this.vendingForm.controls['startHour'].enable();
    this.vendingForm.controls['endHour'].enable();
    this.vendingForm.controls['splCycleDollars'].enable();
    this.vendingForm.controls['splCycleCents'].enable();
    // this.vendingForm.controls['splDryTime'].enable();
  }

  onDaySelection(event: MatCheckboxChange, day: string) {
    this.daySelection(event.checked, day);
  }

  daySelection(checked: boolean, day: string) {
    switch (day) {
      case 'Sun':
        this.vendingSettings.SundaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.SundaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Mon':
        this.vendingSettings.MondaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.MondaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Tue':
        this.vendingSettings.TuesdaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.TuesdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Wed':
        this.vendingSettings.WednesdaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.WednesdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Thu':
        this.vendingSettings.ThursdaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.ThursdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Fri':
        this.vendingSettings.FridaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.FridaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Sat':
        this.vendingSettings.SaturdaySpecialPriceEnable.values.checked = checked;
        this.vendingSettings.SaturdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
    }
  }

  onChange(isEnable: boolean) {
    if (isEnable) {
      this.vendingSettings.SpecialPricingEnable.value = true;
      this.enableSplPricingControls();
    } else {
      this.disableSplPricingControls();
      this.vendingSettings.SpecialPricingEnable.value = false;
      this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default;
      this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
      this.vendingSettings.SundaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.MondaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.TuesdaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.WednesdaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.ThursdaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.FridaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.SaturdaySpecialPriceEnable.values.checked = false;
      this.vendingSettings.SpecialPriceTime.startHour = '0';
      this.vendingSettings.SpecialPriceTime.endHour = '0';
      this.model.SpecialPriceTimeStart = this.vendingSettings.SpecialPriceTime.startHour;
      this.model.SpecialPriceTimeEnd = this.vendingSettings.SpecialPriceTime.endHour;
      this.model.CycleSpecialPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.model.CycleSpecialPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
    }
  }

  setDollarCentValues1() {
    let changeValues = [
      { "dollar": 'splCycleDollars', "cent": 'splCycleCents' },
      { "dollar": 'regularPriceDollars', "cent": 'regularPriceCents' },
      { "dollar": 'coinOneDollars', "cent": 'coinOneCents' },
      { "dollar": 'coinTwoDollars', "cent": 'coinTwoCents' },
    ];
    changeValues.forEach(data => {
      this.vendingForm.get(data["dollar"]).valueChanges.subscribe(x => {
        if (x > 9) {
          this.vendingForm.get(data["dollar"]).setValue(9, { emitEvent: false });
          this.vendingForm.get(data["cent"]).setValue(0, { emitEvent: false });
        }
      })
      this.vendingForm.get(data["cent"]).valueChanges.subscribe(x => {
        var d = Number(this.vendingForm.get(data["dollar"]).value);
        if (x > 95) {
          this.vendingForm.get(data["cent"]).setValue(95, { emitEvent: false });
        }
        if (x == 100 && d < 9) {
          var result = this.dollarConverts(x);
          d = d + Number(result[0]);
          this.vendingForm.get(data["dollar"]).setValue(d);
          this.vendingForm.get(data["cent"]).setValue(result[1]);
        }
      });
    });
  }

  setDollarCentValues2() {
    let changeValues = [
      { "dollar": 'coolWaterDollar', "cent": 'coolWaterCents' },
      { "dollar": 'warmWaterDollar', "cent": 'warmWaterCents' },
      { "dollar": 'hotWaterDollar', "cent": 'hotWaterCents' },
      { "dollar": 'solLevelDollar', "cent": 'solLevelCents' },
      { "dollar": 'extraRinseDollar', "cent": 'extraRinseCents' },
    ];
    changeValues.forEach(data => {
      this.vendingForm.get(data["dollar"]).valueChanges.subscribe(x => {
        if (x > 9) {
          this.vendingForm.get(data["dollar"]).setValue(10, { emitEvent: false });
          this.vendingForm.get(data["cent"]).setValue(0, { emitEvent: false });
        }
      });
      this.vendingForm.get(data["cent"]).valueChanges.subscribe(x => {
        var d = Number(this.vendingForm.get(data["dollar"]).value);
        if (d == 10) {
          this.vendingForm.get(data["cent"]).setValue(0, { emitEvent: false });
          return;
        }
        if (x > 95) {
          this.vendingForm.get(data["cent"]).setValue(95, { emitEvent: false });
        }
        if (x == 100 && d < 9) {
          var result = this.dollarConverts(x);
          d = d + Number(result[0]);
          this.vendingForm.get(data["dollar"]).setValue(d);
          this.vendingForm.get(data["cent"]).setValue(result[1]);
        }
      });
    });
  }

  dollarConverts(c) {
    var nCents = c;
    var nDollar = (nCents / 100);
    var rDollar = Math.floor(nDollar);
    var cents = (nDollar - rDollar) * 100;
    var rCents = Math.round(cents);
    return [rDollar, rCents]
  }

  getDollarCents() {
    if (this.modelType != '9J') {
      this.vendingSettings.SpecialPriceTime.value[0] = this.model.SpecialPriceTimeStart;
      this.vendingSettings.SpecialPriceTime.value[1] = this.model.SpecialPriceTimeEnd;
      this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.model.CycleSpecialPriceDollar;
      this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.model.CycleSpecialPriceCents;
      this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.model.CycleRegularPriceDollar;
      this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.model.CycleRegularPriceCents;
      this.vendingSettings.CoolTemperature.value[0] = this.model.CoolTemperatureDollar;
      this.vendingSettings.CoolTemperature.value[1] = this.model.CoolTemperatureCents;
      this.vendingSettings.WarmTemperature.value[0] = this.model.WarmTemperatureDollar;
      this.vendingSettings.WarmTemperature.value[1] = this.model.WarmTemperatureCents;
      this.vendingSettings.HotTemperature.value[0] = this.model.HotTemperatureDollar;
      this.vendingSettings.HotTemperature.value[1] = this.model.HotTemperatureCents;
      this.vendingSettings.HeavySoil.value[0] = this.model.HeavySoilDollar;
      this.vendingSettings.HeavySoil.value[1] = this.model.HeavySoilCents;
      this.vendingSettings.ExtraRinseAdder.value[0] = this.model.ExtraRinseDollar;
      this.vendingSettings.ExtraRinseAdder.value[1] = this.model.ExtraRinseCents;
    }
    this.vendingSettings.ValueCoin1.value[0] = this.model.ValueCoin1Dollar;
    this.vendingSettings.ValueCoin1.value[1] = this.model.ValueCoin1Cents;
    this.vendingSettings.ValueCoin2.value[0] = this.model.ValueCoin2Dollar;
    this.vendingSettings.ValueCoin2.value[1] = this.model.ValueCoin2Cents;
    this._tlWasherModelServices.coin1Value.dollar = this.model.ValueCoin1Dollar;
    this._tlWasherModelServices.coin1Value.cent = this.model.ValueCoin1Cents;
  }

  inputStartHourRange(value) {
    if (!this.vendingForm.get("startHour").value) {
      this.vendingForm.get("startHour").setValue(this.vendingSettings.SpecialPriceTime.default);
      return;
    }
    if (this.vendingForm.get("startHour").value > this.vendingSettings.SpecialPriceTime.max) {
      this.vendingForm.get("startHour").setValue(this.vendingSettings.SpecialPriceTime.max);
    }
    if (this.vendingForm.get("startHour").value < this.vendingSettings.SpecialPriceTime.min) {
      this.vendingForm.get("startHour").setValue(this.vendingSettings.SpecialPriceTime.min);
    }
    this.vendingSettings.SpecialPriceTime.value[0] = value;
  }

  inputEndHourRange(value) {
    if (!this.vendingForm.get("endHour").value) {
      this.vendingForm.get("endHour").setValue(this.vendingSettings.SpecialPriceTime.default);
      return;
    }
    if (this.vendingForm.get("endHour").value > this.vendingSettings.SpecialPriceTime.max) {
      this.vendingForm.get("endHour").setValue(this.vendingSettings.SpecialPriceTime.max);
    }
    if (this.vendingForm.get("endHour").value < this.vendingSettings.SpecialPriceTime.min) {
      this.vendingForm.get("endHour").setValue(this.vendingSettings.SpecialPriceTime.min);
    }
    this.vendingSettings.SpecialPriceTime.value[1] = value;
  }

  inputSplDryTime() {
    if (!this.vendingForm.get("splDryTime").value && this.vendingForm.get("splDryTime").value != '0') {
      this.vendingForm.get("splDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default);
      return;
    }
    if (this.vendingForm.get("splDryTime").value > this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.max) {
      this.vendingForm.get("splDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.max);
    }
    if (this.vendingForm.get("splDryTime").value < this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.min) {
      this.vendingForm.get("splDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.min);
    }
  }

  inputRegularDryTime() {
    if (!this.vendingForm.get("regularDryTime").value && this.vendingForm.get("regularDryTime").value != '0') {
      this.vendingForm.get("regularDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.default);
      return;
    }
    if (this.vendingForm.get("regularDryTime").value > this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.max) {
      this.vendingForm.get("regularDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.max);
    }
    if (this.vendingForm.get("regularDryTime").value < this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.min) {
      this.vendingForm.get("regularDryTime").setValue(this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.min);
    }
  }

  changeDollar(name: string, value: string) {
    switch (name) {
      case 'CycleSpecialPrice':
        this.model.CycleSpecialPriceDollar = this.model.CycleSpecialPriceDollar ? this.model.CycleSpecialPriceDollar : '0';
        if (Number(value) > 9 && this.modelType == 'PD') {
          value = '9';
          this.model.CycleSpecialPriceDollar = '9';
          return;
        }
        if (!value && value != '0') {
          this.model.CycleSpecialPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
          value = this.model.CycleSpecialPriceDollar;
        }
        this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = value;
        this.calcRegularDryTime();
        break;
      case 'CycleRegularPrice':
        this.model.CycleRegularPriceDollar = this.model.CycleRegularPriceDollar ? this.model.CycleRegularPriceDollar : '0';
        if (!value && value != '0') {
          this.model.CycleRegularPriceDollar = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
          value = this.model.CycleRegularPriceDollar;
        }
        this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = value;
        this.calcRegularDryTime();

        break;
      case 'ValueCoin1':
        this._tlWasherModelServices.prevCoinValue = this.vendingSettings.ValueCoin1.value[0];
        this.model.ValueCoin1Dollar = this.model.ValueCoin1Dollar ? this.model.ValueCoin1Dollar : '0';
        const isValid = this.validateDollarCoin(this.modelType, value);
        if (isValid) {
          if (!value && value != '0') {
            this.model.ValueCoin1Dollar = this.vendingSettings.ValueCoin1.dollars;
            value = this.model.ValueCoin1Dollar;
          }
          this.vendingSettings.ValueCoin1.value[0] = value;
          this._tlWasherModelServices.coin1Value.dollar = value;
          if (this.modelType == 'PD') {
            this.hasChangedValue('coin1Dollar');
          }
        }
        const isValidPrice = this._tlWasherModelServices.setCyclePrice(true, value);
        if (!isValidPrice) {
          this.vendingSettings.ValueCoin1.value[0] = this._tlWasherModelServices.prevCoinValue;
          this._tlWasherModelServices.coin1Value.dollar = this._tlWasherModelServices.prevCoinValue;
          this.model.ValueCoin1Dollar = this._tlWasherModelServices.prevCoinValue;
        }
        break;
      case 'ValueCoin2':
        this.model.ValueCoin2Dollar = this.model.ValueCoin2Dollar ? this.model.ValueCoin2Dollar : '0';
        if (Number(this.model.ValueCoin2Dollar) > 9) {
          value = '9';
          this.model.ValueCoin2Dollar = '9';
          return;
        }
        if (!value && value != '0') {
          this.model.ValueCoin2Dollar = this.vendingSettings.ValueCoin2.dollars;
          value = this.model.ValueCoin2Dollar;
        }
        this.vendingSettings.ValueCoin2.value[0] = value;
        break;
      case 'CoolTemperature':
        this.model.CoolTemperatureDollar = this.model.CoolTemperatureDollar ? this.model.CoolTemperatureDollar : '0';
        if (!value && value != '0') {
          this.model.CoolTemperatureDollar = this.vendingSettings.CoolTemperature.dollars;
          value = this.model.CoolTemperatureDollar;
        }
        this.vendingSettings.CoolTemperature.value[0] = value;
        break;
      case 'WarmTemperature':
        this.model.WarmTemperatureDollar = this.model.WarmTemperatureDollar ? this.model.WarmTemperatureDollar : '0';
        if (!value && value != '0') {
          this.model.WarmTemperatureDollar = this.vendingSettings.WarmTemperature.dollars;
          value = this.model.WarmTemperatureDollar;
        }
        this.vendingSettings.WarmTemperature.value[0] = value;
        break;
      case 'HotTemperature':
        this.model.HotTemperatureDollar = this.model.HotTemperatureDollar ? this.model.HotTemperatureDollar : '0';
        if (!value && value != '0') {
          this.model.HotTemperatureDollar = this.vendingSettings.HotTemperature.dollars;
          value = this.model.HotTemperatureDollar;
        }
        this.vendingSettings.HotTemperature.value[0] = value;
        break;
      case 'HeavySoil':
        this.model.HeavySoilDollar = this.model.HeavySoilDollar ? this.model.HeavySoilDollar : '0';
        if (!value && value != '0') {
          this.model.HeavySoilDollar = this.vendingSettings.HeavySoil.dollars;
          value = this.model.HeavySoilDollar;
        }
        this.vendingSettings.HeavySoil.value[0] = value;
        break;
      case 'ExtraRinse':
        this.model.ExtraRinseDollar = this.model.ExtraRinseDollar ? this.model.ExtraRinseDollar : '0';
        if (!value && value != '0') {
          this.model.ExtraRinseDollar = this.vendingSettings.ExtraRinseAdder.dollars;
          value = this.model.ExtraRinseDollar;
        }
        this.vendingSettings.ExtraRinseAdder.value[0] = value;
        break;
      default:
        break;
    }
  }

  validateDollarCoin(modelType: string, value: string): boolean {
    if (Number(value) > 9 && this.modelType == 'PD') {
      value = '9';
      this.model.ValueCoin1Dollar = '9';
      return;
    } else if (Number(value) > 9 && (modelType == 'PR' || modelType == '9J')) {
      value = '9';
      this.model.ValueCoin1Dollar = '9';
      return false;
    }
    return true;
  }

  changeCents(name: string, value: string) {
    this.jqueryFunc(name);
    switch (name) {
      case 'CycleSpecialPrice':
        this.model.CycleSpecialPriceCents = this.model.CycleSpecialPriceCents ? this.model.CycleSpecialPriceCents : '0';
        if (!value && value != '0') {
          this.model.CycleSpecialPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
          value = this.model.CycleSpecialPriceCents;
        }
        this.vendingSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1] = value;
        this.calcRegularDryTime()
        break;
      case 'CycleRegularPrice':
        this.model.CycleRegularPriceCents = this.model.CycleRegularPriceCents ? this.model.CycleRegularPriceCents : '0';
        if (!value && value != '0') {
          this.model.CycleRegularPriceCents = this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.cents;
          value = this.model.CycleRegularPriceCents;
        }
        this.vendingSettings.DnldCycle_PaySet_CycleRegularPrice.value[1] = value;
        this.calcRegularDryTime();
        break;
      case 'ValueCoin1':
        this._tlWasherModelServices.prevCoinValue = this.vendingSettings.ValueCoin1.value[1];
        this.model.ValueCoin1Cents = this.model.ValueCoin1Cents ? this.model.ValueCoin1Cents : '0';
        const isValid = this.validateCents(this.modelType, value);
        if (isValid) {

          if (!value && value != '0') {
            this.model.ValueCoin1Cents = this.vendingSettings.ValueCoin1.cents;
            value = this.model.ValueCoin1Cents;
          }
          this.vendingSettings.ValueCoin1.value[1] = value;
          this._tlWasherModelServices.coin1Value.dollar = this.model.ValueCoin1Dollar;
          this._tlWasherModelServices.coin1Value.cent = value;
          if (this.modelType == 'PD') {
            this.hasChangedValue('coin1Cents');
          }
        }
        const isValidPrice = this._tlWasherModelServices.setCyclePrice(true, value);
        if (!isValidPrice) {
          this.vendingSettings.ValueCoin1.value[1] = this._tlWasherModelServices.prevCoinValue;
          this._tlWasherModelServices.coin1Value.cent = this._tlWasherModelServices.prevCoinValue;
          this.model.ValueCoin1Cents = this._tlWasherModelServices.prevCoinValue;
        }
        break;
      case 'ValueCoin2':
        this.model.ValueCoin2Cents = this.model.ValueCoin2Cents ? this.model.ValueCoin2Cents : '0';
        if (Number(this.model.ValueCoin2Dollar) > 9) {
          value = '9';
          this.model.ValueCoin2Cents = '95';
          this.model.ValueCoin2Dollar = '9';
          return;
        }
        if (!value && value != '0') {
          this.model.ValueCoin2Cents = this.vendingSettings.ValueCoin2.cents;
          value = this.model.ValueCoin2Cents;
        }
        this.vendingSettings.ValueCoin2.value[1] = value;
        break;

      case 'CoolTemperature':
        this.model.CoolTemperatureCents = this.model.CoolTemperatureCents ? this.model.CoolTemperatureCents : '0';
        if (!value && value != '0') {
          this.model.CoolTemperatureCents = this.vendingSettings.CoolTemperature.cents;
          value = this.model.CoolTemperatureCents;
        }
        this.vendingSettings.CoolTemperature.value[1] = value;
        break;
      case 'WarmTemperature':
        this.model.WarmTemperatureCents = this.model.WarmTemperatureCents ? this.model.WarmTemperatureCents : '0';
        if (!value && value != '0') {
          this.model.WarmTemperatureCents = this.vendingSettings.WarmTemperature.cents;
          value = this.model.WarmTemperatureCents;
        }
        this.vendingSettings.WarmTemperature.value[1] = value;
        break;
      case 'HotTemperature':
        this.model.HotTemperatureCents = this.model.HotTemperatureCents ? this.model.HotTemperatureCents : '0';
        if (!value && value != '0') {
          this.model.HotTemperatureCents = this.vendingSettings.HotTemperature.cents;
          value = this.model.HotTemperatureCents;
        }
        this.vendingSettings.HotTemperature.value[1] = value;
        break;
      case 'HeavySoil':
        this.model.HeavySoilCents = this.model.HeavySoilCents ? this.model.HeavySoilCents : '0';
        if (!value && value != '0') {
          this.model.HeavySoilCents = this.vendingSettings.HeavySoil.cents;
          value = this.model.HeavySoilCents;
        }
        this.vendingSettings.HeavySoil.value[1] = value;
        break;
      case 'ExtraRinse':
        this.model.ExtraRinseCents = this.model.ExtraRinseCents ? this.model.ExtraRinseCents : '0';
        if (!value && value != '0') {
          this.model.ExtraRinseCents = this.vendingSettings.ExtraRinseAdder.cents;
          value = this.model.ExtraRinseCents;
        }
        this.vendingSettings.ExtraRinseAdder.value[1] = value;
        break;
      default:
        break;
    }
  }

  validateCents(type: string, value: string): boolean {
    if (Number(this.model.ValueCoin1Dollar) > 9 && this.modelType == 'PD') {
      value = '95';
      this.model.ValueCoin1Cents = '95';
      this.model.ValueCoin1Dollar = '9';
      this.hasChangedValue('coin1Dollar');
      this.hasChangedValue('coin1Cents');
      this.calcRegularDryTime();
      return false;
    } else if (Number(this.model.ValueCoin1Dollar) > 9 && (type == 'PR' || type == '9J')) {
      value = '95';
      this.model.ValueCoin1Cents = '95';
      this.model.ValueCoin1Dollar = '9';
      return false;
    }
    return true;
  }

  changeTimeCoin() {
    this.hasChangedValue('timeCoin');
    this.calcRegularDryTime();
  }

  hasChangedValue(controlName: string) {
    this.isValueChanged.map(item => {
      if (item.name === controlName) {
        item.value = true;
      } else {
        item.value = false;
      }
    });
  }

  calcRegularDryTime() {
    setTimeout(() => {
      const cycleSpecialPriceDollar = Number(this.model.CycleSpecialPriceDollar);
      const cycleSpecialPriceCents = Number(this.model.CycleSpecialPriceCents);
      const cycleRegularPriceDollar = Number(this.model.CycleRegularPriceDollar);
      const cycleRegularPriceCents = Number(this.model.CycleRegularPriceCents);
      const coin1Dollar = Number(this.model.ValueCoin1Dollar);
      const coin1Cents = Number(this.model.ValueCoin1Cents);
      const regularPrice = (cycleRegularPriceDollar * 100) + cycleRegularPriceCents;
      const specialPrice = (cycleSpecialPriceDollar * 100) + cycleSpecialPriceCents;
      const coin1 = (coin1Dollar * 100) + coin1Cents;
      if (coin1 === 0) {
        this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = '0';
        this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = '0';
        return;
      }
      const regularDryTime = (regularPrice / coin1) * this.timePerCoinVal;
      const specialDryTime = (specialPrice / coin1) * this.timePerCoinVal;
      this.vendingSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = String(Math.round(regularDryTime));
      this.vendingSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = String(Math.round(specialDryTime));

    }, 100);
  }

  jqueryFunc(name: string) {
    const inputName = `${'#' + name}`;
    $(inputName).bind('keyup input', function () {
      this.setAttribute("step", "5");
    });
  }

  jqueryFuncDefault() {
    $("input[type=number]").bind('keyup input', function () {
      setTimeout(() => {
        this.setAttribute("step", "5");
      }, 50);
    });
  }

  ngOnDestroy(): void {
    const isModel = this._tlWasherModelServices.modelChanged();
    if (!isModel) {
      this.getDollarCents();
      this._tlWasherModelServices.setVenusCycleModels(this.vendingSettings);
    }
  }

}
