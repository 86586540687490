import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonServices } from 'src/app/services/CommonServices';
import { IModelList, IMultiloadModelList, ModelServices } from 'src/app/services/ModelServices';
import { NovoFileNames } from 'src/app/shared/enums/enum';
import * as VenusModel from "src/assets/JsonFiles/VenusModel.json";
import * as TLDryerModel from "src/assets/JsonFiles/tl-dryer/tl-dryer-model.json";
import * as tlWasherModel from "src/assets/JsonFiles/tl-washer/tl-washer-model.json";
import * as flDryerModel from "src/assets/JsonFiles/fl-dryer/fl-dryer-model.json";
import * as flWasherModel from "src/assets/JsonFiles/fl-washer/model.json"
import { ICommonModel } from 'src/app/shared/models/common';
import { UploadFile } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-setup-file-usb',
  templateUrl: './setup-file-usb.component.html',
  styleUrls: ['./setup-file-usb.component.scss']
})

export class SetupFileUsbComponent implements OnInit {
  @ViewChild('uploadFile', { static: false }) uploadFile: ElementRef;
  public cspModel: IModelList = (VenusModel as any).default;
  public tlDryerModel: IModelList = (TLDryerModel as any).default;
  public tlWasherModel: IModelList = (tlWasherModel as any).default;
  public flDryerModel: IModelList = (flDryerModel as any).default;
  public flWasherModel: IModelList = (flWasherModel as any).default;
  SetUsbPageThree: boolean = false;
  previousUrl: string;
  file: any;
  novoFiles: any[] = [];
  nmbrOfFiles: number;
  resultString: any;
  modelName: string;
  multiLoadModelList: IMultiloadModelList = {
    ModelName: '',
    ModelType: '',
    Models: [],
    ProductType: '',
    Weight: ''
  };
  oplFilesName: any[] = [
    NovoFileNames.Cycle_data_Settings.toLowerCase(),
    NovoFileNames.hmi_display_Settings.toLowerCase(),
    NovoFileNames.Machine_Config.toLowerCase()
  ]
  vendedFilesName: any[] = [
    NovoFileNames.Cycle_data_Settings.toLowerCase(),
    NovoFileNames.hmi_display_Settings.toLowerCase(),
    NovoFileNames.Machine_Config.toLowerCase(),
    NovoFileNames.cycle_vending_Settings.toLowerCase()
  ]
  commonModel: ICommonModel = {
    ModelNumber: '',
    isValid: false,
    FileType: ''
  };

  constructor(
    private toastr: ToastrService,
    private modelServices: ModelServices,
    private commonService: CommonServices,
    private _uploadFile: UploadFile,
  ) {
  }

  ngOnInit() {
    this.multiLoadModelList = this.modelServices.getModelList();
    this.commonService.disbleUSB.subscribe(
      (a) => {
        this.SetUsbPageThree = a;
      }
    )
  }

  fileChanged(e) {
    this.novoFiles = [];
    const targetFiles = this.checkFileType(e.target.files);
    if (!targetFiles) {
      this.showError('File not correct !!');
      return;
    }

    this.nmbrOfFiles = targetFiles.length;
    switch (targetFiles.length) {
      case 3:
        targetFiles[0].fileType = 'Novo'
        this.novoFiles.push(...targetFiles);
        break;
      case 4:
        targetFiles[0].fileType = 'Novo'
        this.novoFiles.push(...targetFiles);
        break;
      case 1:
        // this.showSuccess(this.novoFiles[0].fileType);
        break;
      default:
        this.showError('File not correct !!');
        break;
    }
  }

  checkFileType(files: any): any[] {
    let arrFile = [];
    let targetFiles = [];
    for (let i = 0; i < files.length; i++) {
      arrFile.push(files[i].name.toLowerCase());
    }
    const isOpl = this.oplFilesName.every(e => arrFile.includes(e))
    const isVended = this.vendedFilesName.every(e => arrFile.includes(e))
    if (isVended) {
      for (let i = 0; i < files.length; i++) {
        if (this.vendedFilesName.includes(files[i].name.toLowerCase())) {
          targetFiles.push(files[i]);
        }
      }
      return targetFiles;
    } else if (isOpl) {
      for (let i = 0; i < files.length; i++) {
        if (this.vendedFilesName.includes(files[i].name.toLowerCase())) {
          targetFiles.push(files[i]);
        }
      }
      return targetFiles;
    } else {
      const fileName = arrFile[0].split('.')[0].replace('ms', '');
      const isValid = this.findEchoModel(fileName, files);
      if (isValid) {
        return files;
      } else {
        return null;
      }
    }
  }

  findEchoModel(fileName: string, files: any): boolean {
    const csp = this.cspModel.Models.some(f => { return f.toLowerCase() == fileName });
    if (csp) {
      files[0].fileType = 'CSP'
      files[0].ModelName = fileName.toUpperCase();
      this.novoFiles.push(...files);
      return true;
    }
    const tlDryer = this.tlDryerModel.Models.some(f => { return f.toLowerCase() == fileName });
    if (tlDryer) {
      files[0].fileType = 'TLDryer'
      files[0].ModelName = fileName.toUpperCase();
      this.novoFiles.push(...files);
      return true;
    }
    const tlWasher = this.tlWasherModel.Models.some(f => { return f.toLowerCase() == fileName });
    if (tlWasher) {
      files[0].fileType = 'TLWasher'
      files[0].ModelName = fileName.toUpperCase();
      this.novoFiles.push(...files);
      return true;
    }
    const flDryer = this.flDryerModel.Models.some(f => { return f.toLowerCase() == fileName });
    if (flDryer) {
      files[0].fileType = 'FLDryer'
      files[0].ModelName = fileName.toUpperCase();
      this.novoFiles.push(...files);
      return true;
    }
    const flWasher = this.flWasherModel.Models.some(f => { return f.toLowerCase() == fileName });
    if (flWasher) {
      files[0].fileType = 'FLWasher'
      files[0].ModelName = fileName.toUpperCase();
      this.novoFiles.push(...files);
      return true;
    } else {
      return false;
    }
  }

  uploadDocument() {
    this.novoFiles.map((e, index) => {
      const fr = new FileReader();
      fr.onload = () => {
        var string = this.resultString != null ? this.resultString : fr.result;
        var result = new Uint8Array(string.length);
        for (var i = 0; i < string.length; i++) {
          result[i] = string.charCodeAt(i);
        }
        e['value'] = result;
        if (index === this.novoFiles.length - 1) {
          if (this.novoFiles[0].fileType == 'Novo') {
            this.validateNovoModel(this.novoFiles);
          }
          else {
            this.commonModel = this._uploadFile.getNovoModelName(this.novoFiles[0]);
            this.commonModel.FileType = this.novoFiles[0].fileType;
            this.commonModel.ModelNumber = this.novoFiles[0].ModelName;
            if (!this.commonModel.isValid) {
              this.showError('File is not correct');
              return;
            } else {
              this.validateEchoModel(this.commonModel);
            }
          }
        }
      }
      return fr.readAsBinaryString(e);
    });
  }

  reset() {
    this.uploadFile.nativeElement.value = "";
    this.novoFiles = [];
  }

  validateNovoModel(novoFiles) {
    const files = novoFiles.filter(e => { if (e.name === NovoFileNames.Machine_Config) { return e } });
    const isFileTypeValid = this.validateNmbrOfFiles(files[0].value[0], novoFiles);
    if (!isFileTypeValid) {
      this.reset();
      this.showError('File not correct !!');
      return;
    }
    let modelArr: number[] = [];
    for (let [index, value] of files[0].value.entries()) {
      if (index >= 37 && index <= 52 && value != 0) {
        modelArr.push(value);
      }
    };
    const modelName = String.fromCharCode(...modelArr);
    const isModelExist = this.validateModelExist(modelName);
    if (!isModelExist) {
      return;
    }
    novoFiles[0]['ModelName'] = this.modelName;
    this.commonModel['ModelNumber'] = this.modelName;
    this.commonModel['FileType'] = 'Novo';
    this.commonService.setBinaryData(novoFiles);
    this.commonService.homeScreen.next(this.commonModel);
    this.commonService.getLoadingBar('Please Wait ..');
    this.reset();
  }

  validateEchoModel(echoFiles) {
    this.commonService.homeScreen.next(this.commonModel);
    this.reset();
  }

  validateModelExist(modelName: string): boolean {
    console.log('Model Name is : ', modelName);
    this.modelName = this.multiLoadModelList.Models.find(f => { return modelName.indexOf(f) != -1 });
    const isModelExist = this.multiLoadModelList.Models.some(f => { return modelName.indexOf(f) != -1 });
    if (!isModelExist) {
      this.reset();
      this.showError('Model not exist !!');
    }
    return isModelExist;
  }

  validateNmbrOfFiles(modelType: number, files): boolean {
    // OPL =1 and Coin =2
    if (modelType === 1 && this.nmbrOfFiles === 3) {
      const isBytesMatch = this.validateNovoBytes(modelType, files);
      return isBytesMatch === true ? true : false;
    } else if (modelType === 2 && this.nmbrOfFiles === 4) {
      const isBytesMatch = this.validateNovoBytes(modelType, files);
      return isBytesMatch === true ? true : false;
    } else {
      this.reset();
      this.showError('File not correct [Invalid number of files]!!');
      return false;
    }
  }

  validateNovoBytes(modelType: number, novoFiles: any): boolean {
    const setupBytes = novoFiles.find(e => { if (e.name.toLowerCase() === NovoFileNames.Machine_Config.toLowerCase()) { return e } }).size;
    const hmiBytes = novoFiles.find(e => { if (e.name.toLowerCase() === NovoFileNames.hmi_display_Settings.toLowerCase()) { return e } }).size;
    const cycleSettingBytes = novoFiles.find(e => { if (e.name.toLowerCase() === NovoFileNames.Cycle_data_Settings.toLowerCase()) { return e } }).size;
    switch (modelType) {
      case 2:
        const cycleVendingBytes = novoFiles.find(e => { if (e.name.toLowerCase() === NovoFileNames.cycle_vending_Settings.toLowerCase()) { return e } }).size;
        if (setupBytes == 126 && (hmiBytes == 117 || 116) && cycleVendingBytes == 117 && cycleSettingBytes == 24634) {
          return true;
        } else {
          this.showError('Invalid configuration Files!!');
          return false;
        }
      case 1:
        if (setupBytes == 126 && hmiBytes == 117 && cycleSettingBytes == 202810) {
          return true;
        } else {
          this.showError('Invalid configuration Files!!');
          return false;
        }
    }
  }

  showError(message) {
    this.toastr.error(message, 'Failed', {
      positionClass: 'toast-top-right'
    });
  }

  showSuccess(message) {
    this.toastr.success(message, 'Success', {
      positionClass: 'toast-top-right'
    });
  }
}