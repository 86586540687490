import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { AuthenticationService } from "src/app/services/AuthenticationService";
import { CommonServices } from "src/app/services/CommonServices";
import { KeyboardSelectionEnum } from "src/app/shared/enums/enum";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { editProfileService } from "./edit-profile.service";
import { ErrorList, ICompanyList, ICountryList, IUserDetail } from "./edit-profiler.model";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
  userDetail: IUserDetail = {
    defaultFirstName: '',
    defaultLastName: '',
    accountId: '',
    firstName: '',
    lastName: '',
    companyName: '',
    countryId: '',
    email: '',
    phoneNo: '',
    lastUpdateDate: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  keyboardSelectionEnum = KeyboardSelectionEnum;
  countryList: ICountryList[];
  filteredCountryList: ICountryList[];
  companyList: ICompanyList[];
  filteredCompanyList: ICompanyList[];
  addNewCompanyShow: boolean = false;
  dialogRef: any;
  value3: string;
  hide: boolean = true;
  oldPasswordHide: boolean = true;
  newPasswordHide: boolean = true;
  confirmPasswordHide: boolean = true;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  decrypt = new Map();
  editData: boolean = false;
  errorList = new ErrorList();

  constructor(
    private authenticationService: AuthenticationService,
    private _editProfileService: editProfileService,
    public dialog3: MatDialog,
  ) { }

  ngOnInit() {
    this.getCountryList();
    this.getUserDetail();
  }

  saveEditForm(data: IUserDetail) {
    if (Number(data.phoneNo.length) <= 9) {
      this._editProfileService.showMessage(this.errorList.msgPhoneNumber, true);
      return;
    }
    const payload = {
      "countryId": data.countryId,
      "account": {
        "accountId": this.userDetail.accountId,
        "firstName": data.firstName,
        "lastName": data.lastName,
        "email": this.userDetail.email,
        "phoneNo": data.phoneNo,
        "alternateEmail": '',
        "languageId": '1'
      },
      "accountId": this.userDetail.accountId
    }
    const url = "/api/v1/updateAccount";
    this._editProfileService.putData(url, JSON.stringify(payload)).subscribe((res) => {
      this._editProfileService.showMessage('User updated successfully.', false)
      this.getUserDetail();
    }, (err) => {
      this._editProfileService.showMessage(err, true);
    });
  }

  getCountryList() {
    this.countryList = [];
    this.filteredCountryList = [];
    const requestURL = "/public/v1/countryCL";
    this._editProfileService.getData(requestURL).subscribe((res) => {
      this.countryList = res;
      this.filteredCountryList = this.countryList;
    });
  }

  changePassword(mytemplate) {
    this.userDetail.oldPassword = '';
    this.userDetail.newPassword = '';
    this.userDetail.confirmPassword = '';
    this.dialogRef = this.dialog3.open(mytemplate, {
      width: "700px",
      height: "550px",
    });
  }

  close() {
    this.dialogRef.close();
  }

  userOldPassword() {
    this.oldPasswordHide = !this.oldPasswordHide;
  }

  userNewPassword() {
    this.newPasswordHide = !this.newPasswordHide
  }

  userConfirmPassword() {
    this.confirmPasswordHide = !this.confirmPasswordHide
  }

  saveChangePassword(data: IUserDetail) {
    const payload = {
      "countryId": this.userDetail.countryId,
      "account": {
        "accountId": this.userDetail.accountId,
        "firstName": this.userDetail.firstName,
        "lastName": this.userDetail.lastName,
        "email": this.userDetail.email,
        "phoneNo": this.userDetail.phoneNo,
        "alternateEmail": '',
        "languageId": '1',
        "oldPassword": data.oldPassword,
        "password": data.confirmPassword
      },
      "accountId": this.userDetail.accountId
    }
    const url = "/api/v1/updateAccount";
    this._editProfileService.putData(url, JSON.stringify(payload)).subscribe((res) => {
      this._editProfileService.showMessage('Password has been successfully updated. Please re-login with new credentials.', false);
      this.close();
      setTimeout(() => {
        this.authenticationService.logout();
        location.reload();
      }, 20);
    }, (err) => {
      this._editProfileService.showMessage(err, true);
    });
  }

  getUserDetail() {
    const accountId = JSON.parse(localStorage.getItem('currentUser')).accountId;
    const url = `${'/api/v1/client_auth/accountCL/' + accountId}`;
    this._editProfileService.getData(url).subscribe((res) => {
      this.userDetail.firstName = res[0].FIRST_NAME;
      this.userDetail.lastName = res[0].LAST_NAME;
      this.userDetail.email = res[0].EMAIL;
      this.userDetail.phoneNo = res[0].PHONE_NO;
      this.userDetail.companyName = res[0].COMPANY_NAME;
      this.userDetail.countryId = res[0].COUNTRY_ID;
      this.userDetail.accountId = res[0].ACCOUNT_ID;
      this.userDetail.lastUpdateDate = res[0].UPDATED_AT;
      this.userDetail.defaultFirstName = res[0].FIRST_NAME;
      this.userDetail.defaultLastName = res[0].LAST_NAME;
    }, (err) => {
      this._editProfileService.showMessage(err, true);
    });
  }
}