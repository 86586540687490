import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { FlWasherModelServices } from "src/app/services/FlWasherModel.service";
import { CycleName, ICycleDataList, Temperature } from "../fl-washer.model";
import { FlWasherCycleSettingCycleName, FlWasherCycleSettingList, FlWasherCycleSettingRinse, FlWasherCycleSettingValues, IBinaryKey, ICycleSettingDnldCycle, ICycleSettingModelList, ICycleSettingValue, IEntityKey } from "./fl-washer-cycle.model";

@Component({
  selector: "app-fl-washer-cycle",
  templateUrl: "./fl-washer-cycle.component.html",
  styleUrls: ["./fl-washer-cycle.component.scss"],
})
export class FlWasherCycleComponent implements OnInit, AfterViewInit, OnDestroy {
  FlWasherForm: FormGroup;
  modelType: string;

  model: ICycleSettingModelList = {
    CycleTitle: '',
    CycleValue: '',
    Temperature: '',
    ExtraRinse: '',
    CycleSpecialPriceDollar: '',
    CycleSpecialPriceCents: '',
    CycleRegularPriceDollar: '',
    CycleRegularPriceCents: '',
  }

  tlCycleData: ICycleDataList;
  cycleName: CycleName[] = [];
  temperature: Temperature[] = [];
  cycleDataList: ICycleDataList = {
    CycleName: this.cycleName,
    Temperature: this.temperature
  };

  cycleSettingValue: ICycleSettingValue = {
    id: '',
    name: ''
  }

  cycleSettingValues: FlWasherCycleSettingValues = {
    value: '',
    default: '',
    values: this.cycleSettingValue,
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  cycleSettingRinseValues: FlWasherCycleSettingRinse = {
    value: false,
    default: '',
    values: this.cycleSettingValue,
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  cycleSettingDnldCycle: ICycleSettingDnldCycle = {
    value: [],
    default: '',
    dollars: '',
    cents: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  cycleSettingBinaryKey: IBinaryKey = {
    binaryKeyValue: '',
    arrKey: [],
    type: ''
  }

  cycleSettingEntityKey: IEntityKey = {
    value: '',
    binaryKeyValue: '',
    arrKey: [],
    type: ''
  }


  cycleSettingCycleDetail: FlWasherCycleSettingList = {
    Relational_Envelope_Open: this.cycleSettingBinaryKey,
    Relational_Envelope_Action: this.cycleSettingBinaryKey,
    Entity_Cycle_Open: this.cycleSettingBinaryKey,
    Relational_Entity_Name: this.cycleSettingEntityKey,
    Relational_Entity_Id: this.cycleSettingBinaryKey,
    DnldCycle_CycleSet_CycleCavityPosition: this.cycleSettingBinaryKey,
    DnldCycle_PaySet_CycleRegularPrice: this.cycleSettingDnldCycle,
    DnldCycle_PaySet_CycleSpecialPrice: this.cycleSettingDnldCycle,
    DnldCycle_OpSet_Temperature: this.cycleSettingValues,
    Relational_Array_Open: this.cycleSettingBinaryKey,
    Entity_Cycle_Part1_Open: this.cycleSettingBinaryKey,
    Entity_Cycle_Part1_Close: this.cycleSettingBinaryKey,
    Relational_Array_Close: this.cycleSettingBinaryKey,
    Entity_Cycle_Close: this.cycleSettingBinaryKey,
    Relational_Envelope_Close: this.cycleSettingBinaryKey,
    DnldCycle_OpSet_ExtraRinse: this.cycleSettingRinseValues,
    hasValue: false
  }

  cycleSettingList: FlWasherCycleSettingCycleName[] = [{
    cycleName: this.cycleSettingCycleDetail
  }]
  prevValues = {
    regDollar: '',
    regCents: '',
    splDollar: '',
    splCents: '',
  }

  constructor(
    private fb: FormBuilder,
    private _FlWasherModelServices: FlWasherModelServices,
    private cdref: ChangeDetectorRef,
    public dialog3: MatDialog
  ) { }

  ngOnInit() {
    this.getControls();
    this.setDollarCentValues();
    this.modelType = localStorage.getItem("ProductType");
    this._FlWasherModelServices.selectedWasherCycle.subscribe((cycle: any) => {
      this.model.CycleValue = cycle.cycleValue;
      this.model.CycleTitle = cycle.title;
      this.cycleDataList.Temperature = cycle.temperature;
      this._FlWasherModelServices.prevWasherSelectedCycle = cycle.cycleName;
      this.getCyclesData();

    });
    this.setOnInItData(this._FlWasherModelServices.selectedWasherCycleSettings);
  }

  getControls() {
    this.FlWasherForm = this.fb.group({
      cycleName: ["", Validators.required],
      temperature: ["", Validators.required],
      extraRinse: ["", Validators.required],
      splCycleDollars: ["", Validators.required],
      splCycleCents: ["", Validators.required],
      regularPriceDollars: ["", Validators.required],
      regularPriceCents: ["", Validators.required],
    });
  }

  getCyclesData() {
    this.cycleSettingList = this._FlWasherModelServices.getFlWasherCycleSettings(this.modelType);
    this.cycleSettingCycleDetail = this.cycleSettingList[this.model.CycleValue];
    this.setSelectedCycleData(this.cycleSettingCycleDetail);
    if (!this.cycleSettingCycleDetail) {
      this.cycleSettingList = this._FlWasherModelServices.getFlWasherCycleSettings(this.modelType);
      this.cycleSettingCycleDetail = this.cycleSettingList[this.model.CycleValue];
      this.setToDefault();
    } else if (this.cycleSettingCycleDetail && !this.cycleSettingCycleDetail.hasValue) {
      this.cycleSettingList = this._FlWasherModelServices.getFlWasherCycleSettings(this.modelType);
      this.cycleSettingCycleDetail = this.cycleSettingList[this.model.CycleValue];
      this._FlWasherModelServices.setCoinPrice();
      this.setToDefault();
    } else if (this.cycleSettingCycleDetail.hasValue) {
      this.setDollarCents();
    }
  }

  setOnInItData(selectedWasherCycleSettings: any) {
    if (selectedWasherCycleSettings.cycleValue) {
      this.model.CycleValue = selectedWasherCycleSettings.cycleValue;
      this.model.CycleTitle = selectedWasherCycleSettings.title;
      this.cycleDataList.Temperature = selectedWasherCycleSettings.temperature;
      this.getCyclesData();
    }
  }

  setToDefault() {
    this.cycleSettingCycleDetail.Relational_Entity_Name.value = this.model.CycleTitle;
    this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.cents;
    this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.dollars;
    this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.cents;
    this.cycleSettingCycleDetail.DnldCycle_OpSet_Temperature.value = this.cycleSettingCycleDetail.DnldCycle_OpSet_Temperature.default;
    this.cycleSettingCycleDetail.DnldCycle_OpSet_ExtraRinse.value = this.cycleSettingCycleDetail.DnldCycle_OpSet_ExtraRinse.default == '1' ? true : false;
    this.cycleSettingCycleDetail.hasValue = true;
    this.setDefaultDollarCents();
  }

  setDefaultDollarCents() {
    this.model.CycleSpecialPriceDollar = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.model.CycleSpecialPriceCents = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.cents;
    this.model.CycleRegularPriceDollar = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.dollars;
    this.model.CycleRegularPriceCents = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.cents;
  }

  setDollarCents() {
    this.model.CycleSpecialPriceDollar = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[0];
    this.model.CycleSpecialPriceCents = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1];
    this.model.CycleRegularPriceDollar = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[0];
    this.model.CycleRegularPriceCents = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1];
  }

  setSelectedCycleData(cycleSettingCycleDetail: FlWasherCycleSettingList) {
    const washerCycleData =
      JSON.parse(JSON.stringify(this._FlWasherModelServices.getWasherCycleList(localStorage.getItem('modelNumber'))));
    this._FlWasherModelServices.selectedWasherCycleSettings = {
      cycleValue: this.model.CycleValue,
      title: this.model.CycleTitle,
      temperature: washerCycleData.Temperature
    }
  }

  setDollarCentValues() {
    let changeValues = [
      { "dollar": 'splCycleDollars', "cent": 'splCycleCents' },
      { "dollar": 'regularPriceDollars', "cent": 'regularPriceCents' },
    ];
    changeValues.forEach(data => {
      this.FlWasherForm.get(data["dollar"]).valueChanges.subscribe(x => {
        if (x > 49) {
          this.FlWasherForm.get(data["dollar"]).setValue(50, { emitEvent: false });
          this.FlWasherForm.get(data["cent"]).setValue(0, { emitEvent: false });
        }
      })
      this.FlWasherForm.get(data["cent"]).valueChanges.subscribe(x => {
        var d = Number(this.FlWasherForm.get(data["dollar"]).value);
        if (d == 50) {
          this.FlWasherForm.get(data["cent"]).setValue(0, { emitEvent: false });
          return;
        }
        if (x > 95) {
          this.FlWasherForm.get(data["cent"]).setValue(99, { emitEvent: false });
        }
        if (x == 100 && d < 49) {
          var result = this.dollarConverts(x);
          d = d + Number(result[0]);
          this.FlWasherForm.get(data["dollar"]).setValue(d);
          this.FlWasherForm.get(data["cent"]).setValue(result[1]);
        }
      });
    });
  }

  dollarConverts(c) {
    var nCents = c;
    var nDollar = (nCents / 100);
    var rDollar = Math.floor(nDollar);
    var cents = (nDollar - rDollar) * 100;
    var rCents = Math.round(cents);
    return [rDollar, rCents]
  }


  validateSpecialPrice(value: string) {
    if (Number(this.model.CycleSpecialPriceDollar) >= 50) {
      this.model.CycleSpecialPriceCents = '0';
      this.model.CycleSpecialPriceDollar = '50';
      return false;
    }
    return true;
  }

  validateRegularPrice(value: string) {
    if (Number(this.model.CycleRegularPriceDollar) >= 50) {
      this.model.CycleRegularPriceCents = '0';
      this.model.CycleRegularPriceDollar = '50';
      return false;
    }
    return true;
  }

  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  setToFormDefault() {
    const modeltype = localStorage.getItem("ProductType");
    const cycles = this._FlWasherModelServices.getWasherCycleList(localStorage.getItem("modelNumber"));
    this.cycleSettingList = this._FlWasherModelServices.getFlWasherCycleSettings(modeltype);
    for (let index = 0; index < cycles.CycleName.length; index++) {
      this.cycleSettingCycleDetail = this.cycleSettingList[cycles.CycleName[index].tvalue];
      this.cycleSettingCycleDetail.Relational_Entity_Name.value = cycles.CycleName[index].tvalue;
      this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.cents;
      this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.dollars;
      this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.cents;
      this.cycleSettingCycleDetail.DnldCycle_OpSet_Temperature.value = this.cycleSettingCycleDetail.DnldCycle_OpSet_Temperature.default;
      this.cycleSettingCycleDetail.DnldCycle_OpSet_ExtraRinse.value = this.cycleSettingCycleDetail.DnldCycle_OpSet_ExtraRinse.default == '1' ? true : false;
      this.cycleSettingCycleDetail.hasValue = true;
      this.setDefaultDollarCents();
    }
    this._FlWasherModelServices.resetCoinValue();
  }

  onChange(check: any) {
    this.cycleSettingCycleDetail.DnldCycle_OpSet_ExtraRinse.value = check;
    this.model.ExtraRinse = check;
  }

  changeDollar(name: string, value: string) {
    switch (name) {
      case 'CycleSpecialPrice':
        this.prevValues.splDollar = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[0];
        this.prevValues.splCents = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1];
        this.model.CycleSpecialPriceDollar = this.model.CycleSpecialPriceDollar ? this.model.CycleSpecialPriceDollar : '0';
        if (!value && value != '0') {
          this.model.CycleSpecialPriceDollar = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.dollars;
          value = this.model.CycleSpecialPriceDollar;
        }
        if (Number(value) == 50) {
          this.model.CycleSpecialPriceCents = '0';
          this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1] = '0'
        }
        if (Number(value) > 50) {
          this.model.CycleSpecialPriceCents = '0';
          this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1] = '0'
          value = '50';
          this.model.CycleSpecialPriceDollar = value;
        }
        this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[0] = value;
        this.calculateSplPrice(true);
        break;
      case 'CycleRegularPrice':
        this.prevValues.regDollar = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[0];
        this.prevValues.regCents = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1];

        this.model.CycleRegularPriceDollar = this.model.CycleRegularPriceDollar ? this.model.CycleRegularPriceDollar : '0';
        if (!value && value != '0') {
          this.model.CycleRegularPriceDollar = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.dollars;
          value = this.model.CycleRegularPriceDollar;
        }
        if (Number(value) == 50) {
          this.model.CycleRegularPriceCents = '0';
          this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1] = '0';
        }
        if (Number(value) > 50) {
          this.model.CycleRegularPriceCents = '0';
          this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1] = '0'
          value = '50';
          this.model.CycleRegularPriceDollar = value;
        }
        this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[0] = value;
        this.calculateRegPrice(true);
        break;
      default:
        break;
    }
  }

  changeCents(name: string, value: string) {
    let isValid: boolean = true;
    switch (name) {
      case 'CycleSpecialPrice':
        this.prevValues.splDollar = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[0];
        this.prevValues.splCents = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1];
        this.model.CycleSpecialPriceCents = this.model.CycleSpecialPriceCents ? this.model.CycleSpecialPriceCents : '0';
        isValid = this.validateSpecialPrice(value);
        if (!isValid) {
          this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.model.CycleSpecialPriceDollar;
          this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.model.CycleSpecialPriceCents;
          return;
        }
        if (!value && value != '0') {
          this.model.CycleSpecialPriceCents = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.cents;
          value = this.model.CycleSpecialPriceCents;
        }
        this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1] = value;
        this.calculateSplPrice(false);
        break;
      case 'CycleRegularPrice':
        this.prevValues.regDollar = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[0];
        this.prevValues.regCents = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1];
        this.model.CycleRegularPriceCents = this.model.CycleRegularPriceCents ? this.model.CycleRegularPriceCents : '0';
        isValid = this.validateRegularPrice(value);
        if (!isValid) {
          this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.model.CycleRegularPriceDollar;
          this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.model.CycleRegularPriceCents;
          return;
        }
        if (!value && value != '0') {
          this.model.CycleRegularPriceCents = this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.cents;
          value = this.model.CycleRegularPriceCents;
        }
        this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1] = value;
        this.calculateRegPrice(false);
        break;
      default:
        break;
    }
  }

  calculateRegPrice(isDollar?: boolean) {
    const dollar = this._FlWasherModelServices.coin1Value.dollar == '' ? '0' : this._FlWasherModelServices.coin1Value.dollar;
    const cents = this._FlWasherModelServices.coin1Value.cent == '' ? '0' : this._FlWasherModelServices.coin1Value.cent;
    const price = (+dollar * 100) + +cents;
    const priceCalc = (+this.model.CycleRegularPriceDollar * 100) + +this.model.CycleRegularPriceCents;
    let calPrice = 0;
    let i = 1;
    let isBreakCond: boolean = false;
    if (!price) {
      this.model.CycleRegularPriceDollar = '0';
      this.model.CycleRegularPriceCents = '0';
      this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[0] = '0';
      this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1] = '0';
      return;
    }
    if (isDollar && this.prevValues.regDollar <= this.model.CycleRegularPriceDollar) {
      while (priceCalc > calPrice) {
        calPrice = price * i;
        i = i + 1;
      }
    } else if (isDollar && this.prevValues.regDollar > this.model.CycleRegularPriceDollar) {
      while (priceCalc > calPrice) {
        if (priceCalc == calPrice) {
          isBreakCond = true;
          break;
        }
        calPrice = price * i;
        i = i + 1;
      };
    } else if (!isDollar && this.prevValues.regCents <= this.model.CycleRegularPriceCents) {
      while (priceCalc > calPrice) {
        calPrice = price * i;
        i = i + 1;
      }
    } else if (!isDollar && this.prevValues.regCents > this.model.CycleRegularPriceCents) {
      while (priceCalc > calPrice) {
        if (priceCalc == calPrice) {
          isBreakCond = true;
          break;
        }
        calPrice = price * i;
        i = i + 1;
      }
      if (isBreakCond == false) {
        calPrice = calPrice - price;
      }
    }
    if (calPrice > 5000) {
      calPrice = calPrice - price;
    }
    const value = this._FlWasherModelServices.dollarConverts(calPrice);
    this.model.CycleRegularPriceDollar = value[0].toString();
    this.model.CycleRegularPriceCents = value[1].toString();
    this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[0] = value[0].toString();
    this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleRegularPrice.value[1] = value[1].toString();
  }

  calculateSplPrice(isDollar?: boolean) {
    const dollar = this._FlWasherModelServices.coin1Value.dollar == '' ? '0' : this._FlWasherModelServices.coin1Value.dollar;
    const cents = this._FlWasherModelServices.coin1Value.cent == '' ? '0' : this._FlWasherModelServices.coin1Value.cent;
    const price = (+dollar * 100) + +cents;
    const priceCalc = (+this.model.CycleSpecialPriceDollar * 100) + +this.model.CycleSpecialPriceCents;
    let calPrice = 0;
    let i = 1;
    let isBreakCond: boolean = false
    if (!price) {
      this.model.CycleSpecialPriceDollar = '0';
      this.model.CycleSpecialPriceCents = '0';
      this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[0] = '0';
      this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1] = '0';
      return;
    }
    if (isDollar && this.prevValues.splDollar <= this.model.CycleSpecialPriceDollar) {
      while (priceCalc > calPrice) {
        calPrice = price * i;
        i = i + 1;
      }
    } else if (isDollar && this.prevValues.splDollar > this.model.CycleSpecialPriceDollar) {
      while (priceCalc > calPrice) {
        if (priceCalc == calPrice) {
          isBreakCond = true;
          break;
        }
        calPrice = price * i;
        i = i + 1;
      }
    } else if (!isDollar && this.prevValues.splCents <= this.model.CycleSpecialPriceCents) {
      while (priceCalc > calPrice) {
        calPrice = price * i;
        i = i + 1;
      }
    } else if (!isDollar && this.prevValues.splCents > this.model.CycleSpecialPriceCents) {
      while (priceCalc > calPrice) {
        if (priceCalc == calPrice) {
          isBreakCond = true;
          break;
        }
        calPrice = price * i;
        i = i + 1;
      }
      if (isBreakCond == false) {
        calPrice = calPrice - price;
      }
    }
    if (calPrice > 5000) {
      calPrice = calPrice - price;
    }
    const value = this._FlWasherModelServices.dollarConverts(calPrice);
    this.model.CycleSpecialPriceDollar = value[0].toString();
    this.model.CycleSpecialPriceCents = value[1].toString();
    this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[0] = value[0].toString();
    this.cycleSettingCycleDetail.DnldCycle_PaySet_CycleSpecialPrice.value[1] = value[1].toString();
  }

  ngOnDestroy() {
    const isModel = this._FlWasherModelServices.modelChanged();
    if (!isModel) {
      this._FlWasherModelServices.setTlWasherCycleSettingModelList(this.cycleSettingCycleDetail);
    }
  }
}