import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    public currentUser;
    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        localStorage.removeItem('CurrentURL');
        localStorage.setItem('CurrentURL', window.location.href);
        const CurrentURL = window.location.href;
        if (CurrentURL.includes('create-password')) {
            this.router.navigate(['/create-password']);
            return;
        }
        if (CurrentURL.includes('reset-password')) {
            this.router.navigate(['/reset-password']);
            return;
        }
        this.currentUser = JSON.parse(localStorage.getItem("currentUserToken"));
        if (this.currentUser) {
            return true;
        }
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}