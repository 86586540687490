import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { AuthenticationService } from './AuthenticationService';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as xlsx from 'xlsx-js-style';

@Injectable({
  providedIn: 'root'
})
export class auditReportService {
  requestURL: string = '';
  requestHeaderData = new Array<any>();

  constructor(
    private http: HttpClient,
    private _authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) { }

  getData(requestURL, requestParam?: any): Observable<any> {
    this.requestURL = requestURL;
    return this._authenticationService.callGetAPI(this.requestURL, this.requestHeaderData);
  }

  //Export Audit Report Data      
  exportAsExcelFile(json: any[], excelFileName: string) {
    var Heading = ["Model No", "Serial No", "Revenue", "No. of Cycle", "Revenue / No_Of_Cycle"];
    const worksheet: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const workbook = {
      Sheets: { 'Data': worksheet }, SheetNames: ['Data'],
    };

    xlsx.utils.sheet_add_json(worksheet, json, { skipHeader: true, origin: 'A2', cellStyles: true });
    xlsx.utils.sheet_add_aoa(worksheet, [Heading]);
    var wscols = [
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 25 }
    ];
    worksheet['!cols'] = wscols;
    for (var i in worksheet) {
      console.log(worksheet[i]);
      if (typeof worksheet[i] != 'object') continue;
      let cell = xlsx.utils.decode_cell(i);
      worksheet[i].s = {
        // styling for all cells
        font: {
          name: 'Calibri',
          sz: "11",
        },
        alignment: {
          wrapText: '0', // any truthy value here
        },
        border: {
          right: {
            style: 'thin',
            color: '000000',
          },
          left: {
            style: 'thin',
            color: '000000',
          },
          bottom: {
            style: 'thin',
            color: '000000',
          }
        },
      };
      if (cell.c != 3) {
        worksheet[i].s.numFmt = '$#,##0.00';
      }

      if (cell.r == 0) {
        // first row
        worksheet[i].s = {
          alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: '1', // any truthy value here
          },
          border: {
            right: {
              style: 'thin',
              color: '000000',
            },
            left: {
              style: 'thin',
              color: '000000',
            },
            bottom: {
              style: 'thin',
              color: '000000',
            }
          },
        }
        worksheet[i].s.font = {
          bold: true,
          name: 'Calibri',
          sz: "12"
        }
        worksheet[i].s.fill = {
          // background color
          patternType: 'solid',
          bgColor: { rgb: 'ffffff' },
          fgColor: { rgb: 'cbd5ed' }
        };
        worksheet[i].s.border.bottom = {
          // bottom border
          style: 'thin',
          color: '000000',
        };
      }
    }
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  showMessage(message, isError) {
    if (!isError) {
      this.toastr.success(message, 'Success', {
        positionClass: 'toast-top-right'
      });
    } else {
      this.toastr.error(message, 'Failed', {
        positionClass: 'toast-top-right'
      });
    }
  }
}