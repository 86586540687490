import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModelServices } from 'src/app/services/ModelServices';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ISaveConfig } from '../pages/upload-machine-config/upload-machine-config.model';
import { AppSettings } from '../shared/constant/AppSettings';
import { NovoFileNames } from '../shared/enums/enum';
import { IGetUserDefinedConfiguration } from '../shared/layout/user-defined-setup/user-defined-setup.model';
import { ICommonModel, INovoEcoModels } from '../shared/models/common';
import { Cycle } from '../shared/models/cycle';

@Injectable({
  providedIn: 'root'
})
export class CommonServices {
  private subTopFeaturesDisable = new Subject<any>();
  disbleUSB = new Subject<any>();
  public disableTopFeaure = new Subject<any>();
  private subLeftMenuHide = new Subject<any>();
  private subShowButtons = new Subject<any>();
  private subCycleSettingValues = new Subject<any>();
  private apiChange = new Subject<any>();
  paraHeader = new Subject<any>();
  generateButtonHide = new Subject<any>();
  private apiChangeTwo = new Subject<any>();

  private setUsbPage = new Subject<string>();
  private HomeLeftPage = new Subject<any>();
  selectedCycle = new Subject<Cycle>();

  defaultScreen = new Subject<any>();
  selectedWash = new Subject<any>();
  currentCycle = new Subject();

  noDrainChange: string;
  selCycleSetting = new Cycle();
  currCycleSetting = new Cycle();
  closeAllMenu = new Subject<any>();
  homeScreen = new Subject<any>();
  selectModel = new Subject<ICommonModel>();
  selectTab = new Subject<any>();
  selectModelTab = new Subject<any>();
  isSetupTabSelected = new Subject<any>();
  isCSPSetupTabSelected = new Subject<any>();
  novoBinaryFiles: any[] = [];
  cspBinaryFiles: any[] = [];
  tlDryerBinaryFiles: any[] = [];
  tlWasherBinaryFiles: any[] = [];
  flDryerBinaryFiles: any[] = [];
  flWasherBinaryFiles: any[] = [];
  setBinaryCSP = new Subject<ICommonModel>();
  setBinaryTlDryer = new Subject<ICommonModel>();
  setBinaryTlWasher = new Subject<any>();
  setBinaryFlWasher = new Subject<any>();
  setBinaryFlDryer = new Subject<any>();
  public isModelSerial: boolean = false;
  public novoEcoModels: INovoEcoModels = {
    setupConfig: '',
    hmiConfig: '',
    cycleConfig: '',
    vendingConfig: ''
  };
  subSetUpValues = new Subject<any>();
  subHmiSetUpValues = new Subject<any>();
  subVendingSettingValues = new Subject<any>();
  isUserDefinedScreen = new Subject<boolean>();
  setVendingSetting = new Subject<any>();
  isNovoModelSelected = new Subject<IGetUserDefinedConfiguration>();
  isEchoModelSelected = new Subject<IGetUserDefinedConfiguration>();
  isTlDryerModel = new Subject<IGetUserDefinedConfiguration>();
  isTlWasherModel = new Subject<IGetUserDefinedConfiguration>();
  isFlDryerModel = new Subject<IGetUserDefinedConfiguration>();
  isFlWasherModel = new Subject<IGetUserDefinedConfiguration>();
  isSetToDefaultHide: boolean = false;
  isConfigSave = new Subject<boolean>();
  isHideDeleteButton = new Subject<boolean>();
  getSaveConfigModelList = new Subject<string>();
  setCycleNumber = new Subject<string>();
  defaultCycleNumber = {
    Default: '1',
    Max: "0",
    Min: '1'
  };

  public userDefinedConfig: IGetUserDefinedConfiguration = {
    companyId: '',
    accountId: '',
    name: '',
    modelNo: '',
    configId: '',
    cycleConfig: '',
    hmiConfig: '',
    setupConfig: '',
    vendingConfig: ''
  };

  public saveConfigData: ISaveConfig = {
    configNumber: '',
    modelNumber: ''
  };

  constructor(
    private http: HttpClient,
    private modelServices: ModelServices
  ) { }

  getDataUsb() {
    return this.http.get(AppSettings.dummyURL);
  }

  setSetUpValue(): any {
    this.subSetUpValues.next();
  }

  getSetUpValue(): Observable<any> {
    return this.subSetUpValues.asObservable();
  }

  setHmiSetUpValue() {
    this.subHmiSetUpValues.next();
  }

  getHmiSetUpValue(): Observable<any> {
    return this.subHmiSetUpValues.asObservable();
  }

  setVendingSettingValue(): any {
    this.subVendingSettingValues.next();
  }

  getVendingSettingValue(): Observable<any> {
    return this.subVendingSettingValues.asObservable();
  }

  getMaxWashCycleVended(array) {
    return Math.max.apply(Math, array.map(function (o) { return o.value; }));
  }

  getSecondMaxWashCycleVended(array) {
    let max = -Infinity, result = -Infinity;
    for (const value of array) {
      const nr = Number(value.value)

      if (nr > max) {
        [result, max] = [max, nr] // save previous max
      } else if (nr < max && nr > result) {
        result = nr; // new second biggest
      }
    }

    return result;

  }

  dollarConverts(c) {
    var nCents = c;
    var nDollar = (nCents / 100);
    var rDollar = Math.floor(nDollar);

    var cents = (nDollar - rDollar) * 100;
    var rCents = Math.round(cents);
    return [rDollar, rCents]
  }

  setCycleSettingValue() {
    this.subCycleSettingValues.next();
  }

  getCycleSettingValue(): Observable<any> {
    return this.subCycleSettingValues.asObservable();
  }

  getLoadingBar(msg) {
    let timerInterval
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      title: msg,
      grow: 'true',
      // imageUrl: '/assets/images/progress.jpg',
      width: 400,
      height: 200,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },

      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })
  }

  getClose() {
    Swal.fire({
      didOpen: function () {
        Swal.showLoading()
        // AJAX request simulated with setTimeout
        setTimeout(function () {
          Swal.close()
        }, 10)
      }
    })
  }

  getModelSettingForTabSelection(msg) {
    Swal.fire({
      title: "<h5 style='color: midnightblue;   font-weight: 600;   font-size:60%;'>" + msg + "</h5>",
      width: '300px',
      height: '300px',
      // imageUrl: '/assets/images/Maytag-Brand-Logo.png',
      didOpen: function () {
        Swal.showLoading()
        // AJAX request simulated with setTimeout
        setTimeout(function () {
          Swal.close()
        }, 3000)
      }
    })
  }

  getProgressClose(msg) {
    Swal.fire({
      title: "<h5 style='color: midnightblue;   font-weight: 600;   font-size:60%;'>" + msg + "</h5>",
      width: '300px',
      height: '300px',
      // imageUrl: '/assets/images/Maytag-Brand-Logo.png',
      didOpen: function () {
        Swal.showLoading()
        // AJAX request simulated with setTimeout
        setTimeout(function () {
          Swal.close()
        }, 2000)
      }
    })
  }

  sendTopFeaturesDisable(modelNumber: any) {
    this.subTopFeaturesDisable.next(this.modelServices.getProductType(modelNumber));
  }

  getTopFeaturesDisable(): Observable<any> {
    return this.subTopFeaturesDisable.asObservable();
  }

  sendLeftMenuHide() {
    this.subLeftMenuHide.next();
  }

  getLeftMenuHide(): Observable<any> {
    return this.subLeftMenuHide.asObservable();
  }

  showButtons() {
    this.subShowButtons.next();
  }

  getShowButtons(): Observable<any> {
    return this.subShowButtons.asObservable();
  }

  sendApiVal() {
    this.apiChange.next(false);
    this.apiChangeTwo.next(true);
  }

  receivedApiVal(): Observable<any> {
    return this.apiChange.asObservable();
  }

  sendApiValTwo() {
    this.apiChangeTwo.next(false);
    this.apiChange.next(true);
  }

  receivedApiValTwo(): Observable<any> {
    return this.apiChangeTwo.asObservable();
  }

  sendUsbVal(moduleName: string) {
    this.setUsbPage.next(moduleName);
    // this.HomeLeftPage.next(false);
  }

  hideLeftMenu(moduleName: string) {
    this.setUsbPage.next(moduleName);
    // this.HomeLeftPage.next(true);
  }

  receivedsendUsbVal(): Observable<any> {
    return this.setUsbPage.asObservable();
  }

  sendUsbValTwo(moduleName: string) {
    //this.HomeLeftPage.next(true);
    this.setUsbPage.next(moduleName);
  }

  clearSubscription() {
    this.subHmiSetUpValues.observers.length = 0;
    this.noDrainChange = "1";
  }

  setBinaryData(novoFiles) {
    this.novoBinaryFiles = novoFiles;
  }

  getBinaryData(fileName: NovoFileNames) {
    const file = this.novoBinaryFiles.filter(e => {
      if (e.name.toLowerCase() === fileName.toLowerCase()) {
        return e
      }
    });
    return file;
  }
}