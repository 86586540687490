import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { AuthenticationService } from 'src/app/services/AuthenticationService';
import { KeyboardSelectionEnum } from 'src/app/shared/enums/enum';
import { ErrorList, ParentErrorStateMatcher, PasswordValidator } from './create-password.model';
import { CreatePasswordService } from './create-password.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
})
export class CreatePasswordComponent implements OnInit {
  @ViewChild('registrationSuccess', { static: false }) templateRef: TemplateRef<any>;

  public createPasswordForm: FormGroup;
  public keyboardSelectionEnum = KeyboardSelectionEnum;
  public matching_passwords_group: FormGroup;
  public validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid mail' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Confirm password is required' },
      { type: 'areEqual', message: 'Password mismatch' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 8 characters long' },
      {
        type: 'pattern', message: `Your password must contain at least one uppercase, one lowercase, one number,
      and one special character` }
    ],
    'terms': [
      { type: 'pattern', message: 'You must accept terms and conditions' }
    ]
  };
  public isSpecifyDisable: boolean = true;
  public errorList = new ErrorList();
  public parentErrorStateMatcher = new ParentErrorStateMatcher();
  public token: string = '';
  public dialogRef: any;
  public isLinkExpire: boolean = false;
  public clientToken: string = '';
  public validity: string = '';
  public decrypt = new Map();
  encrptedRoleId: string;
  encrptedMail: string;
  encrptedAccountid: string;
  encrptedRoleName: string;
  public showPassword: boolean;
  public showConfirmPassword: boolean;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private _createPasswordService: CreatePasswordService,
    private _authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.getFormFields();
    const CurrentURL = localStorage.getItem('CurrentURL');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserToken');
    this.splitURL(CurrentURL);
  }

  splitURL(url: string) {
    this.decrypt.clear();
    const splitURL = url.split('/');
    if (splitURL.length > 8) {
      this.encrptedRoleName = splitURL[10];
      this.clientToken = splitURL[9].replace(/@/g, '.');
      this.validity = splitURL[8];
      this.encrptedRoleId = splitURL[7];
      this.encrptedMail = splitURL[6];
      this.encrptedAccountid = splitURL[5];
      this.validateToken(this.validity);
    } else {
      this.navigateToLogin();
    }
  }

  get passwordInput() { return this.matching_passwords_group.get('password'); }

  openDialog(mytemplate) {
    const dialogRef = this.dialog.open(mytemplate);
  }

  openPrivacyDialog(mytemplate) {
    this.dialogRef = this.dialog.open(mytemplate);
  }

  openRegistrationSuccess(mytemplate?) {
    this.dialogRef = this.dialog.open(mytemplate, {
      disableClose: true,
      width: '530px',
      height: '230px'
    });
  }

  close() {
    this.dialogRef.close();
  }

  clearForm() {
    this.createPasswordForm.reset();
    Object.keys(this.createPasswordForm.controls).forEach(key => {
      this.createPasswordForm.get(key).setErrors(null);
    });
  }

  getFormFields() {
    this.matching_passwords_group = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$') //this is for the letters (both uppercase and lowercase) and numbers validation
      ])),
      confirm_password: new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => {
      return PasswordValidator.areEqual(formGroup)
    });

    // user details form validations
    this.createPasswordForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      matching_passwords: this.matching_passwords_group,
      terms: new FormControl(false, Validators.pattern('true'))
    })
  }

  onSubmit(form: FormGroup) {
    const email = this.decrypt.get('Email');
    if (!form.valid) {
      return;
    } if (!email) {
      this._createPasswordService.showMessage(this.errorList.msgMandotary, true);
      return
    }
    const url = `${'/api/v1/client_auth/addCLUser/' + this.validity}`;
    const payload = {
      account: {
        userRole: this.decrypt.get('RoleId'),
        email: email,
        password: form.value.matching_passwords.password
      },
      handheldFpPrivelegesId: -1,
      handheldViewPrivelegesId: -1,
      all: false,
      monitor: false,
      setup: false,
      collect: false,
      accountId: this.decrypt.get('AccountId'),
    }

    this._createPasswordService.postCreatePassword(url, JSON.stringify(payload), this.clientToken).subscribe(res => {
      // this._createPasswordService.showMessage(res, false);
      this.openRegistrationSuccess(this.templateRef);
    }, err => {
      this._createPasswordService.showMessage(err ? err : 'Unknown Error!!', true);
    })
  }

  validateToken(token) {
    const url = `${'/public/v1/client_auth/validatetoken/' + token}`;
    this._createPasswordService.getCreatePassword(url).subscribe(res => {
      if (res.tokenverification == 'Passed') {
        this.isLinkExpire = false;
        this.decryptData('Email', this.encrptedMail);
        this.decryptData('AccountId', this.encrptedAccountid);
        this.decryptData('RoleId', this.encrptedRoleName);
        return;
      } else {
        this.isLinkExpire = true;
      }
    }, err => {
      this._createPasswordService.showMessage(err, true);
    })
  }

  decryptData(fieldName: string, data: string) {
    const url = `${'/public/v1/client_auth/decryptEmail/' + data}`;
    this._createPasswordService.getCreatePassword(url).subscribe((res: string) => {
      this.decrypt.set(fieldName, res)
      if (fieldName == 'Email') {
        this.createPasswordForm.patchValue({ email: res });
      }
    }, err => {
      const errMessage = `${'Error in decrypt ' + fieldName + '.'}`
      this._createPasswordService.showMessage(err, true);
    })
  }

  regenerateLink() {
    const createURL = `${this.encrptedAccountid + '/' + this.encrptedMail + '/' + this.encrptedRoleId + '/' + this.encrptedRoleName}`
    const url = `${'/public/v1/client_auth/accounts/generatetoken/' + createURL}`;
    this._createPasswordService.getCreatePassword(url).subscribe((res: string) => {
      this._createPasswordService.showMessage(res, false);
    }, err => {
      this._createPasswordService.showMessage(err, true);
    })
  }

  navigateToLogin() {
    localStorage.clear();
    this._authenticationService.logout();
    this.router.navigate(['/login']);
    if (this.dialogRef) {
      this.close();
    }
  }
}