import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { CommonServices } from 'src/app/services/CommonServices';
import { Utilities } from 'src/app/services/UtliltyService';
import { routerTransition } from '../../configs/config';
import { AuthenticationService } from '../../services/AuthenticationService';
import { AppSettings } from "../../shared/constant/AppSettings";
import { MessageList } from "./login.model";
import { LoginService } from "./login.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [routerTransition()],
  host: { '[@routerTransition]': '' },
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isLogout: any;
  errorMessage = ''
  messageList = new MessageList();
  dialogRef: any;
  forgotPasswordResponse: any;
  validation_messages = {
    terms: [
      { type: "pattern", message: "You must accept terms and conditions" },
    ],
  };
  public showPassword: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private commonService: CommonServices,
    private _loginService: LoginService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      terms: [false, Validators.pattern("true")],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.isLogout = this.route.snapshot.queryParams.logout;
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    const isValid = this.validateForm();
    if (!isValid) {
      return;
    }
    this.commonService.getLoadingBar(Utilities.ProgressMessage);
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.authenticationService.getUserDetail(data.access_token)
            .pipe(first())
            .subscribe(
              data => {
                this.commonService.getClose();
                this.router.navigate(['/']);
                //this.router.navigate([this.returnUrl]);
              },
              error => {
                this.commonService.getClose();
                console.log('Error :' + error.message);
                this.loading = false;
              });
        },
        error => {
          this.commonService.getClose();
          if (error && error.message) {
            console.log('Error :' + error.message);
          }
          this.loading = false;
          this.toastr.error(Utilities.InvalidCredMessage, 'Failed', {
            positionClass: 'toast-top-right'
          });
        });
  }

  validateForm(): boolean {
    if (this.loginForm.invalid) {
      if (this.loginForm.value.username && this.loginForm.value.password && !this.loginForm.value.terms) {
        this.toastr.error(this.messageList.msgAcceptTerms, 'Failed', {
          positionClass: 'toast-top-right'
        });
      } else {
        this.toastr.error(Utilities.InvalidCredMessage, 'Failed', {
          positionClass: 'toast-top-right'
        });
      }
      return false;
    }
    return true;
  }

  openDialog(mytemplate) {
    const dialogRef = this.dialog.open(mytemplate);
  }

  openPrivacyDialog(mytemplate) {
    const dialogRef = this.dialog.open(mytemplate);
  }

  close() {
    this.dialogRef.close();
  }

  verifyEmail(email, templateName) {
    const emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
    const isValid = this.formBuilder.group({
      officialEmail: [
        email,
        [Validators.required, Validators.pattern(emailPattern)],
      ],
    }).valid;
    if (!isValid) {
      this._loginService.showMessage(this.messageList.msgValidEmail, true);
      return;
    }
    let jsonBody = "";
    const requestURL = `${"/public/v1/client_auth/accounts/forgotPasswordCL/" +
      email +
      "/" +
      AppSettings.APP_NAME
      }`;
    this._loginService.postData(requestURL, jsonBody).subscribe(
      (res) => {
        if (res) {
          this._loginService.showMessage(res, false);
          // this.dialog.open(templateName);
          this.forgotPasswordResponse = res;
        }
      },
      (err) => {
        this._loginService.showMessage(err, true);
      }
    );
  }

}
