export enum KeyboardSelectionEnum {
    PositiveInteger = 0,
    Alphabets = 1,
    Numbers = 2,
    NoSpace = 3,
}

export enum MenuNames {
    MultiLoad = 'MYR/S',
    MlgMle = 'MLG / MLE',
    TLDryer = 'TL-Dryer',
    TLWasher = 'TL-Washer',
    FLDryer = 'FL-Dryer',
    FLWasher = 'FL-Washer',
}

export enum NovoFileNames {
    Machine_Config = 'Machine_Config.set',
    hmi_display_Settings = 'hmi_display_Settings.set',
    Cycle_data_Settings = 'Cycle_data_Settings.set',
    cycle_vending_Settings = 'cycle_vending_Settings.set',
}