export class Header {
  MachineType: string = '1';
  SWXXVer: string = '0';
  SWYYVer: string = '0';
  SWZZVer: string = '0';
  SAID: string = '00000000000000000000';
  SerialNumber: string = '0000000000000';
  ModelNumber: string = '';
  ModelCode: string = '0';
  ModelTrail: string = '';

  arrHeader: any = new Array();
  constructor() {
    if (localStorage.getItem("ProductType") !== undefined) {
      var MType = localStorage.getItem("ProductType");
      this.ModelNumber = localStorage.getItem("modelNumber");
      this.ModelTrail = this.getZeroToAdd();

      if (MType == 'PN')
        this.MachineType = '1';
      else
        this.MachineType = '2';

      this.arrHeader.push(this.MachineType); this.arrHeader.push(this.SWXXVer); this.arrHeader.push(this.SWYYVer); this.arrHeader.push(this.SWZZVer);
      this.arrHeader.push(this.SAID); this.arrHeader.push(this.SerialNumber); this.arrHeader.push(this.ModelNumber); this.arrHeader.push(this.ModelCode);
      this.arrHeader.push(this.ModelTrail);
      localStorage.setItem('arrHeader', this.arrHeader);
    }
  }

  getZeroToAdd() {
    var zeroTOAdd = 15 - this.ModelNumber.length;
    var value = '';
    value
    for (var i = 0; i < zeroTOAdd; i++) {
      value = value + '0';
    }
    return value;
  }
}

export class MachineConfig {
  MachineConstructType: string = '0';
  MachineType: string = '0';
  TemperatureWait: string = '0';
  AutomatiCoolDown: string = '0';
  TemperatureOverShot: string = '00';
  HeatingTimeMax: string = '0';
  FullHeating: string = '00';
  StrictTemperature: string = '0';
  TemperatureHysterisis: string = '00';
  HeavyTime: string = '0';
  SmartMotion: string = '0';
  SmartMotionSpeed: string = '0';
  OverFillDetection: string = '0';
  LiquidSoapSupply: string = '0';

  MinLevelStartSupply: string = '0';//WATER LEVEL BEFORE DISPENSING

  DrainTimeExtended: string = '0';
  DrainExtend: string = '0';
  WaterInletValves: string = '0';
  NumberOfDrains: string = '0';
  NumberOfOffBalanceRetries: string = '0';
  NumberOfTempSensors: string = '0';
  IsHeaterOn: string = '0';

  ExternalChemicalDispenser: string = '0';

  TemperatureHot: string = '0';
  TemperatureWarm: string = '0';
  TemperatureCool: string = '0';
  TemperatureCold: string = '0';
  SpinSpeedLow: string = '00';
  SpinSpeedMedium: string = '00';
  SpinSpeedHigh: string = '00';
  RecirculationDrainValve: string = '0';
  RecirculationWaterValve: string = '0';

  ExDisDetergentValve_1: string = '0';
  ExDisDetergentValve_2: string = '0';
  ExDisDetergentValve_3: string = '0';
  ExDisDetergentValve_4: string = '0';

  ExDisFabricValve_1: string = '0';
  ExDisFabricValve_2: string = '0';
  ExDisFabricValve_3: string = '0';
  ExDisFabricValve_4: string = '0';

  ExDisBleachValve_1: string = '0';
  ExDisBleachValve_2: string = '0';
  ExDisBleachValve_3: string = '0';
  ExDisBleachValve_4: string = '0';

  ExDisChemical1Valve_1: string = '0';
  ExDisChemical1Valve_2: string = '0';
  ExDisChemical1Valve_3: string = '0';
  ExDisChemical1Valve_4: string = '0';

  ExDisChemical2Valve_1: string = '0';
  ExDisChemical2Valve_2: string = '0';
  ExDisChemical2Valve_3: string = '0';
  ExDisChemical2Valve_4: string = '0';

  ExDisChemical3Valve_1: string = '0';
  ExDisChemical3Valve_2: string = '0';
  ExDisChemical3Valve_3: string = '0';
  ExDisChemical3Valve_4: string = '0';

  ExDisChemical4Valve_1: string = '0';
  ExDisChemical4Valve_2: string = '0';
  ExDisChemical4Valve_3: string = '0';
  ExDisChemical4Valve_4: string = '0';

  ExDisChemical5Valve_1: string = '0';
  ExDisChemical5Valve_2: string = '0';
  ExDisChemical5Valve_3: string = '0';
  ExDisChemical5Valve_4: string = '0';

  SanitaryTemperature: string = '0';
  SanitaryTime: string = '0';
  
  constructor() {
    var value = '';
    var MType = localStorage.getItem("ProductType");
    var ModelType = localStorage.getItem("ModelType");
    var Weight = parseInt(localStorage.getItem("Weight"));

    if (MType == 'PN')
      this.MachineConstructType = '1';
    else
      this.MachineConstructType = '2';

    this.MachineType = this.getMachineType(ModelType, Weight);
  }
  getMachineType(ModelType: any, Weight: number) {
    var value = '';
    if (ModelType == 'YS') {
      if (Weight == 20)
        value = '\0';
      else if (Weight == 30)
        value = '1';
      else if (Weight == 40)
        value = '2';
      else if (Weight == 55)
        value = '3';
      else if (Weight == 65)
        value = '4';
      else if (Weight == 80)
        value = '5';
      else if (Weight == 130)
        value = '6';
      else if(Weight > 130)
        value = '15';      
    }
    else {
      if (Weight == 20)
        value = '7';
      else if (Weight == 25)
        value = '8';
      else if (Weight == 30)
        value = '9';
      else if (Weight == 40)
        value = '10';
      else if (Weight == 55)
        value = '11';
      else if (Weight == 65)
        value = '12';
      else if (Weight == 80)
        value = '13';
      else if (Weight == 135)
        value = '14';
      else if (Weight > 135)
        value = '15';
    }
    return value;
  }
}

export class HmiDisplaySettings {
  screensaver: string = '0';// Screen Saver Mode
  cyclecounter: string = '0';// Cycle Counter
  time_ispm: string = '0';//
  defaultcycleshow: string = '0';// Default Cycle Screen
  cyclenumber: string = '0';// Default Cycle Number --- UInt
  HMI_DISPLAY_DEFAULT_SCREEN_TYPE: string = '0';// defaultscreen;
  HMI_DISPLAY_LANGUAGE_TYPE: string = '0';// Display Language
  HMI_DISPLAY_TEMPERATURE_TYPE: string = '0';// Display Temprature
  HMI_DISPLAY_BRIGHTNESS_TYPE: string = '0';// Display Percentage
  HMI_DISPLAY_COUNT_VIEWING_TYPE: string = '0';// Count Viewing
  HMI_SCREEN_TIMEDATE_TYPE: string = '0000000';// seven zeros
  additives: string = '0';// Additives Adder
  spinspeed: string = '0';// Spin Speed Adder
  adder_option_screen: string = '0';// Adder Option Screen
  water_temp: string = '0';// Water Temp Adder
  soillevel: string = '0';// Sol Level Adder
  extra_rinse: string = '0';// Extra Rinse Adder
  upgradablecycle: string = '0';// Add Garment Time (Min)
  pausetime_minutes: string = '0';// Uint8
  rfu: string = '00000000000000000000000000000000000000';//rfu 38 zeros
}

export class CycleSettings{
  NumberOfCycles:string='0';
  CycleName: string;
  PasswordProtected: string = '0';
  RegularPriceDoller: string = '0';
  RegularPriceCents: string = '0';
  SpecialPriceDoller: string = '0';
  SpecialPriceCents: string = '0';

  CyclePhaseSettings:CyclePhase;

  CycleIcon: string = '0';
  CycleDescription: string = '0';
  ExtraRinse: string = '0';
  SoilLevel: string = '0';
  IntervaRegularPriceCounterDoller: string = '0';
  IntervaRegularPriceCounterCent: string = '0';
  Rfu: string = '0';
}

export class CyclePhase{
  PhaseName: string = '0';
  Dispenser1: string = '0';
  Dispenser2: string = '0';
  WaterLevel: string = '0';
  Temprature: string = '0';
  ExternalChemicalSupplyTime_1: string = '0';
  ExternalChemicalSupplyTime_2: string = '0';
  ExternalChemicalSupplyTime_3: string = '0';
  ExternalChemicalSupplyTime_4: string = '0';
  Sanitory: string = '0';
  standard: string = '0';
  WaterReclamation: string = '0';
  TumbleSpeed1: string = '0';
  MotorOn: string = '0';
  MotorOff: string = '0';
  TumbleTime: string = '0';
  DrainValve: string = '0';
  SpinSpeed: string = '0';
  DrainTime: string = '0';
  ExternalChemicalSupply: string = '0';

}