import { Injectable } from "@angular/core";
import { IMachineConfigList } from "src/app/pages/upload-machine-config/upload-machine-config.model";
import { ICommonModel, ICylclesDataList } from "../shared/models/common";
import { ModelServices } from "./ModelServices";
import { Utilities } from "./UtliltyService";


@Injectable({
    providedIn: "root",
})
export class UploadFile {
    machineConfigListCopy: IMachineConfigList;
    cycleData: ICylclesDataList[] = [{
        Relational_Entity_Name: '',
        Relational_Entity_Id: 0,
        DnldCycle_OpSet_SoilLevel: '',
        DnldCycle_OpSet_Temperature: '',
        CycleSpecialPrice: [],
        CycleRegularPrice: [],
        DnldCycle_OpSet_ExtraRinse: ''
    }];
    commonModel: ICommonModel = {
        ModelNumber: '',
        SerialNumber: '',
        CycleCount: 0,
        CoinMoney: '',
        CardMoney: '',
        CycleCountingEnable: 0,
        MoneyCountingOption: 0,
        CountViewing: 0,
        VendingDisplay: 0,
        CoinPaymentEnable: 0,
        DebitCardPaymentProtocol: 0,
        AutoClearEscrowEnable: 0,
        CycleRegularPrice: [],
        CycleSpecialPrice: [],
        RegularPriceCycleLen: [],
        SpecialPriceCycleLen: [],
        FixedCycleEnable: '',
        ValueCoin1: [],
        ValueCoin2: [],
        CoinSlideEnable: 0,
        ExtraRinse: false,
        CoolTemperature: [],
        WarmTemperature: [],
        HotTemperature: [],
        ExtraRinseAdder: [],
        HeavySoil: [],
        CyclesDataList: [],
        isValid: false
    }
    isCycleKeyExist: string = '';
    pointer = 0;
    cycleCount = 0;
    index: number = 0;

    constructor(
        private modelServices: ModelServices,
        private utilityService: Utilities
    ) {
        this.machineConfigListCopy = this.modelServices.getMachineConfigList();
    }

    getNovoModelName(selectedFile): ICommonModel {
        this.clearModelValue();
        this.pointer = 0;
        // const files = selectedFile[0].value;
        const files = selectedFile.value;
        let chunkLen = 4;
        this.commonModel.CyclesDataList = [];
        this.index = 1;
        while (this.pointer < files.length) {
            let value;
            let createKey = this.getKeys(files, this.pointer, chunkLen);
            if (createKey == '00000000') {
                break;
            }
            if (createKey.match("^00") && createKey.match("^00")[0] == '00') {
                break;
            }
            this.pointer = this.pointer + chunkLen;
            let isKeyExist = this.machineConfigListCopy[createKey];
            if (!isKeyExist) {
                console.error('Key not found - ' + createKey);
                this.commonModel.isValid = false;
                return this.commonModel;
            }
            let key = this.commonModel.hasOwnProperty(isKeyExist.attribute);
            if (createKey == 'FE010001') {
                this.isCycleKeyExist = createKey;
            }
            if (createKey == 'FE010001' || this.isCycleKeyExist == 'FE010001') {
                this.bindCycleList(createKey, isKeyExist, files);
                continue;
            }
            if (isKeyExist) {
                if (isKeyExist.dataType == 1) {
                    value = this.getValue(files, this.pointer, isKeyExist.dataType);
                } else if (isKeyExist.dataType == 4) {
                    const val = this.getValue(files, this.pointer, isKeyExist.dataType)
                    value = this.utilityService.convert4BytetoDecimal(val[0], val[1], val[2], val[3]);
                } else if (isKeyExist.dataType == 2) {
                    value = this.getValue(files, this.pointer, isKeyExist.dataType);
                } else if (isKeyExist.dataType == 10) {
                    value = this.getStringValue(files, this.pointer);
                    this.pointer = Number(this.pointer) + Number(value.size) + 1;
                    if (key) {
                        this.commonModel[isKeyExist.attribute] = value.key;
                    }
                    console.log(isKeyExist.attribute + ': ' + value.key);
                    continue;
                }
                if (key) {
                    this.commonModel[isKeyExist.attribute] = value;
                }
                console.log(isKeyExist.attribute + ': ' + value);
                this.pointer = Number(this.pointer) + Number(isKeyExist.dataType);
            }
        }
        this.commonModel.isValid = true;
        return this.commonModel;
    }

    bindCycleList(createKey, isKeyExist, files) {
        let cycleKey = this.cycleData[0].hasOwnProperty(isKeyExist.attribute);
        let key = this.commonModel.hasOwnProperty(isKeyExist.attribute);
        let value;
        if (isKeyExist) {
            if (isKeyExist.dataType == 1) {
                value = this.getValue(files, this.pointer, isKeyExist.dataType);
                if (createKey == 'FE010003') {
                    this.cycleCount = value;
                }
            } else if (isKeyExist.dataType == 4) {
                const val = this.getValue(files, this.pointer, isKeyExist.dataType)
                value = this.utilityService.convert4BytetoDecimal(val[0], val[1], val[2], val[3]);
            } else if (isKeyExist.dataType == 2) {
                value = this.getValue(files, this.pointer, isKeyExist.dataType);
            } else if (isKeyExist.dataType == 10) {
                value = this.getStringValue(files, this.pointer);
                this.pointer = Number(this.pointer) + Number(value.size) + 1;
                if (key) {
                    this.commonModel[isKeyExist.attribute] = value.key;
                }
                if (cycleKey) {
                    this.cycleData[0][isKeyExist.attribute] = value.key;
                }
                console.log(isKeyExist.attribute + ': ' + value.key);
                return;
            }
            if (key) {
                this.commonModel[isKeyExist.attribute] = value;
            }
            if (cycleKey) {
                this.cycleData[0][isKeyExist.attribute] = value;
            }
            console.log(isKeyExist.attribute + ': ' + value);
            this.pointer = Number(this.pointer) + Number(isKeyExist.dataType);
            if (createKey == 'FE010002') {
                this.isCycleKeyExist = '';
                this.commonModel.CyclesDataList.push(
                    JSON.stringify(this.cycleData[0])
                );
            }
        }
    }

    getKeys(arr: any, index, chunkLen: number) {
        let key = [];
        let item;
        for (var i = 0; i < chunkLen; i++) {
            if (Number(arr[index]) < 10) {
                item = ('0' + arr[index]).slice(-2);
            } else {
                item = arr[index];
            }
            if (Number(item) > 9) {
                const hexValue = Number(item).toString(16).toUpperCase();
                item = ('0' + hexValue).slice(-2);
            }
            key.push(item);
            index++;
        }
        return `${key.join("")}`;
    }

    getValue(arr: any, index, dataType: number) {
        let key = [];
        if (dataType == 2) {
            for (var i = 0; i < dataType; i++) {
                key.push(this.calculateBytes(arr[index], dataType));
                index++;
            }
            return key;
        } else {
            for (var i = 0; i < dataType; i++) {
                key.push(this.calculateBytes(arr[index], dataType));
                index++;
            }
            return key;
        }
    }

    getStringValue(arr: any, index) {
        let key: number[] = [];
        let i;
        for (i = 0; i < 50; i++) {
            if (Number(arr[index]) === 0) {
                break;
            }
            const item = arr[index];
            key.push(item);
            index++;
        }
        const char = String.fromCharCode(...key);
        const obj = {
            key: char,
            size: i
        }
        return obj;
    }

    calculateBytes(inputNum: string | number, type: number) {
        let arr = [];
        switch (Number(type)) {
            case 2:
                return inputNum;
            case 1:
                return inputNum;
            case 4:
                return inputNum;
        }
    }

    clearModelValue() {
        this.commonModel = {
            ModelNumber: '',
            SerialNumber: '',
            CycleCount: 0,
            CoinMoney: '',
            CardMoney: '',
            CycleCountingEnable: 0,
            MoneyCountingOption: 0,
            CountViewing: 0,
            VendingDisplay: 0,
            CoinPaymentEnable: 0,
            DebitCardPaymentProtocol: 0,
            AutoClearEscrowEnable: 0,
            CoinSlideEnable: 0,
            CycleRegularPrice: [],
            CycleSpecialPrice: [],
            RegularPriceCycleLen: [],
            SpecialPriceCycleLen: [],
            FixedCycleEnable: '',
            ValueCoin1: [],
            ValueCoin2: [],
            ExtraRinse: false,
            CoolTemperature: [],
            WarmTemperature: [],
            HotTemperature: [],
            ExtraRinseAdder: [],
            HeavySoil: [],
            isValid: false
        }
    }

}