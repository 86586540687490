import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppSettings } from "../shared/constant/AppSettings";
import { TokenDetail } from "../shared/models/TokenDetail";
import { User } from "../shared/models/User";
import { CommonServices } from "./CommonServices";


@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private userLoggedIn = new Subject<boolean>();
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  tokenDetail: TokenDetail;

  private currentUserTokenDetailSubject: BehaviorSubject<TokenDetail>;
  public currentUserTokenDetail: Observable<TokenDetail>;
  public requestHeader = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private router: Router,
    private commonService: CommonServices
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );

    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserTokenDetailSubject = new BehaviorSubject<TokenDetail>(
      JSON.parse(localStorage.getItem("currentUserToken"))
    );

    this.currentUserTokenDetail =
      this.currentUserTokenDetailSubject.asObservable();
    this.userLoggedIn.next(false);
  }

  public get currentUserValue(): TokenDetail {
    return this.currentUserTokenDetailSubject.value;
  }

  login(username: string, password: string) {
    const url = `${AppSettings.API_ENDPOINT + AppSettings.API_TOKENFUNC}`;
    const Content_Type = `${AppSettings.ContentType}`;
    const pass = `${AppSettings.password}`;
    const clientid = `${AppSettings.client_id}`;
    const clientsecret = `${AppSettings.client_secret}`;
    const myheader = new HttpHeaders()
      .set("Content-Type", Content_Type)
      .set("Access-Control-Allow-Origin", AppSettings.API_ENDPOINT);
    let body = new HttpParams();
    body = body.set("grant_type", pass);
    body = body.set("username", username);
    body = body.set("password", password);
    body = body.set("client_id", clientid);
    body = body.set("client_secret", clientsecret);
    return this.http.post<any>(url, body, { headers: myheader }).pipe(
      map((userToken) => {
        if (userToken && userToken.access_token) {
          localStorage.setItem("currentUserToken", JSON.stringify(userToken));
          this.currentUserSubject.next(userToken);
          this.userLoggedIn.next(true);
        }
        return userToken;
      })
    );
  } //time out,2159(seconds(6 hrs))--30 minutes at application level

  getUserDetail(token) {
    const url = `${AppSettings.API_ENDPOINT + AppSettings.API_DETAILSFUNC}`;
    return this.http.get<any>(url).pipe(
      map((user) => {
        if (user) {
          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      })
    );
  }

  logout() {
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.currentUserTokenDetailSubject.next(null);
    this.userLoggedIn.next(false);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  public callGetAPI(requestURL: string, requestHeaderData?: Array<any>): Observable<any> {
    try {
      this.commonService.getLoadingBar('Please Wait ..');
      const url = `${AppSettings.API_ENDPOINT + requestURL}`;
      this.requestHeader = new HttpHeaders();
      const httpOptions = { headers: this.requestHeader };
      return this.http.get(url, httpOptions).pipe(
        map((response: any) => {
          this.commonService.getClose();
          return response;
        }),
        catchError((res: any) => {
          this.commonService.getClose();
          return throwError(res);
        })
      );
    } catch (e) {
      this.commonService.getClose();
      return throwError(e);
    }
  }

  public callPostAPI(requestURL: string, requestPostData: string): Observable<any> {
    try {
      this.commonService.getLoadingBar('Please Wait ..');
      const url = `${AppSettings.API_ENDPOINT + requestURL}`;
      this.requestHeader = new HttpHeaders();
      const httpOptions = { headers: this.requestHeader };
      return this.http.post(url, requestPostData, httpOptions).pipe(
        map((response: any) => {
          this.commonService.getClose();
          return response;
        }),
        catchError((res: any) => {
          this.commonService.getClose();
          return throwError(res);
        })
      );
    } catch (e) {
      this.commonService.getClose();
      return throwError(e);
    }
  }

  public callPutAPI(requestURL: string, requestPostData: string): Observable<any> {
    try {
      this.commonService.getLoadingBar('Please Wait ..');
      const url = `${AppSettings.API_ENDPOINT + requestURL}`;
      this.requestHeader = new HttpHeaders();
      const httpOptions = { headers: this.requestHeader };
      return this.http.put(url, requestPostData, httpOptions).pipe(
        map((response: any) => {
          this.commonService.getClose();
          return response;
        }),
        catchError((res: any) => {
          this.commonService.getClose();
          return throwError(res);
        })
      );
    } catch (e) {
      this.commonService.getClose();
      return throwError(e);
    }
  }

  public callDeleteAPI(requestURL: string): Observable<any> {
    try {
      this.commonService.getLoadingBar('Please Wait ..');
      const url = `${AppSettings.API_ENDPOINT + requestURL}`;
      this.requestHeader = new HttpHeaders();
      const httpOptions = { headers: this.requestHeader };
      return this.http.delete(url, httpOptions).pipe(
        map((response: any) => {
          this.commonService.getClose();
          return response;
        }),
        catchError((res: any) => {
          this.commonService.getClose();
          return throwError(res);
        })
      );
    } catch (e) {
      this.commonService.getClose();
      return throwError(e);
    }
  }

  public callGetNoAuth(requestURL: string, requestHeaderData?: Array<any>): Observable<any> {
    try {
      this.commonService.getLoadingBar('Please Wait ..');
      const url = `${AppSettings.API_ENDPOINT + requestURL}`;
      this.requestHeader = new HttpHeaders();
      const httpOptions = { headers: this.requestHeader };
      return this.http.get(url, httpOptions).pipe(
        map((response: any) => {
          this.commonService.getClose();
          return response;
        }),
        catchError((res: any) => {
          this.commonService.getClose();
          return throwError(res);
        })
      );
    } catch (e) {
      this.commonService.getClose();
      return throwError(e);
    }
  }
  
  public callPostNoAuth(requestURL: string, requestPostData: string, token: string): Observable<any> {
    try {
      this.commonService.getLoadingBar('Please Wait ..');
      const url = `${AppSettings.API_ENDPOINT + requestURL}`;
      this.requestHeader = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${token}`)
      const httpOptions = { headers: this.requestHeader };
      return this.http.post(url, requestPostData, httpOptions).pipe(
        map((response: any) => {
          this.commonService.getClose();
          return response;
        }),
        catchError((res: any) => {
          this.commonService.getClose();
          return throwError(res);
        })
      );
    } catch (e) {
      this.commonService.getClose();
      return throwError(e);
    }
  }


}
