import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import JSZip from 'jszip';
import FileSaver from "file-saver";
import * as ModelSerialJson from "src/assets/JsonFiles/ModelSerial.json";
import { IModelSerialList } from './model-serial-number.model';
import { ToastrService } from "ngx-toastr";
import { KeyboardSelectionEnum, MenuNames } from 'src/app/shared/enums/enum';
import { IParentMenu } from 'src/app/shared/layout/modellist/modellist.model';
import { ModelServices } from 'src/app/services/ModelServices';

@Component({
  selector: 'app-model-serial-number',
  templateUrl: './model-serial-number.component.html',
  styleUrls: ['./model-serial-number.component.scss']
})
export class ModelSerialNumberComponent implements OnInit {
  @ViewChild(FormGroupDirective, { static: false }) formGroupDirective: FormGroupDirective;
  modelSerialList: IModelSerialList = (ModelSerialJson as any).default;
  modelSerialForm: FormGroup;
  keyboardSelectionEnum = KeyboardSelectionEnum;
  data = {
    modelType: '',
    modelNumber: '',
    serialNumber: '',
  };
  validation_messages = {
    modelNumber: [
      { type: "required", message: "Please select model number" },
      // { type: "pattern", message: "Length should be 12 and 15" }
    ],
    serialNumber: [
      { type: "required", message: "Please enter serial number" },
      // { type: "pattern", message: "Length should be 12 and 15" }
    ]
  };
  isRequired: boolean = true;
  isPattern: boolean = false;
  arrModelSerial = [];
  mapKeyValueWise: boolean = true;
  isSerialReadOnly: boolean = true;

  searchText: string;
  parentMenu: IParentMenu[] = [{
    id: '01',
    parentText: MenuNames.MultiLoad,
    showSubmenu: false,
    subMenuText: []
  }, {
    id: '02',
    parentText: MenuNames.MlgMle,
    showSubmenu: false,
    subMenuText: []
  }];
  modelListMenu: string = '';

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modelServices: ModelServices
  ) { }

  ngOnInit() {
    this.modelServices.selectedModelSerial.subscribe((val: any) => {
      this.formGroupDirective.reset('');
      this.data.modelNumber = val[0];
      this.modelListMenu = val[1];
      this.isSerialReadOnly = false;
      if (this.modelListMenu == MenuNames.TLWasher || this.modelListMenu == MenuNames.FLWasher || this.modelListMenu == MenuNames.MultiLoad) {
        this.data.modelType = 'C'
      } else if (this.modelListMenu == MenuNames.TLDryer || this.modelListMenu == MenuNames.FLDryer || this.modelListMenu == MenuNames.MlgMle) {
        this.data.modelType = 'M'
      }
    });
    this.getControls();
  }

  get f() {
    return this.modelSerialForm.controls;
  }

  getControls() {
    this.modelSerialForm = this.fb.group({
      modelNumber: ["", Validators.required],
      serialNumber: ["", Validators.required],
    })
  }

  generateBinary(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    const modelNumber = this.data.modelNumber.trim().length;
    const serialNumber = this.data.serialNumber.trim().length;
    if (modelNumber <= 12 && serialNumber <= 12) {
      if (this.mapKeyValueWise) {
        this.mapKeyValueWiseData();
        return;
      }
      this.mapValue();
      return
    }
    this.showMessage(`Length of Model and Serial No should be less or equals to 12`, true);
  }

  mapValue() {
    const modelNumber = this.data.modelNumber.toUpperCase().trim();
    const serialNumber = this.data.modelType.toUpperCase().trim() + this.data.serialNumber.toUpperCase().trim();
    this.arrModelSerial = [];
    for (var i = 0; i < modelNumber.length; i++) {
      this.arrModelSerial.push(modelNumber[i].charCodeAt(0).toString(10));
    }
    this.arrModelSerial.push('13');
    this.arrModelSerial.push('10');
    for (var i = 0; i < serialNumber.length; i++) {
      this.arrModelSerial.push(serialNumber[i].charCodeAt(0).toString(10));
    }
    this.generateBinaryFile();
  }

  mapKeyValueWiseData() {
    this.arrModelSerial = [];
    this.modelSerialList.ModelNumber.value = this.data.modelNumber.toUpperCase().trim();
    this.modelSerialList.SerialNumber.value = this.data.modelType.toUpperCase().trim() + this.data.serialNumber.toUpperCase().trim();
    this.modelSerialList.ModelNumber.arrKey.map(item => {
      this.arrModelSerial.push(item);
    });
    this.arrModelSerial.push('57');
    this.arrModelSerial.push('57');
    this.arrModelSerial.push('44');
    this.calculateBytes(this.modelSerialList.ModelNumber.value, this.modelSerialList.ModelNumber.type);
    this.modelSerialList.SerialNumber.arrKey.map(item => {
      this.arrModelSerial.push(item);
    });
    this.arrModelSerial.push('57');
    this.arrModelSerial.push('57');
    this.arrModelSerial.push('44');
    this.arrModelSerial.push('01');
    this.calculateBytes(this.modelSerialList.SerialNumber.value, this.modelSerialList.SerialNumber.type);
    this.generateBinaryFile();
  }

  generateBinaryFile() {
    var geneBytes = new Uint8Array(
      this.arrModelSerial
    );
    let zip = new JSZip();
    zip
      .folder('Model#Serial#')
      .file(
        `${'modelserial' + '.set'}`,
        new Blob([geneBytes], { type: "octet/stream" })
      );
    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, "" + `${'Model#Serial#.zip'}`);
    });
  }

  calculateBytes(inputNum: string | number, type: string) {
    switch (type.toLowerCase()) {
      case 'string':
        var hArray = inputNum.toString();
        for (var j = 0; j < hArray.length; j++) {
          this.arrModelSerial.push(hArray[j].charCodeAt(0).toString(10));
        }
        this.arrModelSerial.push("0");
        break;
    }
  }

  showMessage(message, isError) {
    if (!isError) {
      this.toastr.success(message, 'Success', {
        positionClass: 'toast-top-right'
      });
      return;
    }
    this.toastr.error(message, 'Failed', {
      positionClass: 'toast-top-right'
    });
  }

}