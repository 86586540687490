import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/shared/constant/AppSettings';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    public currentUser: any;

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        this.currentUser = JSON.parse(localStorage.getItem("currentUserToken"));
        if (this.currentUser && this.currentUser.access_token) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.currentUser.access_token}`
                }
            });
        }
        return next.handle(request);
    }
}
