import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UploadFile } from 'src/app/services/upload-file.service';
import { ICommonModel } from 'src/app/shared/models/common';
import { Audit, IFileValid, IMachineConfigList, IUploadReportList } from './upload-machine-config.model';
import { UploadMachineConfigService } from './upload-machine-config.service';

@Component({
  selector: 'app-upload-machine-config',
  templateUrl: './upload-machine-config.component.html',
  styleUrls: ['./upload-machine-config.component.scss']
})

export class UploadMachineConfigComponent implements OnInit {
  @ViewChild('File', { static: false }) File: ElementRef;
  machineDate: Date;
  invalidDates: Array<Date>
  uploadFile: any;
  uploadedFiles: any[] = [];
  listFile: true;
  filename: File;
  currentDate = new Date();
  machineConfigList: IMachineConfigList;

  selectedFiles: any[] = [];
  nmbrOfFiles: number;
  resultString: any;

  commonModel: ICommonModel;
  uploadReportList: IUploadReportList = {
    date: '',
    machineInfo: []
  }
  auditData: Audit;
  filesName: IFileValid[] = [];

  constructor(
    private toastr: ToastrService,
    private _reportService: UploadMachineConfigService,
    private _uploadFile: UploadFile,
  ) {
  }

  ngOnInit() {

  }

  fileChanged(e) {
    this.selectedFiles = [];
    this.filesName = [];
    this.uploadedFiles = [];
    this.nmbrOfFiles = e.target.files.length;
    for (let i = 0; i < e.target.files.length; i++) {
      this.selectedFiles.push(e.target.files[i]);
      this.filesName.push({
        name: e.target.files[i].name,
        isValid: null
      })
    }
  }

  chooseFile(event) {
    this.uploadedFiles = event.target.files;
  }

  onUploadFile() {
    if (!this.machineDate) {
      this.showError("Please select Date.");
      return;
    }
    this.selectedFiles.map((e, index) => {
      const fr = new FileReader();
      fr.onload = () => {
        var string = this.resultString != null ? this.resultString : fr.result;
        var result = new Uint8Array(string.length);
        for (var i = 0; i < string.length; i++) {
          result[i] = string.charCodeAt(i);
        }
        e['value'] = result;
        //if (index === this.selectedFiles.length) {
        this.commonModel = this._uploadFile.getNovoModelName(e);
        if (!this.commonModel.isValid) {
          const inValidFile = this.filesName.find(f => { return f.name == e.name });
          inValidFile.isValid = false;
          this.showError(inValidFile.name + 'File is not correct');
          return;
        } else {
          this.postReportData(e.name);
        }
      }
      //}
      return fr.readAsBinaryString(e);
    });
  }

  postReportData(fileName: string) {
    this.uploadReportList.machineInfo = [];
    //if (!this.commonModel.ModelNumber || !this.commonModel.SerialNumber) {
    let serial: string;
    if (!this.commonModel.SerialNumber) {
      serial = fileName.split('_')[0];
    } else {
      serial = this.commonModel.SerialNumber.replace(/[^\t\r\n -~]/gi, '');
    }
    if (!this.commonModel.ModelNumber) {
      const inValidFile = this.filesName.find(f => { return f.name == fileName });
      inValidFile.isValid = false;
      this.showError(inValidFile.name + 'Model no not exist in file');
      return;
    }
    this.auditData = {
      cycleCount: this.commonModel.CycleCount,
      coinMoney: (Math.floor(+this.commonModel.CoinMoney) / 100).toFixed(2).toString(),
      cardMoney: (Math.floor(+this.commonModel.CardMoney) / 100).toFixed(2).toString()      
    }
    this.uploadReportList.date = this.dateFormat();
    this.uploadReportList.machineInfo.push({
      audit: this.auditData,
      diagnostics: [],
      model: this.commonModel.ModelNumber,
      serial: serial
    })
    console.log(JSON.stringify(this.uploadReportList));
    const requestURL = `${'/api/v1/reports/saveUsbMachineData'}`
    this._reportService
      .postData(requestURL, JSON.stringify(this.uploadReportList))
      .subscribe(
        (res) => {
          this._reportService.showMessage(res.message, false);
          this.machineDate = null;
          this.selectedFiles = [];
        },
        (err) => {
          this._reportService.showMessage(err, true);
        }
      );
  }

  dateFormat() {
    const date: Date = this.machineDate;
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return `${year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0')}`;
  }

  clear() {
    this.uploadedFiles = [];
    this.File.nativeElement.value = null;
    this.machineDate = null;
    this.filesName = [];
    this.selectedFiles = [];
  }

  showError(message) {
    this.toastr.error(message, 'Failed', {
      positionClass: 'toast-top-right'
    });
  }

}