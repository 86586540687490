import { NgModule } from "@angular/core";
import { MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatNativeDateModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatSlideToggleModule, MatToolbarModule, MatTooltipModule } from "@angular/material";
import { TableModule } from "primeng/table";

@NgModule({
    exports: [
        MatTooltipModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatDialogModule,
        MatCardModule,
        MatSelectModule,
        MatRadioModule,
        MatCardModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        TableModule
    ]
})
export class AppMaterialModule { }