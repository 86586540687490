import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { CommonServices } from "src/app/services/CommonServices";
import { ManageUserService } from "src/app/services/manageUser.service";
import { Utilities } from "src/app/services/UtliltyService";
import { KeyboardSelectionEnum } from "src/app/shared/enums/enum";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorList } from "../create-user/create-user.model";
import { CreateUserService } from "../create-user/create-user.service";
import { ICompanyList, ICountryList, IEditUserList, userList } from "./manage-user.model";

@Component({
  selector: "app-manage-user",
  templateUrl: "./manage-user.component.html",
  styleUrls: ["./manage-user.component.scss"],
})

export class ManageUserComponent implements OnInit {
  cols: any[];
  userList: userList[] = [];
  editUserList: IEditUserList = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    countryId: '',
    phoneNo: ''
  }
  isShowModel = true;
  dialogRef: any;
  countryList: ICountryList[];
  filteredCountryList: ICountryList[];
  submitted = false;
  keyboardSelectionEnum = KeyboardSelectionEnum;
  errorList = new ErrorList();
  isHide: boolean = true;

  constructor(
    private _manageUserService: ManageUserService,
    public dialog3: MatDialog,
    private _createUserService: CreateUserService,
    private _commonService: CommonServices
  ) { }

  ngOnInit() {
    this.cols = [
      { field: 'userName', header: 'User Name' },
      { field: 'email', header: 'Email' },
      // { field: 'company', header: 'Company' },
      { field: 'country', header: 'Country' },
      { field: 'phoneNo', header: 'Phone No' },
      { field: 'action', header: 'Action', width: '25%' }
    ];
    this.getUserData();
  }

  getUserData() {
    this.isHide = true;
    this.userList = [];
    const url = '/api/v1/userDetails';
    this._commonService.getLoadingBar(Utilities.ProgressMessage);
    this._manageUserService.getData(url).subscribe((res) => {
      res.map(item => {
        this.userList.push({
          Id: item.ACCOUNT_ID,
          company: item.COMPANY_NAME,
          country: item.COUNTRY_NAME,
          email: item.EMAIL,
          phoneNo: item.PHONE_NO,
          userName: item.USERNAME
        });
      });
      this.isHide = false;
      this._commonService.getClose();
    }, (err) => {
      this._createUserService.showMessage(err, true);
    });
  }

  editUserData(rowData: userList, mytemplate) {
    this.countryList = [];
    this.filteredCountryList = [];
    const requestURL = "/public/v1/countryCL";
    this.dialogRef = this.dialog3.open(mytemplate, {
      width: "1140px",
      height: "630px",
    });
    this._createUserService.getData(requestURL).subscribe((res) => {
      this.countryList = res;
      this.filteredCountryList = this.countryList;
      this.editUserList.firstName = rowData.userName.split(' ')[0];
      this.editUserList.lastName = rowData.userName.split(' ')[1];
      this.editUserList.email = rowData.email;
      this.editUserList.companyName = rowData.company;
      this.editUserList.countryId = res.find(f => { return f.COUNTRY_NAME == rowData.country }).COUNTRY_ID;
      this.editUserList.phoneNo = rowData.phoneNo;
      this.editUserList.id = rowData.Id;
    });
  }

  saveEditForm(rowData: IEditUserList) {
    this.submitted = true;
    const payload = {
      "countryId": rowData.countryId,
      "account": {
        "accountId": this.editUserList.id,
        "firstName": rowData.firstName,
        "lastName": rowData.lastName,
        "email": this.editUserList.email,
        "phoneNo": rowData.phoneNo,
        "alternateEmail": '',
        "languageId": '1'
      },
      "accountId": this.editUserList.id
    }
    const url = "/api/v1/updateAccount";
    this._manageUserService.putData(url, JSON.stringify(payload)).subscribe((res) => {
      this._manageUserService.showMessage('User updated successfully.', false)
      this.getUserData();
      this.close();
    }, (err) => {
      this._createUserService.showMessage(err, true);
    });
  }

  close() {
    this.dialogRef.close();
  }

  deleteUserData(rowData: userList) {
    Swal.fire({
      title: '<span class="user-icons">Delete User</span>',
      html: "<p style='font-size:16px;text-align: left;'>" + "Are you sure, you want to delete user " + "<b> " + "<q style='text-transform: capitalize;'>" + rowData.userName + "</q>" + "</b>" + "</p>",
      type: 'warning',
      showConfirmButton: true,
      confirmButtonText: 'Confirm',
      showCancelButton: true,
      confirmButtonColor: "#5973b6",
      cancelButtonColor: "#5973b6",
      customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'confirm-button-class cancel-btn-class',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `${"/api/v1/deleteUser/" + rowData.Id}`;
        this._manageUserService.deleteData(url).subscribe((res) => {
          this._manageUserService.showMessage('User deleted successfully.', false)
          this.userList = this.userList.filter(f => { return Number(f.Id) != Number(rowData.Id) });
        }, (err) => {
          this._createUserService.showMessage(err, true);
        });
      }
    });
  }

}