import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as VendingSettings from "src/assets/JsonFiles/VenusCycleSettings.json";
import * as VenusModel from "src/assets/JsonFiles/VenusModel.json";
import * as VenusSetup from "src/assets/JsonFiles/VenusSetup.json";
import { ICycleSpecialPrice, IFixedCycleEnable, IRegularPriceCycleLen, IRelationalEnvelopeOpen, ISpecialPriceCycleLen, ISpecialPriceTime, ISpecialPricingEnable, ISundaySpecialPriceEnable, ITimePerCoin, IValueChecked, IValueCoin1, IVended, IVendedList } from "../shared/layout/csp/venus-cycle-vending/venus-cycle-vending.model";
import { IValue, IVenusSetup, IVenusSetupDetail, IVenusSetupList } from "../shared/layout/csp/venus-setup/venus-setup.model";
import { IGetUserDefinedConfiguration } from "../shared/layout/user-defined-setup/user-defined-setup.model";
import { ICommonModel } from "../shared/models/common";
import { CommonServices } from "./CommonServices";
import { IModelList } from "./ModelServices";
import { Utilities } from "./UtliltyService";

@Injectable({
    providedIn: "root",
})
export class VenusModelServices {
    public venusModel: IModelList = (VenusModel as any).default;
    venussetup: IVenusSetupList = (VenusSetup as any).default;
    vendingsettings: IVendedList = (VendingSettings as any).default;
    isModelChange: boolean = false;
    value: IValue = {
        id: 0,
        name: ''
    }
    regularOffSet: IVenusSetupDetail = {
        value: 0,
        values: this.value,
        Default: 0,
        arrKey: [],
        binaryKey: '',
        type: ''
    }
    setupModelList: IVenusSetup = {
        CycleCountingEnable: this.regularOffSet,
        MoneyCountingOption: this.regularOffSet,
        CountViewing: this.regularOffSet,
        VendingDisplay: this.regularOffSet,
        CoinPaymentEnable: this.regularOffSet,
        DebitCardPaymentProtocol: this.regularOffSet,
        AutoClearEscrowEnable: this.regularOffSet,
        hasValue: true
    }

    ValueChecked: IValueChecked = {
        id: 0,
        name: '',
        checked: false
    }

    regularPriceCycleLen: IRegularPriceCycleLen = {
        value: '',
        default: '',
        values: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        min: '',
        max: ''
    }

    fixedCycleEnable: IFixedCycleEnable = {
        value: '',
        default: '',
        values: [],
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    specialPricingEnable: ISpecialPricingEnable = {
        value: false,
        default: false,
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    specialPriceEnable: ISundaySpecialPriceEnable = {
        value: '',
        default: '',
        values: this.ValueChecked,
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    specialPriceTime: ISpecialPriceTime = {
        value: [],
        default: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        endHour: '',
        startHour: ''
    }

    specialPriceCycleLen: ISpecialPriceCycleLen = {
        value: '',
        default: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        max: '',
        min: ''
    }

    cycleSpecialPrice: ICycleSpecialPrice = {
        value: [],
        default: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        cents: '',
        dollars: ''
    }

    ValueCoin1: IValueCoin1 = {
        value: [],
        default: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        dollars: '',
        cents: '',
    }

    relationalEnvelopeOpen: IRelationalEnvelopeOpen = {
        binaryKeyValue: '',
        arrKey: [],
        type: ''
    }

    timePerCoinVal: ITimePerCoin = {
        value: 0,
        default: 0
    }

    vendingSettingList: IVended = {
        Relational_Envelope_Open: this.relationalEnvelopeOpen,
        Relational_Envelope_Action: this.relationalEnvelopeOpen,
        Entity_Cycle_Open: this.relationalEnvelopeOpen,
        Relational_Entity_Name: this.relationalEnvelopeOpen,
        Relational_Entity_Id: this.relationalEnvelopeOpen,
        DnldCycle_CycleSet_CycleCavityPosition: this.relationalEnvelopeOpen,
        Relational_Array_Open: this.relationalEnvelopeOpen,

        Entity_Cycle_Part1_Open: this.relationalEnvelopeOpen,
        Entity_Cycle_Part1_Close: this.relationalEnvelopeOpen,
        Entity_Cycle_Close: this.relationalEnvelopeOpen,
        Relational_Array_Close: this.relationalEnvelopeOpen,
        Relational_Envelope_Close: this.relationalEnvelopeOpen,
        DnldCycle_OpSet_RegularPriceCycleLen: this.regularPriceCycleLen,
        FixedCycleEnable: this.fixedCycleEnable,
        SpecialPricingEnable: this.specialPricingEnable,
        SundaySpecialPriceEnable: this.specialPriceEnable,
        MondaySpecialPriceEnable: this.specialPriceEnable,
        TuesdaySpecialPriceEnable: this.specialPriceEnable,
        WednesdaySpecialPriceEnable: this.specialPriceEnable,
        ThursdaySpecialPriceEnable: this.specialPriceEnable,
        FridaySpecialPriceEnable: this.specialPriceEnable,
        SaturdaySpecialPriceEnable: this.specialPriceEnable,
        SpecialPriceTime: this.specialPriceTime,
        DnldCycle_OpSet_SpecialPriceCycleLen: this.specialPriceCycleLen,
        DnldCycle_PaySet_CycleSpecialPrice: this.cycleSpecialPrice,
        DnldCycle_PaySet_CycleRegularPrice: this.cycleSpecialPrice,
        ValueCoin1: this.ValueCoin1,
        ValueCoin2: this.ValueCoin1,
        TimePerCoinVal: this.timePerCoinVal,
        hasValue: false
    }

    arrVenusSetupConfigSettings: any[] = [];
    arrVenusCycleVendingConfigSettings: any[] = [];
    commonModel: ICommonModel;

    constructor(
        private utilityService: Utilities,
        private commonService: CommonServices,
        private router: Router,
    ) {
        this.commonService.setBinaryCSP.subscribe((item: ICommonModel) => {
            this.setBinarySetupModels(item);
        })
        this.commonService.isEchoModelSelected.subscribe((item: IGetUserDefinedConfiguration) => {
            const setupModelList = JSON.parse(item.setupConfig);
            const commonModel = JSON.parse(item.vendingConfig)
            if (setupModelList.hasValue) {
                this.setModelSetupModels(setupModelList);
            }
            if (commonModel.hasValue) {
                this.setModelVenusCycleModels(commonModel);
            }
        })
    }

    getVenusModelList(): IModelList {
        return this.venusModel;
    }

    getVenusSetupSettings(modelType: string) {
        this.isModelChange = false;
        if (modelType === 'PD') {
            return this.venussetup.setup.PD;
        } else if (modelType === 'PR') {
            return this.venussetup.setup.PR;
        }
    }

    getVenusVendingSettings(modelType: string) {
        this.isModelChange = false;
        if (modelType === 'PD') {
            return this.vendingsettings.vended.PD;
        } else if (modelType === 'PR') {
            return this.vendingsettings.vended.PR;
        }
    }

    setSetupModels(setupList: IVenusSetup) {
        this.isModelChange = false;
        this.setupModelList = setupList;
    }

    getSetupModels() {
        return this.setupModelList;
    }

    setBinarySetupModels(commonModel: ICommonModel) {
        this.commonModel = commonModel;
        this.setupModelList = this.getVenusSetupSettings(localStorage.getItem("ProductType"));
        this.setupModelList.CycleCountingEnable.value = Number(commonModel.CycleCountingEnable) ? Number(commonModel.CycleCountingEnable) : 0
        this.setupModelList.MoneyCountingOption.value = Number(commonModel.MoneyCountingOption) ? Number(commonModel.MoneyCountingOption) : 0;
        this.setupModelList.CountViewing.value = Number(commonModel.CountViewing) ? Number(commonModel.CountViewing) : 0;
        this.setupModelList.VendingDisplay.value = Number(commonModel.VendingDisplay) ? Number(commonModel.VendingDisplay) : 0;
        this.setupModelList.CoinPaymentEnable.value = Number(commonModel.CoinPaymentEnable) ? Number(commonModel.CoinPaymentEnable) : 0;
        this.setupModelList.DebitCardPaymentProtocol.value = Number(commonModel.DebitCardPaymentProtocol) ? Number(commonModel.DebitCardPaymentProtocol) : 0;
        this.setupModelList.AutoClearEscrowEnable.value = Number(commonModel.AutoClearEscrowEnable) ? Number(commonModel.AutoClearEscrowEnable) : 0;
        this.setupModelList.hasValue = true;
        this.isModelChange = false;
        this.setBinaryVenusCycleModels(commonModel);
    }

    setModelSetupModels(setupModelList: any) {
        this.setupModelList = this.getVenusSetupSettings(localStorage.getItem("ProductType"));
        this.setupModelList.CycleCountingEnable.value = setupModelList.CycleCountingEnable.value;
        this.setupModelList.MoneyCountingOption.value = setupModelList.MoneyCountingOption.value;
        this.setupModelList.CountViewing.value = setupModelList.CountViewing.value;
        this.setupModelList.VendingDisplay.value = setupModelList.VendingDisplay.value;
        this.setupModelList.CoinPaymentEnable.value = setupModelList.CoinPaymentEnable.value;
        this.setupModelList.DebitCardPaymentProtocol.value = setupModelList.DebitCardPaymentProtocol.value;
        this.setupModelList.AutoClearEscrowEnable.value = setupModelList.AutoClearEscrowEnable.value;
        this.setupModelList.hasValue = true;
        this.isModelChange = false;
    }

    clearSetupModels() {
        this.setupModelList.CycleCountingEnable.value = 0;
        this.setupModelList.MoneyCountingOption.value = 0;
        this.setupModelList.CountViewing.value = 1;
        this.setupModelList.VendingDisplay.value = 0;
        this.setupModelList.CoinPaymentEnable.value = 0;
        this.setupModelList.DebitCardPaymentProtocol.value = 0;
        this.setupModelList.AutoClearEscrowEnable.value = 0;
        this.setupModelList.hasValue = false;
        this.isModelChange = true;
        return this.setupModelList;
    }

    setVenusCycleModels(venusCycleModel: IVended) {
        this.isModelChange = false;
        this.vendingSettingList = venusCycleModel;
    }

    getVenusCycleModels() {
        return this.vendingSettingList;
    }

    setBinaryVenusCycleModels(commonModel: ICommonModel) {
        let twoBytes;
        let splBytes;
        let regBytes;
        this.vendingSettingList = this.getVenusVendingSettings(localStorage.getItem("ProductType"));
        this.vendingSettingList.FixedCycleEnable.value = commonModel.FixedCycleEnable;
        const splDryTime = this.utilityService.convertU16ToDryTime(commonModel.SpecialPriceCycleLen[0], commonModel.SpecialPriceCycleLen[1]);
        this.vendingSettingList.DnldCycle_OpSet_SpecialPriceCycleLen.value = String(splDryTime);
        const regDryTime = this.utilityService.convertU16ToDryTime(commonModel.RegularPriceCycleLen[0], commonModel.RegularPriceCycleLen[1]);
        this.vendingSettingList.DnldCycle_OpSet_RegularPriceCycleLen.value = String(regDryTime);
        splBytes = this.utilityService.convertBinToDecArry(commonModel.CycleSpecialPrice[0], commonModel.CycleSpecialPrice[1]);
        this.vendingSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = splBytes[0];
        this.vendingSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = splBytes[1];
        regBytes = this.utilityService.convertBinToDecArry(commonModel.CycleRegularPrice[0], commonModel.CycleRegularPrice[1]);
        this.vendingSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = regBytes[0];
        this.vendingSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = regBytes[1];
        twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin1[0], commonModel.ValueCoin1[1]);
        this.vendingSettingList.ValueCoin1.value[0] = twoBytes[0];
        this.vendingSettingList.ValueCoin1.value[1] = twoBytes[1];
        this.calcTimePerCoin(regBytes[0], regBytes[1], twoBytes[0], twoBytes[1]);
        twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin2[0], commonModel.ValueCoin2[1]);
        this.vendingSettingList.ValueCoin2.value[0] = twoBytes[0];
        this.vendingSettingList.ValueCoin2.value[1] = twoBytes[1];
        this.vendingSettingList.hasValue = true;
    }

    setModelVenusCycleModels(commonModel: any) {
        this.vendingSettingList = this.getVenusVendingSettings(localStorage.getItem("ProductType"));
        this.vendingSettingList.FixedCycleEnable.value = commonModel.FixedCycleEnable.value;
        this.vendingSettingList.DnldCycle_OpSet_SpecialPriceCycleLen.value = commonModel.DnldCycle_OpSet_SpecialPriceCycleLen.value;
        this.vendingSettingList.DnldCycle_OpSet_RegularPriceCycleLen.value = commonModel.DnldCycle_OpSet_RegularPriceCycleLen.value;

        this.vendingSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = commonModel.DnldCycle_PaySet_CycleSpecialPrice.value[0];
        this.vendingSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = commonModel.DnldCycle_PaySet_CycleSpecialPrice.value[1];

        this.vendingSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = commonModel.DnldCycle_PaySet_CycleRegularPrice.value[0];
        this.vendingSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = commonModel.DnldCycle_PaySet_CycleRegularPrice.value[1];

        this.vendingSettingList.ValueCoin1.value[0] = commonModel.ValueCoin1.value[0];
        this.vendingSettingList.ValueCoin1.value[1] = commonModel.ValueCoin1.value[1];
        this.vendingSettingList.TimePerCoinVal.value = commonModel.TimePerCoinVal.value;

        this.vendingSettingList.ValueCoin2.value[0] = commonModel.ValueCoin2.value[0];
        this.vendingSettingList.ValueCoin2.value[1] = commonModel.ValueCoin2.value[1];
        this.vendingSettingList.hasValue = true;
    }

    clearVenusCycleModels() {
        this.vendingSettingList.DnldCycle_OpSet_RegularPriceCycleLen.value = '0';
        this.vendingSettingList.FixedCycleEnable.value = '0';
        this.vendingSettingList.SpecialPricingEnable.value = false;
        this.vendingSettingList.SundaySpecialPriceEnable.value = '0';
        this.vendingSettingList.MondaySpecialPriceEnable.value = '0';
        this.vendingSettingList.TuesdaySpecialPriceEnable.value = '0';
        this.vendingSettingList.WednesdaySpecialPriceEnable.value = '0';
        this.vendingSettingList.ThursdaySpecialPriceEnable.value = '0';
        this.vendingSettingList.FridaySpecialPriceEnable.value = '0';
        this.vendingSettingList.SaturdaySpecialPriceEnable.value = '0';
        this.vendingSettingList.SundaySpecialPriceEnable.values.checked = false;
        this.vendingSettingList.MondaySpecialPriceEnable.values.checked = false;
        this.vendingSettingList.TuesdaySpecialPriceEnable.values.checked = false;
        this.vendingSettingList.WednesdaySpecialPriceEnable.values.checked = false;
        this.vendingSettingList.ThursdaySpecialPriceEnable.values.checked = false;
        this.vendingSettingList.FridaySpecialPriceEnable.values.checked = false;
        this.vendingSettingList.SaturdaySpecialPriceEnable.values.checked = false;
        this.vendingSettingList.SpecialPriceTime.value[0] = '0';
        this.vendingSettingList.SpecialPriceTime.value[1] = '0';
        this.vendingSettingList.DnldCycle_OpSet_SpecialPriceCycleLen.value = '0';
        this.vendingSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = '0';
        this.vendingSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = '0';
        this.vendingSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = '0';
        this.vendingSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = '0';
        this.vendingSettingList.ValueCoin1.value[0] = '0';
        this.vendingSettingList.ValueCoin1.value[1] = '0';
        this.vendingSettingList.ValueCoin2.value[0] = '0';
        this.vendingSettingList.ValueCoin2.value[1] = '0';
        this.vendingSettingList.hasValue = false
        this.isModelChange = true;
        return this.vendingSettingList;
    }

    modelChanged() {
        return this.isModelChange;
    }

    mapSetupBinaryKey() {
        this.arrVenusSetupConfigSettings = [];
        const venusSetup = this.getVenusSetupSettings(localStorage.getItem("ProductType"));
        if (venusSetup) {
            if (venusSetup.hasValue) {
                venusSetup.CycleCountingEnable.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.CycleCountingEnable.value);

                venusSetup.MoneyCountingOption.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.MoneyCountingOption.value);
                venusSetup.CountViewing.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.CountViewing.value);
                venusSetup.VendingDisplay.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.VendingDisplay.value);
                venusSetup.CoinPaymentEnable.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.CoinPaymentEnable.value);
                venusSetup.DebitCardPaymentProtocol.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.DebitCardPaymentProtocol.value);
                venusSetup.AutoClearEscrowEnable.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.AutoClearEscrowEnable.value);
            } else {
                venusSetup.CycleCountingEnable.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.CycleCountingEnable.Default);

                venusSetup.MoneyCountingOption.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.MoneyCountingOption.Default);
                venusSetup.CountViewing.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.CountViewing.Default);
                venusSetup.VendingDisplay.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.VendingDisplay.Default);
                venusSetup.CoinPaymentEnable.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.CoinPaymentEnable.Default);
                venusSetup.DebitCardPaymentProtocol.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.DebitCardPaymentProtocol.Default);
                venusSetup.AutoClearEscrowEnable.arrKey.map(item => {
                    this.arrVenusSetupConfigSettings.push(item);
                })
                this.arrVenusSetupConfigSettings.push(venusSetup.AutoClearEscrowEnable.Default);
            }
        }
        return this.arrVenusSetupConfigSettings;

    }

    mapCycleVendingBinaryKey() {
        this.arrVenusCycleVendingConfigSettings = [];
        const cycleVending = this.getVenusVendingSettings(localStorage.getItem("ProductType"));
        if (cycleVending) {
            if (cycleVending.hasValue) {
                cycleVending.Relational_Envelope_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Envelope_Action.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Entity_Name.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                this.arrVenusCycleVendingConfigSettings.push("0");
                cycleVending.Relational_Entity_Id.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.DnldCycle_CycleSet_CycleCavityPosition.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.DnldCycle_PaySet_CycleRegularPrice.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleRegularPrice.value[0], cycleVending.DnldCycle_PaySet_CycleRegularPrice.value[1], cycleVending.DnldCycle_PaySet_CycleRegularPrice.type);
                cycleVending.DnldCycle_PaySet_CycleSpecialPrice.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleSpecialPrice.value[0], cycleVending.DnldCycle_PaySet_CycleSpecialPrice.value[1], cycleVending.DnldCycle_PaySet_CycleSpecialPrice.type);
                cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                const regularCycleLen = (Number(cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.value) * 60);
                this.calculateBytes(regularCycleLen, cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.type);
                cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                const splCycleLen = (Number(cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.value) * 60)
                this.calculateBytes(splCycleLen, cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.type);

                cycleVending.Relational_Array_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Array_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Envelope_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.FixedCycleEnable.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculateBytes(cycleVending.FixedCycleEnable.value, cycleVending.FixedCycleEnable.type);
                cycleVending.ValueCoin1.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin1.value[0], cycleVending.ValueCoin1.value[1], cycleVending.ValueCoin1.type);
                cycleVending.ValueCoin2.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin2.value[0], cycleVending.ValueCoin2.value[1], cycleVending.ValueCoin2.type);
            } else {
                cycleVending.Relational_Envelope_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Envelope_Action.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Entity_Name.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                this.arrVenusCycleVendingConfigSettings.push("0");
                cycleVending.Relational_Entity_Id.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.DnldCycle_CycleSet_CycleCavityPosition.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.DnldCycle_PaySet_CycleRegularPrice.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleRegularPrice.dollars, cycleVending.DnldCycle_PaySet_CycleRegularPrice.cents, cycleVending.DnldCycle_PaySet_CycleRegularPrice.type);
                cycleVending.DnldCycle_PaySet_CycleSpecialPrice.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleSpecialPrice.dollars, cycleVending.DnldCycle_PaySet_CycleSpecialPrice.cents, cycleVending.DnldCycle_PaySet_CycleSpecialPrice.type);
                cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                const regularCycleLen = (Number(cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.default) * 60)
                this.calculateBytes(regularCycleLen, cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.type);
                cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                const splCycleLen = (Number(cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.default) * 60)
                this.calculateBytes(splCycleLen, cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.type);
                cycleVending.Relational_Array_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Array_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Envelope_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.FixedCycleEnable.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculateBytes(cycleVending.FixedCycleEnable.default, cycleVending.FixedCycleEnable.type);
                cycleVending.ValueCoin1.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin1.dollars, cycleVending.ValueCoin1.cents, cycleVending.ValueCoin1.type);
                cycleVending.ValueCoin2.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin2.dollars, cycleVending.ValueCoin2.cents, cycleVending.ValueCoin2.type);
            }
        }
        return this.arrVenusCycleVendingConfigSettings;
    }

    calculateBytes(inputNum: string | number, type: string) {
        switch (type.toLowerCase()) {
            case 'u16':
                let firstByte = 0, secondByte = 0;
                secondByte = (Number(inputNum) & 0x00FF);
                firstByte = (Number(inputNum) & 0xFF00) >> 8;
                this.arrVenusCycleVendingConfigSettings.push(firstByte);
                this.arrVenusCycleVendingConfigSettings.push(secondByte);
                break;
            case 'string':
                var hArray = inputNum.toString();
                for (var j = 0; j < hArray.length; j++) {
                    this.arrVenusCycleVendingConfigSettings.push(hArray[j].charCodeAt(0).toString(10));
                }
                this.arrVenusCycleVendingConfigSettings.push("0");
                break;
            case 'u8':
                this.arrVenusCycleVendingConfigSettings.push(inputNum);
                break;
        }
    }

    calculatePrice(dollar: string, cents: string, type: string) {
        let price = Number(dollar) * 100 + Number(cents);
        this.calculateBytes(price, type);
    }

    calcTimePerCoin(splDollar: any, splCent: any, coin1: any, coin2: any) {
        const cycleSpecialPriceDollar = Number(splDollar);
        const cycleSpecialPriceCents = Number(splCent);
        const coin1Dollar = Number(coin1);
        const coin1Cents = Number(coin2);
        const specialPrice1 = (cycleSpecialPriceDollar * 100) + cycleSpecialPriceCents;
        const specialPrice2 = (coin1Dollar * 100) + coin1Cents;
        if (specialPrice2 === 0) {
            this.vendingSettingList.DnldCycle_OpSet_SpecialPriceCycleLen.value = '0';
            return;
        }
        const specialDryTime = String(Math.round((specialPrice2 / specialPrice1) * Number(this.vendingSettingList.DnldCycle_OpSet_RegularPriceCycleLen.value)));
        this.vendingSettingList.TimePerCoinVal.value = Number(specialDryTime);
    }

    getSetupModelData() {
        const venusSetup = this.getVenusSetupSettings(localStorage.getItem("ProductType"));
        this.commonService.novoEcoModels.setupConfig = '';
        this.commonService.novoEcoModels.setupConfig = JSON.stringify(venusSetup);
    }


    getVendingModelData() {
        const vendingSettingList = this.getVenusVendingSettings(localStorage.getItem("ProductType"));
        this.commonService.novoEcoModels.vendingConfig = '';
        this.commonService.novoEcoModels.vendingConfig = JSON.stringify(vendingSettingList);
    }
}
