import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder, FormGroup,
  Validators
} from "@angular/forms";
import { MatCheckboxChange } from "@angular/material";
import { CommonServices } from "src/app/services/CommonServices";
import { TlDryerModelServices } from "src/app/services/TlDryerModel.service";
import { ICycleSpecialPrice, IFixedCycleEnable, IRegularPriceCycleLen, IRelationalEnvelopeOpen, ISpecialPriceCycleLen, ISpecialPriceTime, ISpecialPricingEnable, ISundaySpecialPriceEnable, ITimePerCoin, IValueChecked, IValueCoin1, ITlDryer, IVenusCycleModelList } from "./tl-dryer-cycle-vending.model";
declare const $: any;

@Component({
  selector: "app-tl-dryer-cycle-vending",
  templateUrl: "./tl-dryer-cycle-vending.component.html",
  styleUrls: ["./tl-dryer-cycle-vending.component.scss"],
})

export class TLDryerCycleVendingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('splPriceDayChecked', { static: false }) splPriceDayChecked: ElementRef;
  subscription1: any;
  subscription2: any;
  vendingForm: FormGroup;
  model: IVenusCycleModelList = {
    SpecialPriceTimeStart: '',
    SpecialPriceTimeEnd: '',
    CycleSpecialPriceDollar: '',
    CycleSpecialPriceCents: '',
    CycleRegularPriceDollar: '',
    CycleRegularPriceCents: '',
    ValueCoin1Dollar: '',
    ValueCoin1Cents: '',
    ValueCoin2Dollar: '',
    ValueCoin2Cents: '',
    TimePerCoinVal: 5
  }

  ValueChecked: IValueChecked = {
    id: 0,
    name: '',
    checked: false
  }

  regularPriceCycleLen: IRegularPriceCycleLen = {
    value: '',
    default: '',
    values: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    min: 0,
    max: 0
  }

  fixedCycleEnable: IFixedCycleEnable = {
    value: '',
    default: '',
    values: [],
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  specialPricingEnable: ISpecialPricingEnable = {
    value: false,
    default: false,
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  specialPriceEnable: ISundaySpecialPriceEnable = {
    value: '',
    default: '',
    values: this.ValueChecked,
    binaryKey: '',
    arrKey: [],
    type: ''
  }

  specialPriceTime: ISpecialPriceTime = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    endHour: '',
    startHour: ''
  }

  specialPriceCycleLen: ISpecialPriceCycleLen = {
    value: '',
    default: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    min: '',
    max: ''
  }

  cycleSpecialPrice: ICycleSpecialPrice = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    cents: '',
    dollars: ''
  }

  ValueCoin1: IValueCoin1 = {
    value: [],
    default: '',
    min: '',
    max: '',
    binaryKey: '',
    arrKey: [],
    type: '',
    dollars: '',
    cents: '',
  }

  relationalEnvelopeOpen: IRelationalEnvelopeOpen = {
    binaryKeyValue: '',
    arrKey: [],
    type: ''
  }

  timePerCoinVal: ITimePerCoin = {
    value: 0,
    default: 0
  }

  tlDryerSettings: ITlDryer = {
    Relational_Envelope_Open: this.relationalEnvelopeOpen,
    Relational_Envelope_Action: this.relationalEnvelopeOpen,
    Entity_Cycle_Open: this.relationalEnvelopeOpen,
    Relational_Entity_Name: this.relationalEnvelopeOpen,
    Relational_Entity_Id: this.relationalEnvelopeOpen,
    DnldCycle_CycleSet_CycleCavityPosition: this.relationalEnvelopeOpen,
    Relational_Array_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Open: this.relationalEnvelopeOpen,
    Entity_Cycle_Part1_Close: this.relationalEnvelopeOpen,
    Relational_Array_Close: this.relationalEnvelopeOpen,
    Entity_Cycle_Close: this.relationalEnvelopeOpen,
    Relational_Envelope_Close: this.relationalEnvelopeOpen,
    DnldCycle_OpSet_RegularPriceCycleLen: this.regularPriceCycleLen,
    FixedCycleEnable: this.fixedCycleEnable,
    SpecialPricingEnable: this.specialPricingEnable,
    SundaySpecialPriceEnable: this.specialPriceEnable,
    MondaySpecialPriceEnable: this.specialPriceEnable,
    TuesdaySpecialPriceEnable: this.specialPriceEnable,
    WednesdaySpecialPriceEnable: this.specialPriceEnable,
    ThursdaySpecialPriceEnable: this.specialPriceEnable,
    FridaySpecialPriceEnable: this.specialPriceEnable,
    SaturdaySpecialPriceEnable: this.specialPriceEnable,
    SpecialPriceTime: this.specialPriceTime,
    DnldCycle_OpSet_SpecialPriceCycleLen: this.specialPriceCycleLen,
    DnldCycle_PaySet_CycleSpecialPrice: this.cycleSpecialPrice,
    DnldCycle_PaySet_CycleRegularPrice: this.cycleSpecialPrice,
    ValueCoin1: this.ValueCoin1,
    ValueCoin2: this.ValueCoin1,
    TimePerCoinVal: this.timePerCoinVal,
    hasValue: false
  }
  master_indeterminate: boolean = false;
  modelType: string = '';
  centsName = [
    'CycleSpecialPrice',
    'CycleRegularPrice',
    'ValueCoin1TL',
    'ValueCoin2'
  ]
  timePerCoin = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
  ]
  prevValue = {
    timePerCoin: this.tlDryerSettings.TimePerCoinVal.value,
    regularDollar: '',
    regularCents: '',
    specialDollar: '',
    specialCents: '',
    coin1Dollar: '',
    coin1Cents: '',
  }
  isValueChanged = [{
    name: 'timeCoin',
    value: false
  }, {
    name: 'regularDollar',
    value: false
  }, {
    name: 'regularCents',
    value: false
  }, {
    name: 'specialDollar',
    value: false
  }, {
    name: 'specialCents',
    value: false
  }, {
    name: 'coin1Dollar',
    value: false
  }, {
    name: 'coin1Cents',
    value: false
  }]
  isSetToDefaultHide: boolean = false;

  constructor(
    private commonServices: CommonServices,
    private _tlDryerModelServices: TlDryerModelServices,
    private fb: FormBuilder
  ) {

  }

  ngOnInit() {
    this.isSetToDefaultHide = this.commonServices.isSetToDefaultHide;
    this.modelType = localStorage.getItem("ProductType");
    this.bindControls();
    this.setDollarCentValues();
    this.tlDryerSettings = this._tlDryerModelServices.getVenusCycleModels();
    if (this.modelType == 'PR') {
      this.vendingForm.controls['regularDryTime'].enable();
      this.vendingForm.controls['splDryTime'].enable();
    } else {
      this.vendingForm.controls['regularDryTime'].disable();
      this.vendingForm.controls['splDryTime'].disable();
    }
    if (!this.tlDryerSettings) {
      this.tlDryerSettings = this._tlDryerModelServices.getTlDryerCycleVendingSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    } else if (this.tlDryerSettings && !this.tlDryerSettings.hasValue) {
      this.tlDryerSettings = this._tlDryerModelServices.getTlDryerCycleVendingSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    } else if (this.tlDryerSettings.hasValue) {
      this.setDollarCents();
    }
  }

  ngAfterViewInit(): void {
    this.bindCentsFields();
    if (this.modelType != 'PR') {
      this.calcRegularDryTime();
    }
  }

  bindCentsFields() {
    for (var i = 0; i < this.centsName.length; i++) {
      this.jqueryFunc(this.centsName[i]);
    }
  }

  bindControls() {
    this.vendingForm = this.fb.group({
      regularDryTime: [{ value: "" }, Validators.required],
      typeofDryerPrice: ["", Validators.required],
      pennyOfset: ["", Validators.required],
      splPricingChecked: ["", Validators.required],
      splPriceDayChecked: ["", Validators.required],
      startHour: [{ value: "", disabled: !this.tlDryerSettings.SpecialPricingEnable }, Validators.required],
      endHour: [{ value: "", disabled: !this.tlDryerSettings.SpecialPricingEnable }, Validators.required],
      splCycleDollars: [{ value: "" }, Validators.required],
      splCycleCents: [{ value: "" }, Validators.required],
      coinOneDollars: ["", Validators.required],
      coinOneCentsTL: ["", Validators.required],
      coinTwoCents: ["", Validators.required],
      coinTwoDollars: ["", Validators.required],
      regularPriceDollars: ["", Validators.required],
      regularPriceCents: ["", Validators.required],
      splDryTime: [{ value: "" }, Validators.required],
      timePerCoin: ["", Validators.required],
    });
  }

  setDollarCents() {
    if (this.modelType != '9J') {
      this.model.SpecialPriceTimeStart = this.tlDryerSettings.SpecialPriceTime.value[0];
      this.model.SpecialPriceTimeEnd = this.tlDryerSettings.SpecialPriceTime.value[1];
      this.model.CycleSpecialPriceDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0];
      this.model.CycleSpecialPriceCents = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1];

      this.prevValue.specialDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0];
      this.prevValue.specialCents = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1];
    }
    this.model.CycleRegularPriceDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[0];
    this.model.CycleRegularPriceCents = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[1];
    this.model.ValueCoin1Dollar = this.tlDryerSettings.ValueCoin1.value[0];
    this.model.ValueCoin1Cents = this.tlDryerSettings.ValueCoin1.value[1];
    this.model.ValueCoin2Dollar = this.tlDryerSettings.ValueCoin2.value[0];
    this.model.ValueCoin2Cents = this.tlDryerSettings.ValueCoin2.value[1];
    this.model.TimePerCoinVal = this.tlDryerSettings.TimePerCoinVal.value;
    this.prevValue.regularDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[0];
    this.prevValue.regularCents = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[1];
    this.prevValue.timePerCoin = this.tlDryerSettings.TimePerCoinVal.value;
    this.prevValue.coin1Dollar = this.tlDryerSettings.ValueCoin1.value[0];
    this.prevValue.coin1Cents = this.tlDryerSettings.ValueCoin1.value[1];
    if (this.modelType != '9J') {
      if (!this.tlDryerSettings.SpecialPricingEnable.value) {
        this.disableSplPricingControls();
      }
    }
  }

  setToDefault(isClick?: boolean) {
    this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.default;
    this.tlDryerSettings.FixedCycleEnable.value = this.tlDryerSettings.FixedCycleEnable.default;
    if (this.modelType != '9J') {
      this.tlDryerSettings.SpecialPricingEnable.value = this.tlDryerSettings.SpecialPricingEnable.default;
      this.tlDryerSettings.SundaySpecialPriceEnable.value = this.tlDryerSettings.SundaySpecialPriceEnable.default;
      this.tlDryerSettings.MondaySpecialPriceEnable.value = this.tlDryerSettings.MondaySpecialPriceEnable.default;
      this.tlDryerSettings.TuesdaySpecialPriceEnable.value = this.tlDryerSettings.TuesdaySpecialPriceEnable.default;
      this.tlDryerSettings.WednesdaySpecialPriceEnable.value = this.tlDryerSettings.WednesdaySpecialPriceEnable.default;
      this.tlDryerSettings.ThursdaySpecialPriceEnable.value = this.tlDryerSettings.ThursdaySpecialPriceEnable.default;
      this.tlDryerSettings.FridaySpecialPriceEnable.value = this.tlDryerSettings.FridaySpecialPriceEnable.default;
      this.tlDryerSettings.SaturdaySpecialPriceEnable.value = this.tlDryerSettings.SaturdaySpecialPriceEnable.default;
      this.tlDryerSettings.SpecialPriceTime.value[0] = this.tlDryerSettings.SpecialPriceTime.startHour;
      this.tlDryerSettings.SpecialPriceTime.value[1] = this.tlDryerSettings.SpecialPriceTime.endHour;
      this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default;

      this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
      this.prevValue.specialDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.prevValue.specialCents = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
    }
    this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
    this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.cents;
    this.tlDryerSettings.ValueCoin1.value[0] = this.tlDryerSettings.ValueCoin1.dollars;
    this.tlDryerSettings.ValueCoin1.value[1] = this.tlDryerSettings.ValueCoin1.cents;
    this.tlDryerSettings.ValueCoin2.value[0] = this.tlDryerSettings.ValueCoin2.dollars;
    this.tlDryerSettings.ValueCoin2.value[1] = this.tlDryerSettings.ValueCoin2.cents;
    this.prevValue.regularDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
    this.prevValue.regularCents = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.cents;

    this.prevValue.timePerCoin = this.tlDryerSettings.TimePerCoinVal.value;
    this.prevValue.coin1Dollar = this.tlDryerSettings.ValueCoin1.dollars;
    this.prevValue.coin1Cents = this.tlDryerSettings.ValueCoin1.cents;
    this.tlDryerSettings.hasValue = true;
    this.setDefaultDollarCents();
    if (this.modelType == 'PD') {
      this.calcRegularDryTime();
    }
    if (this.modelType != '9J') {
      if (isClick) {
        this.setToDefaultSplPricing();
      }
      if (!this.tlDryerSettings.SpecialPricingEnable.value) {
        this.disableSplPricingControls();
      }
    }
  }

  setDefaultDollarCents() {
    if (this.modelType != '9J') {
      this.model.SpecialPriceTimeStart = this.tlDryerSettings.SpecialPriceTime.startHour;
      this.model.SpecialPriceTimeEnd = this.tlDryerSettings.SpecialPriceTime.endHour;
      this.model.CycleSpecialPriceDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.model.CycleSpecialPriceCents = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
    }
    this.model.CycleRegularPriceDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
    this.model.CycleRegularPriceCents = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.cents;
    this.model.ValueCoin1Dollar = this.tlDryerSettings.ValueCoin1.dollars;
    this.model.ValueCoin1Cents = this.tlDryerSettings.ValueCoin1.cents;
    this.model.ValueCoin2Dollar = this.tlDryerSettings.ValueCoin2.dollars;
    this.model.ValueCoin2Cents = this.tlDryerSettings.ValueCoin2.cents;
    this.model.TimePerCoinVal = this.tlDryerSettings.TimePerCoinVal.default;
  }

  setToDefaultSplPricing() {
    if (this.modelType == 'PR') {
      this.disableSplPricingControls();
    } else if (this.modelType == 'PD') {
      this.enableSplPricingControls();
    }
    this.tlDryerSettings.SpecialPricingEnable.value = this.tlDryerSettings.SpecialPricingEnable.default;
    this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default;
    this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.cents = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;

    this.tlDryerSettings.SundaySpecialPriceEnable.values.checked = false;
    this.tlDryerSettings.MondaySpecialPriceEnable.values.checked = false;
    this.tlDryerSettings.TuesdaySpecialPriceEnable.values.checked = false;
    this.tlDryerSettings.WednesdaySpecialPriceEnable.values.checked = false;
    this.tlDryerSettings.ThursdaySpecialPriceEnable.values.checked = false;
    this.tlDryerSettings.FridaySpecialPriceEnable.values.checked = false;
    this.tlDryerSettings.SaturdaySpecialPriceEnable.values.checked = false;
    this.tlDryerSettings.SpecialPriceTime.startHour = '0';
    this.tlDryerSettings.SpecialPriceTime.endHour = '0';

    this.model.SpecialPriceTimeStart = this.tlDryerSettings.SpecialPriceTime.startHour;
    this.model.SpecialPriceTimeEnd = this.tlDryerSettings.SpecialPriceTime.endHour;
    this.model.CycleSpecialPriceDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
    this.model.CycleSpecialPriceCents = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
  }

  disableSplPricingControls() {
    this.vendingForm.controls['startHour'].disable();
    this.vendingForm.controls['endHour'].disable();
    // this.vendingForm.controls['splCycleDollars'].disable();
    // this.vendingForm.controls['splCycleCents'].disable();
    // this.vendingForm.controls['splDryTime'].disable();
  }

  enableSplPricingControls() {
    this.vendingForm.controls['startHour'].enable();
    this.vendingForm.controls['endHour'].enable();
    this.vendingForm.controls['splCycleDollars'].enable();
    this.vendingForm.controls['splCycleCents'].enable();
    if (this.modelType === 'PR') {
      this.vendingForm.controls['splDryTime'].enable();
    } else {
      this.vendingForm.controls['splDryTime'].disable();
    }
  }

  onDaySelection(event: MatCheckboxChange, day: string) {
    this.daySelection(event.checked, day);
  }

  daySelection(checked: boolean, day: string) {
    switch (day) {
      case 'Sun':
        this.tlDryerSettings.SundaySpecialPriceEnable.values.checked = checked;
        this.tlDryerSettings.SundaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Mon':
        this.tlDryerSettings.MondaySpecialPriceEnable.values.checked = checked;
        this.tlDryerSettings.MondaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Tue':
        this.tlDryerSettings.TuesdaySpecialPriceEnable.values.checked = checked;
        this.tlDryerSettings.TuesdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Wed':
        this.tlDryerSettings.WednesdaySpecialPriceEnable.values.checked = checked;
        this.tlDryerSettings.WednesdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Thu':
        this.tlDryerSettings.ThursdaySpecialPriceEnable.values.checked = checked;
        this.tlDryerSettings.ThursdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Fri':
        this.tlDryerSettings.FridaySpecialPriceEnable.values.checked = checked;
        this.tlDryerSettings.FridaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
      case 'Sat':
        this.tlDryerSettings.SaturdaySpecialPriceEnable.values.checked = checked;
        this.tlDryerSettings.SaturdaySpecialPriceEnable.value = checked ? '1' : '0';
        break;
    }
  }

  onChange(isEnable: boolean) {
    if (isEnable) {
      this.tlDryerSettings.SpecialPricingEnable.value = true;
      this.enableSplPricingControls();
    } else {
      this.disableSplPricingControls();
      this.tlDryerSettings.SpecialPricingEnable.value = false;
      this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default;
      this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.cents = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
      this.tlDryerSettings.SundaySpecialPriceEnable.values.checked = false;
      this.tlDryerSettings.MondaySpecialPriceEnable.values.checked = false;
      this.tlDryerSettings.TuesdaySpecialPriceEnable.values.checked = false;
      this.tlDryerSettings.WednesdaySpecialPriceEnable.values.checked = false;
      this.tlDryerSettings.ThursdaySpecialPriceEnable.values.checked = false;
      this.tlDryerSettings.FridaySpecialPriceEnable.values.checked = false;
      this.tlDryerSettings.SaturdaySpecialPriceEnable.values.checked = false;
      this.tlDryerSettings.SpecialPriceTime.startHour = '0';
      this.tlDryerSettings.SpecialPriceTime.endHour = '0';
      this.model.SpecialPriceTimeStart = this.tlDryerSettings.SpecialPriceTime.startHour;
      this.model.SpecialPriceTimeEnd = this.tlDryerSettings.SpecialPriceTime.endHour;
      this.model.CycleSpecialPriceDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
      this.model.CycleSpecialPriceCents = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
    }
  }

  setDollarCentValues() {
    let changeValues = [
      { "dollar": 'splCycleDollars', "cent": 'splCycleCents' },
      { "dollar": 'regularPriceDollars', "cent": 'regularPriceCents' },
      { "dollar": 'coinOneDollars', "cent": 'coinOneCentsTL' },
      { "dollar": 'coinTwoDollars', "cent": 'coinTwoCents' },
    ];
    changeValues.forEach(data => {
      this.vendingForm.get(data["dollar"]).valueChanges.subscribe(x => {
        if (x > 99) {
          this.vendingForm.get(data["dollar"]).setValue(99, { emitEvent: false });
        }
      })
      this.vendingForm.get(data["cent"]).valueChanges.subscribe(x => {
        var d = Number(this.vendingForm.get(data["dollar"]).value);
        if (x > 95) {
          this.vendingForm.get(data["cent"]).setValue(99, { emitEvent: false });
        }
        if (x == 100 && d < 99) {
          var result = this.dollarConverts(x);
          d = d + Number(result[0]);
          this.vendingForm.get(data["dollar"]).setValue(d);
          this.vendingForm.get(data["cent"]).setValue(result[1]);
        }
      });
    });
  }

  dollarConverts(c) {
    var nCents = c;
    var nDollar = (nCents / 100);
    var rDollar = Math.floor(nDollar);
    var cents = (nDollar - rDollar) * 100;
    var rCents = Math.round(cents);
    return [rDollar, rCents]
  }

  getDollarCents() {
    if (this.modelType != '9J') {
      this.tlDryerSettings.SpecialPriceTime.value[0] = this.model.SpecialPriceTimeStart;
      this.tlDryerSettings.SpecialPriceTime.value[1] = this.model.SpecialPriceTimeEnd;
      this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.model.CycleSpecialPriceDollar;
      this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.model.CycleSpecialPriceCents;
    }
    this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.model.CycleRegularPriceDollar;
    this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.model.CycleRegularPriceCents;
    this.tlDryerSettings.ValueCoin1.value[0] = this.model.ValueCoin1Dollar;
    this.tlDryerSettings.ValueCoin1.value[1] = this.model.ValueCoin1Cents;
    this.tlDryerSettings.ValueCoin2.value[0] = this.model.ValueCoin2Dollar;
    this.tlDryerSettings.ValueCoin2.value[1] = this.model.ValueCoin2Cents;
    this.tlDryerSettings.TimePerCoinVal.value = this.model.TimePerCoinVal;
  }

  inputStartHourRange(value) {
    if (!this.vendingForm.get("startHour").value) {
      this.vendingForm.get("startHour").setValue(this.tlDryerSettings.SpecialPriceTime.default);
      return;
    }
    if (this.vendingForm.get("startHour").value > this.tlDryerSettings.SpecialPriceTime.max) {
      this.vendingForm.get("startHour").setValue(this.tlDryerSettings.SpecialPriceTime.max);
    }
    if (this.vendingForm.get("startHour").value < this.tlDryerSettings.SpecialPriceTime.min) {
      this.vendingForm.get("startHour").setValue(this.tlDryerSettings.SpecialPriceTime.min);
    }
    this.tlDryerSettings.SpecialPriceTime.value[0] = value;
  }

  inputEndHourRange(value) {
    if (!this.vendingForm.get("endHour").value) {
      this.vendingForm.get("endHour").setValue(this.tlDryerSettings.SpecialPriceTime.default);
      return;
    }
    if (this.vendingForm.get("endHour").value > this.tlDryerSettings.SpecialPriceTime.max) {
      this.vendingForm.get("endHour").setValue(this.tlDryerSettings.SpecialPriceTime.max);
    }
    if (this.vendingForm.get("endHour").value < this.tlDryerSettings.SpecialPriceTime.min) {
      this.vendingForm.get("endHour").setValue(this.tlDryerSettings.SpecialPriceTime.min);
    }
    this.tlDryerSettings.SpecialPriceTime.value[1] = value;
  }

  inputSplDryTime() {
    if (!this.vendingForm.get("splDryTime").value && this.vendingForm.get("splDryTime").value != '0') {
      this.vendingForm.get("splDryTime").setValue(this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.default);
      return;
    }
    if (this.vendingForm.get("splDryTime").value > this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.max) {
      this.vendingForm.get("splDryTime").setValue(this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.max);
    }
    if (this.vendingForm.get("splDryTime").value < this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.min) {
      this.vendingForm.get("splDryTime").setValue(this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.min);
    }
  }

  inputRegularDryTime() {
    if (!this.vendingForm.get("regularDryTime").value && this.vendingForm.get("regularDryTime").value != '0') {
      this.vendingForm.get("regularDryTime").setValue(this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.default);
      return;
    }
    if (this.vendingForm.get("regularDryTime").value > this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.max) {
      this.vendingForm.get("regularDryTime").setValue(this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.max);
    }
    if (this.vendingForm.get("regularDryTime").value < this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.min) {
      this.vendingForm.get("regularDryTime").setValue(this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.min);
    }
  }

  changeDollar(name: string, value: string) {
    let isValid = false;
    switch (name) {
      case 'CycleSpecialPrice':
        this.model.CycleSpecialPriceDollar = this.model.CycleSpecialPriceDollar ? this.model.CycleSpecialPriceDollar : '0';
        isValid = this.validateSpecialPrice(this.modelType, value, 'Dollar');
        if (isValid) {
          if (!value && value != '0') {
            this.model.CycleSpecialPriceDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.dollars;
            value = this.model.CycleSpecialPriceDollar;
          }
          this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = value;
          if (this.modelType == 'PD') {
            this.hasChangedValue('specialDollar');
            this.calcRegularDryTime();
          }
        }
        break;
      case 'CycleRegularPrice':
        this.model.CycleRegularPriceDollar = this.model.CycleRegularPriceDollar ? this.model.CycleRegularPriceDollar : '0';
        isValid = this.validateRegularPrice(this.modelType, value, 'Dollar');
        if (isValid) {
          if (!value && value != '0') {
            this.model.CycleRegularPriceDollar = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.dollars;
            value = this.model.CycleRegularPriceDollar;
          }
          this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = value;
          if (this.modelType != 'PR') {
            this.hasChangedValue('regularDollar');
            this.calcRegularDryTime();
          }
        }
        break;
      case 'ValueCoin1':
        this.model.ValueCoin1Dollar = this.model.ValueCoin1Dollar ? this.model.ValueCoin1Dollar : '0';
        isValid = this.validateDollarCoin1(this.modelType, value);
        if (isValid) {
          if (!value && value != '0') {
            this.model.ValueCoin1Dollar = this.tlDryerSettings.ValueCoin1.dollars;
            value = this.model.ValueCoin1Dollar;
          }
          this.tlDryerSettings.ValueCoin1.value[0] = value;
          if (this.modelType != 'PR') {
            this.hasChangedValue('coin1Dollar');
            this.calcRegularDryTime();
          }
        }
        break;
      case 'ValueCoin2':
        this.model.ValueCoin2Dollar = this.model.ValueCoin2Dollar ? this.model.ValueCoin2Dollar : '0';
        isValid = this.validateDollarCoin2(this.modelType, value);
        if (isValid) {
          if (!value && value != '0') {
            this.model.ValueCoin2Dollar = this.tlDryerSettings.ValueCoin2.dollars;
            value = this.model.ValueCoin2Dollar;
          }
          this.tlDryerSettings.ValueCoin2.value[0] = value;
          break;
        }
      default:
        break;
    }
  }

  validateSpecialPrice(modelType: string, value: string, type: string): boolean {
    switch (type) {
      case 'Dollar':
        if ((Number(value) == 9 && Number(this.model.CycleSpecialPriceCents) > 75) && this.modelType == 'PD') {
          value = '9';
          this.model.CycleSpecialPriceDollar = '9';
          this.model.CycleSpecialPriceCents = '75'
          this.hasChangedValue('specialDollar');
          this.calcRegularDryTime();
          return false;
        } else if (Number(value) > 9 && this.modelType == 'PD') {
          value = '9';
          this.model.CycleSpecialPriceDollar = '9';
          this.hasChangedValue('specialDollar');
          this.calcRegularDryTime();
          return false;
        } else if (Number(value) > 9 && (modelType == 'PR' || modelType == '9J')) {
          value = '9';
          this.model.CycleSpecialPriceDollar = '9';
          return false;
        }
        return true;
      case 'Cents':
        if ((Number(this.model.CycleSpecialPriceDollar) == 9 && Number(this.model.CycleSpecialPriceCents) > 75) && this.modelType == 'PD') {
          value = '75';
          this.model.CycleSpecialPriceCents = '75';
          this.model.CycleSpecialPriceDollar = '9';
          this.hasChangedValue('specialCents');
          this.hasChangedValue('specialDollar');
          this.calcRegularDryTime();
          return false;
        } else if (Number(this.model.CycleSpecialPriceDollar) > 9 && modelType == 'PD') {
          value = '75';
          this.model.CycleSpecialPriceCents = '75';
          this.model.CycleSpecialPriceDollar = '9';
          this.hasChangedValue('specialCents');
          this.hasChangedValue('specialDollar');
          this.calcRegularDryTime();
          return false;
        } else if ((Number(this.model.CycleSpecialPriceDollar) == 9 && Number(this.model.CycleSpecialPriceCents) > 75) && (modelType == 'PR' || modelType == '9J')) {
          value = '75';
          this.model.CycleSpecialPriceDollar = '9';
          this.model.CycleSpecialPriceCents = '75';
          return false;
        } else if (Number(this.model.CycleSpecialPriceDollar) > 9 && (modelType == 'PR' || modelType == '9J')) {
          value = '95';
          this.model.CycleSpecialPriceDollar = '9';
          this.model.CycleSpecialPriceCents = '75';
          return false;
        }
        return true;
    }
  }

  validateRegularPrice(modelType: string, value: string, type: string): boolean {
    switch (type) {
      case 'Dollar':
        if ((Number(value) == 9 && Number(this.model.CycleRegularPriceCents) > 75) && this.modelType == 'PD') {
          value = '9';
          this.model.CycleRegularPriceDollar = '9';
          this.model.CycleRegularPriceCents = '75'
          this.hasChangedValue('regularDollar');
          this.calcRegularDryTime();
          return false;
        } else if (Number(value) > 9 && this.modelType == 'PD') {
          value = '9';
          this.model.CycleRegularPriceDollar = '9';
          this.hasChangedValue('regularDollar');
          this.calcRegularDryTime();
          return false;
        } else if (Number(value) > 9 && (modelType == 'PR' || modelType == '9J')) {
          value = '9';
          this.model.CycleRegularPriceDollar = '9';
          return false;
        }
        return true;
      case 'Cents':
        if ((Number(this.model.CycleRegularPriceDollar) == 9 && Number(this.model.CycleRegularPriceCents) > 75) && modelType == 'PD') {
          value = '75';
          this.model.CycleRegularPriceCents = '75';
          this.model.CycleRegularPriceDollar = '9';
          this.hasChangedValue('regularDollar');
          this.hasChangedValue('regularCents');
          this.calcRegularDryTime();
          return false;
        } else if (Number(this.model.CycleRegularPriceDollar) > 9 && this.modelType == 'PD') {
          value = '75';
          this.model.CycleRegularPriceDollar = '9';
          this.model.CycleRegularPriceCents = '75';
          return false;
        } else if ((Number(this.model.CycleRegularPriceDollar) == 9 && Number(this.model.CycleRegularPriceCents) > 75) && (modelType == 'PR' || modelType == '9J')) {
          value = '75';
          this.model.CycleRegularPriceDollar = '9';
          this.model.CycleRegularPriceCents = '75';
          return false;
        } else if (Number(this.model.CycleRegularPriceDollar) > 9 && (modelType == 'PR' || modelType == '9J')) {
          value = '95';
          this.model.CycleRegularPriceDollar = '9';
          this.model.CycleRegularPriceCents = '75';
          return false;
        }
        return true;
    }
  }

  validateDollarCoin1(modelType: string, value: string): boolean {
    if ((Number(value) == 9 && Number(this.model.ValueCoin1Cents) > 75) && modelType == 'PD') {
      value = '9';
      this.model.ValueCoin1Dollar = '9';
      this.model.ValueCoin1Cents = '75';
      this.hasChangedValue('coin1Dollar');
      this.calcRegularDryTime();
      return false;
    } else if (Number(value) > 9 && modelType == 'PD') {
      value = '9';
      this.model.ValueCoin1Dollar = '9';
      this.hasChangedValue('coin1Dollar');
      this.calcRegularDryTime();
      return false;
    } else if (Number(value) > 9 && (modelType == 'PR' || modelType == '9J')) {
      value = '9';
      this.model.ValueCoin1Dollar = '9';
      return false;
    }
    return true;
  }

  changeCents(name: string, value: string) {
    this.jqueryFunc(name);
    let isValid = false;
    switch (name) {
      case 'CycleSpecialPrice':
        this.model.CycleSpecialPriceCents = this.model.CycleSpecialPriceCents ? this.model.CycleSpecialPriceCents : '0';
        isValid = this.validateSpecialPrice(this.modelType, value, 'Cents');
        if (isValid) {
          if (!value && value != '0') {
            this.model.CycleSpecialPriceCents = this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.cents;
            value = this.model.CycleSpecialPriceCents;
          }
          this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[1] = value;
          this.tlDryerSettings.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.model.CycleSpecialPriceDollar;
          if (this.modelType == 'PD') {
            this.hasChangedValue('specialCents');
            this.calcRegularDryTime();
          }
          break;
        }
      case 'CycleRegularPrice':
        this.model.CycleRegularPriceCents = this.model.CycleRegularPriceCents ? this.model.CycleRegularPriceCents : '0';
        isValid = this.validateRegularPrice(this.modelType, value, 'Cents');
        if (isValid) {
          if (!value && value != '0') {
            this.model.CycleRegularPriceCents = this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.cents;
            value = this.model.CycleRegularPriceCents;
          }
          this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[1] = value;
          this.tlDryerSettings.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.model.CycleRegularPriceDollar;
          if (this.modelType != 'PR') {
            this.hasChangedValue('regularCents');
            this.calcRegularDryTime();
          }
        }
        break;
      case 'ValueCoin1':
        this.model.ValueCoin1Cents = this.model.ValueCoin1Cents ? this.model.ValueCoin1Cents : '0';
        isValid = this.validateCents1(this.modelType, value);
        if (isValid) {
          if (!value && value != '0') {
            this.model.ValueCoin1Cents = this.tlDryerSettings.ValueCoin1.cents;
            value = this.model.ValueCoin1Cents;
          }
          this.tlDryerSettings.ValueCoin1.value[1] = value;
          this.tlDryerSettings.ValueCoin1.value[0] = this.model.ValueCoin1Dollar;
          if (this.modelType == 'PD') {
            this.hasChangedValue('coin1Cents');
            this.calcRegularDryTime();
          }
        }
        break;
      case 'ValueCoin2':
        this.model.ValueCoin2Cents = this.model.ValueCoin2Cents ? this.model.ValueCoin2Cents : '0';
        if (Number(this.model.ValueCoin2Dollar) == 9 && Number(this.model.ValueCoin2Cents) > 95) {
          value = '95';
          this.model.ValueCoin2Cents = '95';
          this.model.ValueCoin2Dollar = '9';
          return;
        }
        if (Number(this.model.ValueCoin2Dollar) > 9) {
          value = '95';
          this.model.ValueCoin2Cents = '95';
          this.model.ValueCoin2Dollar = '9';
          return;
        }
        if (!value && value != '0') {
          this.model.ValueCoin2Cents = this.tlDryerSettings.ValueCoin2.cents;
          value = this.model.ValueCoin2Cents;
        }
        this.tlDryerSettings.ValueCoin2.value[1] = value;
        this.tlDryerSettings.ValueCoin2.value[0] = this.model.ValueCoin2Dollar;
        break;
      default:
        break;
    }
  }

  validateCents1(type: string, value: string): boolean {
    if (Number(this.model.ValueCoin1Dollar) > 9 && this.modelType == 'PD') {
      value = '95';
      this.model.ValueCoin1Cents = '95';
      this.model.ValueCoin1Dollar = '9';
      this.hasChangedValue('coin1Dollar');
      this.hasChangedValue('coin1Cents');
      this.calcRegularDryTime();
      return false;
    } else if ((Number(this.model.ValueCoin1Dollar) == 9 && Number(this.model.ValueCoin1Cents) > 95) && (type == 'PR' || type == '9J')) {
      value = '95';
      this.model.ValueCoin1Cents = '95';
      this.model.ValueCoin1Dollar = '9';
      return false;
    } else if (Number(this.model.ValueCoin1Dollar) > 9 && (type == 'PR' || type == '9J')) {
      value = '95';
      this.model.ValueCoin1Cents = '95';
      this.model.ValueCoin1Dollar = '9';
      return false;
    }
    return true;
  }

  validateDollarCoin2(type: string, value: string): boolean {
    if (Number(value) == 9 && Number(this.model.ValueCoin2Cents) > 95) {
      value = '9';
      this.model.ValueCoin2Dollar = '9';
      this.model.ValueCoin2Cents = '95';
      this.hasChangedValue('coin1Dollar');
      this.calcRegularDryTime();
      return false;
    } else if (Number(this.model.ValueCoin2Dollar) > 9) {
      value = '9';
      this.model.ValueCoin2Dollar = '9';
      return;
    }
    return true;
  }

  changeTimeCoin() {
    this.hasChangedValue('timeCoin');
    this.calcRegularDryTime();
  }

  hasChangedValue(controlName: string) {
    this.isValueChanged.map(item => {
      if (item.name === controlName) {
        item.value = true;
      } else {
        item.value = false;
      }
    });
  }

  calcRegularDryTime() {
    if (this.modelType == 'PD') {
      setTimeout(() => {
        const cycleSpecialPriceDollar = Number(this.model.CycleSpecialPriceDollar);
        const cycleSpecialPriceCents = Number(this.model.CycleSpecialPriceCents);
        const cycleRegularPriceDollar = Number(this.model.CycleRegularPriceDollar);
        const cycleRegularPriceCents = Number(this.model.CycleRegularPriceCents);
        const coin1Dollar = Number(this.model.ValueCoin1Dollar);
        const coin1Cents = Number(this.model.ValueCoin1Cents);
        const regularPrice = (cycleRegularPriceDollar * 100) + cycleRegularPriceCents;
        const specialPrice = (cycleSpecialPriceDollar * 100) + cycleSpecialPriceCents;
        const coin1 = (coin1Dollar * 100) + coin1Cents;
        if (coin1 === 0) {
          this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = '0';
          this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = '0';
          return;
        }
        const regularDryTime = String(Math.round((regularPrice / coin1) * this.model.TimePerCoinVal));
        const specialDryTime = String(Math.round((specialPrice / coin1) * this.model.TimePerCoinVal));
        const isValid = this.validateMinMaxDryTime(regularDryTime, specialDryTime);
        if (isValid) {
          this.setPrevValuesInModel(regularDryTime, specialDryTime);
        }
      }, 100);
    } else if (this.modelType == '9J') {
      setTimeout(() => {
        const cycleRegularPriceDollar = Number(this.model.CycleRegularPriceDollar);
        const cycleRegularPriceCents = Number(this.model.CycleRegularPriceCents);
        const coin1Dollar = Number(this.model.ValueCoin1Dollar);
        const coin1Cents = Number(this.model.ValueCoin1Cents);
        const regularPrice = (cycleRegularPriceDollar * 100) + cycleRegularPriceCents;
        const coin1 = (coin1Dollar * 100) + coin1Cents;
        if (coin1 === 0) {
          this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = '0';
          return;
        }
        const regularDryTime = String(Math.round((regularPrice / coin1) * this.model.TimePerCoinVal));
        const isValid = this.validateMinMaxDryTime(regularDryTime);
        if (isValid) {
          this.setPrevValuesInModel(regularDryTime);
        }
      }, 100);
    }
  }

  setPrevValuesInModel(regularDryTime: string, specialDryTime?: string) {
    if (this.modelType == 'PD') {
      this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = regularDryTime;
      this.tlDryerSettings.DnldCycle_OpSet_SpecialPriceCycleLen.value = specialDryTime;
      this.tlDryerSettings.TimePerCoinVal.value = this.model.TimePerCoinVal;
      this.prevValue.timePerCoin = this.model.TimePerCoinVal;
      this.prevValue.regularDollar = this.model.CycleRegularPriceDollar;
      this.prevValue.regularCents = this.model.CycleRegularPriceCents;
      this.prevValue.specialDollar = this.model.CycleSpecialPriceDollar;
      this.prevValue.specialCents = this.model.CycleSpecialPriceCents;
      this.prevValue.coin1Dollar = this.model.ValueCoin1Dollar;
      this.prevValue.coin1Cents = this.model.ValueCoin1Cents;
    } else if (this.modelType == '9J') {
      this.tlDryerSettings.DnldCycle_OpSet_RegularPriceCycleLen.value = regularDryTime;
      this.tlDryerSettings.TimePerCoinVal.value = this.model.TimePerCoinVal;
      this.prevValue.timePerCoin = this.model.TimePerCoinVal;
      this.prevValue.regularDollar = this.model.CycleRegularPriceDollar;
      this.prevValue.regularCents = this.model.CycleRegularPriceCents;
      this.prevValue.coin1Dollar = this.model.ValueCoin1Dollar;
      this.prevValue.coin1Cents = this.model.ValueCoin1Cents;
    }
  }

  validateMinMaxDryTime(regularDryTime: string, specialDryTime?: string): boolean {
    if (this.modelType == 'PD') {
      if (Number(regularDryTime) > 75 || Number(specialDryTime) > 75) {
        this.setPrevValues();
        return false;
      }
      if (Number(regularDryTime) < 5 || Number(specialDryTime) < 5) {
        this.setPrevValues();
        return false;
      }
    } else if (this.modelType == '9J') {
      if (Number(regularDryTime) > 75) {
        this.setPrevValues();
        return false;
      }
      if (Number(regularDryTime) < 5) {
        this.setPrevValues();
        return false;
      }
    }
    return true;
  }

  setPrevValues() {
    if (this.modelType == 'PD') {
      this.isValueChanged.map(item => {
        if (item.value) {
          switch (item.name.toLowerCase()) {
            case 'timecoin':
              this.model.TimePerCoinVal = this.prevValue.timePerCoin;
              break;
            case 'regulardollar':
              this.model.CycleRegularPriceDollar = this.prevValue.regularDollar;
              break;
            case 'regularcents':
              this.model.CycleRegularPriceCents = this.prevValue.regularCents;
              break;
            case 'specialdollar':
              this.model.CycleSpecialPriceDollar = this.prevValue.specialDollar;
              break;
            case 'specialcents':
              this.model.CycleSpecialPriceCents = this.prevValue.specialCents
              break;
            case 'coin1dollar':
              this.model.ValueCoin1Dollar = this.prevValue.coin1Dollar;
              break;
            case 'coin1cents':
              this.model.ValueCoin1Cents = this.prevValue.coin1Cents;
              break;
          }
        }
      });
    } else if (this.modelType == '9J') {
      this.isValueChanged.map(item => {
        if (item.value) {
          switch (item.name.toLowerCase()) {
            case 'timecoin':
              this.model.TimePerCoinVal = this.prevValue.timePerCoin;
              break;
            case 'regulardollar':
              this.model.CycleRegularPriceDollar = this.prevValue.regularDollar;
              break;
            case 'regularcents':
              this.model.CycleRegularPriceCents = this.prevValue.regularCents;
              break;
            case 'coin1dollar':
              this.model.ValueCoin1Dollar = this.prevValue.coin1Dollar;
              break;
            case 'coin1cents':
              this.model.ValueCoin1Cents = this.prevValue.coin1Cents;
              break;
          }
        }
      });
    }
  }

  jqueryFunc(name: string) {
    const inputName = `${'#' + name}`;
    $(inputName).bind('keyup input', function () {
      this.setAttribute("step", "5");
    });
  }

  ngOnDestroy(): void {
    const isModel = this._tlDryerModelServices.modelChanged();
    if (!isModel) {
      this.getDollarCents();
      this._tlDryerModelServices.setVenusCycleModels(this.tlDryerSettings);
    }
  }

}
