import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/AuthenticationService';
import { CommonServices } from 'src/app/services/CommonServices';
import { FlDryerModelServices } from 'src/app/services/FlDryerModel.service';
import { FlWasherModelServices } from 'src/app/services/FlWasherModel.service';
import { IModelList, ModelServices } from 'src/app/services/ModelServices';
import { TlDryerModelServices } from 'src/app/services/TlDryerModel.service';
import { TlWasherModelServices } from 'src/app/services/TlWasherModel.service';
import { VenusModelServices } from 'src/app/services/VenusModel.service';
import * as flDryerModel from "src/assets/JsonFiles/fl-dryer/fl-dryer-model.json";
import * as flWasherModel from "src/assets/JsonFiles/fl-washer/model.json";
import * as TLDryerModel from "src/assets/JsonFiles/tl-dryer/tl-dryer-model.json";
import * as tlWasherModel from "src/assets/JsonFiles/tl-washer/tl-washer-model.json";
import * as UserDefinedList from "src/assets/JsonFiles/userDefinedSetup.json";
import * as VenusModel from "src/assets/JsonFiles/VenusModel.json";
import { MenuNames } from '../../enums/enum';
import { IGetModelList, IGetUserDefinedConfiguration } from './user-defined-setup.model';

@Component({
  selector: 'app-user-defined-setup',
  templateUrl: './user-defined-setup.component.html',
  styleUrls: ['./user-defined-setup.component.scss']
})
export class UserDefinedSetupComponent implements OnInit {
  public getConfiguration: IGetUserDefinedConfiguration = (UserDefinedList as any).default;
  public cspModel: IModelList = (VenusModel as any).default;
  public tlDryerModel: IModelList = (TLDryerModel as any).default;
  public tlWasherModel: IModelList = (tlWasherModel as any).default;
  public flDryerModel: IModelList = (flDryerModel as any).default;
  public flWasherModel: IModelList = (flWasherModel as any).default;
  public searchText: string;
  public modelNo: any;
  public productType: string;
  public bindModelList: IGetModelList[] = [];
  public configurationId: string = '';

  constructor(
    private _venusModelServices: VenusModelServices,
    private toastr: ToastrService,
    private router: Router,
    private _commonServices: CommonServices,
    private modelServices: ModelServices,
    private _authenticationService: AuthenticationService,
    private _TlDryerModelServices: TlDryerModelServices,
    private _TlWasherModelServices: TlWasherModelServices,
    private _FlDryerModelServices: FlDryerModelServices,
    private _FlWasherModelServices: FlWasherModelServices
  ) { }

  ngOnInit() {
    this.callIsUserDefinedScreen();
    this.getSaveConfigModelList();
  }

  callIsUserDefinedScreen() {
    this._commonServices.isUserDefinedScreen.subscribe((isUserDefinedScreen) => {
      if (isUserDefinedScreen) {
        this.getModelList();
      }
    })
  }

  getModelList() {
    this.bindModelList = [];
    const requestURL = '/api/v1/usbConfigSetups';
    this._authenticationService.callGetAPI(requestURL).subscribe((res) => {
      res.map(item => {
        this.bindModelList.push({
          configurationId: item,
          isActive: false
        })
      })

      this.activeModel();
    },
      (err) => {
        this.showMessage(err, true);
      }
    );
  }

  getConfigData(configId: string) {
    const requestURL = `${'/api/v1/usbConfigSetups/' + configId}`;
    this._authenticationService
      .callGetAPI(requestURL)
      .subscribe(
        (res) => {
          this._commonServices.userDefinedConfig = res;
          this._commonServices.isSetToDefaultHide = true;
          this.activeModel(configId);
          this._commonServices.generateButtonHide.next(false);
          this._commonServices.paraHeader.next("false");
          this.modelServices.selectedModel.next(this.modelNo);
          this._commonServices.defaultScreen.next("default");
          localStorage.setItem("leftMenuHide", "1");
          localStorage.setItem("isShowModel", "1");
          this.findEcoModel(this.modelNo);
          this.addNovoClassOnBox();
          this._commonServices.isHideDeleteButton.next(true);
          this._commonServices.sendTopFeaturesDisable(this.modelNo);
        },
        (err) => {
          this.showMessage(err, true);
        });
  }

  selectModel(node: IGetModelList) {
    this._commonServices.disableTopFeaure.next("Disalbe");
    this.modelNo = node.configurationId.split("-")[0];
    this.configurationId = node.configurationId;
    this.getConfigData(this.configurationId);
  }

  addNovoClassOnBox() {
    if (localStorage.getItem("ProductType") === "PN") {
      document.getElementById("box4").classList.add("vending_disable");
      document.getElementById("nav-setup-tab").classList.remove("active");
      document.getElementById("nav-HMI-tab").classList.remove("active");
      document.getElementById("nav-cycle-tab").classList.remove("active");
      document.getElementById("nav-vending-tab").classList.remove("active");
    } else {
      document.getElementById("box4").classList.remove("vending_disable");
      document.getElementById("nav-setup-tab").classList.remove("active");
      document.getElementById("nav-HMI-tab").classList.remove("active");
      document.getElementById("nav-cycle-tab").classList.remove("active");
      document.getElementById("nav-vending-tab").classList.remove("active");
    }
  }

  activeModel(configId?: string) {
    this.bindModelList.map(item => {
      const configurationId = item.configurationId;// .split("-")[0];
      if (configurationId == configId) {
        item.isActive = true;
        return;
      }
      item.isActive = false;
    })
  }

  findEcoModel(modelNo: string) {
    this.flushModelService();
    localStorage.removeItem("isShowModel");
    localStorage.setItem("isShowModel", "1");
    const csp = this.cspModel.Models.some(f => { return f.toLowerCase() == modelNo.toLowerCase() });
    if (csp) {
      localStorage.setItem("SelectedMenu", MenuNames.MlgMle);
      this.setLocalStorage(MenuNames.MlgMle);
      this._venusModelServices.clearSetupModels();
      this._venusModelServices.clearVenusCycleModels();
      const tab = {
        tab: 'nav-setup-tab',
        type: MenuNames.MlgMle
      }
      this._commonServices.selectModelTab.next(tab);
      return true;
    }
    const tlDryer = this.tlDryerModel.Models.some(f => { return f.toLowerCase() == modelNo.toLowerCase() });
    if (tlDryer) {
      localStorage.setItem("SelectedMenu", MenuNames.TLDryer);
      this.setLocalStorage(MenuNames.TLDryer);
      this._TlDryerModelServices.clearSetupModels();
      this._TlDryerModelServices.clearVenusCycleModels();
      const tab = {
        tab: 'nav-setup-tab',
        type: MenuNames.TLDryer
      }
      const userDefinedConfig = this._commonServices.userDefinedConfig;
      this._commonServices.selectModelTab.next(tab);
      this._commonServices.isTlDryerModel.next(userDefinedConfig);
      return true;
    }
    const tlWasher = this.tlWasherModel.Models.some(f => { return f.toLowerCase() == modelNo.toLowerCase() });
    if (tlWasher) {
      localStorage.setItem("SelectedMenu", MenuNames.TLWasher);
      this.setLocalStorage(MenuNames.TLWasher);
      this._TlWasherModelServices.clearSetupModels();
      this._TlWasherModelServices.clearVenusCycleModels();
      this._TlWasherModelServices.clearCycleSettingModels(this.modelNo, this.productType);
      const tab = {
        tab: 'nav-setup-tab',
        type: MenuNames.TLWasher
      }
      this.modelServices.tlWasherSelectedModel.next(this.modelNo)
      const userDefinedConfig = this._commonServices.userDefinedConfig;
      this._commonServices.selectModelTab.next(tab);
      this._commonServices.isTlWasherModel.next(userDefinedConfig);
      return true;
    }
    const flDryer = this.flDryerModel.Models.some(f => { return f.toLowerCase() == modelNo.toLowerCase() });
    if (flDryer) {
      localStorage.setItem("SelectedMenu", MenuNames.FLDryer);
      this.setLocalStorage(MenuNames.FLDryer);
      this._FlDryerModelServices.clearSetupModels();
      this._FlDryerModelServices.clearCycleSettingModels();
      const tab = {
        tab: 'nav-setup-tab',
        type: MenuNames.FLDryer
      }
      const userDefinedConfig = this._commonServices.userDefinedConfig;
      this._commonServices.selectModelTab.next(tab);
      this._commonServices.isFlDryerModel.next(userDefinedConfig);
      return true;
    }
    const flWasher = this.flWasherModel.Models.some(f => { return f.toLowerCase() == modelNo.toLowerCase() });
    if (flWasher) {
      localStorage.setItem("SelectedMenu", MenuNames.FLWasher);
      this.setLocalStorage(MenuNames.FLWasher);
      this._FlWasherModelServices.clearSetupModels();
      this._FlWasherModelServices.clearFlWasherCycleVendingModels();
      this._FlWasherModelServices.clearCycleSettingModels(this.modelNo, this.productType);
      const tab = {
        tab: 'nav-setup-tab',
        type: MenuNames.FLWasher
      }
      this.modelServices.flWasherSelectedModel.next(this.modelNo);
      const userDefinedConfig = this._commonServices.userDefinedConfig;
      this._commonServices.selectModelTab.next(tab);
      this._commonServices.isFlWasherModel.next(userDefinedConfig);
      return true;
    } else {
      localStorage.removeItem("isShowModel");
      localStorage.setItem("SelectedMenu", MenuNames.MultiLoad);
      this.setLocalStorage(MenuNames.MultiLoad);
      this.navigateTabs();
      return true;
    }
  }

  setLocalStorage(mnuName: string) {
    let isFLDryerReq = false;
    let isTLDryerReq = false;
    const modelVal = this.modelNo.substr(4, 4);
    if (mnuName === MenuNames.FLDryer && (modelVal === "258J" || modelVal === "268J")) {
      isFLDryerReq = true;
    }
    if (mnuName === MenuNames.FLWasher && (modelVal === "258J" || modelVal === "268J")) {
      isFLDryerReq = true;
    }
    if (mnuName === MenuNames.TLWasher && this.modelNo == 'MAT20PDFGW0') {
      isTLDryerReq = true;
    }
    this.productType = this.modelServices.getProductType(this.modelNo, isFLDryerReq, isTLDryerReq);
    const weight = this.modelServices.getModelWeight(this.modelNo);
    const modelType = this.modelServices.getModelType(this.modelNo);
    localStorage.setItem("modelNumber", this.modelNo);
    localStorage.setItem("ProductType", this.productType);
    localStorage.setItem("ModelType", modelType);
    localStorage.setItem("Weight", weight);
  }

  navigateTabs() {
    setTimeout(() => {
      this.router.navigate(['/setup']);
      setTimeout(() => {
        this.router.navigate(['/Hmi-setup']);
        setTimeout(() => {
          this.router.navigate(['/cycle-setting']);
          if (localStorage.getItem("ProductType") !== "PN") {
            setTimeout(() => {
              this.router.navigate(['/vending-setting']);
            }, 2)
          }
          setTimeout(() => {
            this.router.navigate(['/']);
            localStorage.setItem("isShowModel", "0");
            const tab = {
              tab: 'nav-setup-tab',
              type: MenuNames.MultiLoad
            }
            this._commonServices.selectModelTab.next(tab);
            // this._commonServices.setBinaryTlDryer.next(commonModel);
          }, 2);
        }, 2);
      }, 2);
    }, 2);
  }

  showMessage(message, isError) {
    if (!isError) {
      this.toastr.success(message, 'Success', {
        positionClass: 'toast-top-right'
      });
    } else {
      this.toastr.error(message, 'Failed', {
        positionClass: 'toast-top-right'
      });
    }
  }


  flushModelService() {
    this.modelServices.modelSetup = undefined;

    this.modelServices.vendingSettings = undefined;
    this.modelServices.isCycleWashCycleComponentTobePush = false;
    this.modelServices.isPush();
    this.modelServices.isHMIComponentTobePush = false;
    this.modelServices.isPushHMI();
    this.modelServices.isCycleVendingSettingsTobePush = false;
    if (this.modelServices.cycleWashCycleComponentReact != null) {
      this.modelServices.cycleWashCycleComponentReact.drainForm = "";
      this.modelServices.cycleWashCycleComponentReact.reclaimForm = "";
      this.modelServices.cycleWashCycleComponentReact.washForm = "";
      this.modelServices.cycleWashCycleComponentReact = null;
    }
    this.modelServices.phaseList = null;
    localStorage.removeItem("lastTabId")
    localStorage.removeItem("arrMachineConfigSettings");
    localStorage.removeItem("arrHmiDisplaySettings");
    localStorage.removeItem("arrCycleSettings");
    localStorage.removeItem("arrVendingSettingDetail");
    this._commonServices.clearSubscription();
    this.modelServices.userAddedCycles.forEach((element) => {
      if (
        this.modelServices.modelCycleDataCopy.hasOwnProperty(element.tvalue)
      ) {
        delete this.modelServices.modelCycleDataCopy[element.tvalue];
        delete this.modelServices.selectedCycleListObj[element.tvalue];
      }
      let index = this.modelServices.cycleData.findIndex(function (wizard) {
        return wizard.tvalue === element.tvalue;
      });
      if (index == -1) {
      } else {
        this.modelServices.cycleData.splice(index, 1);
      }
    });

    this.modelServices.userAddedCycles.length = 0;
  }

  getSaveConfigModelList() {
    this._commonServices.getSaveConfigModelList.subscribe(configurationId => {
      this.bindModelList = this.bindModelList.filter(f => { return f.configurationId != configurationId });
    })
  }

}