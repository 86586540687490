import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonServices } from "src/app/services/CommonServices";

import { AuthenticationService } from "../../../services/AuthenticationService";
import { User } from "../../models/User";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  fullName: String = "";
  currentUser: User;
  currentUserHide: boolean = true;
  public isCurrentUserAdmin: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private commonServices: CommonServices,
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  ngOnInit() {
    this.hideUser();
    if (this.currentUser) {
      this.fullName =
        this.currentUser.lastName + " " + this.currentUser.firstName;
    }
    if (this.currentUser && (this.currentUser.userRolePrivilege[0].roleName === "USB_ADMIN")) {
      this.isCurrentUserAdmin = true;
    }
  }

  hideUser() {
    let urlTree = "";
    if (this.activatedRoute.snapshot.url.length) {
      urlTree = this.activatedRoute.snapshot.url[0].path;
    }
    if (urlTree === "create-password" || urlTree === "reset-password") {
      this.currentUserHide = false;
    } else {
      this.currentUserHide = true;
    }
  }

  logout() {
    localStorage.clear();
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  createUser() {
    this.router.navigate(["/create-user"]);
  }

  home() {
    if (this.currentUserHide) {
      this.router.navigate(['/']);
      setTimeout(() => {
        location.reload();
      }, 20);
      return;
    }
  }

  help() {
    this.router.navigate(["/help"]);
  }

  manageUser() {
    this.router.navigate(["/manage-user"]);

  }

  editProfile() {
    this.router.navigate(["/edit-profile"]);
  }

}
