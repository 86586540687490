import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[specialCharacters]",
})
export class SpecialCharactersDirective {
  elementRef: ElementRef;
  @Input() inputDataType: number;
  @Input() isAllowCopyPaste: boolean = false;
  charSet: any = [];

  constructor(private el: ElementRef) {
    this.elementRef = el;
  }

  @HostListener("keypress", ["$event"]) onKeyPress(event) {
    let key = event.keyCode;
    let keyboardSelectionKeyEnum: number = this.inputDataType;
    this.charSet = this.characterSet();
    var character = event.key;
    if (this.charSet[keyboardSelectionKeyEnum].indexOf(character) == -1) {
      if (key != 13) {
        if (key == 0) {
          return;
        } else {
          event.returnValue = null;
          event.preventDefault();
        }
      }
    }
    if (key == 32 && event.target.value.trim() == "") {
      event.target.value == "";
      return false;
    }
  }

  @HostListener("paste", ["$event"]) blockPaste(event) {
    if (this.isAllowCopyPaste) {
      const { clipboardData } = event;
      const pasteText = clipboardData.getData("text");
      let result = this.validateFields(pasteText);
      if (!result) {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  }

  @HostListener("change", ["$event"]) blockDragDrop(event) {
    if (this.isAllowCopyPaste) {
      const { clipboardData } = event;
      const pasteText = clipboardData.getData("text");
      let result = this.validateFields(pasteText);
      if (!result) {
        event.preventDefault();
      }
    } else {
      setTimeout(() => {
        event.target.value == null;
        event.preventDefault();
      }, 10);
    }
  }

  validateFields(value: string): boolean {
    let keyboardSelectionEnumValue: number = this.inputDataType;
    this.charSet = this.characterSet();
    for (let i = 0; i < value.length; i++) {
      let stringChar = value.charAt(i);
      if (this.charSet[keyboardSelectionEnumValue].indexOf(stringChar) == -1) {
        return false;
      }
    }
    return true;
  }

  characterSet(): any {
    var charSet = new Array();
    charSet[0] =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ";
      charSet[1] =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ";
      charSet[2] =
      "0123456789";
      charSet[3] =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return charSet;
  }
}
