import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent, homeChildRoutes } from './pages/home/home.component'
import { LoginComponent } from './pages/login/login.component'
import { AuthGuard } from './helpers/guards/AuthGuard'
import { CreateUserComponent } from './pages/create-user/create-user.component';
import { CreatePasswordComponent } from './pages/create-password/create-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { ManageUserComponent } from './pages/manage-user/manage-user.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent, children: homeChildRoutes,
    canActivate: [AuthGuard], pathMatch: 'prefix'
  },
  { path: 'login', component: LoginComponent },
  {
    path: 'create-user', component: CreateUserComponent, canActivate: [AuthGuard],
  },
  {
    path: 'manage-user', component: ManageUserComponent, canActivate: [AuthGuard],
  },
  {
    path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthGuard],
  },
  { path: 'create-password', component: CreatePasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: '**', redirectTo: '' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }