import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import FileSaver from "file-saver";
import JSZip from "jszip";
import { ToastrService } from "ngx-toastr";
import { Utilities } from "src/app/services/UtliltyService";
import { NovoFileNames } from "../shared/enums/enum";
import { Header } from "../shared/models/UsbGenerator";
import { AuthenticationService } from "./AuthenticationService";
import { CommonServices } from "./CommonServices";
import { FlDryerModelServices } from "./FlDryerModel.service";
import { FlWasherModelServices } from "./FlWasherModel.service";
import { ModelServices } from "./ModelServices";
import { TlDryerModelServices } from "./TlDryerModel.service";
import { TlWasherModelServices } from "./TlWasherModel.service";
import { VenusModelServices } from "./VenusModel.service";

@Injectable({
  providedIn: "root",
})
export class UsbDownload {
  dialogRef: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private commonService: CommonServices,
    private utilities: Utilities,
    private venusModelServices: VenusModelServices,
    private tlDryerModelServices: TlDryerModelServices,
    private tlWasherModelServices: TlWasherModelServices,
    private _flDryerModelServices: FlDryerModelServices,
    private _flWasherModelServices: FlWasherModelServices,
    private _authenticationService: AuthenticationService,
    private commonServices: CommonServices,
    private modelServices: ModelServices,
  ) { }

  ConvertIntoArray(values: any) {
    if (values !== null) {
      var tempArray = new Array();
      var hArray = new Array();
      hArray = values.split(",");
      for (var i = 0; i < hArray.length; i++) {
        if (!isNaN(hArray[i]) && parseInt(hArray[i]) == 0) {
          for (var j = 0; j < hArray[i].length; j++) {
            tempArray.push(hArray[i].charAt(j));
          }
        } else {
          if (!isNaN(hArray[i]) && parseInt(hArray[i]) > 0) {
            tempArray.push(hArray[i]);
          } else {
            for (var j = 0; j < hArray[i].length; j++) {
              if (!isNaN(hArray[i].charAt(j)))
                tempArray.push(hArray[i].charAt(j).charCodeAt(0).toString(10));
              else tempArray.push(hArray[i].charAt(j));
            }
          }
        }
      }
      return tempArray;
    }
  }

  ConvertIntoArraycycle(values: any) {
    if (values !== null) {
      var tempArray = new Array();
      var hArray = new Array();
      hArray = values.split("@$");
      for (var i = 0; i < hArray.length; i++) {
        if (!isNaN(hArray[i]) && parseInt(hArray[i]) == 0) {
          for (var j = 0; j < hArray[i].length; j++) {
            tempArray.push(hArray[i].charAt(j));
          }
        } else {
          if (!isNaN(hArray[i]) && parseInt(hArray[i]) > 0) {
            tempArray.push(hArray[i]);
          } else {
            for (var j = 0; j < hArray[i].length; j++) {
              if (!isNaN(hArray[i].charAt(j)))
                tempArray.push(hArray[i].charAt(j).charCodeAt(0).toString(10));
              else tempArray.push(hArray[i].charAt(j));
            }
          }
        }
      }
      return tempArray;
    }
  }

  combinedArray(arr1: any, arr2: any, arr3: any) {
    if (
      (arr1 && arr1.length !== undefined) ||
      (arr2 && arr2.length !== undefined) ||
      (arr3 && arr3.length !== undefined)
    ) {
      var combinedArray: number[] = [];
      for (var i = 0; i < arr1.length; i++) {
        if (isNaN(arr1[i]))
          combinedArray.push(parseInt(arr1[i].charCodeAt(0).toString(10)));
        else combinedArray.push(parseInt(arr1[i]));
      }
      for (var j = 0; j < arr2.length; j++) {
        if (isNaN(arr2[j]))
          combinedArray.push(parseInt(arr2[j].charCodeAt(0).toString(10)));
        else combinedArray.push(parseInt(arr2[j]));
      }
      if (arr3 != 0) combinedArray.push(parseInt(arr3));
      return combinedArray;
    }
  }

  Uint32_To_Byte_Array(value) {
    if (value !== undefined) {
      let firstByte = BigInt(value) & BigInt(0x000000ff);
      let secondByte = (BigInt(value) & BigInt(0x0000ff00)) >> BigInt(8);
      let thirdByte = (BigInt(value) & BigInt(0x00ff0000)) >> BigInt(16);
      let fourthByte = (BigInt(value) & BigInt(0xff000000)) >> BigInt(16 | 8);
      return [
        firstByte.toString(),
        secondByte.toString(),
        thirdByte.toString(),
        fourthByte.toString(),
      ];
    }
  }

  combined32BitArray(arr1: any, arr2: any) {
    if (
      (arr1 && arr1.length !== undefined) ||
      (arr2 && arr2.length !== undefined)
    ) {
      var combinedArray: number[] = [];
      for (var i = 0; i < arr1.length; i++) {
        if (isNaN(arr1[i]))
          combinedArray.push(parseInt(arr1[i].charCodeAt(0).toString(10)));
        else combinedArray.push(parseInt(arr1[i]));
      }
      for (var j = 0; j < arr2.length; j++) {
        if (isNaN(arr2[j]))
          combinedArray.push(parseInt(arr2[j].charCodeAt(0).toString(10)));
        else combinedArray.push(parseInt(arr2[j]));
      }
      return combinedArray;
    }
  }

  downloadFromButon() {
    var header: Header = new Header();
    var arrHeader = this.ConvertIntoArray(localStorage.getItem("arrHeader"));
    var arrMachineConfigSettings = this.ConvertIntoArray(
      localStorage.getItem("arrMachineConfigSettings")
    );
    var arrMachineCombined = this.combinedArray(
      arrHeader,
      arrMachineConfigSettings,
      0
    );
    var machineCheckSum = this.utilities.ComputeChecksum(arrMachineCombined);
    var MachineBytes = new Uint8Array(
      this.combinedArray(arrHeader, arrMachineConfigSettings, machineCheckSum)
    );

    var arrHmiDisplaySettings = this.ConvertIntoArray(
      localStorage.getItem("arrHmiDisplaySettings")
    );
    var arrHmiCombined = this.combinedArray(
      arrHeader,
      arrHmiDisplaySettings,
      0
    );
    var hmiCheckSum = this.utilities.ComputeChecksum(arrHmiCombined);
    var HmiBytes = new Uint8Array(
      this.combinedArray(arrHeader, arrHmiDisplaySettings, hmiCheckSum)
    );
    var arrCycleSettings = this.ConvertIntoArraycycle(
      localStorage.getItem("arrCycleSettings")
    );
    var arrCycleCombined = this.combinedArray(arrHeader, arrCycleSettings, 0);
    var cycleCheckSum = this.Uint32_To_Byte_Array(
      this.utilities.ComputeChecksum32(arrCycleCombined)
    );
    var cycleBytes = new Uint8Array(
      this.combined32BitArray(arrCycleCombined, cycleCheckSum)
    );

    let zip = new JSZip();
    zip
      .folder("NOVO")
      .file(
        NovoFileNames.Machine_Config,
        new Blob([MachineBytes], { type: "octet/stream" })
      );
    zip
      .folder("NOVO")
      .file(
        NovoFileNames.hmi_display_Settings,
        new Blob([HmiBytes], { type: "octet/stream" })
      );
    zip
      .folder("NOVO")
      .file(
        NovoFileNames.Cycle_data_Settings,
        new Blob([cycleBytes], { type: "octet/stream" })
      );

    var MType = localStorage.getItem("ProductType");
    if (MType !== "PN") {
      var arrVendingConfigSettings = this.ConvertIntoArray(
        localStorage.getItem("arrVendingSettingDetail")
      );
      var arrVendingCombined = this.combinedArray(
        arrHeader,
        arrVendingConfigSettings,
        0
      );
      var VendingCheckSum = this.utilities.ComputeChecksum(arrVendingCombined);
      var VendingBytes = new Uint8Array(
        this.combinedArray(arrHeader, arrVendingConfigSettings, VendingCheckSum)
      );
      zip
        .folder("NOVO")
        .file(
          NovoFileNames.cycle_vending_Settings,
          new Blob([VendingBytes], { type: "octet/stream" })
        );
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, "" + header.ModelNumber + ".zip");
    });
  }

  generateCSPSetupBinaryFile() {
    var header: Header = new Header();
    var arrHeader = this.ConvertIntoArray(localStorage.getItem("arrHeader"));
    const venusSetupBinary = this.venusModelServices.mapSetupBinaryKey();
    const cycleSettingBinary = this.venusModelServices.mapCycleVendingBinaryKey();
    // Setup start
    var setupBytes = new Uint8Array(
      venusSetupBinary
    );

    // Cycle Vending start
    var cycleVendingBytes = new Uint8Array(
      cycleSettingBinary
    );
    let zip = new JSZip();
    zip
      .folder(`${'MS' + header.ModelNumber}`)
      .file(
        `${'MS' + header.ModelNumber + '.set'}`,
        new Blob([setupBytes, cycleVendingBytes], { type: "octet/stream" })
      );

    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, "" + `${'MS' + header.ModelNumber + '.zip'}`);
    });
  }

  generateTlDryerSetupBinaryFile() {
    var header: Header = new Header();
    const tlDryerSetupBinary = this.tlDryerModelServices.mapSetupBinaryKey();
    const cycleSettingBinary = this.tlDryerModelServices.mapCycleVendingBinaryKey();
    // Setup start
    var setupBytes = new Uint8Array(
      tlDryerSetupBinary
    );

    // Cycle Vending start
    var cycleVendingBytes = new Uint8Array(
      cycleSettingBinary
    );
    let zip = new JSZip();
    zip
      .folder(`${'MS' + header.ModelNumber}`)
      .file(
        `${'MS' + header.ModelNumber + '.set'}`,
        new Blob([setupBytes, cycleVendingBytes], { type: "octet/stream" })
      );

    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, "" + `${'MS' + header.ModelNumber + '.zip'}`);
    });
  }

  generateTlWasherBinaryFile() {
    var header: Header = new Header();
    const tlDryerSetupBinary = this.tlWasherModelServices.mapSetupBinaryKey();
    const cycleSettingBinary = this.tlWasherModelServices.mapCycleSettingBinaryKey();
    const cycleVendingSettingBinary = this.tlWasherModelServices.mapCycleVendingBinaryKey();
    // Setup start
    var setupBytes = new Uint8Array(
      tlDryerSetupBinary
    );
    // Cycle Setting
    var cycleSettingBytes = new Uint8Array(
      cycleSettingBinary
    );
    // Cycle Vending start
    var cycleVendingBytes = new Uint8Array(
      cycleVendingSettingBinary
    );
    let zip = new JSZip();
    zip
      .folder(`${'MS' + header.ModelNumber}`)
      .file(
        `${'MS' + header.ModelNumber + '.set'}`,
        new Blob([setupBytes, cycleSettingBytes, cycleVendingBytes], { type: "octet/stream" })
      );
    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, "" + `${'MS' + header.ModelNumber + '.zip'}`);
    });
  }

  generateFlDryerSetupBinaryFile() {
    var header: Header = new Header();
    const tlDryerSetupBinary = this._flDryerModelServices.mapSetupBinaryKey();
    const cycleSettingBinary = this._flDryerModelServices.mapCycleVendingBinaryKey();
    // Setup start
    var setupBytes = new Uint8Array(
      tlDryerSetupBinary
    );

    // Cycle Vending start
    var cycleVendingBytes = new Uint8Array(
      cycleSettingBinary
    );
    let zip = new JSZip();
    zip
      .folder(`${'MS' + header.ModelNumber}`)
      .file(
        `${'MS' + header.ModelNumber + '.set'}`,
        new Blob([setupBytes, cycleVendingBytes], { type: "octet/stream" })
      );

    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, "" + `${'MS' + header.ModelNumber + '.zip'}`);
    });
  }

  generateFlWasherSetupBinaryFile() {
    var header: Header = new Header();
    const flWasherSetupBinary = this._flWasherModelServices.mapSetupBinaryKey();
    const cycleSettingBinary = this._flWasherModelServices.mapCycleSettingBinaryKey();
    const cycleVendingSettingBinary = this._flWasherModelServices.mapCycleVendingBinaryKey();
    var setupBytes = new Uint8Array(
      flWasherSetupBinary
    );
    var cycleSettingBytes = new Uint8Array(
      cycleSettingBinary
    );
    var cycleVendingBytes = new Uint8Array(
      cycleVendingSettingBinary
    );
    let zip = new JSZip();
    zip
      .folder(`${'MS' + header.ModelNumber}`)
      .file(
        `${'MS' + header.ModelNumber + '.set'}`,
        new Blob([setupBytes, cycleSettingBytes, cycleVendingBytes], { type: "octet/stream" })
      );

    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, "" + `${'MS' + header.ModelNumber + '.zip'}`);
    });
  }

  generateNovoModel() {
    this.commonService.novoEcoModels.setupConfig = '';
    this.commonService.novoEcoModels.cycleConfig = '';
    this.commonService.novoEcoModels.vendingConfig = '';
    this.commonService.novoEcoModels.hmiConfig = '';
    this.commonService.setSetUpValue();
    this.commonService.setHmiSetUpValue();
    // if (this.modelServices.isCycleVendingSettingsTobePush) {
    this.commonService.setVendingSettingValue();
    // }
    if (this.commonServices.userDefinedConfig.configId) {
      this.putSaveConfig(this.commonService.novoEcoModels)
      return;
    }
    this.postSaveConfig(this.commonService.novoEcoModels)
  }

  generateCSPModel() {
    this.commonService.novoEcoModels.setupConfig = '';
    this.commonService.novoEcoModels.vendingConfig = '';
    this.venusModelServices.getSetupModelData();
    this.venusModelServices.getVendingModelData();
    if (this.commonServices.userDefinedConfig.configId) {
      this.putSaveConfig(this.commonService.novoEcoModels)
      return;
    }
    this.postSaveConfig(this.commonService.novoEcoModels)
  }

  generateTlDryerModel() {
    this.commonService.novoEcoModels.setupConfig = '';
    this.commonService.novoEcoModels.vendingConfig = '';
    this.tlDryerModelServices.getSetupModelData();
    this.tlDryerModelServices.getVendingModelData();
    if (this.commonServices.userDefinedConfig.configId) {
      this.putSaveConfig(this.commonService.novoEcoModels)
      return;
    }
    this.postSaveConfig(this.commonService.novoEcoModels)
  }

  generateTlWasherModel() {
    this.commonService.novoEcoModels.setupConfig = '';
    this.commonService.novoEcoModels.vendingConfig = '';
    this.commonService.novoEcoModels.cycleConfig = '';
    this.tlWasherModelServices.getSetupModelData();
    this.tlWasherModelServices.getVendingModelData();
    this.tlWasherModelServices.getSettingModelData();
    if (this.commonServices.userDefinedConfig.configId) {
      this.putSaveConfig(this.commonService.novoEcoModels)
      return;
    }
    this.postSaveConfig(this.commonService.novoEcoModels)
  }

  generateFlDryerModel() {
    this.commonService.novoEcoModels.setupConfig = '';
    this.commonService.novoEcoModels.vendingConfig = '';
    this._flDryerModelServices.getSetupModelData();
    this._flDryerModelServices.getVendingModelData();
    if (this.commonServices.userDefinedConfig.configId) {
      this.putSaveConfig(this.commonService.novoEcoModels)
      return;
    }
    this.postSaveConfig(this.commonService.novoEcoModels)
  }

  generateFlWasherModel() {
    this.commonService.novoEcoModels.setupConfig = '';
    this.commonService.novoEcoModels.vendingConfig = '';
    this.commonService.novoEcoModels.cycleConfig = '';
    this._flWasherModelServices.getSetupModelData();
    this._flWasherModelServices.getVendingModelData();
    this._flWasherModelServices.getSettingModelData();
    if (this.commonServices.userDefinedConfig.configId) {
      this.putSaveConfig(this.commonService.novoEcoModels)
      return;
    }
    this.postSaveConfig(this.commonService.novoEcoModels)
  }

  deleteModel() {
    const configId = this.commonServices.userDefinedConfig.configId;
    const requestURL = `${'/api/v1/usbConfigSetups/' + configId}`;
    this._authenticationService
      .callDeleteAPI(requestURL)
      .subscribe(
        (res) => {
          if (res.message == 'Success') {
            this.showMessage('Config deleted successfully', false);
            this.commonServices.getSaveConfigModelList.next(configId);
            for (const prop of Object.getOwnPropertyNames(this.commonServices.userDefinedConfig)) {
              delete this.commonServices.userDefinedConfig[prop];
            }
            this.commonServices.disableTopFeaure.next("Disalbe");
            this.commonServices.generateButtonHide.next(true);
            this.router.navigate(['/']);
          }
        },
        (err) => {
          this.showMessage(err, true);
        }
      );
  }

  postSaveConfig(data: any) {
    const requestURL = '/api/v1/usbConfigSetups';
    const payload = {
      "modelNo": this.commonServices.saveConfigData.modelNumber,
      "name": this.commonServices.saveConfigData.configNumber,
      ...data
    }
    this._authenticationService
      .callPostAPI(requestURL, JSON.stringify(payload))
      .subscribe(
        (res) => {
          if (res.message == 'Success') {
            this.showMessage('Config saved successfully', false);
            this.commonService.isConfigSave.next(true);
          }
          this.commonServices.saveConfigData.configNumber = '';
        },
        (err) => {
          this.showMessage(err, true);
          this.commonService.isConfigSave.next(false);
        }
      );
  }

  putSaveConfig(data: any) {
    const configId = this.commonServices.userDefinedConfig.configId;
    const requestURL = '/api/v1/usbConfigSetups';
    const payload = {
      "configId": configId,
      "modelNo": configId.split("-")[0],
      "name": configId.replace(configId.split('-')[0] + '-', ''),
      ...data
    }
    this._authenticationService
      .callPutAPI(requestURL, JSON.stringify(payload))
      .subscribe(
        (res) => {
          if (res.message == 'Success') {
            this.showMessage('Config updated successfully', false);
          }
          this.commonServices.saveConfigData.configNumber = '';
        },
        (err) => {
          this.showMessage(err, true);
        }
      );
  }

  showMessage(message, isError) {
    if (!isError) {
      this.toastr.success(message, 'Success', {
        positionClass: 'toast-top-right'
      });
    } else {
      this.toastr.error(message, 'Failed', {
        positionClass: 'toast-top-right'
      });
    }
  }

}