import { Injectable } from "@angular/core";
import * as tlWasherModel from "src/assets/JsonFiles/tl-washer/tl-washer-model.json";
import * as tlWasherSetup from "src/assets/JsonFiles/tl-washer/tl-washer-setup.json";
import * as tlWasherSCycleetting from "src/assets/JsonFiles/tl-washer/tl-washer-cycle-setting.json";
import * as tlWasherCycleVendingSettings from "src/assets/JsonFiles/tl-washer/tl-washer-Cycle-Vending-Settings.json";
import { IModelList } from "./ModelServices";
import { TlWasherSetup, TlWasherSetupDetail, TlWasherSetupList, IValue } from "../shared/layout/tl-washer/tl-washer-setup/tl-washer-setup.model";
import { ICycleSettingDnldCycle, ICycleSettingValue, ITlWasherCycleSetting, ITlWasherCycleSettingCycleName, ITlWasherCycleSettingData, ITlWasherCycleSettingList, ITlWasherCycleSettingRinse, ITlWasherCycleSettingValues } from "../shared/layout/tl-washer/tl-washer-cycle/tl-washer-cycle.model";
import { ICoin1Value, ICoolTemperature, ICycleSpecialPrice, IFixedCycleEnable, IRegularPriceCycleLen, IRelationalEntityKey, IRelationalEnvelopeOpen, ISpecialPriceCycleLen, ISpecialPriceTime, ISpecialPricingEnable, ISundaySpecialPriceEnable, IValueChecked, IValueCoin1, IVended, TlWasherList } from "../shared/layout/tl-washer/tl-washer-cycle-vending/tl-washer-cycle-vending.model";
import { Subject } from "rxjs";
import * as WasherCycle from "src/assets/JsonFiles/tl-washer/tl-washer-cycles-data.json";
import { ICycleDataList, ITlCycleData } from "../shared/layout/tl-washer/tl-washer.model";
import { Utilities } from "./UtliltyService";
import { CommonServices } from "./CommonServices";
import { ICommonModel, ICylclesDataList } from "../shared/models/common";
import { IGetUserDefinedConfiguration } from "../shared/layout/user-defined-setup/user-defined-setup.model";

@Injectable({
    providedIn: "root",
})
export class TlWasherModelServices {
    public tlWasherModel: IModelList = (tlWasherModel as any).default;
    tlwashersetup: TlWasherSetupList = (tlWasherSetup as any).default;
    vendingsettings: TlWasherList = (tlWasherCycleVendingSettings as any).default;
    tlWasherCycleSettings: ITlWasherCycleSetting = (tlWasherSCycleetting as any).default;
    isModelChange: boolean = false;
    selectedCycle = new Subject<any>();
    selectedCycleSettings: any;
    prevSelectedCycle: string;
    public coin1Value: ICoin1Value = {
        cent: '',
        dollar: ''
    };
    public washercycles: ITlCycleData = (WasherCycle as any).default;
    value: IValue = {
        id: 0,
        name: ''
    }
    regularOffSet: TlWasherSetupDetail = {
        value: 0,
        values: this.value,
        Default: 0,
        arrKey: [],
        binaryKey: '',
        type: ''
    }
    setupModelList: TlWasherSetup = {
        CycleCountingEnable: this.regularOffSet,
        MoneyCountingOption: this.regularOffSet,
        CountViewing: this.regularOffSet,
        VendingDisplay: this.regularOffSet,
        CoinPaymentEnable: this.regularOffSet,
        DebitCardPaymentProtocol: this.regularOffSet,
        AutoClearEscrowEnable: this.regularOffSet,
        CoinSlideEnable: this.regularOffSet,
        hasValue: false
    }

    ValueChecked: IValueChecked = {
        id: 0,
        name: '',
        checked: false
    }

    regularPriceCycleLen: IRegularPriceCycleLen = {
        value: '',
        default: '',
        values: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        min: '',
        max: ''
    }

    fixedCycleEnable: IFixedCycleEnable = {
        value: '',
        default: '',
        values: [],
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    specialPricingEnable: ISpecialPricingEnable = {
        value: false,
        default: false,
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    specialPriceEnable: ISundaySpecialPriceEnable = {
        value: '',
        default: '',
        values: this.ValueChecked,
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    specialPriceTime: ISpecialPriceTime = {
        value: [],
        default: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        endHour: '',
        startHour: ''
    }

    specialPriceCycleLen: ISpecialPriceCycleLen = {
        value: '',
        default: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        max: '',
        min: ''
    }

    cycleSpecialPrice: ICycleSpecialPrice = {
        value: [],
        default: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        cents: '',
        dollars: ''
    }

    ValueCoin1: IValueCoin1 = {
        value: [],
        default: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        dollars: '',
        cents: '',
    }

    relationalEnvelopeOpen: IRelationalEnvelopeOpen = {
        binaryKeyValue: '',
        arrKey: [],
        type: ''
    }

    relationalEntityKey: IRelationalEntityKey = {
        value: '',
        binaryKeyValue: '',
        arrKey: [],
        type: ''
    }

    vendingTemperature: ICoolTemperature = {
        value: [],
        default: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        dollars: '',
        cents: ''
    }

    vendingSettingList: IVended = {
        Relational_Envelope_Open: this.relationalEnvelopeOpen,
        Relational_Envelope_Action: this.relationalEnvelopeOpen,
        Entity_Cycle_Open: this.relationalEnvelopeOpen,
        Relational_Entity_Name: this.relationalEntityKey,
        Relational_Entity_Id: this.relationalEnvelopeOpen,
        DnldCycle_CycleSet_CycleCavityPosition: this.relationalEnvelopeOpen,
        Relational_Array_Open: this.relationalEnvelopeOpen,

        Entity_Cycle_Part1_Open: this.relationalEnvelopeOpen,
        Entity_Cycle_Part1_Close: this.relationalEnvelopeOpen,
        Entity_Cycle_Close: this.relationalEnvelopeOpen,
        Relational_Array_Close: this.relationalEnvelopeOpen,
        Relational_Envelope_Close: this.relationalEnvelopeOpen,
        DnldCycle_OpSet_RegularPriceCycleLen: this.regularPriceCycleLen,
        FixedCycleEnable: this.fixedCycleEnable,
        SpecialPricingEnable: this.specialPricingEnable,
        SundaySpecialPriceEnable: this.specialPriceEnable,
        MondaySpecialPriceEnable: this.specialPriceEnable,
        TuesdaySpecialPriceEnable: this.specialPriceEnable,
        WednesdaySpecialPriceEnable: this.specialPriceEnable,
        ThursdaySpecialPriceEnable: this.specialPriceEnable,
        FridaySpecialPriceEnable: this.specialPriceEnable,
        SaturdaySpecialPriceEnable: this.specialPriceEnable,
        SpecialPriceTime: this.specialPriceTime,
        DnldCycle_OpSet_SpecialPriceCycleLen: this.specialPriceCycleLen,
        DnldCycle_PaySet_CycleSpecialPrice: this.cycleSpecialPrice,
        DnldCycle_PaySet_CycleRegularPrice: this.cycleSpecialPrice,
        ValueCoin1: this.ValueCoin1,
        ValueCoin2: this.ValueCoin1,
        CoolTemperature: this.vendingTemperature,
        WarmTemperature: this.vendingTemperature,
        HotTemperature: this.vendingTemperature,
        HeavySoil: this.vendingTemperature,
        ExtraRinseAdder: this.vendingTemperature,
        hasValue: false
    }

    cycleSettingValue: ICycleSettingValue = {
        id: '',
        name: ''
    }

    cycleSettingValues: ITlWasherCycleSettingValues = {
        value: '',
        default: '',
        values: this.cycleSettingValue,
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    cycleSettingRinseValues: ITlWasherCycleSettingRinse = {
        value: false,
        default: '',
        values: this.cycleSettingValue,
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    cycleSettingDnldCycle: ICycleSettingDnldCycle = {
        value: [],
        default: '',
        dollars: '',
        cents: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    cycleSettingList: ITlWasherCycleSettingList = {
        Relational_Envelope_Open: this.relationalEnvelopeOpen,
        Relational_Envelope_Action: this.relationalEnvelopeOpen,
        Entity_Cycle_Open: this.relationalEnvelopeOpen,
        Relational_Entity_Name: this.relationalEntityKey,
        Relational_Entity_Id: this.relationalEnvelopeOpen,
        DnldCycle_CycleSet_CycleCavityPosition: this.relationalEnvelopeOpen,
        DnldCycle_PaySet_CycleRegularPrice: this.cycleSettingDnldCycle,
        DnldCycle_PaySet_CycleSpecialPrice: this.cycleSettingDnldCycle,
        DnldCycle_OpSet_SoilLevel: this.cycleSettingValues,
        DnldCycle_OpSet_Temperature: this.cycleSettingValues,
        Relational_Array_Open: this.relationalEnvelopeOpen,
        Entity_Cycle_Part1_Open: this.relationalEnvelopeOpen,
        Entity_Cycle_Part1_Close: this.relationalEnvelopeOpen,
        Relational_Array_Close: this.relationalEnvelopeOpen,
        Entity_Cycle_Close: this.relationalEnvelopeOpen,
        Relational_Envelope_Close: this.relationalEnvelopeOpen,
        DnldCycle_OpSet_ExtraRinse: this.cycleSettingRinseValues,
        hasValue: false
    }

    CycleSettingCycleName: ITlWasherCycleSettingCycleName[] = [{
        ['']: this.cycleSettingList
    }]

    CycleSettingModel: ITlWasherCycleSettingData = {
        ['']: this.CycleSettingCycleName,
    }

    // TlDryerSetup
    arrTlWasherSetupConfigSettings: any[] = [];
    arrTlWasherCycleVendingConfigSettings: any[] = [];
    arrTlWasherCycleSettingConfigSettings: any[] = [];
    cycleData: ICylclesDataList = {
        Relational_Entity_Name: '',
        Relational_Entity_Id: 0,
        DnldCycle_OpSet_SoilLevel: '',
        DnldCycle_OpSet_Temperature: '',
        CycleSpecialPrice: [],
        CycleRegularPrice: [],
        DnldCycle_OpSet_ExtraRinse: ''
    };

    prevCoinValue: string = '';

    constructor(
        private utilityService: Utilities,
        private commonService: CommonServices
    ) {
        this.commonService.setBinaryTlWasher.subscribe((item: ICommonModel) => {
            this.setBinarySetupModels(item);
        });
        this.commonService.isTlWasherModel.subscribe((item: IGetUserDefinedConfiguration) => {
            const setupModelList = JSON.parse(item.setupConfig);
            const vendConf = JSON.parse(item.vendingConfig);
            const cycleConf = JSON.parse(item.cycleConfig);
            if (setupModelList.hasValue) {
                this.setModelSetupModels(setupModelList);
            }
            if (vendConf.hasValue) {
                this.setModelCycleVending(vendConf);
            }
            if (cycleConf) {
                this.setModelsCycleSettingModels(cycleConf);
            }
        });
    }

    getDefaultWasherCycleList() {
        return this.washercycles;
    }

    getWasherCycleList(modelNumber: any): ICycleDataList {
        const type = this.getProductType(modelNumber)
        switch (type) {
            case "PR":
                return this.washercycles.Cycles.PR;
            case "PD":
                return this.washercycles.Cycles.PD;
            case "9J":
                return this.washercycles.Cycles["9J"];
            case "PDFG":
                return this.washercycles.Cycles["PDFG"];
        }
    }


    getProductType(modelNumber: any) {
        if (modelNumber == 'MAT20PDFGW0') {
            this.tlWasherModel.ProductType = modelNumber.substr(5, 4);
            return this.tlWasherModel.ProductType;
        }
        var weight = modelNumber.substr(3, 3);
        var productType = null;
        if (isNaN(+weight)) productType = modelNumber.substr(5, 2);
        else productType = modelNumber.substr(6, 2);
        this.tlWasherModel.ProductType = productType;
        return productType;
    }

    getTlWasherSetupSettings(modelType: string) {
        this.isModelChange = false;
        if (modelType === 'PD') {
            return this.tlwashersetup.setup.PD;
        } else if (modelType === 'PR') {
            return this.tlwashersetup.setup.PR;
        } else if (modelType === '9J') {
            return this.tlwashersetup.setup['9J'];
        } else if (modelType === 'PDFG') {
            return this.tlwashersetup.setup['PDFG'];
        }
    }

    getTlWasherCycleVendingSettings(modelType: string) {
        this.isModelChange = false;
        if (modelType === 'PD') {
            return this.vendingsettings.vended.PD;
        } else if (modelType === 'PR') {
            return this.vendingsettings.vended.PR;
        } else if (modelType === '9J') {
            return this.vendingsettings.vended['9J'];
        } else if (modelType === 'PDFG') {
            return this.vendingsettings.vended['PDFG'];
        }
    }

    getTlWasherCycleSettings(modelType: string): ITlWasherCycleSettingCycleName[] {
        this.isModelChange = false;
        if (modelType === 'PD') {
            return this.tlWasherCycleSettings.cycleSetting['PD'];
        } else if (modelType === 'PR') {
            return this.tlWasherCycleSettings.cycleSetting['PR'];
        } else if (modelType === '9J') {
            return this.tlWasherCycleSettings.cycleSetting['9J'];
        } else if (modelType === 'PDFG') {
            return this.tlWasherCycleSettings.cycleSetting['PDFG'];
        }
    }

    setTlWasherCycleSettingModelList(cycleSettingList: ITlWasherCycleSettingList) {
        this.isModelChange = false;
        this.cycleSettingList = cycleSettingList;
    }

    getTlWasherModelList(): IModelList {
        return this.tlWasherModel;
    }

    clearCycleSettingModels(modelNumber: string, modelType: string) {
        const cycles = this.getWasherCycleList(modelNumber);
        this.CycleSettingCycleName = this.getTlWasherCycleSettings(modelType);
        for (let index = 0; index < cycles.CycleName.length; index++) {
            this.cycleSettingList = this.CycleSettingCycleName[cycles.CycleName[index].tvalue];
            this.cycleSettingList.Relational_Entity_Name.value = '';
            this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = '0';
            this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = '0';
            this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = '0';
            this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = '0';
            this.cycleSettingList.DnldCycle_OpSet_SoilLevel.value = '';
            this.cycleSettingList.DnldCycle_OpSet_Temperature.value = '';
            this.cycleSettingList.DnldCycle_OpSet_ExtraRinse.value = false;
            this.cycleSettingList.hasValue = false
            this.isModelChange = true;
        }
    }

    setCycleSettingModels(commonModel: ICommonModel) {
        let splBytes;
        let regBytes;
        const modelType = localStorage.getItem("ProductType");
        this.CycleSettingCycleName = this.getTlWasherCycleSettings(modelType);
        for (let index = 0; index < commonModel.CyclesDataList.length; index++) {
            this.cycleData = JSON.parse(commonModel.CyclesDataList[index]);
            this.cycleSettingList = this.CycleSettingCycleName[this.cycleData.Relational_Entity_Name.replace(/\s/g, "").toUpperCase()];
            if (!this.cycleSettingList) {
                continue;
            }
            this.cycleSettingList.Relational_Entity_Name.value = this.cycleData.Relational_Entity_Name;
            if (modelType != '9J') {
                splBytes = this.utilityService.convertBinToDecArry(this.cycleData.CycleSpecialPrice[0], this.cycleData.CycleSpecialPrice[1]);
                this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = splBytes[0];
                this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = splBytes[1];
            }
            regBytes = this.utilityService.convertBinToDecArry(this.cycleData.CycleRegularPrice[0], this.cycleData.CycleRegularPrice[1]);
            this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = regBytes[0];
            this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = regBytes[1];
            this.cycleSettingList.DnldCycle_OpSet_SoilLevel.value = this.cycleData.DnldCycle_OpSet_SoilLevel;
            this.cycleSettingList.DnldCycle_OpSet_Temperature.value = this.cycleData.DnldCycle_OpSet_Temperature;
            this.cycleSettingList.DnldCycle_OpSet_ExtraRinse.value = this.cycleData.DnldCycle_OpSet_ExtraRinse == '1' ? true : false;
            this.cycleSettingList.hasValue = true;
        }
    }

    setModelsCycleSettingModels(commonModel: any) {
        const modelType = localStorage.getItem("ProductType");
        this.CycleSettingCycleName = this.getTlWasherCycleSettings(modelType);
        let result = Object.keys(commonModel).map((key) => commonModel[key]);
        for (let index = 0; index < result.length; index++) {
            const cycleData = result[index];
            const relational_Entity_Name: string = cycleData.Relational_Entity_Name.value.replace(/\s/g, "");
            this.cycleSettingList = this.CycleSettingCycleName[relational_Entity_Name.toUpperCase()];
            this.cycleSettingList.Relational_Entity_Name.value = cycleData.Relational_Entity_Name.value;
            if (modelType != '9J') {
                this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = cycleData.DnldCycle_PaySet_CycleSpecialPrice.value[0];
                this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = cycleData.DnldCycle_PaySet_CycleSpecialPrice.value[1];
            }
            this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = cycleData.DnldCycle_PaySet_CycleRegularPrice.value[0];
            this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = cycleData.DnldCycle_PaySet_CycleRegularPrice.value[1];
            this.cycleSettingList.DnldCycle_OpSet_SoilLevel.value = cycleData.DnldCycle_OpSet_SoilLevel.value;
            this.cycleSettingList.DnldCycle_OpSet_Temperature.value = cycleData.DnldCycle_OpSet_Temperature.value;
            this.cycleSettingList.DnldCycle_OpSet_ExtraRinse.value = cycleData.DnldCycle_OpSet_ExtraRinse.value;
            this.cycleSettingList.hasValue = true;
        }
    }

    setSetupModels(setupList: TlWasherSetup) {
        this.isModelChange = false;
        this.setupModelList = setupList;
    }

    getSetupModels() {
        return this.setupModelList;
    }

    clearSetupModels() {
        this.setupModelList.CycleCountingEnable.value = 0;
        this.setupModelList.MoneyCountingOption.value = 0;
        this.setupModelList.CountViewing.value = 1;
        this.setupModelList.VendingDisplay.value = 0;
        this.setupModelList.CoinPaymentEnable.value = 0;
        this.setupModelList.DebitCardPaymentProtocol.value = 0;
        this.setupModelList.AutoClearEscrowEnable.value = 0;
        this.setupModelList.CoinSlideEnable.value = 0;
        this.setupModelList.hasValue = false;
        this.isModelChange = true;
        return this.setupModelList;
    }

    setBinarySetupModels(commonModel: ICommonModel) {
        this.setupModelList = this.getTlWasherSetupSettings(localStorage.getItem("ProductType"));
        this.setupModelList.CycleCountingEnable.value = commonModel.CycleCountingEnable ? commonModel.CycleCountingEnable : 0;
        this.setupModelList.MoneyCountingOption.value = commonModel.MoneyCountingOption ? commonModel.MoneyCountingOption : 0;
        this.setupModelList.CountViewing.value = commonModel.CountViewing ? commonModel.CountViewing : 0;
        this.setupModelList.VendingDisplay.value = commonModel.VendingDisplay ? commonModel.VendingDisplay : 0;
        this.setupModelList.CoinPaymentEnable.value = commonModel.CoinPaymentEnable ? commonModel.CoinPaymentEnable : 0;
        this.setupModelList.DebitCardPaymentProtocol.value = commonModel.DebitCardPaymentProtocol ? commonModel.DebitCardPaymentProtocol : 0;
        this.setupModelList.AutoClearEscrowEnable.value = commonModel.AutoClearEscrowEnable ? commonModel.AutoClearEscrowEnable : 0;
        this.setupModelList.CoinSlideEnable.value = commonModel.CoinSlideEnable ? commonModel.CoinSlideEnable : 0;
        this.setupModelList.hasValue = true;
        this.commonService.getClose();
        this.setCycleSettingModels(commonModel);
        this.setBinaryCycleVending(commonModel);
    }

    setModelSetupModels(setupModelList: any) {
        this.setupModelList = this.getTlWasherSetupSettings(localStorage.getItem("ProductType"));
        this.setupModelList.CycleCountingEnable.value = setupModelList.CycleCountingEnable.value;
        this.setupModelList.MoneyCountingOption.value = setupModelList.MoneyCountingOption.value;
        this.setupModelList.CountViewing.value = setupModelList.CountViewing.value;
        this.setupModelList.VendingDisplay.value = setupModelList.VendingDisplay.value;
        this.setupModelList.CoinPaymentEnable.value = setupModelList.CoinPaymentEnable.value;
        this.setupModelList.DebitCardPaymentProtocol.value = setupModelList.DebitCardPaymentProtocol.value;
        this.setupModelList.AutoClearEscrowEnable.value = setupModelList.AutoClearEscrowEnable.value;
        this.setupModelList.CoinSlideEnable.value = setupModelList.CoinSlideEnable.value;
        this.setupModelList.hasValue = true;
    }

    setVenusCycleModels(venusCycleModel: IVended) {
        this.isModelChange = false;
        this.vendingSettingList = venusCycleModel;
    }

    getVenusCycleModels() {
        return this.vendingSettingList;
    }

    clearVenusCycleModels() {
        this.vendingSettingList.DnldCycle_OpSet_RegularPriceCycleLen.value = '0';
        //  this.vendingSettingList.FixedCycleEnable.value = '0';
        if (this.vendingSettingList.SpecialPricingEnable != undefined) {
            this.vendingSettingList.SpecialPricingEnable.value = false;
            this.vendingSettingList.SundaySpecialPriceEnable.value = '0';
            this.vendingSettingList.MondaySpecialPriceEnable.value = '0';
            this.vendingSettingList.TuesdaySpecialPriceEnable.value = '0';
            this.vendingSettingList.WednesdaySpecialPriceEnable.value = '0';
            this.vendingSettingList.ThursdaySpecialPriceEnable.value = '0';
            this.vendingSettingList.FridaySpecialPriceEnable.value = '0';
            this.vendingSettingList.SaturdaySpecialPriceEnable.value = '0';
            this.vendingSettingList.SundaySpecialPriceEnable.values.checked = false;
            this.vendingSettingList.MondaySpecialPriceEnable.values.checked = false;
            this.vendingSettingList.TuesdaySpecialPriceEnable.values.checked = false;
            this.vendingSettingList.WednesdaySpecialPriceEnable.values.checked = false;
            this.vendingSettingList.ThursdaySpecialPriceEnable.values.checked = false;
            this.vendingSettingList.FridaySpecialPriceEnable.values.checked = false;
            this.vendingSettingList.SaturdaySpecialPriceEnable.values.checked = false;
            this.vendingSettingList.SpecialPriceTime.value[0] = '0';
            this.vendingSettingList.SpecialPriceTime.value[1] = '0';
            this.vendingSettingList.DnldCycle_OpSet_SpecialPriceCycleLen.value = '0';
            this.vendingSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = '0';
            this.vendingSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = '0';

            this.vendingSettingList.CoolTemperature.value[0] = '0';
            this.vendingSettingList.CoolTemperature.value[1] = '0';

            this.vendingSettingList.WarmTemperature.value[0] = '0';
            this.vendingSettingList.WarmTemperature.value[1] = '0';

            this.vendingSettingList.HotTemperature.value[0] = '0';
            this.vendingSettingList.HotTemperature.value[1] = '0';

            this.vendingSettingList.HeavySoil.value[0] = '0';
            this.vendingSettingList.HeavySoil.value[1] = '0';

            this.vendingSettingList.ExtraRinseAdder.value[0] = '0';
            this.vendingSettingList.ExtraRinseAdder.value[1] = '0';
        }
        this.vendingSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = '0';
        this.vendingSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = '0';
        this.vendingSettingList.ValueCoin1.value[0] = '0';
        this.vendingSettingList.ValueCoin1.value[1] = '0';
        this.vendingSettingList.ValueCoin2.value[0] = '0';
        this.vendingSettingList.ValueCoin2.value[1] = '0';
        this.vendingSettingList.hasValue = false
        this.isModelChange = true;
        return this.vendingSettingList;
    }

    setBinaryCycleVending(commonModel: ICommonModel) {
        let twoBytes;
        this.vendingSettingList = this.getTlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
        const modelType = localStorage.getItem("ProductType");
        if (modelType != '9J') {
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.CoolTemperature[0], commonModel.CoolTemperature[1]);
            this.vendingSettingList.CoolTemperature.value[0] = twoBytes[0];
            this.vendingSettingList.CoolTemperature.value[1] = twoBytes[1];
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.WarmTemperature[0], commonModel.WarmTemperature[1]);
            this.vendingSettingList.WarmTemperature.value[0] = twoBytes[0];
            this.vendingSettingList.WarmTemperature.value[1] = twoBytes[1];
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.HotTemperature[0], commonModel.HotTemperature[1]);
            this.vendingSettingList.HotTemperature.value[0] = twoBytes[0];
            this.vendingSettingList.HotTemperature.value[1] = twoBytes[1];
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.HeavySoil[0], commonModel.HeavySoil[1]);
            this.vendingSettingList.HeavySoil.value[0] = twoBytes[0];
            this.vendingSettingList.HeavySoil.value[1] = twoBytes[1];
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.ExtraRinseAdder[0], commonModel.ExtraRinseAdder[1]);
            this.vendingSettingList.ExtraRinseAdder.value[0] = twoBytes[0];
            this.vendingSettingList.ExtraRinseAdder.value[1] = twoBytes[1];
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin1[0], commonModel.ValueCoin1[1]);
            this.vendingSettingList.ValueCoin1.value[0] = twoBytes[0];
            this.vendingSettingList.ValueCoin1.value[1] = twoBytes[1];
            this.coin1Value.dollar = twoBytes[0];
            this.coin1Value.cent = twoBytes[1];
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin2[0], commonModel.ValueCoin2[1]);
            this.vendingSettingList.ValueCoin2.value[0] = twoBytes[0];
            this.vendingSettingList.ValueCoin2.value[1] = twoBytes[1];
        } else {
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin1[0], commonModel.ValueCoin1[1]);
            this.vendingSettingList.ValueCoin1.value[0] = twoBytes[0];
            this.vendingSettingList.ValueCoin1.value[1] = twoBytes[1];
            this.coin1Value.dollar = twoBytes[0];
            this.coin1Value.cent = twoBytes[1];
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin2[0], commonModel.ValueCoin2[1]);
            this.vendingSettingList.ValueCoin2.value[0] = twoBytes[0];
            this.vendingSettingList.ValueCoin2.value[1] = twoBytes[1];
        }

        this.vendingSettingList.hasValue = true;
        this.isModelChange = false;
    }

    setModelCycleVending(commonModel: any) {
        this.vendingSettingList = this.getTlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
        const modelType = localStorage.getItem("ProductType");
        if (modelType != '9J') {

            this.vendingSettingList.CoolTemperature.value[0] = commonModel.CoolTemperature.value[0];
            this.vendingSettingList.CoolTemperature.value[1] = commonModel.CoolTemperature.value[1]

            this.vendingSettingList.WarmTemperature.value[0] = commonModel.WarmTemperature.value[0];
            this.vendingSettingList.WarmTemperature.value[1] = commonModel.WarmTemperature.value[1];

            this.vendingSettingList.HotTemperature.value[0] = commonModel.HotTemperature.value[0];
            this.vendingSettingList.HotTemperature.value[1] = commonModel.HotTemperature.value[1];

            this.vendingSettingList.HeavySoil.value[0] = commonModel.HeavySoil.value[0];
            this.vendingSettingList.HeavySoil.value[1] = commonModel.HeavySoil.value[1];

            this.vendingSettingList.ExtraRinseAdder.value[0] = commonModel.ExtraRinseAdder.value[0];
            this.vendingSettingList.ExtraRinseAdder.value[1] = commonModel.ExtraRinseAdder.value[1];

            this.vendingSettingList.ValueCoin1.value[0] = commonModel.ValueCoin1.value[0];
            this.vendingSettingList.ValueCoin1.value[1] = commonModel.ValueCoin1.value[1];

            this.vendingSettingList.ValueCoin2.value[0] = commonModel.ValueCoin2.value[0];
            this.vendingSettingList.ValueCoin2.value[1] = commonModel.ValueCoin2.value[1];
        } else {

            this.vendingSettingList.ValueCoin1.value[0] = commonModel.ValueCoin1.value[0];
            this.vendingSettingList.ValueCoin1.value[1] = commonModel.ValueCoin1.value[1];

            this.vendingSettingList.ValueCoin2.value[0] = commonModel.ValueCoin2.value[0];
            this.vendingSettingList.ValueCoin2.value[1] = commonModel.ValueCoin2.value[1];
        }
        this.vendingSettingList.hasValue = true;
        this.isModelChange = false;
    }

    modelChanged() {
        return this.isModelChange;
    }

    mapSetupBinaryKey() {
        this.arrTlWasherSetupConfigSettings = [];
        const TlDryerSetup = this.getTlWasherSetupSettings(localStorage.getItem("ProductType"));
        if (TlDryerSetup) {
            if (TlDryerSetup.hasValue) {
                TlDryerSetup.CycleCountingEnable.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.CycleCountingEnable.value);

                TlDryerSetup.MoneyCountingOption.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.MoneyCountingOption.value);
                TlDryerSetup.CountViewing.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.CountViewing.value);
                TlDryerSetup.VendingDisplay.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.VendingDisplay.value);
                TlDryerSetup.CoinPaymentEnable.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.CoinPaymentEnable.value);
                TlDryerSetup.DebitCardPaymentProtocol.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.DebitCardPaymentProtocol.value);
                TlDryerSetup.AutoClearEscrowEnable.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.AutoClearEscrowEnable.value);
                TlDryerSetup.CoinSlideEnable.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.CoinSlideEnable.value);
            } else {
                TlDryerSetup.CycleCountingEnable.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.CycleCountingEnable.Default);

                TlDryerSetup.MoneyCountingOption.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.MoneyCountingOption.Default);
                TlDryerSetup.CountViewing.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.CountViewing.Default);
                TlDryerSetup.VendingDisplay.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.VendingDisplay.Default);
                TlDryerSetup.CoinPaymentEnable.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.CoinPaymentEnable.Default);
                TlDryerSetup.DebitCardPaymentProtocol.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.DebitCardPaymentProtocol.Default);
                TlDryerSetup.AutoClearEscrowEnable.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.AutoClearEscrowEnable.Default);
                TlDryerSetup.CoinSlideEnable.arrKey.map(item => {
                    this.arrTlWasherSetupConfigSettings.push(item);
                })
                this.arrTlWasherSetupConfigSettings.push(TlDryerSetup.CoinSlideEnable.Default);
            }
        }
        return this.arrTlWasherSetupConfigSettings;

    }

    mapCycleVendingBinaryKey() {
        this.arrTlWasherCycleVendingConfigSettings = [];
        const modelType = localStorage.getItem("ProductType");
        const cycleVending = this.getTlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
        if (cycleVending) {
            if (cycleVending.hasValue) {
                if (modelType != '9J') {
                }
                cycleVending.ValueCoin1.arrKey.map(item => {
                    this.arrTlWasherCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin1.value[0], cycleVending.ValueCoin1.value[1], cycleVending.ValueCoin1.type, this.arrTlWasherCycleVendingConfigSettings);
                cycleVending.ValueCoin2.arrKey.map(item => {
                    this.arrTlWasherCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin2.value[0], cycleVending.ValueCoin2.value[1], cycleVending.ValueCoin2.type, this.arrTlWasherCycleVendingConfigSettings);

                if (modelType != '9J') {
                    cycleVending.CoolTemperature.arrKey.map(item => {
                        this.arrTlWasherCycleVendingConfigSettings.push(item);
                    });
                    this.calculatePrice(cycleVending.CoolTemperature.value[0], cycleVending.CoolTemperature.value[1], cycleVending.CoolTemperature.type, this.arrTlWasherCycleVendingConfigSettings);

                    cycleVending.WarmTemperature.arrKey.map(item => {
                        this.arrTlWasherCycleVendingConfigSettings.push(item);
                    });
                    this.calculatePrice(cycleVending.WarmTemperature.value[0], cycleVending.WarmTemperature.value[1], cycleVending.WarmTemperature.type, this.arrTlWasherCycleVendingConfigSettings);

                    cycleVending.HotTemperature.arrKey.map(item => {
                        this.arrTlWasherCycleVendingConfigSettings.push(item);
                    });
                    this.calculatePrice(cycleVending.HotTemperature.value[0], cycleVending.HotTemperature.value[1], cycleVending.HotTemperature.type, this.arrTlWasherCycleVendingConfigSettings);

                    cycleVending.HeavySoil.arrKey.map(item => {
                        this.arrTlWasherCycleVendingConfigSettings.push(item);
                    });
                    this.calculatePrice(cycleVending.HeavySoil.value[0], cycleVending.HeavySoil.value[1], cycleVending.HeavySoil.type, this.arrTlWasherCycleVendingConfigSettings);

                    cycleVending.ExtraRinseAdder.arrKey.map(item => {
                        this.arrTlWasherCycleVendingConfigSettings.push(item);
                    });
                    this.calculatePrice(cycleVending.ExtraRinseAdder.value[0], cycleVending.ExtraRinseAdder.value[1], cycleVending.ExtraRinseAdder.type, this.arrTlWasherCycleVendingConfigSettings);
                }
            } else {
                if (modelType != '9J') {
                }
                cycleVending.ValueCoin1.arrKey.map(item => {
                    this.arrTlWasherCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin1.dollars, cycleVending.ValueCoin1.cents, cycleVending.ValueCoin1.type, this.arrTlWasherCycleVendingConfigSettings);
                cycleVending.ValueCoin2.arrKey.map(item => {
                    this.arrTlWasherCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin2.dollars, cycleVending.ValueCoin2.cents, cycleVending.ValueCoin2.type, this.arrTlWasherCycleVendingConfigSettings);
                if (modelType != '9J') {
                    cycleVending.CoolTemperature.arrKey.map(item => {
                        this.arrTlWasherCycleVendingConfigSettings.push(item);
                    })
                    this.calculatePrice(cycleVending.CoolTemperature.dollars, cycleVending.CoolTemperature.cents, cycleVending.CoolTemperature.type, this.arrTlWasherCycleVendingConfigSettings);

                    cycleVending.WarmTemperature.arrKey.map(item => {
                        this.arrTlWasherCycleVendingConfigSettings.push(item);
                    })
                    this.calculatePrice(cycleVending.WarmTemperature.dollars, cycleVending.WarmTemperature.cents, cycleVending.WarmTemperature.type, this.arrTlWasherCycleVendingConfigSettings);

                    cycleVending.HotTemperature.arrKey.map(item => {
                        this.arrTlWasherCycleVendingConfigSettings.push(item);
                    })
                    this.calculatePrice(cycleVending.HotTemperature.dollars, cycleVending.HotTemperature.cents, cycleVending.HotTemperature.type, this.arrTlWasherCycleVendingConfigSettings);

                    cycleVending.HeavySoil.arrKey.map(item => {
                        this.arrTlWasherCycleVendingConfigSettings.push(item);
                    })
                    this.calculatePrice(cycleVending.HeavySoil.dollars, cycleVending.HeavySoil.cents, cycleVending.HeavySoil.type, this.arrTlWasherCycleVendingConfigSettings);

                    cycleVending.ExtraRinseAdder.arrKey.map(item => {
                        this.arrTlWasherCycleVendingConfigSettings.push(item);
                    })
                    this.calculatePrice(cycleVending.ExtraRinseAdder.dollars, cycleVending.ExtraRinseAdder.cents, cycleVending.ExtraRinseAdder.type, this.arrTlWasherCycleVendingConfigSettings);
                }
            }
        }
        return this.arrTlWasherCycleVendingConfigSettings;
    }

    mapCycleSettingBinaryKey() {
        this.arrTlWasherCycleSettingConfigSettings = [];
        const modelType = localStorage.getItem("ProductType");
        const cycles = this.getWasherCycleList(localStorage.getItem("modelNumber"));
        this.CycleSettingCycleName = this.getTlWasherCycleSettings(localStorage.getItem("ProductType"));
        if (!this.CycleSettingCycleName) {
            return;
        }
        for (let index = 0; index < cycles.CycleName.length; index++) {
            const cycleVending = this.CycleSettingCycleName[cycles.CycleName[index].tvalue];
            cycleVending.Relational_Envelope_Open.arrKey.map(item => {
                this.arrTlWasherCycleSettingConfigSettings.push(item);
            });
            cycleVending.Relational_Envelope_Action.arrKey.map(item => {
                this.arrTlWasherCycleSettingConfigSettings.push(item);
            });

            if (cycleVending.hasValue) {
                cycleVending.Entity_Cycle_Open.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.Relational_Entity_Name.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                this.calculateBytes(cycleVending.Relational_Entity_Name.value.replace(/\s/g, ""), cycleVending.Relational_Entity_Name.type, this.arrTlWasherCycleSettingConfigSettings);
                // this.arrTlWasherCycleSettingConfigSettings.push("0");
                cycleVending.Relational_Entity_Id.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                const count = index + 1;
                this.arrTlWasherCycleSettingConfigSettings.push(count);
                cycleVending.DnldCycle_CycleSet_CycleCavityPosition.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.DnldCycle_PaySet_CycleRegularPrice.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleRegularPrice.value[0], cycleVending.DnldCycle_PaySet_CycleRegularPrice.value[1], cycleVending.DnldCycle_PaySet_CycleRegularPrice.type, this.arrTlWasherCycleSettingConfigSettings);
                if (modelType != '9J') {
                    cycleVending.DnldCycle_PaySet_CycleSpecialPrice.arrKey.map(item => {
                        this.arrTlWasherCycleSettingConfigSettings.push(item);
                    });
                    this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleSpecialPrice.value[0], cycleVending.DnldCycle_PaySet_CycleSpecialPrice.value[1], cycleVending.DnldCycle_PaySet_CycleSpecialPrice.type, this.arrTlWasherCycleSettingConfigSettings);
                }
                cycleVending.DnldCycle_OpSet_Temperature.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                })
                this.arrTlWasherCycleSettingConfigSettings.push(cycleVending.DnldCycle_OpSet_Temperature.value);
                cycleVending.DnldCycle_OpSet_SoilLevel.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                })
                this.arrTlWasherCycleSettingConfigSettings.push(cycleVending.DnldCycle_OpSet_SoilLevel.value);
                cycleVending.DnldCycle_OpSet_ExtraRinse.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                })
                const rinse = cycleVending.DnldCycle_OpSet_ExtraRinse.value == true ? '1' : '0';
                this.arrTlWasherCycleSettingConfigSettings.push(rinse);

                cycleVending.Relational_Array_Open.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Open.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Close.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.Relational_Array_Close.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Close.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });

            } else {
                cycleVending.Entity_Cycle_Open.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.Relational_Entity_Name.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                this.calculateBytes(cycles.CycleName[index].tvalue, cycleVending.Relational_Entity_Name.type, this.arrTlWasherCycleSettingConfigSettings)
                // this.arrTlWasherCycleSettingConfigSettings.push("0");
                cycleVending.Relational_Entity_Id.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                const count = index + 1;
                this.arrTlWasherCycleSettingConfigSettings.push(count);
                cycleVending.DnldCycle_CycleSet_CycleCavityPosition.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.DnldCycle_PaySet_CycleRegularPrice.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleRegularPrice.dollars, cycleVending.DnldCycle_PaySet_CycleRegularPrice.cents, cycleVending.DnldCycle_PaySet_CycleRegularPrice.type, this.arrTlWasherCycleSettingConfigSettings);
                if (modelType != '9J') {
                    cycleVending.DnldCycle_PaySet_CycleSpecialPrice.arrKey.map(item => {
                        this.arrTlWasherCycleSettingConfigSettings.push(item);
                    })
                    this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleSpecialPrice.dollars, cycleVending.DnldCycle_PaySet_CycleSpecialPrice.cents, cycleVending.DnldCycle_PaySet_CycleSpecialPrice.type, this.arrTlWasherCycleSettingConfigSettings);
                }
                cycleVending.DnldCycle_OpSet_Temperature.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                })
                this.arrTlWasherCycleSettingConfigSettings.push(cycleVending.DnldCycle_OpSet_Temperature.default);
                cycleVending.DnldCycle_OpSet_SoilLevel.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                })
                this.arrTlWasherCycleSettingConfigSettings.push(cycleVending.DnldCycle_OpSet_SoilLevel.default);
                cycleVending.DnldCycle_OpSet_ExtraRinse.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                })
                this.arrTlWasherCycleSettingConfigSettings.push(cycleVending.DnldCycle_OpSet_ExtraRinse.default);
                cycleVending.Relational_Array_Open.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Open.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Close.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.Relational_Array_Close.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Close.arrKey.map(item => {
                    this.arrTlWasherCycleSettingConfigSettings.push(item);
                });

            }
            cycleVending.Relational_Envelope_Close.arrKey.map(item => {
                this.arrTlWasherCycleSettingConfigSettings.push(item);
            });
        }
        return this.arrTlWasherCycleSettingConfigSettings;
    }

    calculateBytes(inputNum: string | number, type: string, arr: any[]): any[] {
        switch (type.toLowerCase()) {
            case 'u16':
                let firstByte = 0, secondByte = 0;
                secondByte = (Number(inputNum) & 0x00FF);
                firstByte = (Number(inputNum) & 0xFF00) >> 8;
                arr.push(firstByte);
                arr.push(secondByte);
                return arr;
            case 'string':
                var hArray = inputNum.toString();
                for (var j = 0; j < hArray.length; j++) {
                    arr.push(hArray[j].charCodeAt(0).toString(10));
                }
                arr.push("0");
                return arr;
            case 'u8':
                arr.push(inputNum);
                return arr;
        }
    }

    calculatePrice(dollar: string, cents: string, type: string, arr: any[]): any[] {
        let price = Number(dollar) * 100 + Number(cents);
        return this.calculateBytes(price, type, arr);
    }

    setCoinPrice() {
        const modeltype = localStorage.getItem("ProductType");
        const vendingSettings = this.getTlWasherCycleVendingSettings(modeltype);
        if (!vendingSettings.ValueCoin1.value.length || (vendingSettings.ValueCoin1.value[0] == '0' && vendingSettings.ValueCoin1.value[1] == '0')) {
            this.coin1Value.dollar = vendingSettings.ValueCoin1.dollars;
            this.coin1Value.cent = vendingSettings.ValueCoin1.cents;
        } else {
            this.coin1Value.dollar = vendingSettings.ValueCoin1.value[0];
            this.coin1Value.cent = vendingSettings.ValueCoin1.value[1];
        }
        console.log(this.coin1Value.dollar + ' - ' + this.coin1Value.cent)
    }

    setCyclePrice(isCallDefault: boolean, coinValue?: string): boolean {
        const modeltype = localStorage.getItem("ProductType");
        const cycles = this.getWasherCycleList(localStorage.getItem("modelNumber"));
        this.CycleSettingCycleName = this.getTlWasherCycleSettings(modeltype);
        let isValidPrice = true;
        for (let index = 0; index < cycles.CycleName.length; index++) {
            this.cycleSettingList = this.CycleSettingCycleName[cycles.CycleName[index].tvalue];
            const isValid = this.priceCalc(this.cycleSettingList, cycles.CycleName[index].title, isCallDefault, coinValue);
            if (isValid == false) {
                isValidPrice = false;
                break;
            }
        }
        return isValidPrice;
    }

    priceCalc(cycleSettingList: ITlWasherCycleSettingList, cycleName: string, isCallDefault: boolean, coinValue: string) {
        const dollar = this.coin1Value.dollar == '' ? '0' : this.coin1Value.dollar;
        const cents = this.coin1Value.cent == '' ? '0' : this.coin1Value.cent;
        const price = (+dollar * 100) + +cents;
        let regPriceCalc: number;
        let splPriceCalc: number;
        let prevValue: boolean;
        if (!isCallDefault) {
            prevValue = true;
        } else {
            prevValue = +(this.prevCoinValue) < +(coinValue);
        }
        if (+(cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0]) == 50 && +(this.prevCoinValue) < +(coinValue)) {
            return false;
        }
        if (cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] == '0' && cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] == '0' && !isCallDefault) {
            regPriceCalc = (+cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.dollars * 100) + +cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.cents;
            splPriceCalc = (+cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.dollars * 100) + +cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.cents;
            this.setDefault(cycleSettingList, cycleName);
        } else {
            regPriceCalc = (+cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] * 100) + +cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1];
            splPriceCalc = (+cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] * 100) + +cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1];
        }
        this.setRegPriceValue(cycleSettingList, price, regPriceCalc, prevValue);
        if (localStorage.getItem("ProductType") != '9J') {
            this.setSplPriceValue(cycleSettingList, price, splPriceCalc, prevValue);
        }
    }

    setRegPriceValue(cycleSettingList: ITlWasherCycleSettingList, price: number, priceCalc: number, prevValue: boolean) {
        let calRegPrice = 0;
        let i = 1;
        let isBreakCond: boolean = false;
        if (price) {
            while (priceCalc > calRegPrice) {
                if (priceCalc == calRegPrice) {
                    isBreakCond = true;
                    break;
                }
                calRegPrice = price * i;
                i = i + 1;
            }
            if (prevValue == false && priceCalc != calRegPrice) {
                calRegPrice = calRegPrice - price;
            }
            if (calRegPrice > 5000) {
                calRegPrice = calRegPrice - price;
            }
            const regValue = this.dollarConverts(calRegPrice);
            cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = regValue[0].toString();
            cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = regValue[1].toString();
            cycleSettingList.hasValue = true;
            return;
        }
        cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = '0';
        cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = '0';
        cycleSettingList.hasValue = true;
    }

    setSplPriceValue(cycleSettingList: ITlWasherCycleSettingList, price: number, priceCalc: number, prevValue: boolean) {
        let calRegPrice = 0;
        let i = 1;
        let isBreakCond: boolean = false;
        if (price) {
            while (priceCalc > calRegPrice) {
                if (priceCalc == calRegPrice) {
                    isBreakCond = true;
                    break;
                }
                calRegPrice = price * i;
                i = i + 1;
            }
            if (prevValue == false && priceCalc != calRegPrice) {
                calRegPrice = calRegPrice - price;
            }
            if (calRegPrice > 5000) {
                calRegPrice = calRegPrice - price;
            }
            const regValue = this.dollarConverts(calRegPrice);
            cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = regValue[0].toString();
            cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = regValue[1].toString();
            return;
        }
        cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = '0';
        cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = '0';
    }

    dollarConverts(c) {
        var nCents = c;
        var nDollar = (nCents / 100);
        var rDollar = Math.floor(nDollar);
        var cents = (nDollar - rDollar) * 100;
        var rCents = Math.round(cents);
        return [rDollar, rCents]
    }

    setDefault(cycleSettingList: ITlWasherCycleSettingList, cycleName: string) {
        cycleSettingList.Relational_Entity_Name.value = cycleName;
        cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.dollars;
        cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.cents;
        cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.dollars;
        cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.cents;
    }

    resetPriceValue() {
        const modeltype = localStorage.getItem("ProductType");
        const cycles = this.getWasherCycleList(localStorage.getItem("modelNumber"));
        this.CycleSettingCycleName = this.getTlWasherCycleSettings(modeltype);
        for (let index = 0; index < cycles.CycleName.length; index++) {
            this.cycleSettingList = this.CycleSettingCycleName[cycles.CycleName[index].tvalue];
            this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.dollars;
            this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = this.cycleSettingList.DnldCycle_PaySet_CycleSpecialPrice.cents;
            this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.dollars;
            this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = this.cycleSettingList.DnldCycle_PaySet_CycleRegularPrice.cents;
        }
    }

    resetCoinValue() {
        this.vendingSettingList.ValueCoin1.value[0] = this.vendingSettingList.ValueCoin1.dollars;
        this.vendingSettingList.ValueCoin1.value[1] = this.vendingSettingList.ValueCoin1.cents;
        this.vendingSettingList.ValueCoin2.value[0] = this.vendingSettingList.ValueCoin2.dollars;
        this.vendingSettingList.ValueCoin2.value[1] = this.vendingSettingList.ValueCoin2.cents;
    }

    getSetupModelData() {
        const setupModelList = this.getTlWasherSetupSettings(localStorage.getItem("ProductType"));
        this.commonService.novoEcoModels.setupConfig = '';
        this.commonService.novoEcoModels.setupConfig = JSON.stringify(setupModelList);
    }

    getVendingModelData() {
        const tlDryerVendingList = this.getTlWasherCycleVendingSettings(localStorage.getItem("ProductType"));
        this.commonService.novoEcoModels.vendingConfig = '';
        this.commonService.novoEcoModels.vendingConfig = JSON.stringify(tlDryerVendingList);
    }

    getSettingModelData() {
        const tlDryerSettingList = this.getTlWasherCycleSettings(localStorage.getItem("ProductType"));
        this.commonService.novoEcoModels.cycleConfig = '';
        this.commonService.novoEcoModels.cycleConfig = JSON.stringify(tlDryerSettingList);
    }
}