import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServices } from 'src/app/services/CommonServices';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isUserDefinedScreen: boolean = false;

  constructor(
    private _commonService: CommonServices,
    private router: Router) { }

  ngOnInit() {
    this._commonService.isUserDefinedScreen.subscribe((isUserDefinedScreen) => {
      this.isUserDefinedScreen = isUserDefinedScreen;
      this.router.navigate(['/']);
    })
  }

}
