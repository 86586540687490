import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonServices } from "src/app/services/CommonServices";
import { VenusModelServices } from "src/app/services/VenusModel.service";
import { ISetupModelList, IValue, IVenusSetup, IVenusSetupDetail } from "./venus-setup.model";
declare const $: any;

@Component({
  selector: "app-venus-setup",
  templateUrl: "./venus-setup.component.html",
  styleUrls: ["./venus-setup.component.scss"],
})

export class VenusSetupComponent implements OnInit, OnDestroy {
  venusSetupForm: FormGroup;
  model: ISetupModelList = {
    CycleCountingEnable: 0,
    MoneyCountingOption: 0,
    CoinBoxOpenMoneyCount: 0,
    VendingDisplay: 0,
    CoinPaymentEnable: 0,
    DebitCardPaymentProtocol: 0,
    AutoClearEscrowEnable: 0,
    hasValue: false
  }
  value: IValue = {
    id: 0,
    name: ''
  }
  regularOffSet: IVenusSetupDetail = {
    value: 0,
    values: this.value,
    Default: 0,
    arrKey: [],
    binaryKey: '',
    type: ''
  }
  venusSettings: IVenusSetup = {
    CycleCountingEnable: this.regularOffSet,
    MoneyCountingOption: this.regularOffSet,
    CountViewing: this.regularOffSet,
    VendingDisplay: this.regularOffSet,
    CoinPaymentEnable: this.regularOffSet,
    DebitCardPaymentProtocol: this.regularOffSet,
    AutoClearEscrowEnable: this.regularOffSet,
    hasValue: true
  }
  isSetToDefaultHide: boolean = false;

  constructor(
    private commonService: CommonServices,
    private _venusModelServices: VenusModelServices,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.isSetToDefaultHide = this.commonService.isSetToDefaultHide;
    this.getControls();
    this.venusSettings = this._venusModelServices.getSetupModels()
    if (!this.venusSettings) {
      this.venusSettings = this._venusModelServices.getVenusSetupSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    } else if (this.venusSettings && !this.venusSettings.hasValue) {
      this.venusSettings = this._venusModelServices.getVenusSetupSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    }
  }

  getControls() {
    this.venusSetupForm = this.fb.group({
      cycleCounter: ["", Validators.required],
      moneyCounter: ["", Validators.required],
      vaultViewingOption: ["", Validators.required],
      addCoinOption: ["", Validators.required],
      coinOption: ["", Validators.required],
      debitOption: ["", Validators.required],
      priceSupressionOption: ["", Validators.required],
      clearEscrowOption: ["", Validators.required]
    });
  }

  setToDefault() {
    this.venusSettings.CycleCountingEnable.value = this.venusSettings.CycleCountingEnable.Default;
    this.venusSettings.MoneyCountingOption.value = this.venusSettings.MoneyCountingOption.Default;
    this.venusSettings.CountViewing.value = this.venusSettings.CountViewing.Default;
    this.venusSettings.VendingDisplay.value = this.venusSettings.VendingDisplay.Default;
    this.venusSettings.CoinPaymentEnable.value = this.venusSettings.CoinPaymentEnable.Default;
    this.venusSettings.DebitCardPaymentProtocol.value = this.venusSettings.DebitCardPaymentProtocol.Default;
    this.venusSettings.AutoClearEscrowEnable.value = this.venusSettings.AutoClearEscrowEnable.Default;
    this.venusSettings.hasValue = true;
  }

  returnResult(_value: any) {
    const value = _value;
    return value;
  }

  ngOnDestroy() {
    const isModel = this._venusModelServices.modelChanged();
    if (!isModel) {
      this._venusModelServices.setSetupModels(this.venusSettings);
    }
  }
}