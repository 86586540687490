import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class ResetFormService {
    reset: boolean = false;
  
    private emitChangeSource = new BehaviorSubject<boolean>(this.reset);
    changeEmitted$ = this.emitChangeSource.asObservable();
  
    emitChange(change: any) {
      this.emitChangeSource.next(change);
    }
  
    constructor() { }
  }