import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/AuthenticationService';
import { KeyboardSelectionEnum } from 'src/app/shared/enums/enum';
import { ParentErrorStateMatcher, PasswordValidator } from '../create-password/create-password.model';
import { ErrorList } from './reset-password.model';
import { ResetPasswordService } from './reset-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public keyboardSelectionEnum = KeyboardSelectionEnum;
  public matching_passwords_group: FormGroup;
  public validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Enter a valid mail' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Confirm password is required' },
      { type: 'areEqual', message: 'Password mismatch' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 8 characters long' },
      {
        type: 'pattern', message: `Your password must contain at least one uppercase, one lowercase, one number,
      and one special character`  }
    ],
  };
  public isSpecifyDisable: boolean = true;
  public errorList = new ErrorList();
  public parentErrorStateMatcher = new ParentErrorStateMatcher();
  public token: string = '';
  public dialogRef: any;
  public isLinkExpire: boolean = false;
  public validity: string = '';
  public emailEncrypt: string;
  public clientToken: string;
  public decrypt = new Map();
  public showPassword: boolean;
  public showConfirmPassword: boolean;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private _resetPasswordService: ResetPasswordService,
    private _authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    const CurrentURL = localStorage.getItem('CurrentURL');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserToken');
    this.getFormFields();
    this.splitURL(CurrentURL);
  }

  splitURL(url: string) {
    this.decrypt.clear();
    const splitURL = url.split('/');
    if (splitURL.length > 4) {
      this.emailEncrypt = splitURL[5];
      this.clientToken = splitURL[6].replace(/@/g, '.');
      this.decryptData('Email', this.emailEncrypt);
    } else {
      this.navigateToLogin();
    }
  }

  getFormFields() {
    this.matching_passwords_group = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.minLength(8),
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$') //this is for the letters (both uppercase and lowercase) and numbers validation
      ])),
      confirm_password: new FormControl('', Validators.required)
    }, (formGroup: FormGroup) => {
      return PasswordValidator.areEqual(formGroup)
    });

    // user details form validations
    this.resetPasswordForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      matching_passwords: this.matching_passwords_group,
    })
  }

  onSubmit(form: FormGroup) {
    const email = this.decrypt.get('Email');
    const password = form.value.matching_passwords.password;
    const confirm_password = form.value.matching_passwords.confirm_password
    if (!form.valid) {
      return;
    } if (!email) {
      this._resetPasswordService.showMessage(this.errorList.msgMandotary, true);
      return
    }
    const url = `${'/public/v1/client_auth/accounts/rest_pd/' + this.emailEncrypt + '/' + password + '/' + confirm_password + '/' + this.clientToken}`;
    const payload = {
    }
    this._resetPasswordService.postData(url, JSON.stringify(payload)).subscribe(res => {
      this._resetPasswordService.showMessage(res, false);
      this.navigateToLogin();
    }, err => {
      this._resetPasswordService.showMessage(err ? err : 'Unknown Error!!', true);
    })
  }

  decryptData(fieldName: string, data: string) {
    const url = `${'/public/v1/client_auth/decryptEmail/' + data}`;
    this._resetPasswordService.getData(url).subscribe((res: string) => {
      this.decrypt.set(fieldName, res)
      if (fieldName == 'Email') {
        this.resetPasswordForm.patchValue({ email: res });
      }
    }, err => {
      const errMessage = `${'Error in decrypt ' + fieldName + '.'}`
      console.log(errMessage)
      this._resetPasswordService.showMessage(err, true);
      this.navigateToLogin();
    })
  }

  navigateToLogin() {
    localStorage.clear();
    this._authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
