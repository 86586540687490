import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonServices } from 'src/app/services/CommonServices';
import { ModelServices } from 'src/app/services/ModelServices';
import { UsbDownload } from 'src/app/services/UsbDownloadService';
import { NovoFileNames } from '../../../enums/enum';
import { HmiDisplaySettings } from '../../../models/UsbGenerator';
import { IGetUserDefinedConfiguration } from '../../user-defined-setup/user-defined-setup.model';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-hmisetup',
  templateUrl: './hmisetup.component.html',
  styleUrls: ['./hmisetup.component.scss']
})
export class HmisetupComponent implements OnInit, OnDestroy {
  ModelType: string = localStorage.getItem("ModelType");//="YS";//or YR
  ProductType: string = localStorage.getItem("ProductType");//="PN"; //or not PN
  Weight: any = localStorage.getItem("Weight");//=99; or //gt99
  model = {
    ddlLanguage: '0',
    ddlScreenSaverMode: '0',
    ddlCycleCounter: '0',
    ddlDefaultCycleScreen: '0',
    txtCycleNumber: '1',
    ddlDisplayTemperature: '0',
    ddlDisplayBrightness: '3',
    ddlCountViewing: '0',
    ddlAdditiveAdder: '0',
    ddlSpinSpeedAdder: '0',
    ddlAdderOption: '0',
    ddlWaterTempAddder: '0',
    ddlSolLevelAdder: '0',
    ddlExtraRinseAdder: '0',
    txtAddGarment: '10'
  }
  isShowModel = true;
  options: any;
  elmts; any;
  arrHmiDisplaySettings: any = new Array();
  isSetToDefaultHide: boolean = false;

  constructor(
    private modelServices: ModelServices,
    private commonServices: CommonServices,
    private usbDownload: UsbDownload,
    private cdr: ChangeDetectorRef,
  ) {
    this.options = modelServices.geHMISettings(this.ProductType);
  }

  ngOnInit() {
    this.isSetToDefaultHide = this.commonServices.isSetToDefaultHide;
    this.isShowModel = localStorage.getItem("isShowModel") == '0' ? false : true;
    var hmiDisplaySettings: HmiDisplaySettings = new HmiDisplaySettings();
    //need to replace this. setup subscription is calling once but HMI is calling multiple times
    // if(this.commonServices.subHmiSetUpValues.observers.length==0){
    this.commonServices.getHmiSetUpValue().subscribe(() => {
      hmiDisplaySettings.screensaver = String(this.model.ddlScreenSaverMode === undefined ? 0 : this.model.ddlScreenSaverMode); //screensaver:string;// Screen Saver Mode

      hmiDisplaySettings.cyclecounter = String(this.model.ddlCycleCounter === undefined ? 0 : this.model.ddlCycleCounter);// Cycle Counter

      //hmiDisplaySettings.time_ispm='0';//

      hmiDisplaySettings.defaultcycleshow = String(this.model.ddlDefaultCycleScreen == '1' ? '0' : '1');// Default Cycle Screen

      hmiDisplaySettings.cyclenumber = String(this.model.txtCycleNumber === undefined ? 1 : this.model.txtCycleNumber);// Default Cycle Number

      hmiDisplaySettings.HMI_DISPLAY_DEFAULT_SCREEN_TYPE = String(this.model.ddlDefaultCycleScreen == '1' ? '0' : '1');// default Cycle screen;

      hmiDisplaySettings.HMI_DISPLAY_LANGUAGE_TYPE = String(this.model.ddlLanguage === undefined ? '0' : this.model.ddlLanguage);// Display Language

      hmiDisplaySettings.HMI_DISPLAY_TEMPERATURE_TYPE = String(this.model.ddlDisplayTemperature === undefined ? '0' : this.model.ddlDisplayTemperature);// Display Temprature

      hmiDisplaySettings.HMI_DISPLAY_BRIGHTNESS_TYPE = String(this.model.ddlDisplayBrightness === undefined ? 3 : this.model.ddlDisplayBrightness);// Display Percentage
      hmiDisplaySettings.HMI_DISPLAY_COUNT_VIEWING_TYPE = String(this.model.ddlCountViewing === undefined ? 0 : this.model.ddlCountViewing);// Count Viewing

      //hmiDisplaySettings.HMI_SCREEN_TIMEDATE_TYPE ='0000000';//

      hmiDisplaySettings.additives = String(this.model.ddlAdditiveAdder === undefined ? 0 : this.model.ddlAdditiveAdder);// Additives Adder

      hmiDisplaySettings.spinspeed = String(this.model.ddlSpinSpeedAdder === undefined ? 0 : this.model.ddlSpinSpeedAdder);// Spin Speed Adder

      hmiDisplaySettings.adder_option_screen = String(this.model.ddlAdderOption === undefined ? 0 : this.model.ddlAdderOption);// Adder Option Screen

      hmiDisplaySettings.water_temp = String(this.model.ddlWaterTempAddder === undefined ? 0 : this.model.ddlWaterTempAddder);// Water Temp Adder

      hmiDisplaySettings.soillevel = String(this.model.ddlSolLevelAdder === undefined ? 0 : this.model.ddlSolLevelAdder);// Sol Level Adder

      hmiDisplaySettings.extra_rinse = String(this.model.ddlExtraRinseAdder === undefined ? 0 : this.model.ddlExtraRinseAdder);// Extra Rinse Adder

      hmiDisplaySettings.pausetime_minutes = String(this.model.txtAddGarment === undefined ? 10 : this.model.txtAddGarment);// Add Garment Time (Min)
      this.HmiConfigBytes(hmiDisplaySettings);
      localStorage.setItem('arrHmiDisplaySettings', this.arrHmiDisplaySettings);
      this.commonServices.novoEcoModels.hmiConfig = '';
      this.commonServices.novoEcoModels.hmiConfig = JSON.stringify(this.model);
    });
    //}
    this.setCycleNumber();
  }

  ngAfterViewInit() {
    this.setToDefault();
    this.bindDefaultValues();
    this.readBinaryConfig();
    this.readModelConfig();
    this.cdr.detectChanges();
  }

  bindUserDefinedModelData(hmiDisplay: any) {
    this.model.ddlScreenSaverMode = hmiDisplay.ddlScreenSaverMode;
    this.model.ddlCycleCounter = hmiDisplay.ddlCycleCounter;
    this.model.txtCycleNumber = hmiDisplay.txtCycleNumber;
    this.model.ddlDefaultCycleScreen = hmiDisplay.ddlDefaultCycleScreen;
    this.model.ddlLanguage = hmiDisplay.ddlLanguage;
    this.model.ddlDisplayTemperature = hmiDisplay.ddlDisplayTemperature;
    this.model.ddlDisplayBrightness = hmiDisplay.ddlDisplayBrightness;
    this.model.ddlCountViewing = hmiDisplay.ddlCountViewing;
    this.model.ddlAdditiveAdder = hmiDisplay.ddlAdditiveAdder;
    this.model.ddlSpinSpeedAdder = hmiDisplay.ddlSpinSpeedAdder;
    this.model.ddlAdderOption = hmiDisplay.ddlAdderOption;
    this.model.ddlWaterTempAddder = hmiDisplay.ddlWaterTempAddder;
    this.model.ddlSolLevelAdder = hmiDisplay.ddlSolLevelAdder;
    this.model.ddlExtraRinseAdder = hmiDisplay.ddlExtraRinseAdder;
    this.model.txtAddGarment = hmiDisplay.txtAddGarment;

    // if (this.modelServices.hmiSetup != null) {
    //   this.model = this.modelServices.hmiSetup;
    // } else {
    //   this.modelServices.isHMIComponentTobePush = true;
    // }
  }

  readBinaryConfig() {
    this.commonServices.isSetupTabSelected.subscribe((tab) => {
      this.binaryDefaultValues();
    });
  }

  readModelConfig() {
    this.commonServices.isNovoModelSelected.subscribe((userDefinedConfig: IGetUserDefinedConfiguration) => {
      this.bindUserDefinedModelData(JSON.parse(userDefinedConfig.hmiConfig));
    });
  }

  binaryDefaultValues() {
    const machineBinaryValue = this.commonServices.getBinaryData(NovoFileNames.hmi_display_Settings);
    if (!machineBinaryValue || !machineBinaryValue.length) {
      return;
    }
    this.model.ddlScreenSaverMode = machineBinaryValue[0].value[53].toString();
    this.model.ddlCycleCounter = machineBinaryValue[0].value[54].toString();
    //this.model.time_ispm= machineBinaryValue[0].value[55];
    //this.model.defaultcycleshow= machineBinaryValue[0].value[56];
    this.model.txtCycleNumber = machineBinaryValue[0].value[57];
    // if (this.model.txtCycleNumber > this.commonServices.defaultCycleNumber.Max) {
    //   this.model.txtCycleNumber = this.commonServices.defaultCycleNumber.Max;
    // }

    this.model.ddlDefaultCycleScreen = machineBinaryValue[0].value[58].toString() == '1' ? '0' : '1';
    this.model.ddlLanguage = machineBinaryValue[0].value[59];
    this.model.ddlDisplayTemperature = machineBinaryValue[0].value[60].toString();
    this.model.ddlDisplayBrightness = machineBinaryValue[0].value[61].toString();
    this.model.ddlCountViewing = machineBinaryValue[0].value[62].toString();
    //this.model.HMI_SCREEN_TIMEDATE_TYPE= machineBinaryValue[0].value[63];

    this.model.ddlAdditiveAdder = machineBinaryValue[0].value[70].toString();
    this.model.ddlSpinSpeedAdder = machineBinaryValue[0].value[71].toString();
    this.model.ddlAdderOption = machineBinaryValue[0].value[72].toString();
    this.model.ddlWaterTempAddder = machineBinaryValue[0].value[73].toString();
    this.model.ddlSolLevelAdder = machineBinaryValue[0].value[74].toString();
    this.model.ddlExtraRinseAdder = machineBinaryValue[0].value[75].toString();


    this.model.txtAddGarment = machineBinaryValue[0].value[77].toString();
    // this.model.pausetime_minutes= machineBinaryValue[0].value[78];
    // this.model.rfu= machineBinaryValue[0].value[79];
    // this.binaryHmiConfigBytes(this.model);

    // localStorage.setItem('arrHmiDisplaySettings', this.arrHmiDisplaySettings);

  }

  HmiConfigBytes(hmiDisplaySettings: any) {
    this.arrHmiDisplaySettings = [];
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.screensaver);// Screen Saver Mode
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.cyclecounter);// Cycle Counter
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.time_ispm);// one zero
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.defaultcycleshow);// Default Cycle Screen
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.cyclenumber);// Default Cycle Number
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.HMI_DISPLAY_DEFAULT_SCREEN_TYPE);// defaultscreen;
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.HMI_DISPLAY_LANGUAGE_TYPE);// Display Language
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.HMI_DISPLAY_TEMPERATURE_TYPE);// Display Temprature
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.HMI_DISPLAY_BRIGHTNESS_TYPE);// Display Percentage
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.HMI_DISPLAY_COUNT_VIEWING_TYPE);// Count Viewing
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.HMI_SCREEN_TIMEDATE_TYPE);// 7 zeros
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.additives);// Additives Adder
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.spinspeed);// Spin Speed Adder
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.adder_option_screen);// Adder Option Screen
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.water_temp);// Water Temp Adder
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.soillevel);// Sol Level Adder
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.extra_rinse);// Extra Rinse Adder
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.upgradablecycle);
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.pausetime_minutes);// Add Garment Time (Min);
    this.arrHmiDisplaySettings.push(hmiDisplaySettings.rfu);//38 zeros
  }

  binaryHmiConfigBytes(model: any) {
    this.arrHmiDisplaySettings = [];
    this.arrHmiDisplaySettings.push(model.ddlScreenSaverMode);// Screen Saver Mode
    this.arrHmiDisplaySettings.push(model.ddlCycleCounter);// Cycle Counter
    this.arrHmiDisplaySettings.push(model.time_ispm);// one zero
    this.arrHmiDisplaySettings.push(model.defaultcycleshow);// Default Cycle Screen
    this.arrHmiDisplaySettings.push(model.txtCycleNumber);// Default Cycle Number
    this.arrHmiDisplaySettings.push(model.ddlDefaultCycleScreen);// defaultscreen;
    this.arrHmiDisplaySettings.push(model.ddlLanguage);// Display Language
    this.arrHmiDisplaySettings.push(model.ddlDisplayTemperature);// Display Temprature
    this.arrHmiDisplaySettings.push(model.ddlDisplayBrightness);// Display Percentage
    this.arrHmiDisplaySettings.push(model.ddlCountViewing);// Count Viewing
    this.arrHmiDisplaySettings.push(model.HMI_SCREEN_TIMEDATE_TYPE);// 7 zeros
    this.arrHmiDisplaySettings.push(model.ddlAdditiveAdder);// Additives Adder
    this.arrHmiDisplaySettings.push(model.ddlSpinSpeedAdder);// Spin Speed Adder
    this.arrHmiDisplaySettings.push(model.ddlAdderOption);// Adder Option Screen
    this.arrHmiDisplaySettings.push(model.ddlWaterTempAddder);// Water Temp Adder
    this.arrHmiDisplaySettings.push(model.ddlSolLevelAdder);// Sol Level Adder
    this.arrHmiDisplaySettings.push(model.ddlExtraRinseAdder);// Extra Rinse Adder
    this.arrHmiDisplaySettings.push(model.upgradablecycle);
    this.arrHmiDisplaySettings.push(model.txtAddGarment);// Add Garment Time (Min);
    this.arrHmiDisplaySettings.push(model.rfu);//38 zeros
  }

  inputCycleNumber() {
    // this.options.DefaultCycleNumber.Min
    // this.options.DefaultCycleNumber.Max
    if (this.model.txtCycleNumber > this.commonServices.defaultCycleNumber.Max) {
      this.model.txtCycleNumber = this.commonServices.defaultCycleNumber.Max;
    }
    if (this.model.txtCycleNumber < '1') {
      this.model.txtCycleNumber = '1';
    }
  }

  txtAddGarment() {
    if (this.model.txtAddGarment > this.options.AddGarment.Max) {
      this.model.txtAddGarment = this.options.AddGarment.Max;
    }
    if (this.model.txtAddGarment < this.options.AddGarment.Min) {
      this.model.txtAddGarment = this.options.AddGarment.Min;
    }
  }

  clearMode() {
    //this.model = undefined;
    this.modelServices.hmiSetup = undefined;
    this.setToDefault();
  }

  setToDefault() {
    this.commonServices.defaultCycleNumber.Max = this.commonServices.defaultCycleNumber.Max;
    this.options.DefaultCycleNumber = this.commonServices.defaultCycleNumber;
    this.BindTextBox("txtCycleNumber", this.returnResult(this.options.DefaultCycleNumber), this.returnResult(this.options.DefaultCycleNumber), this.returnResult(this.options.DefaultCycleNumber));
    this.BindTextBox("txtAddGarment", this.returnResult(this.options.AddGarment), this.returnResult(this.options.AddGarment), this.returnResult(this.options.AddGarment));
    this.BindDropDown("ddlLanguage", this.returnResult(this.options.DisplayLanguage), this.returnResult(this.options.DisplayLanguage));
    this.BindDropDown("ddlDisplayBrightness", this.returnResult(this.options.DisplayBrightness), this.returnResult(this.options.DisplayBrightness));
    this.BindDropDown("ddlDisplayTemperature", this.returnResult(this.options.DisplayTemperature), this.returnResult(this.options.DisplayTemperature));
    this.BindDropDown("ddlDefaultCycleScreen", this.returnResult(this.options.DefaultCycleScreen), this.returnResult(this.options.DefaultCycleScreen));
    this.BindDropDown("ddlWaterTempAddder", this.returnResult(this.options.WaterTempAdder), this.returnResult(this.options.WaterTempAdder));
    this.BindDropDown("ddlAdditiveAdder", this.returnResult(this.options.AdditivesAdder), this.returnResult(this.options.AdditivesAdder));
    this.BindDropDown("ddlSolLevelAdder", this.returnResult(this.options.SoilLevelAdder), this.returnResult(this.options.SoilLevelAdder));
    this.BindDropDown("ddlSpinSpeedAdder", this.returnResult(this.options.SpinSpeedAdder), this.returnResult(this.options.SpinSpeedAdder));
    this.BindDropDown("ddlAdderOption", this.returnResult(this.options.AdderOptionscreen), this.returnResult(this.options.AdderOptionscreen));
    this.BindDropDown("ddlExtraRinseAdder", this.returnResult(this.options.ExtraRinseAdder), this.returnResult(this.options.ExtraRinseAdder));
    this.BindDropDown("ddlCycleCounter", this.returnResult(this.options.CycleCounter), this.returnResult(this.options.CycleCounter));

    this.BindDropDown("ddlCountViewing", this.returnResult(this.options.CountViewing), this.returnResult(this.options.CountViewing));
    this.BindDropDown("ddlScreenSaverMode", this.returnResult(this.options.ScreenSaverMode), this.returnResult(this.options.ScreenSaverMode));
  }

  bindDefaultValues() {
    this.model.ddlScreenSaverMode = this.returnResult(this.options.ScreenSaverMode.Default);//"0";
    this.model.ddlCycleCounter = this.returnResult(this.options.CycleCounter.Default);//"0";
    this.model.txtCycleNumber = this.returnResult(this.options.DefaultCycleNumber.Default);//"1";
    this.model.ddlDefaultCycleScreen = this.returnResult(this.options.DefaultCycleScreen) === undefined ? "0" : this.options.DefaultCycleScreen.Default;//"0";
    this.model.ddlLanguage = this.returnResult(this.options.DisplayLanguage.Default);//"0";
    this.model.ddlDisplayTemperature = this.returnResult(this.options.DisplayTemperature.Default);//"0";
    this.model.ddlDisplayBrightness = this.returnResult(this.options.DisplayBrightness.Default);//"3";
    this.model.ddlCountViewing = this.returnResult(this.options.CountViewing) === undefined ? "0" : this.options.CountViewing.Default;//"0";
    this.model.ddlAdditiveAdder = this.returnResult(this.options.AdditivesAdder) === undefined ? "0" : this.options.AdditivesAdder.Default;//"0";
    this.model.ddlSpinSpeedAdder = this.returnResult(this.options.SpinSpeedAdder) === undefined ? "0" : this.options.SpinSpeedAdder.Default;//"0";
    this.model.ddlAdderOption = this.returnResult(this.options.AdderOptionscreen) === undefined ? "0" : this.options.AdderOptionscreen.Default;//"0";
    this.model.ddlWaterTempAddder = this.returnResult(this.options.WaterTempAdder) === undefined ? "0" : this.options.WaterTempAdder.Default;//"0";
    this.model.ddlSolLevelAdder = this.returnResult(this.options.SoilLevelAdder) === undefined ? "0" : this.options.SoilLevelAdder.Default;//"0";
    this.model.ddlExtraRinseAdder = this.returnResult(this.options.ExtraRinseAdder) === undefined ? "0" : this.options.ExtraRinseAdder.Default;//"0";
    this.model.txtAddGarment = this.returnResult(this.options.AddGarment.Default);//"10";
    if (this.modelServices.hmiSetup != null) {
      this.model = this.modelServices.hmiSetup;
    } else {
      this.modelServices.isHMIComponentTobePush = true;
    }
  }

  BindDropDown(elementId: any, values: any, defaultValue: any) {
    var disablableId = (document.getElementById(elementId.replace("ddl", "dv")) as HTMLDivElement);
    if (values === undefined || defaultValue === undefined) {
      disablableId.classList.add("disableDivTemp");
    }
    else {
      var select = (document.getElementById(elementId) as HTMLSelectElement);
      disablableId.classList.remove("disableDivTemp");
      select.innerHTML = "";
      this.elmts = values.values;
      for (var i = 0; i < this.elmts.length; i++) {
        var optn = this.elmts[i];
        var el = document.createElement("option");
        el.text = String(optn);
        el.value = String(i);
        select.appendChild(el);
      }
      select.value = defaultValue.Default;
    }
  }

  BindTextBox(elementId: any, minVal: any, maxVal: any, defaultValue: any) {
    var disablableId = (document.getElementById(elementId.replace("txt", "dv")) as HTMLDivElement);
    if (minVal === undefined || maxVal === undefined || defaultValue === undefined) {
      disablableId.classList.add("disableDivTemp");
    }
    else {
      disablableId.classList.remove("disableDivTemp");
      var txtElement = (<HTMLInputElement>document.getElementById(elementId));
      txtElement.min = minVal.Min; txtElement.max = maxVal.Max; txtElement.value = defaultValue.Default;
    }
  }

  returnResult(_value: any) {
    var value = _value;
    return value;
  }

  ngOnDestroy(): void {
    this.modelServices.isPushHMI(this.model);
  }

  setCycleNumber() {
    this.commonServices.setCycleNumber.subscribe(item => {
      if (this.model.txtCycleNumber > item) {
        this.model.txtCycleNumber = item;
      }
    });
  }
}