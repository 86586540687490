import { Injectable } from "@angular/core";
import * as TLDryerModel from "src/assets/JsonFiles/tl-dryer/tl-dryer-model.json";
import * as tlDryerSetup from "src/assets/JsonFiles/tl-dryer/tl-dryer-setup.json";
import * as tlDryerCycleVendingSettings from "src/assets/JsonFiles/tl-dryer/tl-dryer-Cycle-Vending-Settings.json";
import { ICycleSpecialPrice, IFixedCycleEnable, IRegularPriceCycleLen, IRelationalEnvelopeOpen, ISpecialPriceCycleLen, ISpecialPriceTime, ISpecialPricingEnable, ISundaySpecialPriceEnable, ITimePerCoin, IValueChecked, IValueCoin1, ITlDryer, ITlDryerList } from "../shared/layout/tl-dryer/tl-dryer-cycle-vending/tl-dryer-cycle-vending.model";
import { IModelList } from "./ModelServices";
import { ITlDryerSetup, ITlDryerSetupDetail, ITlDryerSetupList, IValue } from "../shared/layout/tl-dryer/tl-dryer-setup/tl-dryer-setup.model";
import { CommonServices } from "./CommonServices";
import { Utilities } from "./UtliltyService";
import { ICommonModel } from "../shared/models/common";
import { IGetUserDefinedConfiguration } from "../shared/layout/user-defined-setup/user-defined-setup.model";

@Injectable({
    providedIn: "root",
})
export class TlDryerModelServices {
    public tlDryerModel: IModelList = (TLDryerModel as any).default;
    tldryersetup: ITlDryerSetupList = (tlDryerSetup as any).default;
    vendingsettings: ITlDryerList = (tlDryerCycleVendingSettings as any).default;
    isModelChange: boolean = false;
    value: IValue = {
        id: 0,
        name: ''
    }
    regularOffSet: ITlDryerSetupDetail = {
        value: 0,
        values: this.value,
        Default: 0,
        arrKey: [],
        binaryKey: '',
        type: ''
    }
    setupModelList: ITlDryerSetup = {
        CycleCountingEnable: this.regularOffSet,
        MoneyCountingOption: this.regularOffSet,
        CountViewing: this.regularOffSet,
        VendingDisplay: this.regularOffSet,
        CoinPaymentEnable: this.regularOffSet,
        DebitCardPaymentProtocol: this.regularOffSet,
        AutoClearEscrowEnable: this.regularOffSet,
        CoinSlideEnable: this.regularOffSet,
        hasValue: true
    }

    ValueChecked: IValueChecked = {
        id: 0,
        name: '',
        checked: false
    }

    regularPriceCycleLen: IRegularPriceCycleLen = {
        value: '',
        default: '',
        values: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        min: 0,
        max: 0
    }

    fixedCycleEnable: IFixedCycleEnable = {
        value: '',
        default: '',
        values: [],
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    specialPricingEnable: ISpecialPricingEnable = {
        value: false,
        default: false,
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    specialPriceEnable: ISundaySpecialPriceEnable = {
        value: '',
        default: '',
        values: this.ValueChecked,
        binaryKey: '',
        arrKey: [],
        type: ''
    }

    specialPriceTime: ISpecialPriceTime = {
        value: [],
        default: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        endHour: '',
        startHour: ''
    }

    specialPriceCycleLen: ISpecialPriceCycleLen = {
        value: '',
        default: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        max: '',
        min: ''
    }

    cycleSpecialPrice: ICycleSpecialPrice = {
        value: [],
        default: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        cents: '',
        dollars: ''
    }

    ValueCoin1: IValueCoin1 = {
        value: [],
        default: '',
        min: '',
        max: '',
        binaryKey: '',
        arrKey: [],
        type: '',
        dollars: '',
        cents: '',
    }

    relationalEnvelopeOpen: IRelationalEnvelopeOpen = {
        binaryKeyValue: '',
        arrKey: [],
        type: ''
    }

    timePerCoinVal: ITimePerCoin = {
        value: 0,
        default: 0
    }

    tlDryerSettingList: ITlDryer = {
        Relational_Envelope_Open: this.relationalEnvelopeOpen,
        Relational_Envelope_Action: this.relationalEnvelopeOpen,
        Entity_Cycle_Open: this.relationalEnvelopeOpen,
        Relational_Entity_Name: this.relationalEnvelopeOpen,
        Relational_Entity_Id: this.relationalEnvelopeOpen,
        DnldCycle_CycleSet_CycleCavityPosition: this.relationalEnvelopeOpen,
        Relational_Array_Open: this.relationalEnvelopeOpen,

        Entity_Cycle_Part1_Open: this.relationalEnvelopeOpen,
        Entity_Cycle_Part1_Close: this.relationalEnvelopeOpen,
        Entity_Cycle_Close: this.relationalEnvelopeOpen,
        Relational_Array_Close: this.relationalEnvelopeOpen,
        Relational_Envelope_Close: this.relationalEnvelopeOpen,
        DnldCycle_OpSet_RegularPriceCycleLen: this.regularPriceCycleLen,
        FixedCycleEnable: this.fixedCycleEnable,
        SpecialPricingEnable: this.specialPricingEnable,
        SundaySpecialPriceEnable: this.specialPriceEnable,
        MondaySpecialPriceEnable: this.specialPriceEnable,
        TuesdaySpecialPriceEnable: this.specialPriceEnable,
        WednesdaySpecialPriceEnable: this.specialPriceEnable,
        ThursdaySpecialPriceEnable: this.specialPriceEnable,
        FridaySpecialPriceEnable: this.specialPriceEnable,
        SaturdaySpecialPriceEnable: this.specialPriceEnable,
        SpecialPriceTime: this.specialPriceTime,
        DnldCycle_OpSet_SpecialPriceCycleLen: this.specialPriceCycleLen,
        DnldCycle_PaySet_CycleSpecialPrice: this.cycleSpecialPrice,
        DnldCycle_PaySet_CycleRegularPrice: this.cycleSpecialPrice,
        ValueCoin1: this.ValueCoin1,
        ValueCoin2: this.ValueCoin1,
        TimePerCoinVal: this.timePerCoinVal,
        hasValue: false
    }
    // TlDryerSetup
    arrTlDryerSetupConfigSettings: any[] = [];
    arrVenusCycleVendingConfigSettings: any[] = [];

    constructor(
        private utilityService: Utilities,
        private commonService: CommonServices
    ) {
        this.commonService.setBinaryTlDryer.subscribe((item: ICommonModel) => {
            this.setBinarySetupModels(item);
        })
        this.commonService.isTlDryerModel.subscribe((item: IGetUserDefinedConfiguration) => {
            const setupModelList = JSON.parse(item.setupConfig);
            const vendConf = JSON.parse(item.vendingConfig);
            if (setupModelList.hasValue) {
                this.setModelSetupModels(setupModelList);
            }
            if (vendConf.hasValue) {
                this.setModelsCycleVending(vendConf);
            }
        });
    }

    getTlDryerSetupSettings(modelType: string) {
        this.isModelChange = false;
        if (modelType === 'PD') {
            return this.tldryersetup.setup.PD;
        } else if (modelType === 'PR') {
            return this.tldryersetup.setup.PR;
        } else if (modelType === '9J') {
            return this.tldryersetup.setup['9J'];
        }
    }

    getTlDryerCycleVendingSettings(modelType: string) {
        this.isModelChange = false;
        if (modelType === 'PD') {
            return this.vendingsettings.vended.PD;
        } else if (modelType === 'PR') {
            return this.vendingsettings.vended.PR;
        } else if (modelType === '9J') {
            return this.vendingsettings.vended['9J'];
        }
    }

    getTlDryerModelList(): IModelList {
        return this.tlDryerModel;
    }

    setSetupModels(setupList: ITlDryerSetup) {
        this.isModelChange = false;
        this.setupModelList = setupList;
    }

    getSetupModels() {
        return this.setupModelList;
    }

    setModelSetupModels(setupModelList: any) {
        this.setupModelList = this.getTlDryerSetupSettings(localStorage.getItem("ProductType"));
        this.setupModelList.CycleCountingEnable.value = setupModelList.CycleCountingEnable.value;
        this.setupModelList.MoneyCountingOption.value = setupModelList.MoneyCountingOption.value;
        this.setupModelList.CountViewing.value = setupModelList.CountViewing.value;
        this.setupModelList.VendingDisplay.value = setupModelList.VendingDisplay.value;
        this.setupModelList.CoinPaymentEnable.value = setupModelList.CoinPaymentEnable.value;
        this.setupModelList.DebitCardPaymentProtocol.value = setupModelList.DebitCardPaymentProtocol.value;
        this.setupModelList.AutoClearEscrowEnable.value = setupModelList.AutoClearEscrowEnable.value;
        this.setupModelList.CoinSlideEnable.value = setupModelList.CoinSlideEnable.value;
        this.setupModelList.hasValue = true;
        this.isModelChange = false;
    }

    clearSetupModels() {
        this.setupModelList.CycleCountingEnable.value = 0;
        this.setupModelList.MoneyCountingOption.value = 0;
        this.setupModelList.CountViewing.value = 1;
        this.setupModelList.VendingDisplay.value = 0;
        this.setupModelList.CoinPaymentEnable.value = 0;
        this.setupModelList.DebitCardPaymentProtocol.value = 0;
        this.setupModelList.AutoClearEscrowEnable.value = 0;
        this.setupModelList.CoinSlideEnable.value = 0;
        this.setupModelList.hasValue = false;
        this.isModelChange = true;
        return this.setupModelList;
    }

    setBinarySetupModels(commonModel: ICommonModel) {
        this.setupModelList = this.getTlDryerSetupSettings(localStorage.getItem("ProductType"));
        // const machineBinaryValue = this.commservice.getTlDryerBinaryData();
        // if (!machineBinaryValue.length || !machineBinaryValue) {
        //     this.commservice.getClose();
        //     return;
        // }
        this.setupModelList.CycleCountingEnable.value = commonModel.CycleCountingEnable ? commonModel.CycleCountingEnable : 0;
        this.setupModelList.MoneyCountingOption.value = commonModel.MoneyCountingOption ? commonModel.MoneyCountingOption : 0;
        this.setupModelList.CountViewing.value = commonModel.CountViewing ? commonModel.CountViewing : 0;
        this.setupModelList.VendingDisplay.value = commonModel.VendingDisplay ? commonModel.VendingDisplay : 0;
        this.setupModelList.CoinPaymentEnable.value = commonModel.CoinPaymentEnable ? commonModel.CoinPaymentEnable : 0;
        this.setupModelList.DebitCardPaymentProtocol.value = commonModel.DebitCardPaymentProtocol ? commonModel.DebitCardPaymentProtocol : 0;
        this.setupModelList.AutoClearEscrowEnable.value = commonModel.AutoClearEscrowEnable ? commonModel.AutoClearEscrowEnable : 0;
        this.setupModelList.CoinSlideEnable.value = commonModel.CoinSlideEnable ? commonModel.CoinSlideEnable : 0;
        this.setupModelList.hasValue = true;
        this.isModelChange = false;
        this.commonService.getClose();
        this.setBinaryCycleVending(commonModel);
    }

    setVenusCycleModels(venusCycleModel: ITlDryer) {
        this.isModelChange = false;
        this.tlDryerSettingList = venusCycleModel;
    }

    getVenusCycleModels() {
        return this.tlDryerSettingList;
    }

    clearVenusCycleModels() {
        this.tlDryerSettingList.DnldCycle_OpSet_RegularPriceCycleLen.value = '0';
        this.tlDryerSettingList.FixedCycleEnable.value = '0';
        if (this.tlDryerSettingList.SpecialPricingEnable != undefined) {
            this.tlDryerSettingList.DnldCycle_OpSet_SpecialPriceCycleLen.value = '0';
            this.tlDryerSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = '0';
            this.tlDryerSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = '0';
        }
        this.tlDryerSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = '0';
        this.tlDryerSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = '0';
        this.tlDryerSettingList.ValueCoin1.value[0] = '0';
        this.tlDryerSettingList.ValueCoin1.value[1] = '0';
        this.tlDryerSettingList.ValueCoin2.value[0] = '0';
        this.tlDryerSettingList.ValueCoin2.value[1] = '0';
        this.tlDryerSettingList.hasValue = false
        this.isModelChange = true;
        return this.tlDryerSettingList;
    }

    setBinaryCycleVending(commonModel: ICommonModel) {
        let twoBytes;
        let splBytes;
        let regBytes;
        this.tlDryerSettingList = this.getTlDryerCycleVendingSettings(localStorage.getItem("ProductType"));
        if (this.tlDryerSettingList.SpecialPricingEnable != undefined) {
            const splDryTime = this.utilityService.convertU16ToDryTime(commonModel.SpecialPriceCycleLen[0], commonModel.SpecialPriceCycleLen[1]);
            this.tlDryerSettingList.DnldCycle_OpSet_SpecialPriceCycleLen.value = String(splDryTime);
            splBytes = this.utilityService.convertBinToDecArry(commonModel.CycleSpecialPrice[0], commonModel.CycleSpecialPrice[1]);
            this.tlDryerSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = splBytes[0];
            this.tlDryerSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = splBytes[1];
        }
        regBytes = this.utilityService.convertBinToDecArry(commonModel.CycleRegularPrice[0], commonModel.CycleRegularPrice[1]);
        this.tlDryerSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = regBytes[0];
        this.tlDryerSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = regBytes[1];
        if (this.tlDryerSettingList.SpecialPricingEnable == undefined) {
            const regDryTime = this.utilityService.convertU16ToDryTime(commonModel.RegularPriceCycleLen[0], commonModel.RegularPriceCycleLen[1]);
            this.tlDryerSettingList.DnldCycle_OpSet_RegularPriceCycleLen.value = String(regDryTime);
            this.tlDryerSettingList.FixedCycleEnable.value = commonModel.FixedCycleEnable;
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin1[0], commonModel.ValueCoin1[1]);
            this.tlDryerSettingList.ValueCoin1.value[0] = twoBytes[0];
            this.tlDryerSettingList.ValueCoin1.value[1] = twoBytes[1];
            this.calcTimePerCoin(regBytes[0], regBytes[1], twoBytes[0], twoBytes[1]);
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin2[0], commonModel.ValueCoin2[0]);
            this.tlDryerSettingList.ValueCoin2.value[0] = twoBytes[0];
            this.tlDryerSettingList.ValueCoin2.value[1] = twoBytes[1];
        } else {
            const regDryTime = this.utilityService.convertU16ToDryTime(commonModel.RegularPriceCycleLen[0], commonModel.RegularPriceCycleLen[1]);
            this.tlDryerSettingList.DnldCycle_OpSet_RegularPriceCycleLen.value = String(regDryTime);
            this.tlDryerSettingList.FixedCycleEnable.value = commonModel.FixedCycleEnable;
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin1[0], commonModel.ValueCoin1[1]);
            this.tlDryerSettingList.ValueCoin1.value[0] = twoBytes[0];
            this.tlDryerSettingList.ValueCoin1.value[1] = twoBytes[1];
            this.calcTimePerCoin(regBytes[0], regBytes[1], twoBytes[0], twoBytes[1]);
            twoBytes = this.utilityService.convertBinToDecArry(commonModel.ValueCoin2[0], commonModel.ValueCoin2[1]);
            this.tlDryerSettingList.ValueCoin2.value[0] = twoBytes[0];
            this.tlDryerSettingList.ValueCoin2.value[1] = twoBytes[1];
        }
        this.tlDryerSettingList.hasValue = true;
    }

    setModelsCycleVending(commonModel: any) {
        this.tlDryerSettingList = this.getTlDryerCycleVendingSettings(localStorage.getItem("ProductType"));
        this.tlDryerSettingList.DnldCycle_OpSet_SpecialPriceCycleLen.value = commonModel.DnldCycle_OpSet_SpecialPriceCycleLen.value;
        this.tlDryerSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[0] = commonModel.DnldCycle_PaySet_CycleSpecialPrice.value[0];
        this.tlDryerSettingList.DnldCycle_PaySet_CycleSpecialPrice.value[1] = commonModel.DnldCycle_PaySet_CycleSpecialPrice.value[1];
        this.tlDryerSettingList.DnldCycle_PaySet_CycleRegularPrice.value[0] = commonModel.DnldCycle_PaySet_CycleRegularPrice.value[0]
        this.tlDryerSettingList.DnldCycle_PaySet_CycleRegularPrice.value[1] = commonModel.DnldCycle_PaySet_CycleRegularPrice.value[1];
        this.tlDryerSettingList.DnldCycle_OpSet_RegularPriceCycleLen.value = commonModel.DnldCycle_OpSet_RegularPriceCycleLen.value;
        this.tlDryerSettingList.FixedCycleEnable.value = commonModel.FixedCycleEnable.value;
        this.tlDryerSettingList.ValueCoin1.value[0] = commonModel.ValueCoin1.value[0];
        this.tlDryerSettingList.ValueCoin1.value[1] = commonModel.ValueCoin1.value[1];
        this.tlDryerSettingList.ValueCoin2.value[0] = commonModel.ValueCoin2.value[0];
        this.tlDryerSettingList.ValueCoin2.value[1] = commonModel.ValueCoin2.value[1];
        this.tlDryerSettingList.TimePerCoinVal.value = commonModel.TimePerCoinVal.value;
        this.tlDryerSettingList.hasValue = true;
    }

    modelChanged() {
        return this.isModelChange;
    }

    mapSetupBinaryKey() {
        this.arrTlDryerSetupConfigSettings = [];
        const TlDryerSetup = this.getTlDryerSetupSettings(localStorage.getItem("ProductType"));
        if (TlDryerSetup) {
            if (TlDryerSetup.hasValue) {
                TlDryerSetup.CycleCountingEnable.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.CycleCountingEnable.value);

                TlDryerSetup.MoneyCountingOption.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.MoneyCountingOption.value);
                TlDryerSetup.CountViewing.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.CountViewing.value);
                TlDryerSetup.VendingDisplay.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.VendingDisplay.value);
                TlDryerSetup.CoinPaymentEnable.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.CoinPaymentEnable.value);
                TlDryerSetup.DebitCardPaymentProtocol.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.DebitCardPaymentProtocol.value);
                TlDryerSetup.AutoClearEscrowEnable.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.AutoClearEscrowEnable.value);
                TlDryerSetup.CoinSlideEnable.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.CoinSlideEnable.value);
            } else {
                TlDryerSetup.CycleCountingEnable.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.CycleCountingEnable.Default);

                TlDryerSetup.MoneyCountingOption.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.MoneyCountingOption.Default);
                TlDryerSetup.CountViewing.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.CountViewing.Default);
                TlDryerSetup.VendingDisplay.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.VendingDisplay.Default);
                TlDryerSetup.CoinPaymentEnable.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.CoinPaymentEnable.Default);
                TlDryerSetup.DebitCardPaymentProtocol.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.DebitCardPaymentProtocol.Default);
                TlDryerSetup.AutoClearEscrowEnable.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.AutoClearEscrowEnable.Default);
                TlDryerSetup.CoinSlideEnable.arrKey.map(item => {
                    this.arrTlDryerSetupConfigSettings.push(item);
                })
                this.arrTlDryerSetupConfigSettings.push(TlDryerSetup.CoinSlideEnable.Default);
            }
        }
        return this.arrTlDryerSetupConfigSettings;
    }

    mapCycleVendingBinaryKey() {
        this.arrVenusCycleVendingConfigSettings = [];
        const modelType = localStorage.getItem("ProductType");
        const cycleVending = this.getTlDryerCycleVendingSettings(modelType);
        if (cycleVending) {
            if (cycleVending.hasValue) {
                cycleVending.Relational_Envelope_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Envelope_Action.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Entity_Name.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                this.arrVenusCycleVendingConfigSettings.push("0");
                cycleVending.Relational_Entity_Id.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.DnldCycle_CycleSet_CycleCavityPosition.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.DnldCycle_PaySet_CycleRegularPrice.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleRegularPrice.value[0], cycleVending.DnldCycle_PaySet_CycleRegularPrice.value[1], cycleVending.DnldCycle_PaySet_CycleRegularPrice.type);
                if (modelType != '9J') {
                    cycleVending.DnldCycle_PaySet_CycleSpecialPrice.arrKey.map(item => {
                        this.arrVenusCycleVendingConfigSettings.push(item);
                    });
                    this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleSpecialPrice.value[0], cycleVending.DnldCycle_PaySet_CycleSpecialPrice.value[1], cycleVending.DnldCycle_PaySet_CycleSpecialPrice.type);
                }
                cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                const regularCycleLen = (Number(cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.value) * 60)
                this.calculateBytes(regularCycleLen, cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.type);
                if (modelType != '9J') {
                    cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.arrKey.map(item => {
                        this.arrVenusCycleVendingConfigSettings.push(item);
                    });
                    const splCycleLen = (Number(cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.value) * 60)
                    this.calculateBytes(splCycleLen, cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.type);
                }
                cycleVending.Relational_Array_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Array_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Envelope_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.FixedCycleEnable.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculateBytes(cycleVending.FixedCycleEnable.value, cycleVending.FixedCycleEnable.type);
                cycleVending.ValueCoin1.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin1.value[0], cycleVending.ValueCoin1.value[1], cycleVending.ValueCoin1.type);
                cycleVending.ValueCoin2.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin2.value[0], cycleVending.ValueCoin2.value[1], cycleVending.ValueCoin2.type);
            } else {
                cycleVending.Relational_Envelope_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Envelope_Action.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Entity_Name.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                this.arrVenusCycleVendingConfigSettings.push("0");
                cycleVending.Relational_Entity_Id.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.DnldCycle_CycleSet_CycleCavityPosition.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.DnldCycle_PaySet_CycleRegularPrice.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleRegularPrice.dollars, cycleVending.DnldCycle_PaySet_CycleRegularPrice.cents, cycleVending.DnldCycle_PaySet_CycleRegularPrice.type);
                if (modelType != '9J') {
                    cycleVending.DnldCycle_PaySet_CycleSpecialPrice.arrKey.map(item => {
                        this.arrVenusCycleVendingConfigSettings.push(item);
                    })
                    this.calculatePrice(cycleVending.DnldCycle_PaySet_CycleSpecialPrice.dollars, cycleVending.DnldCycle_PaySet_CycleSpecialPrice.cents, cycleVending.DnldCycle_PaySet_CycleSpecialPrice.type);
                }
                cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                const regularCycleLen = (Number(cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.default) * 60)
                this.calculateBytes(regularCycleLen, cycleVending.DnldCycle_OpSet_RegularPriceCycleLen.type);
                if (modelType != '9J') {
                    cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.arrKey.map(item => {
                        this.arrVenusCycleVendingConfigSettings.push(item);
                    })
                    const splCycleLen = (Number(cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.default) * 60)
                    this.calculateBytes(splCycleLen, cycleVending.DnldCycle_OpSet_SpecialPriceCycleLen.type);
                }
                cycleVending.Relational_Array_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Open.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Part1_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Array_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Entity_Cycle_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.Relational_Envelope_Close.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                });
                cycleVending.FixedCycleEnable.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculateBytes(cycleVending.FixedCycleEnable.default, cycleVending.FixedCycleEnable.type);
                cycleVending.ValueCoin1.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin1.dollars, cycleVending.ValueCoin1.cents, cycleVending.ValueCoin1.type);
                cycleVending.ValueCoin2.arrKey.map(item => {
                    this.arrVenusCycleVendingConfigSettings.push(item);
                })
                this.calculatePrice(cycleVending.ValueCoin2.dollars, cycleVending.ValueCoin2.cents, cycleVending.ValueCoin2.type);
            }
        }
        return this.arrVenusCycleVendingConfigSettings;
    }

    calculateBytes(inputNum: string | number, type: string) {
        switch (type.toLowerCase()) {
            case 'u16':
                let firstByte = 0, secondByte = 0;
                secondByte = (Number(inputNum) & 0x00FF);
                firstByte = (Number(inputNum) & 0xFF00) >> 8;
                this.arrVenusCycleVendingConfigSettings.push(firstByte);
                this.arrVenusCycleVendingConfigSettings.push(secondByte);
                break;
            case 'string':
                var hArray = inputNum.toString();
                for (var j = 0; j < hArray.length; j++) {
                    this.arrVenusCycleVendingConfigSettings.push(hArray[j].charCodeAt(0).toString(10));
                }
                this.arrVenusCycleVendingConfigSettings.push("0");
                break;
            case 'u8':
                this.arrVenusCycleVendingConfigSettings.push(inputNum);
                break;
        }
    }

    calculatePrice(dollar: string, cents: string, type: string) {
        let price = Number(dollar) * 100 + Number(cents);
        this.calculateBytes(price, type);
    }

    calcTimePerCoin(splDollar: any, splCent: any, coin1: any, coin2: any) {
        const cycleSpecialPriceDollar = Number(splDollar);
        const cycleSpecialPriceCents = Number(splCent);
        const coin1Dollar = Number(coin1);
        const coin1Cents = Number(coin2);
        const specialPrice1 = (cycleSpecialPriceDollar * 100) + cycleSpecialPriceCents;
        const specialPrice2 = (coin1Dollar * 100) + coin1Cents;
        if (specialPrice2 === 0) {
            this.tlDryerSettingList.DnldCycle_OpSet_SpecialPriceCycleLen.value = '0';
            return;
        }
        const specialDryTime = String(Math.round((specialPrice2 / specialPrice1) * Number(this.tlDryerSettingList.DnldCycle_OpSet_RegularPriceCycleLen.value)));
        this.tlDryerSettingList.TimePerCoinVal.value = Number(specialDryTime);
    }

    getSetupModelData() {
        const setupModelList = this.getTlDryerSetupSettings(localStorage.getItem("ProductType"));
        this.commonService.novoEcoModels.setupConfig = '';
        this.commonService.novoEcoModels.setupConfig = JSON.stringify(setupModelList);
    }

    getVendingModelData() {
        const tlDryerSettingList = this.getTlDryerCycleVendingSettings(localStorage.getItem("ProductType"));
        this.commonService.novoEcoModels.vendingConfig = '';
        this.commonService.novoEcoModels.vendingConfig = JSON.stringify(tlDryerSettingList);
    }
}