import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonServices } from 'src/app/services/CommonServices';
import { Subscription } from 'rxjs';
import { ElementRef } from '@angular/core';
import { UsbDownload } from 'src/app/services/UsbDownloadService';
import { ModelServices } from 'src/app/services/ModelServices';
import { MenuNames } from '../../enums/enum';
import { MatDialog } from '@angular/material';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit {
  @ViewChild(FormGroupDirective, { static: false }) formGroupDirective: FormGroupDirective;
  @ViewChild('footerButtons', { static: false }) input: ElementRef<HTMLInputElement>;
  @Input() childMessage: string;
  isHide: boolean = true;
  isHidePara: boolean = false;
  isHideButton: boolean = false;
  showButtons: Subscription;
  data: number = 0;
  dialogRef: any;
  public saveConfig: FormGroup;
  public validation_messages = {
    modelName: [{ type: "required", message: "Model Name is required" }],
    configName: [{ type: "required", message: "Configure Name is required" }]
  };
  isSetToDefaultHide: boolean = false;

  constructor(
    private modelServices: ModelServices,
    private commonServices: CommonServices,
    private usbDownload: UsbDownload,
    public dialog3: MatDialog,
    private fb: FormBuilder
  ) {
    this.showButtons = this.commonServices.getShowButtons().subscribe(() => {
      this.hideDiv();
    })
  }

  get f() {
    return this.saveConfig.controls;
  }

  ngOnInit() {
    // this.isSetToDefaultHide = this.commonServices.userDefinedConfig.configId;
    this.commonServices.paraHeader.subscribe((data: any) => {
      this.isHidePara = data.toLowerCase() == 'true';
    })
    this.commonServices.generateButtonHide.subscribe((data: any) => {
      this.input.nativeElement.classList.add('hideDiv');
    })
    this.getFormFields();
    this.closeModal();
    this.hideDeleteButton();
  }

  getFormFields() {
    this.saveConfig = this.fb.group({
      modelName: ["", Validators.required],
      configName: ["", Validators.required]
    });
  }

  downloadFromButon() {
    if (localStorage.getItem("SelectedMenu") === MenuNames.MlgMle) {
      this.usbDownload.generateCSPSetupBinaryFile();
      return;
    }
    if (localStorage.getItem("SelectedMenu") === MenuNames.TLDryer) {
      this.usbDownload.generateTlDryerSetupBinaryFile();
      return;
    }

    if (localStorage.getItem("SelectedMenu") === MenuNames.TLWasher) {
      this.usbDownload.generateTlWasherBinaryFile();
      return;
    }
    if (localStorage.getItem("SelectedMenu") === MenuNames.FLDryer) {
      this.usbDownload.generateFlDryerSetupBinaryFile();
      return;
    }
    if (localStorage.getItem("SelectedMenu") === MenuNames.FLWasher) {
      this.usbDownload.generateFlWasherSetupBinaryFile();
      return;
    }
    // if (this.modelServices.isCycleWashCycleComponentTobePush) {
    this.commonServices.setSetUpValue();
    // }
    this.commonServices.setHmiSetUpValue();
    if (this.modelServices.isCycleVendingSettingsTobePush) {
      this.commonServices.setVendingSettingValue();
    }
    let machineConfig = localStorage.getItem("arrMachineConfigSettings");
    let hmiConfig = localStorage.getItem("arrHmiDisplaySettings");
    let cycleConfig = localStorage.getItem("arrCycleSettings");
    let vendingConfig = localStorage.getItem("arrVendingSettingDetail");
    let prodType = localStorage.getItem("ProductType");
    if (prodType !== 'PN') {
      if (machineConfig == null || hmiConfig == null || cycleConfig == null || vendingConfig == null) {
        this.commonServices.getModelSettingForTabSelection('Please click on each tab settings to generate the usb file.');
        return;
      }
    }
    else {
      if (machineConfig == null || hmiConfig == null || cycleConfig == null) {
        this.commonServices.getModelSettingForTabSelection('Please click on each tab settings to generate the usb file.');
        return;
      }
    }
    this.usbDownload.downloadFromButon();
  }

  hideDiv() {
    this.input.nativeElement.classList.remove('hideDiv');
  }

  saveModel(form?: FormGroup) {
    if (!this.commonServices.userDefinedConfig.configId) {
      if (!form.valid) {
        return;
      }
      this.commonServices.saveConfigData.modelNumber = this.f.modelName.value;
      this.commonServices.saveConfigData.configNumber = this.f.configName.value;
    }
    if (localStorage.getItem("SelectedMenu") === MenuNames.MultiLoad) {
      this.usbDownload.generateNovoModel();
    }
    if (localStorage.getItem("SelectedMenu") === MenuNames.MlgMle) {
      this.usbDownload.generateCSPModel();
    }
    if (localStorage.getItem("SelectedMenu") === MenuNames.TLDryer) {
      this.usbDownload.generateTlDryerModel();
      return;
    }
    if (localStorage.getItem("SelectedMenu") === MenuNames.TLWasher) {
      this.usbDownload.generateTlWasherModel();
      return;
    }
    if (localStorage.getItem("SelectedMenu") === MenuNames.FLDryer) {
      this.usbDownload.generateFlDryerModel();
      return;
    }
    if (localStorage.getItem("SelectedMenu") === MenuNames.FLWasher) {
      this.usbDownload.generateFlWasherModel();
      return;
    }
  }

  openModal(mytemplate) {
    if (this.commonServices.userDefinedConfig.configId) {
      this.saveModel();
      return;
    }
    const modelNumber = localStorage.getItem('modelNumber');
    this.f.modelName.setValue(modelNumber);
    this.f.configName.setValue('');
    this.dialogRef = this.dialog3.open(mytemplate, {
      width: "750px",
      height: "360px",
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.formGroupDirective.resetForm();
    });
  }

  closeModal() {
    this.commonServices.isConfigSave.subscribe(isSuccess => {
      if (isSuccess) {
        this.dialogRef.close();
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  hideDeleteButton() {
    this.commonServices.isHideDeleteButton.subscribe(hide => {
      if (hide) {
        this.isSetToDefaultHide = true;
        return;
      }
      this.isSetToDefaultHide = false;
    })
  }

  deleteModel() {
    Swal.fire({
      title:
        "<h5 style='color:#5973b6'>" +
        `${"Are you sure you want to delete model '" + this.commonServices.userDefinedConfig.configId}` + "'" +
        "</h5>",
      showCancelButton: true,
      confirmButtonText: "Ok",
      confirmButtonColor: "#5973b6",
      cancelButtonColor: "#5973b6",
    }).then((result) => {
      if (result.isConfirmed) {
        this.usbDownload.deleteModel();
        Swal.fire("Model deleted successfully.", "", "success");
        // Swal.fire("You can't delete all cycles.", "", "info");
      } else {

      }
    });
  }
}
