import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// Modules
import { RouterModule } from "@angular/router";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from "angular2-moment";
import { TooltipModule } from "ng2-tooltip-directive";
import { NumericTextboxModule } from "ngx-numeric-textbox";
import { ToastrModule } from "ngx-toastr";
import { AppMaterialModule } from "./app-material/app-material.module";
import { AppRoutingModule } from "./app-routing.module"; ``
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';

// Components
import { AppComponent } from "./app.component";
import { BasicAuthInterceptor } from "./helpers/interceptors/basic-auth.interceptor";
import { ErrorInterceptor } from "./helpers/interceptors/ErrorInterceptor";
import { CreatePasswordComponent } from "./pages/create-password/create-password.component";
import { CreateUserComponent } from "./pages/create-user/create-user.component";
import { HomeComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { SetupFileUsbComponent } from "./pages/setup-file-usb/setup-file-usb.component";
import { TermsAndConditionsComponent } from "./pages/terms-and-conditions/terms-and-conditions.component";
import { DisableControlDirective } from "./shared/directives/DisableControlDirective";
import { NumberInputDirective } from "./shared/directives/number-input.directive";
import { OnlynumberDirective } from "./shared/directives/OnlynumberDirective";
import { SpecialCharactersDirective } from "./shared/directives/special-character.directive";

import { CyclecmobinationComponent } from "./shared/layout/novo/cyclecmobination/cyclecmobination.component";
import { CyclevendingComponent } from "./shared/layout/novo/cyclevending/cyclevending.component";
import { DashboardComponent } from "./shared/layout/dashboard/dashboard.component";
import { FooterComponent } from "./shared/layout/footer/footer.component";
import { HeaderComponent } from "./shared/layout/header/header.component";
import { HmisetupComponent } from "./shared/layout/novo/hmisetup/hmisetup.component";
import { LeftmenuitemComponent } from "./shared/layout/leftmenuitem/leftmenuitem.component";
import { ModellistComponent } from "./shared/layout/modellist/modellist.component";
import { SetupComponent } from "./shared/layout/novo/setup/setup.component";
import { TopfeaturesComponent } from "./shared/layout/topfeatures/topfeatures.component";
import { VendingsettingComponent } from "./shared/layout/novo/vendingsetting/vendingsetting.component";
import { VenusCycleVendingComponent } from "./shared/layout/csp/venus-cycle-vending/venus-cycle-vending.component";
import { VenusSetupComponent } from "./shared/layout/csp/venus-setup/venus-setup.component";
import { WashcycleComponent } from "./shared/layout/novo/washcycle/washcycle.component";
import { FullTextSearchPipe } from "./shared/pipes/full-text-search.pipe";
import { ModelSerialNumberComponent } from './pages/model-serial-number/model-serial-number.component';
import { TLDryerCycleVendingComponent } from "./shared/layout/tl-dryer/tl-dryer-cycle-vending/tl-dryer-cycle-vending.component";
import { TLDryerSetupComponent } from "./shared/layout/tl-dryer/tl-dryer-setup/tl-dryer-setup.component";
import { TlWasherCycleComponent } from "./shared/layout/tl-washer/tl-washer-cycle/tl-washer-cycle.component";
import { TlWasherCyclecmobinationComponent } from "./shared/layout/tl-washer/tl-washer-cyclecmobination/tl-washer-cyclecmobination.component";
import { TLWasherCycleVendingComponent } from "./shared/layout/tl-washer/tl-washer-cycle-vending/tl-washer-cycle-vending.component";
import { TLWasherSetupComponent } from "./shared/layout/tl-washer/tl-washer-setup/tl-washer-setup.component";
import { UploadMachineConfigComponent } from "./pages/upload-machine-config/upload-machine-config.component";
import { FlDryerSetupComponent } from "./shared/layout/fl-dryer/fl-dryer-setup/fl-dryer-setup.component";
import { FLDryerCycleVendingComponent } from "./shared/layout/fl-dryer/fl-dryer-cycle-vending/fl-dryer-cycle-vending.component";
import { FLWasherSetupComponent } from "./shared/layout/fl-washer/fl-washer-setup/fl-washer-setup.component";
import { FLWasherCycleVendingComponent } from "./shared/layout/fl-washer/fl-washer-cycle-vending/fl-washer-cycle-vending.component";
import { FlWasherCyclecmobinationComponent } from "./shared/layout/fl-washer/fl-washer-cyclecmobination/fl-washer-cyclecmobination.component";
import { FlWasherCycleComponent } from "./shared/layout/fl-washer/fl-washer-cycle/fl-washer-cycle.component";
import { AuditReportComponent } from "./pages/audit-report/audit-report.component";
import { DatePipe } from "@angular/common";
import { FilterArrayText } from "./shared/pipes/filter.pipe";
import { UserDefinedSetupComponent } from "./shared/layout/user-defined-setup/user-defined-setup.component";
import { ButtonsComponent } from "./shared/layout/buttons/buttons.component";
import { ManageUserComponent } from "./pages/manage-user/manage-user.component";
import { EditProfileComponent } from "./pages/edit-profile/edit-profile.component";
import { MustMatchDirective } from "./pages/edit-profile/mustMatch.directive";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SetupFileUsbComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    ModellistComponent,
    LeftmenuitemComponent,
    TopfeaturesComponent,
    CyclecmobinationComponent,
    SetupComponent,
    CyclevendingComponent,
    ButtonsComponent,
    HmisetupComponent,
    WashcycleComponent,
    VendingsettingComponent,
    CreateUserComponent,
    CreatePasswordComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    DisableControlDirective,
    OnlynumberDirective,
    NumberInputDirective,
    SpecialCharactersDirective,
    ResetPasswordComponent,
    FullTextSearchPipe,
    FilterArrayText,
    VenusCycleVendingComponent,
    VenusSetupComponent,
    ModelSerialNumberComponent,
    TLDryerCycleVendingComponent,
    TLDryerSetupComponent,
    TlWasherCycleComponent,
    TlWasherCyclecmobinationComponent,
    TLWasherCycleVendingComponent,
    TLWasherSetupComponent,
    UploadMachineConfigComponent,
    FlDryerSetupComponent,
    FLDryerCycleVendingComponent,
    FLWasherSetupComponent,
    FLWasherCycleVendingComponent,
    FlWasherCyclecmobinationComponent,
    FlWasherCycleComponent,
    AuditReportComponent,
    UserDefinedSetupComponent,
    ManageUserComponent,
    EditProfileComponent,
    MustMatchDirective
  ],
  imports: [
    AppMaterialModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NumericTextboxModule,
    TooltipModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    CalendarModule,
    TableModule,
    FileUploadModule
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
