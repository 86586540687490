import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fullTextSearch',
    pure: false
})
export class FullTextSearchPipe implements PipeTransform {

    constructor() { }

    transform(items: any[], searchText: string): any[] {
        if (!items) return [];

        if (!searchText) return items;

        return this.searchItems(items, searchText.toLowerCase());
    }

    private searchItems(items: any[], searchText): any[] {
        let results = [];
        items.forEach(it => {
            if (it.subMenuText.toLowerCase().includes(searchText)) {
                results.push(it);
            } else {
                return;
            }
        });
        return results;
    }
}