import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FlWasherModelServices } from "src/app/services/FlWasherModel.service";
import { FlWasherSetup, FlWasherSetupDetail, ISetupModelList, IValue } from "./fl-washer-setup.model";


@Component({
  selector: "app-fl-washer-setup",
  templateUrl: "./fl-washer-setup.component.html",
  styleUrls: ["./fl-washer-setup.component.scss"],
})

export class FLWasherSetupComponent implements OnInit {
  FlWasherSetupForm: FormGroup;
  model: ISetupModelList = {
    CycleCountingEnable: 0,
    MoneyCountingOption: 0,
    CoinBoxOpenMoneyCount: 0,
    VendingDisplay: 0,
    CoinPaymentEnable: 0,
    DebitCardPaymentProtocol: 0,
    AutoClearEscrowEnable: 0,
    CoinSlideEnable: 0,
    hasValue: false
  }
  value: IValue = {
    id: 0,
    name: ''
  }
  regularOffSet: FlWasherSetupDetail = {
    value: 0,
    values: this.value,
    Default: 0,
    arrKey: [],
    binaryKey: '',
    type: ''
  }
  flWasherSettings: FlWasherSetup = {
    CycleCountingEnable: this.regularOffSet,
    MoneyCountingOption: this.regularOffSet,
    CountViewing: this.regularOffSet,
    VendingDisplay: this.regularOffSet,
    CoinPaymentEnable: this.regularOffSet,
    DebitCardPaymentProtocol: this.regularOffSet,
    AutoClearEscrowEnable: this.regularOffSet,
    CoinSlideEnable: this.regularOffSet,
    hasValue: true
  }

  constructor(
    private _flWasherModelServices: FlWasherModelServices
    , private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getControls();
    this.flWasherSettings = this._flWasherModelServices.getSetupModels();
    if (!this.flWasherSettings) {
      this.flWasherSettings = this._flWasherModelServices.getFlWasherSetupSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    } else if (this.flWasherSettings && !this.flWasherSettings.hasValue) {
      this.flWasherSettings = this._flWasherModelServices.getFlWasherSetupSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    }
  }

  getControls() {
    this.FlWasherSetupForm = this.fb.group({
      cycleCounter: ["", Validators.required],
      moneyCounter: ["", Validators.required],
      vaultViewingOption: ["", Validators.required],
      addCoinOption: ["", Validators.required],
      coinOption: ["", Validators.required],
      debitOption: ["", Validators.required],
      priceSupressionOption: ["", Validators.required],
      clearEscrowOption: ["", Validators.required],
      coinSlideOption: ["", Validators.required]
    });
  }

  setToDefault() {
    this.flWasherSettings.CycleCountingEnable.value = this.flWasherSettings.CycleCountingEnable.Default;
    this.flWasherSettings.MoneyCountingOption.value = this.flWasherSettings.MoneyCountingOption.Default;
    this.flWasherSettings.CountViewing.value = this.flWasherSettings.CountViewing.Default;
    this.flWasherSettings.VendingDisplay.value = this.flWasherSettings.VendingDisplay.Default;
    this.flWasherSettings.CoinPaymentEnable.value = this.flWasherSettings.CoinPaymentEnable.Default;
    this.flWasherSettings.DebitCardPaymentProtocol.value = this.flWasherSettings.DebitCardPaymentProtocol.Default;
    this.flWasherSettings.AutoClearEscrowEnable.value = this.flWasherSettings.AutoClearEscrowEnable.Default;
    this.flWasherSettings.CoinSlideEnable.value = this.flWasherSettings.CoinSlideEnable.Default;
    this.flWasherSettings.hasValue = true;
  }

  returnResult(_value: any) {
    const value = _value;
    return value;
  }

  ngOnDestroy() {
    const isModel = this._flWasherModelServices.modelChanged();
    if (!isModel) {
      this._flWasherModelServices.setSetupModels(this.flWasherSettings);
    }
  }
}