import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  OnInit
} from "@angular/core";
import { Routes } from "@angular/router";
import { VenusCycleVendingComponent } from "src/app/shared/layout/csp/venus-cycle-vending/venus-cycle-vending.component";
import { VenusSetupComponent } from "src/app/shared/layout/csp/venus-setup/venus-setup.component";
import { FLDryerCycleVendingComponent } from "src/app/shared/layout/fl-dryer/fl-dryer-cycle-vending/fl-dryer-cycle-vending.component";
import { FlDryerSetupComponent } from "src/app/shared/layout/fl-dryer/fl-dryer-setup/fl-dryer-setup.component";
import { FLWasherCycleVendingComponent } from "src/app/shared/layout/fl-washer/fl-washer-cycle-vending/fl-washer-cycle-vending.component";
import { FlWasherCycleComponent } from "src/app/shared/layout/fl-washer/fl-washer-cycle/fl-washer-cycle.component";
import { FLWasherSetupComponent } from "src/app/shared/layout/fl-washer/fl-washer-setup/fl-washer-setup.component";
import { TLDryerCycleVendingComponent } from "src/app/shared/layout/tl-dryer/tl-dryer-cycle-vending/tl-dryer-cycle-vending.component";
import { TLDryerSetupComponent } from "src/app/shared/layout/tl-dryer/tl-dryer-setup/tl-dryer-setup.component";
import { TLWasherCycleVendingComponent } from "src/app/shared/layout/tl-washer/tl-washer-cycle-vending/tl-washer-cycle-vending.component";
import { TlWasherCycleComponent } from "src/app/shared/layout/tl-washer/tl-washer-cycle/tl-washer-cycle.component";
import { TLWasherSetupComponent } from "src/app/shared/layout/tl-washer/tl-washer-setup/tl-washer-setup.component";
import { UserDefinedSetupComponent } from "src/app/shared/layout/user-defined-setup/user-defined-setup.component";
import { routerTransition } from "../../configs/config";
import { CommonServices } from "../../services/CommonServices";
import { DashboardComponent } from "../../shared/layout/dashboard/dashboard.component";
import { HmisetupComponent } from "../../shared/layout/novo/hmisetup/hmisetup.component";
import { SetupComponent } from "../../shared/layout/novo/setup/setup.component";
import { VendingsettingComponent } from "../../shared/layout/novo/vendingsetting/vendingsetting.component";
import { WashcycleComponent } from "../../shared/layout/novo/washcycle/washcycle.component";
import { AuditReportComponent } from "../audit-report/audit-report.component";
import { ModelSerialNumberComponent } from "../model-serial-number/model-serial-number.component";
import { SetupFileUsbComponent } from "../setup-file-usb/setup-file-usb.component";
import { UploadMachineConfigComponent } from "../upload-machine-config/upload-machine-config.component";


@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  animations: [routerTransition()],
  host: { "[@routerTransition]": "" },
})

export class HomeComponent implements OnInit {
  isOpen: boolean = false;
  setupUSB: boolean = false;
  setupUSBact: boolean = true;
  location: any;
  showDashboard: boolean = false;
  SetUsbPageTwo: boolean = false;
  showModelSerial: boolean = false;
  showModule: string = '';

  constructor(
    private CommonService: CommonServices,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.CommonService.receivedsendUsbVal().subscribe((moduleName) => {
      setTimeout(() => {
        if (moduleName == 'user-defined-setup') {
          this.showModule = 'dashboard';
          this.showDashboard = moduleName == 'user-defined-setup' ? false : true;
          return;
        }
        this.showModule = moduleName;
        this.showDashboard = moduleName == 'dashboard' ? false : true;
      }, 50);
    });
  }

  toggleNav(isOpenChild: boolean): void {
    this.isOpen = isOpenChild;
  }
}

export const homeChildRoutes: Routes = [
  { path: "dashboard", component: DashboardComponent },
  { path: "setup", component: SetupComponent },
  { path: "Hmi-setup", component: HmisetupComponent },
  { path: "cycle-setting", component: WashcycleComponent },
  { path: "vending-setting", component: VendingsettingComponent },
  { path: "setup-file-usb", component: SetupFileUsbComponent },
  { path: 'venus-setup', component: VenusSetupComponent },
  { path: 'venus-cycle-vending', component: VenusCycleVendingComponent },
  { path: 'tl-dryer-setup', component: TLDryerSetupComponent },
  { path: 'tl-dryer-cycle-vending', component: TLDryerCycleVendingComponent },
  { path: 'model-serial-number', component: ModelSerialNumberComponent },
  { path: 'tl-washer-setup', component: TLWasherSetupComponent },
  { path: 'tl-washer-cycle-vending', component: TLWasherCycleVendingComponent },
  // { path: 'diagnostics', component:DiagnosticsComponent},
  { path: 'tl-washer-cycle-setting', component: TlWasherCycleComponent },
  { path: 'upload-machine', component: UploadMachineConfigComponent },
  { path: 'fl-dryer-setup', component: FlDryerSetupComponent },
  { path: 'fl-dryer-cycle-vending', component: FLDryerCycleVendingComponent },
  { path: 'fl-washer-setup', component: FLWasherSetupComponent },
  { path: 'fl-washer-cycle-vending', component: FLWasherCycleVendingComponent },
  { path: 'fl-washer-cycle-setting', component: FlWasherCycleComponent },
  { path: 'audit-report', component: AuditReportComponent },
  { path: "user-defined-setup", component: UserDefinedSetupComponent },
  // { path: 'manage-user', component: ManageUserComponent },
  // { path: 'edit-profile', component: EditProfileComponent }
];
