import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FlDryerModelServices } from "src/app/services/FlDryerModel.service";
import { FlDryerSetup, FlDryerSetupDetail, ISetupModelList, IValue } from "./fl-dryer-setup.model";

declare const $: any;

@Component({
  selector: "app-fl-dryer-setup",
  templateUrl: "./fl-dryer-setup.component.html",
  styleUrls: ["./fl-dryer-setup.component.scss"],
})

export class FlDryerSetupComponent implements OnInit, OnDestroy {
  FlDryerSetupForm: FormGroup;
  model: ISetupModelList = {
    CycleCountingEnable: 0,
    MoneyCountingOption: 0,
    CoinBoxOpenMoneyCount: 0,
    VendingDisplay: 0,
    CoinPaymentEnable: 0,
    DebitCardPaymentProtocol: 0,
    AutoClearEscrowEnable: 0,
    CoinSlideEnable: 0,
    hasValue: false
  }

  value: IValue = {
    id: 0,
    name: ''
  }

  regularOffSet: FlDryerSetupDetail = {
    value: 0,
    values: this.value,
    Default: 0,
    arrKey: [],
    binaryKey: '',
    type: ''
  }

  FlDryerSettings: FlDryerSetup = {
    CycleCountingEnable: this.regularOffSet,
    MoneyCountingOption: this.regularOffSet,
    CountViewing: this.regularOffSet,
    VendingDisplay: this.regularOffSet,
    CoinPaymentEnable: this.regularOffSet,
    DebitCardPaymentProtocol: this.regularOffSet,
    AutoClearEscrowEnable: this.regularOffSet,
    CoinSlideEnable: this.regularOffSet,
    hasValue: true
  }

  constructor(
    private fb: FormBuilder,
    private _flDryerModelServices: FlDryerModelServices
  ) { }

  ngOnInit() {
    this.getControls();
    this.FlDryerSettings = this._flDryerModelServices.getSetupModels();
    if (!this.FlDryerSettings) {
      this.FlDryerSettings = this._flDryerModelServices.getFlDryerSetupSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    } else if (this.FlDryerSettings && !this.FlDryerSettings.hasValue) {
      this.FlDryerSettings = this._flDryerModelServices.getFlDryerSetupSettings(localStorage.getItem("ProductType"));
      this.setToDefault();
    }
  }
  getControls() {
    this.FlDryerSetupForm = this.fb.group({
      cycleCounter: ["", Validators.required],
      moneyCounter: ["", Validators.required],
      vaultViewingOption: ["", Validators.required],
      addCoinOption: ["", Validators.required],
      coinOption: ["", Validators.required],
      debitOption: ["", Validators.required],
      clearEscrowOption: ["", Validators.required],
      coinSlideEnable: ["", Validators.required],
    });
  }

  setToDefault() {
    this.FlDryerSettings.CycleCountingEnable.value = this.FlDryerSettings.CycleCountingEnable.Default;
    this.FlDryerSettings.MoneyCountingOption.value = this.FlDryerSettings.MoneyCountingOption.Default;
    this.FlDryerSettings.CountViewing.value = this.FlDryerSettings.CountViewing.Default;
    this.FlDryerSettings.VendingDisplay.value = this.FlDryerSettings.VendingDisplay.Default;
    this.FlDryerSettings.CoinPaymentEnable.value = this.FlDryerSettings.CoinPaymentEnable.Default;
    this.FlDryerSettings.DebitCardPaymentProtocol.value = this.FlDryerSettings.DebitCardPaymentProtocol.Default;
    this.FlDryerSettings.AutoClearEscrowEnable.value = this.FlDryerSettings.AutoClearEscrowEnable.Default;
    this.FlDryerSettings.CoinSlideEnable.value = this.FlDryerSettings.CoinSlideEnable.Default;
    this.FlDryerSettings.hasValue = true;
  }


  returnResult(_value: any) {
    const value = _value;
    return value;
  }

  ngOnDestroy() {
    const isModel = this._flDryerModelServices.modelChanged();
    if (!isModel) {
      this._flDryerModelServices.setSetupModels(this.FlDryerSettings);
    }
  }


}