import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FlWasherModelServices } from "src/app/services/FlWasherModel.service";
import { ModelServices } from "src/app/services/ModelServices";

@Component({
  selector: "app-fl-washer-cyclecmobination",
  templateUrl: "./fl-washer-cyclecmobination.component.html",
  styleUrls: ["./fl-washer-cyclecmobination.component.scss"],
})
export class FlWasherCyclecmobinationComponent implements OnInit, AfterViewInit {
  options: any;
  modelNumber: string;
  isdisabled: boolean;
  myOptions = {
    placement: "top",
    "show-delay": "500",
    "tooltip-class": "tooltip",
  };
  selectedIndex: number = null;
  SetOptions: any;
  constructor(
    private modelServices: ModelServices,
    private cdr: ChangeDetectorRef,
    private _FlWasherModelServices: FlWasherModelServices
  ) {}

  ngOnInit() {
    this.modelServices.flWasherSelectedModel.subscribe((modelNumber: any) => {
     this.modelNumber = modelNumber;
      this.selectedIndex = 0;
      this.modelServices.FlWasherCycleData = 
      JSON.parse(JSON.stringify(this._FlWasherModelServices.getWasherCycleList(this.modelNumber)))
      this.options = this.modelServices.FlWasherCycleData.CycleName;
           this.SetOptions = this.options;
           this._FlWasherModelServices.prevWasherSelectedCycle = this.SetOptions[0].tvalue;
           this._FlWasherModelServices.selectedWasherCycleSettings = {
            cycleValue: this.SetOptions[0].tvalue,
            title: this.SetOptions[0].title,
            temperature: this.modelServices.FlWasherCycleData.Temperature
          }
    });
  }
  
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  setIndex(index: number, option: any) {
    this._FlWasherModelServices.selectedWasherCycle.next({
      cycleValue: option.tvalue,
      title: option.title,
      temperature: this.modelServices.FlWasherCycleData.Temperature,
      prevSelectedCycle: this._FlWasherModelServices.prevWasherSelectedCycle,
    });
    this.selectedIndex = index;
  }
}
