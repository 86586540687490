import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import jquery from "jquery";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { CommonServices } from "src/app/services/CommonServices";
import { FlDryerModelServices } from "src/app/services/FlDryerModel.service";
import { FlWasherModelServices } from "src/app/services/FlWasherModel.service";
import { IModelList, IMultiloadModelList, ModelServices } from "src/app/services/ModelServices";
import { TlDryerModelServices } from "src/app/services/TlDryerModel.service";
import { TlWasherModelServices } from "src/app/services/TlWasherModel.service";
import { VenusModelServices } from "src/app/services/VenusModel.service";
import { MenuNames } from "../../enums/enum";
import { ICommonModel } from "../../models/common";
import { IParentMenu, ISubMenu } from "./modellist.model";

@Component({
  selector: "app-modellist",
  templateUrl: "./modellist.component.html",
  styleUrls: ["./modellist.component.scss"],
})
export class ModellistComponent implements OnInit {
  @ViewChild('divToScroll', { read: ElementRef, static: false }) divToScroll: ElementRef;
  myControl = new FormControl();
  mNumber: any;
  // modelServices = new ModelServices();
  venusModelList: IModelList;
  tlDryerModelList: IModelList;
  tlWasherModelList: IModelList;
  FlDryerModelList: IModelList;
  FlWasherModelList: IModelList;
  multiLoadModelList: IMultiloadModelList = {
    ModelName: '',
    ModelType: '',
    Models: [],
    ProductType: '',
    Weight: ''
  };
  filteredOptions: Observable<string[]>;
  datanew: any = [];
  isdisabled: boolean = false;
  isdisabledTwo: boolean = true;
  ModelName: any;
  ModelType: any;
  ProductType: any;
  Weight: any;

  MultiLoad: string[] = [];
  searchText: string;
  parentMenu: IParentMenu[] = [{
    id: '01',
    parentText: MenuNames.MultiLoad,
    showSubmenu: false,
    subMenuText: []
  }, {
    id: '02',
    parentText: MenuNames.MlgMle,
    showSubmenu: false,
    subMenuText: []
  }, {
    id: '03',
    parentText: MenuNames.TLDryer,
    showSubmenu: false,
    subMenuText: []
  }, {
    id: '04',
    parentText: MenuNames.TLWasher,
    showSubmenu: false,
    subMenuText: []
  },
    // {
    //   id: '05',
    //   parentText: MenuNames.FLDryer,
    //   showSubmenu: false,
    //   subMenuText: []
    // }, {
    //   id: '06',
    //   parentText: MenuNames.FLWasher,
    //   showSubmenu: false,
    //   subMenuText: []
    // }
  ];

  options: string[];

  constructor(
    private router: Router,
    private _venusModelServices: VenusModelServices,
    private _TlDryerModelServices: TlDryerModelServices,
    private modelServices: ModelServices,
    private commonServices: CommonServices,
    private _TlWasherModelServices: TlWasherModelServices,
    private _FlDryerModelServices: FlDryerModelServices,
    private _FlWasherModelServices: FlWasherModelServices
  ) {
    const $: JQueryStatic = jquery;
    this.commonServices.getDataUsb().subscribe((datanew) => {
      this.datanew = datanew;
    });
  }

  ngOnInit() {
    this.options = this.modelServices.getModelList().Models; //this.models.Models;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    this.commonServices.receivedApiVal().subscribe((d) => {
      this.isdisabled = d;
    });
    this.commonServices.receivedApiValTwo().subscribe((c) => {
      this.isdisabledTwo = c;
    });
    this.multiLoadModelList = this.modelServices.getModelList();
    this.venusModelList = this._venusModelServices.getVenusModelList();
    this.tlDryerModelList = this._TlDryerModelServices.getTlDryerModelList();
    this.tlWasherModelList = this._TlWasherModelServices.getTlWasherModelList();
    this.FlDryerModelList = this._FlDryerModelServices.getFlDryerModelList();
    this.FlWasherModelList = this._FlWasherModelServices.getFlWasherModelList();
    this.commonServices.closeAllMenu.subscribe((val: any) => {
      setTimeout(() => {
        this.searchText = '';
      }, 50);
      this.parentMenu.map(item => {
        item.subMenuText = [];
        item.showSubmenu = false;
      });
    });
    this.readBinaryConfig();
  }

  readBinaryConfig() {
    this.commonServices.selectModel.subscribe((commonModel: ICommonModel) => {
      if (commonModel.FileType == 'CSP') {
        this.expandMenu(this.parentMenu[1], commonModel);
      } else if (commonModel.FileType == 'TLDryer') {
        this.expandMenu(this.parentMenu[2], commonModel);
      } else if (commonModel.FileType == 'TLWasher') {
        this.expandMenu(this.parentMenu[3], commonModel);
      } else if (commonModel.FileType == 'FLDryer') {
        this.expandMenu(this.parentMenu[4], commonModel);
      } else if (commonModel.FileType == 'FLWasher') {
        this.expandMenu(this.parentMenu[5], commonModel);
      } else {
        this.expandMenu(this.parentMenu[0], commonModel);
      }
    });
  }

  ngAfterViewInit() {
    this.commonServices.sendLeftMenuHide();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  addActiveClass(option): void {
    $("span.addActive").removeClass("addActive");
    $("#" + option).addClass("addActive");
  }

  addActiveClassnew(datanew) {
    $("span.addActive").removeClass("addActive");
    $("#" + datanew.id).addClass("addActive");
  }

  addMenu(subMenuText: string): string[] {
    switch (subMenuText) {
      case this.parentMenu[0].parentText:
        return this.multiLoadModelList.Models;
      case this.parentMenu[1].parentText:
        return this.venusModelList.Models;
      case this.parentMenu[2].parentText:
        return this.tlDryerModelList.Models;
      case this.parentMenu[3].parentText:
        return this.tlWasherModelList.Models;
      case this.parentMenu[4].parentText:
        return this.FlDryerModelList.Models;
      case this.parentMenu[5].parentText:
        return this.FlWasherModelList.Models;
    }
  }

  searchModel(searchValue: any) {
    var filter, ul, li, a, i, txtValue;
    filter = searchValue.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }


  flushModelService() {
    this.modelServices.modelSetup = undefined;

    this.modelServices.vendingSettings = undefined;
    this.modelServices.isCycleWashCycleComponentTobePush = false;
    this.modelServices.isPush();
    this.modelServices.isHMIComponentTobePush = false;
    this.modelServices.isPushHMI();
    this.modelServices.isCycleVendingSettingsTobePush = false;
    if (this.modelServices.cycleWashCycleComponentReact != null) {
      this.modelServices.cycleWashCycleComponentReact.drainForm = "";
      this.modelServices.cycleWashCycleComponentReact.reclaimForm = "";
      this.modelServices.cycleWashCycleComponentReact.washForm = "";
      this.modelServices.cycleWashCycleComponentReact = null;
    }
    this.modelServices.phaseList = null;
    localStorage.removeItem("lastTabId")
    localStorage.removeItem("arrMachineConfigSettings");
    localStorage.removeItem("arrHmiDisplaySettings");
    localStorage.removeItem("arrCycleSettings");
    localStorage.removeItem("arrVendingSettingDetail");
    this.commonServices.clearSubscription();
    this.modelServices.userAddedCycles.forEach((element) => {
      if (
        this.modelServices.modelCycleDataCopy.hasOwnProperty(element.tvalue)
      ) {
        delete this.modelServices.modelCycleDataCopy[element.tvalue];
        delete this.modelServices.selectedCycleListObj[element.tvalue];
      }
      let index = this.modelServices.cycleData.findIndex(function (wizard) {
        return wizard.tvalue === element.tvalue;
      });
      if (index == -1) {
      } else {
        this.modelServices.cycleData.splice(index, 1);
      }
    });

    this.modelServices.userAddedCycles.length = 0;
  }

  selectModel(subMenu: ISubMenu, menu: IParentMenu, isBinaryCall?: boolean, commonModel?: ICommonModel) {
    this.commonServices.isSetToDefaultHide = false;
    menu.subMenuText.map(item => {
      if (subMenu.subMenuText == item.subMenuText) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
    })
    localStorage.setItem("SelectedMenu", menu.parentText);
    const modelNumber = subMenu.subMenuText;
    let isFLDryerReq = false;
    let isTLDryerReq = false;
    if (this.commonServices.isModelSerial) {
      let arr = [];
      arr.push(modelNumber, menu.parentText)
      this.modelServices.selectedModelSerial.next(arr);
      return;
    }
    this.commonServices.generateButtonHide.next(false);
    this.commonServices.paraHeader.next("false");
    if (menu.parentText === MenuNames.TLWasher) {
      this.modelServices.tlWasherSelectedModel.next(modelNumber)
    }
    if (menu.parentText === MenuNames.FLWasher) {
      this.modelServices.flWasherSelectedModel.next(modelNumber)
    }
    else {
      this.modelServices.selectedModel.next(modelNumber);
    }
    this.flushModelService();
    this.commonServices.defaultScreen.next("default");
    this.mNumber = modelNumber;
    this.ModelName = modelNumber;
    this.ModelType = this.modelServices.getModelType(modelNumber);
    const modelVal = this.mNumber.substr(4, 4);
    if (menu.parentText === MenuNames.FLDryer && (modelVal === "258J" || modelVal === "268J")) {
      isFLDryerReq = true;
    }
    if (menu.parentText === MenuNames.FLWasher && (modelVal === "258J" || modelVal === "268J")) {
      isFLDryerReq = true;
    }
    if (menu.parentText === MenuNames.TLWasher && modelNumber == 'MAT20PDFGW0') {
      isTLDryerReq = true;
    }
    this.ProductType = this.modelServices.getProductType(modelNumber, isFLDryerReq, isTLDryerReq);
    this.Weight = this.modelServices.getModelWeight(modelNumber);
    this.commonServices.sendTopFeaturesDisable(modelNumber);
    localStorage.setItem("isShowModel", "1");
    localStorage.setItem("leftMenuHide", "1");
    localStorage.setItem("modelNumber", modelNumber);
    localStorage.setItem("ProductType", this.ProductType);
    localStorage.setItem("ModelType", this.ModelType);
    localStorage.setItem("Weight", this.Weight);
    if (menu.parentText === MenuNames.MultiLoad) {
      this.navigateTabs(isBinaryCall);
    } else if (menu.parentText === MenuNames.MlgMle) {
      this._venusModelServices.clearSetupModels();
      this._venusModelServices.clearVenusCycleModels();
      if (isBinaryCall) {
        const tab = {
          tab: 'nav-setup-tab',
          type: MenuNames.MlgMle
        }
        this.commonServices.selectTab.next(tab);
        this.commonServices.setBinaryCSP.next(commonModel);
      }
    } else if (menu.parentText === MenuNames.TLDryer) {
      this._TlDryerModelServices.clearSetupModels();
      this._TlDryerModelServices.clearVenusCycleModels();
      if (isBinaryCall) {
        const tab = {
          tab: 'nav-setup-tab',
          type: MenuNames.TLDryer
        }
        this.commonServices.selectTab.next(tab);
        this.commonServices.setBinaryTlDryer.next(commonModel);
      }
    } else if (menu.parentText === MenuNames.TLWasher) {
      this._TlWasherModelServices.clearSetupModels();
      this._TlWasherModelServices.clearVenusCycleModels();
      this._TlWasherModelServices.clearCycleSettingModels(modelNumber, this.ProductType);
      if (isBinaryCall) {
        const tab = {
          tab: 'nav-setup-tab',
          type: MenuNames.TLWasher
        }
        this.commonServices.selectTab.next(tab);
        this.commonServices.setBinaryTlWasher.next(commonModel);
      }
    } else if (menu.parentText === MenuNames.FLDryer) {
      this._FlDryerModelServices.clearSetupModels();
      this._FlDryerModelServices.clearCycleSettingModels();
      if (isBinaryCall) {
        const tab = {
          tab: 'nav-setup-tab',
          type: MenuNames.FLDryer
        }
        this.commonServices.selectTab.next(tab);
        this.commonServices.setBinaryFlDryer.next(commonModel);
      }
    } else if (menu.parentText === MenuNames.FLWasher) {
      this._FlWasherModelServices.clearSetupModels();
      this._FlWasherModelServices.clearFlWasherCycleVendingModels();
      this._FlWasherModelServices.clearCycleSettingModels(modelNumber, this.ProductType);
      if (isBinaryCall) {
        const tab = {
          tab: 'nav-setup-tab',
          type: MenuNames.FLWasher
        }
        this.commonServices.selectTab.next(tab);
        this.commonServices.setBinaryFlWasher.next(commonModel);
      }
    }
    this.addNovoClassOnBox();
    setTimeout(() => {
      var elem = document.getElementById(subMenu.subMenuText);
      elem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 50);
  }

  addNovoClassOnBox() {
    if (localStorage.getItem("ProductType") === "PN") {
      document.getElementById("box4").classList.add("vending_disable");
      document.getElementById("nav-setup-tab").classList.remove("active");
      document.getElementById("nav-HMI-tab").classList.remove("active");
      document.getElementById("nav-cycle-tab").classList.remove("active");
      document.getElementById("nav-vending-tab").classList.remove("active");
    } else {
      document.getElementById("box4").classList.remove("vending_disable");
      document.getElementById("nav-setup-tab").classList.remove("active");
      document.getElementById("nav-HMI-tab").classList.remove("active");
      document.getElementById("nav-cycle-tab").classList.remove("active");
      document.getElementById("nav-vending-tab").classList.remove("active");
    }
  }

  navigateTabs(isBinaryCall?: boolean) {
    setTimeout(() => {
      this.router.navigate(['/setup']);
      setTimeout(() => {
        this.router.navigate(['/Hmi-setup']);
        setTimeout(() => {
          this.router.navigate(['/cycle-setting']);
          this.commonServices.defaultCycleNumber.Max = '16';
          if (localStorage.getItem("ProductType") !== "PN") {
            setTimeout(() => {
              this.commonServices.defaultCycleNumber.Max = '5';
              this.router.navigate(['/vending-setting']);
            }, 2)
          }
          setTimeout(() => {
            this.router.navigate(['/']);
            localStorage.setItem("isShowModel", "0");
            if (isBinaryCall) {
              const tab = {
                tab: 'nav-setup-tab',
                type: MenuNames.MultiLoad
              }
              this.commonServices.selectTab.next(tab)
            }
          }, 2);
        }, 2);
      }, 2);
    }, 2);
  }

  expandMenu(node: IParentMenu, commonModel?: ICommonModel) {
    this.commonServices.sendTopFeaturesDisable('');
    this.commonServices.defaultScreen.next("default");
    this.commonServices.generateButtonHide.next(true);
    this.router.navigate(['/']);
    node.showSubmenu = !node.showSubmenu;
    if (node.showSubmenu) {
      this.parentMenu.map(item => {
        if (item.parentText === node.parentText) {
          const subMenuList = this.addMenu(node.parentText);
          subMenuList.map(subMenu => {
            node.subMenuText.push({
              isActive: false,
              subMenuText: subMenu
            })
          })
          if (node.subMenuText.length < 1) {
            node.showSubmenu = false;
          } else {
            node.showSubmenu = true;
          }
        }
        else {
          item.subMenuText = [];
          item.showSubmenu = false;
        }
      });
    } else {
      node.subMenuText = [];
    }
    // Binary Config
    if (commonModel && commonModel.ModelNumber) {
      const subMenu: ISubMenu = {
        isActive: false,
        subMenuText: commonModel.ModelNumber
      }
      this.selectModel(subMenu, node, true, commonModel);
    }
  }

}
