export class Cycle {
    cycleName: string;
    cycleIcon: string;
    title: string;
    cycleData: any;
    cycleType: any;
    drains: number;
    price?: number;
    machineWaterLevelType?: number;
    selectedModelNumberValidation?: any;
    modelWeight?: number;
    productType?: string;
    product?: string;
    extra?: string;
    soil?: string;
    pass?: string;
    regCents?: number;
    regDollar?: number;
    spCents?: number;
    spDollar?: number;
}

export class phaseDetail {
    phase: string;
    value: number;
    tumble: number;
    waterlevel: number;
    temprature: number;
    dispenser1: string;
    dispenser2: string;
    Extchemtype: string;
    Extchemport: string;
    Extchemtime: string;
    Motorspeed: string;
    Motoron: number;
    Motoroff: number;
    Recirc: string;
    Draintime: number;
    Drainvalve: number;
    drainSpeed: number;
    tumbleSpeed: string;

}

export class CycleValidation {
    drainSpeeedMin: number;
    drainSpeedMax: number;
    productType: string;
    product: string;
    vendedDrainSpeed?: any;
    tumbleTimeMin: number;
    tumbleTimeMax: number;
}

export class model {
    cycleName: string;
    cycleSelected: string;
    cycleDescription: string;
}